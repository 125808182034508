import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavService } from 'src/app/core/services/nav.service';
import { RestaurantService } from 'src/app/core/services/restaurant.service';

@Component({
  selector: 'app-follow-order',
  templateUrl: './follow-order.page.html',
  styleUrls: ['./follow-order.page.scss'],
})
export class FollowOrderPage implements OnInit {

  order: any

  constructor(private modalCtrl: ModalController,
              private navService: NavService,
    ) { }

  ngOnInit() { }

  followOrder(){
    this.navService.push('/track', {
      'cid': this.order.clientID,
      'token': this.order.uuid,
      'caller': 'menu'
    });
    this.dismiss()
  }

  async dismiss() {
    await this.modalCtrl.dismiss({
      'dismissed': true
    })
  }

}
