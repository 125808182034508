import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-current-size-dialog',
  templateUrl: './current-size-dialog.page.html',
  styleUrls: ['./current-size-dialog.page.scss'],
})
export class CurrentSizeDialogPage implements OnInit {

  currentSize: any

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  async dismiss() {
    await this.modalCtrl.dismiss({
      'dismissed': true
    })
  }

}
