import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MessageErrorOrderDialogPageRoutingModule } from './message-error-order-dialog-routing.module';

import { MessageErrorOrderDialogPage } from './message-error-order-dialog.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    MessageErrorOrderDialogPageRoutingModule
  ],
  declarations: [MessageErrorOrderDialogPage]
})
export class MessageErrorOrderDialogPageModule {}
