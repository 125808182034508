import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ExtraPageRoutingModule } from './extra-routing.module';

import { ExtraPage } from './extra.page';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { ValidatorsModule } from 'src/app/core/validators/validators.module.';
import { MpImgPageModule } from '../image-tag/image-tag.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ExtraPageRoutingModule,
    PipesModule,
    TranslateModule,
    ValidatorsModule,
    MpImgPageModule
  ],
  declarations: [ExtraPage]
})
export class ExtraPageModule {}
