<ion-header class="sticky">
	<ion-toolbar color="danger">
		<ion-buttons slot="start">
			<ion-button (click)="back()">
				<ion-icon name="chevron-back-outline"></ion-icon>
			</ion-button>
        </ion-buttons>
        <ion-row>
            <ion-col class="ion-text-center">
                <ion-label class="ion-text-wrap">{{currentSize.name}}</ion-label>
            </ion-col>
        </ion-row>
	</ion-toolbar>
</ion-header>

<ion-content >
	<ion-toolbar>
        <ion-item *ngIf="currentSize.priceBehavior != 'incremental'">
            <ion-label class="ion-text-wrap">
                <small>
                    <ion-row>
                        <ion-col class="ion-text-center">
                            *<span translate>{{currentSize.priceBehavior == 'average' ? 'Total Price is calculated by average between flavor prices' : 'Total Price is calculated by most expensive flavor'}}</span>
                        </ion-col>
                    </ion-row>
                </small>
            </ion-label>
        </ion-item>

		<span *ngFor="let type of extraTypes" id="{{type}}">
			<ion-list *ngIf="(currentSize[type] || currentSize.items[type].length > 0) && extras[type].step <= currentStep">
				<ion-radio-group [(ngModel)]="selectedExtraID[type]">
					<ion-list-header>
                        <ion-grid>
                            <ion-row>
                                <ion-col class="ion-no-padding">
                                    <ion-title mode="md" class="p-2 ion-text-center"><b >{{extras[type].name ? extras[type].name : (type | translate)}}</b></ion-title>
                                </ion-col>                                    
                            </ion-row>

                            <ion-row>
                                <ion-col class="ion-no-padding ion-text-center">
                                    <small class="p-2" [innerHTML]="getSafeHtml(extras[type].description)"> </small>
                                </ion-col>                                    
                            </ion-row>
                        </ion-grid>
					</ion-list-header>

					<span *ngFor="let option of extras[type].options; let index = index;">
						<ion-item *ngIf="option.available">
							<ion-radio data-cy="crustDoughRadio" color="danger" (click)="onSelectExtraRadio(type, index)" [attr.value]="option.id"></ion-radio>

							<ion-grid class="ml-2">
								<ion-row>
                                    <ion-label class="text-wrap">
                                        {{option.name}}
                                        <br />
                                        <ion-label *ngIf="option.description" class="text-wrap" style="line-height: 1;">
                                            <small [innerHTML]="getSafeHtml(option.description)"> </small>
                                        </ion-label>
                                    </ion-label>                                    

								</ion-row>
								<ion-row *ngIf="option.price > 0">
									<ion-label class="price-label">
										<b> {{option.price | multiCurrency}} </b>
									</ion-label>
								</ion-row>
							</ion-grid>
						</ion-item>
					</span>
				</ion-radio-group>
			</ion-list>
        </span>
        
        <div [hidden]="pizzaTemplate.step > currentStep">

            <span id="flavorQuantities" [class.mt-2]="extras.crust || extras.dough" *ngIf="currentSize.priceBehavior == 'incremental' && quantityOptions.length > 1">
                <ion-list-header>
                    <ion-title class="p-2 ion-text-center"><b translate>How many flavors?</b></ion-title>
                </ion-list-header>            
                <ion-row>
                    <ion-col *ngFor="let quantity of quantityOptions;">
                        <ion-button 
                            data-cy="btnFlavorsQuantity" fill="{{pizzaTemplate.maxFlavors == quantity ? 'solid' : 'outline'}}" color="danger"  mode="ios"
                            size="{{flavorCategories.length % 3 == 0 ?  '4' : '6'}}" expand="block" style="font-weight: 600;"
                            (click)="changeFlavorsQuantity(quantity)">{{quantity}}</ion-button>
                    </ion-col>
                </ion-row>
            </span>
    
            <span id="flavorCategories" [class.mt-2]="extras.crust || extras.dough"
                [class.invisible]="(quantityOptions.length > 1 && !pizzaTemplate.maxFlavors && currentSize.priceBehavior == 'incremental')">
                <ion-list-header style="padding-left: 0px;">
                    <ion-grid>
                        <ion-row>
                            <ion-title class="p-2 ion-text-center"><b translate>Flavors</b></ion-title>
                        </ion-row>
    
                        <ion-item class="ion-text-center" lines="full" *ngIf="currentSize.priceBehavior != 'incremental'">
                            <ion-label class="ion-text-wrap">
                                <small *ngIf="pizzaTemplate.maxFlavors == 1" translate>Choose the Flavor</small>
                                <small *ngIf="pizzaTemplate.maxFlavors > 1">{{ 'PizzaMaxFlavors' | translate: {maxFlavors: currentSize.maxFlavors} }}</small>
                            </ion-label>
                        </ion-item>
                    </ion-grid>
                </ion-list-header>
                <ion-row>
                    <ion-col *ngFor="let category of flavorCategories; let index = index;" size="12">
                        <ion-button color="danger" mode="ios" fill="{{selectedFlavorCategory == index ? 'solid' : 'outline'}}" expand="block" style="font-weight: 600;"
                            data-cy="flavorCategories" (click)="onSelectFlavorCategorySegment(index)">{{category.name}}</ion-button>
                    </ion-col>
                </ion-row>
                
                <ion-row *ngIf="this.flavorCategories[selectedFlavorCategory].description">
                    <ion-col>
                        <ion-item>
                            <ion-label class="ion-text-wrap ion-text-center">
                                <b [innerHTML]="getSafeHtml(this.flavorCategories[selectedFlavorCategory].description)"></b>
                            </ion-label>
                        </ion-item>                
                    </ion-col>
                </ion-row>

                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-label position="floating" translate>
                                <ion-icon name="search-outline"></ion-icon> Search
                            </ion-label>
                            <ion-input #ionicInput enterkeyhint="search" type="search" inputmode="search" debounce="500" [(ngModel)]="searchString" (ionChange)="searchFlavor($event.target.value)" ></ion-input>
                        </ion-item>                
                    </ion-col>
                </ion-row>
    
                <ion-list id="flavors">
                    <span *ngFor="let flavor of flavorsToShow; let index = index;">
                        <ion-item data-cy="flavorItem" *ngIf="flavor.available" (click)="firstIncrement(flavor)" [color]="flavorQuantity(flavor) > 0 ? 'danger' : ''" class="ion-activatable" style="position: relative;">
                            <ion-ripple-effect></ion-ripple-effect>
                            <ion-label>
                                <ion-row>
                                    <ion-col col="9"  *ngIf="flavor.image" class="flavor-image">
                                        <mp-img imgURL="{{imgURL}}/flavors/{{flavor.image}}/thumb_{{flavor.image}}.jpg" (click)="openViewer(flavor); $event.stopPropagation();"></mp-img>
                                    </ion-col>

                                    <ion-col col="9" class="line-break ion-align-self-center title-description-max-width">
                                        <b>{{flavor.name}}</b>
                                        <br>
                                        <small [innerHTML]="getSafeHtml(flavor.description)"></small>
                                        <span *ngIf="flavor.possiblePrices[pizzaTemplate.maxFlavors] > 0">
                                            <br>
                                            <b [ngClass]="{'flavor-price-active': flavorQuantity(flavor) > 0, 'flavor-price-label': flavorQuantity(flavor) <= 0}">
                                                {{currentSize.priceBehavior == 'incremental' ? '+ ' : ''}}{{flavor.possiblePrices[pizzaTemplate.maxFlavors] | multiCurrency}}
                                            </b>
                                        </span>
                                    </ion-col>
        
                                    <ion-col class="ion-align-self-center" size="3">
                                        <ion-icon data-cy="decrementFlavor" name="remove-outline" class="quantity-button" style="font-size: 25px;" [class.white-quantity-button]="flavorQuantity(flavor) > 0"
                                            [class.invisible]="flavorQuantity(flavor) == 0" (click)="decrementFlavor(flavor); $event.stopPropagation();">
                                        </ion-icon>
                                        <span class="quantity-value"
                                            [class.invisible]="flavorQuantity(flavor) == 0">{{flavorQuantity(flavor)}}</span>
                                        <ion-icon data-cy="incrementFlavor" name="add-outline" class="quantity-button" style="font-size: 25px;" [class.white-quantity-button]="flavorQuantity(flavor) > 0"
                                            (click)="incrementFlavor(flavor)">
                                        </ion-icon>
                                    </ion-col>
                                </ion-row>
                            </ion-label>
                        </ion-item>
                    </span>
                </ion-list>
            </span>
        </div>

	</ion-toolbar>
</ion-content>

<pizza-footer *ngIf="pizzaTemplate" [size]="pizzaTemplate" [showTotal]="!isCombo"></pizza-footer>
