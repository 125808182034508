<div class="mt-4">
	<div class="order-again" translate>
		Order again
	</div>
	<div class="container">
		<div class="show-recommended-products-card--list">
			<ion-card 
				*ngFor="let product of recommendedProducts" 
				class="col-md-3 col-4 show-recommended-products-card--card"
				(click)="goToShoppingCart(product)"
			>
				<ion-label>
					<mp-img *ngIf="product.image" imgURL="{{imgURL}}/products/{{product.image}}/thumb_{{product.image}}.jpg"></mp-img>
				</ion-label>
				<p class="show-recommended-products-card--title text-lg mt-2">
					{{product.name}}
				</p>
				<h4 class="show-recommended-products-card--price">
					{{product.subTotal | multiCurrency}}
				</h4>
			</ion-card>
		</div>
	</div>
</div>
