<ion-list id="menu-list" style="position: relative">
	<ion-item-group *ngIf="showCategory(menu.featuredItems)">
		<h1 id="featuredItems" class="category-name">{{featuredItemsCategoryName}}</h1>
		<span *ngFor="let item of menu.featuredItems" style="position: relative">
			<card
				style="width: 100%"
				[item]="item"
				*ngIf="!item.unavailable && !item.unavailableByTemplate"
			></card>
		</span>
	</ion-item-group>

	<ion-item-group *ngIf="showCategory(menu.combos)">
		<h1 id="combos" class="category-name">{{comboCategoryName}}</h1>
		<span *ngFor="let item of menu.combos" style="position: relative">
			<card
				style="width: 100%"
				[item]="item"
				*ngIf="!item.unavailable && !item.unavailableByTemplate"
			></card>
		</span>
	</ion-item-group>

	<ion-item-group *ngIf="showCategory(menu.pizzas.sizes)">
		<h1 id="pizzas" class="category-name">{{pizzaCategoryName}}</h1>
		<span *ngFor="let item of menu.pizzas.sizes" style="position: relative">
			<card style="width: 100%" [item]="item" *ngIf="!item.unavailable && !item.unavailableByTemplate">
			</card>
		</span>
	</ion-item-group>

	<ion-item-group
        *ngFor="let item of menu.general"
		style="position: relative"
		id="{{item.name}}-{{item.id}}"
	>
		<div *ngIf="!item.unavailable && !item.unavailableByTemplate && showCategory(item.products)"  class="scroll-category">
			<h1 [id]="item.id" class="category-name">{{item.name}}</h1>
			<ion-label *ngIf="item.description" [innerHTML]="getSafeHtml(item.description)" class="text-wrap category-description"></ion-label>
			<span *ngFor="let prod of item.products" style="position: relative">
				<card
					style="width: 100%"
					[item]="prod"
					*ngIf="!prod.unavailable && !prod.unavailableByTemplate && prod.totalCalcType !== 'WEIGHT'"
				></card>
			</span>
		</div>
	</ion-item-group>
</ion-list>
