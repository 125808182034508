<ion-header>
	<ion-toolbar color="danger">
		<ion-buttons slot="start">
			<ion-button (click)="dismissModal()">
				<ion-icon name="chevron-back-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-title class="p-2" translate>Additional Toppings</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<ion-toolbar>

		<h3 class="p-2 m-2" translate>Do you want to add a topping to your pizza?</h3>
		<small style="text-align: center; display: block; font-weight: 800;" translate>Choose the flavor and select the desired additional. If you do not want to include additional items, click NEXT</small>
		<ion-row>
			<ion-col *ngFor="let flavor of flavors; let index = index;" size="12">
				<ion-button mode="ios" color="danger" fill="{{selectedFlavorIndex == index ? 'solid' : 'outline'}}" expand="block"
					(click)="onSelectFlavor(index)">{{flavor.name}}</ion-button>
			</ion-col>
		</ion-row>

		<ion-list *ngIf="selectedFlavorIndex >= 0">
			<span *ngFor="let option of additionalToppings; let index = index;">
				<ion-item *ngIf="option.available">
					<ion-checkbox  color="danger" slot="start" checked="{{checkTopping[option.id] ? true : false}}" (click)="onSelectTopping(index)"></ion-checkbox>

					<ion-grid>
						<ion-row>
							<ion-label>+ {{option.name}}</ion-label>
						</ion-row>
						<ion-row *ngIf="option.price > 0">
							<ion-label class="pizza-additional-price-label">
								<b> {{option.price | multiCurrency}} </b>
							</ion-label>
						</ion-row>
					</ion-grid>
				</ion-item>
			</span>
		</ion-list>
	</ion-toolbar>
</ion-content>

<pizza-footer [size]="size" [toppings]="true"></pizza-footer>