import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonRouterOutlet } from '@ionic/angular';
import { NavService } from 'src/app/core/services/nav.service';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.page.html',
	styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {

	@Input("root") root : Component
	// @Input("root") root : string;
	@ViewChild("modalOutlet") modalOutlet: IonRouterOutlet;

	constructor(
		private navService: NavService
	) { }

	ngOnInit() {
		const nav = document.querySelector('ion-nav');
		// nav.push(
		// this.navService.setTopOutlet(this.modalOutlet);
		// console.log(this.root);
		
		// this.navService.setRoot(this.root);
	}

}
