import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-coupon-dialog',
  templateUrl: './coupon-dialog.page.html',
  styleUrls: ['./coupon-dialog.page.scss'],
})
export class CouponDialogPage implements OnInit {

  header: string
  message: string
  value: any
  isSuccess: boolean
  isWarning: boolean

  constructor(public modalCtrl: ModalController,) { }

  ngOnInit() { }

  async dismiss() {
    await this.modalCtrl.dismiss({
      'dismissed': true,
    });
  }

}
