import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { LoadingSpinnerService } from './loadingSpinner.service';


@Injectable()
export class StartupService {
    private restaurantURL: String;

    constructor(private location: Location, private loadingSpinnerService: LoadingSpinnerService) {}

    getRestaurantURL() {
		this.loadingSpinnerService.present();
        this.restaurantURL = this.location.path()?.split('/')[1]?.split('?')[0];

        if(window.location.href.includes('#!')){
            let url_parts = window.location.href.split('/');
            this.restaurantURL = url_parts[url_parts.length - 1];
        }

        if(location.hostname === 'localhost'){
            return this.restaurantURL;
        }

        if(typeof this.restaurantURL === 'undefined'){
            // window.location.replace("https://multipedidos.com.br");
        }

        return this.restaurantURL;
    }
}