<div style="padding-top: 20px; font-size: 17px; font-family: revert;" translate>
  Select now:
</div>

<div class="text-wrap" style="font-weight: bold; font-size: 18px; font-family: revert; padding-top: 4px;" text-wrap>
  {{ currentSize.name }}
</div>

<div>
  <ion-button mode="ios" color="danger" expand="block"  (click)="dismiss()" style="font-weight: 900;" translate>
    <ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> OK
  </ion-button>
</div>