import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CvvCardPageRoutingModule } from './cvv-card-routing.module';

import { CvvCardPage } from './cvv-card.page';
import { TranslateModule } from '@ngx-translate/core';
import { ValidatorsModule } from 'src/app/core/validators/validators.module.';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CvvCardPageRoutingModule,
    PipesModule,
    TranslateModule,
    ReactiveFormsModule,
    ValidatorsModule,
  ],
  declarations: [CvvCardPage]
})
export class CvvCardPageModule {}
