import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { IonicModule, NavParams } from '@ionic/angular';

import { CoreModule, createTranslateLoader } from './core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptor/token.interceptor';
import { ShoppingCartPageModule } from './pages/shopping-cart/shopping-cart.module';
import { MenuPageModule } from './pages/menu/menu.module';
import { TabletPageModule } from './pages/menu/tablet/tablet.module';
import { CurrencyMaskModule } from "ng2-currency-mask"
import { PaymentPage } from './pages/payment/payment.page';
import { DeliveryPageModule } from './pages/delivery/delivery.module';
import {NgxMaskModule} from 'ngx-mask'
import { FormBuilder } from '@angular/forms';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';

import { PipesModule } from './shared/pipe/pipe.module';
import { NotesDialogPageModule } from './pages/shopping-cart/notes-dialog/notes-dialog.module';
import { NotesDialogPage } from './pages/shopping-cart/notes-dialog/notes-dialog.page';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MessageErrorOrderDialogPage } from './pages/payment/message-error-order-dialog/message-error-order-dialog.page';
import { DataGuardService } from './core/services/data-guard.service';
import { PushNotificationPageModule } from './pages/payment/push-notification/push-notification.module';
import { RedirectWhatsappPageModule } from './pages/payment/redirect-whatsapp/redirect-whatsapp.module';
import { FollowOrderPageModule } from './pages/payment/follow-order/follow-order.module';
import { CvvCardPage } from './pages/credit-cards/cvv-card/cvv-card.page';
import { CvvCardPageModule } from './pages/credit-cards/cvv-card/cvv-card.module';
import { TimeoutPage } from './pages/timeout/timeout.page';
import { TimeoutPageModule } from './pages/timeout/timeout.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot({scrollAssist: true, scrollPadding: true}),
    AppRoutingModule,
    IonicSelectableModule,

    CoreModule,

    MenuPageModule,
	TabletPageModule,
    ShoppingCartPageModule,
    DeliveryPageModule,
    CvvCardPageModule,
    TimeoutPageModule,
    NotesDialogPageModule,
    PushNotificationPageModule,

    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    NgxIonicImageViewerModule,
    RedirectWhatsappPageModule,
    FollowOrderPageModule,

  ],
  providers: [
    TranslateModule,
    PaymentPage,
    CurrencyPipe,
    FormBuilder,
    CvvCardPage,
    TimeoutPage,
    NotesDialogPage,
    PipesModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  exports: [
    TranslateModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
