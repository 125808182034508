import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';

import { idleTimeoutService } from './core/services/idleTimeout.service';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent } from '@angular/router';
import { CartRecoverService, RestaurantService } from './core';
import { Location } from '@angular/common';
import { LoadingSpinnerService } from './core/services/loadingSpinner.service';
import { NavService } from './core/services/nav.service';
import { TranslatePipe } from "@ngx-translate/core";
import { OrderService } from './core/services/order.service';
import { AfterPaymentService } from './core/services/afterPayment.service';
import { LocalStorageService } from './core/services/localStorage.service'
import { MenuSelector } from './core/services/menuSelector.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})

export class AppComponent {

	private restaurantUrl

	constructor(
				private idleService: idleTimeoutService,
				private restaurantService: RestaurantService,
				private router: Router,
				private location: Location,
				private loadingSpinner: LoadingSpinnerService,
				private navService: NavService,
				private activatedRoute: ActivatedRoute,
				public translatePipe: TranslatePipe,
                private afterPaymentService: AfterPaymentService,
                private orderService: OrderService,
                private localStorageService: LocalStorageService,
                private menuSelector: MenuSelector,
                private cartRecoverService: CartRecoverService,
	) {	}

	async ngOnInit() {	
		this.router.events.subscribe(
			(event:RouterEvent) => {
				if (event instanceof NavigationEnd) {
					if (this.activatedRoute.snapshot.queryParamMap['params']['preference_id'] && this.activatedRoute.snapshot.queryParamMap['params']['collection_status'] == "null")
                    this.localStorageService.setItem('external_reference_' + this.activatedRoute.snapshot.queryParamMap['params']['external_reference'], this.activatedRoute.snapshot.queryParamMap['params']['preference_id']);         
                        
					if(this.activatedRoute.snapshot.queryParamMap['params']['cid'] && this.activatedRoute.snapshot.queryParamMap['params']['oid']){
                        this.proceedAfterPayment(this.activatedRoute.snapshot.queryParamMap['params']['oid'], this.activatedRoute.snapshot.queryParamMap['params']['cid']);
                        return;
					}
				}
			}
		);
		
		this.restaurantUrl = this.restaurantService.restaurant ? this.restaurantService.restaurant.info.restaurant_url : "not-found";
		let currentRoute = this.location.path().split('/');
		if (this.menuSelector.isTabletLink()) {
			return this.navService.push('tablet').then(() => this.loadingSpinner.dismiss());
		}

		let routeTo = '/menu';
        let params = null;
        let token = null;
        let asModal = false;

        if(this.restaurantUrl && currentRoute.some(path => path.match(/^track|reset-password|rate-order.*$/))) {            
            currentRoute.splice(1,1)
            routeTo = currentRoute.join('/');
            token = currentRoute[1].split('?token=')[1];
		} else {
            token = this.restaurantService.restaurant ? this.localStorageService.getItem('lastOrderToken-'+this.restaurantService.restaurant.info.id) : null
            if (token) routeTo = 'rate-order';
        }

        let shouldRequestOrder = true;
        let isRatePage = routeTo.includes('rate-order');
        if (isRatePage) shouldRequestOrder = this.restaurantService.restaurant ? this.restaurantService.restaurant.info.review_settings.enabled : false;

        if (token && shouldRequestOrder && !currentRoute.some(path => path.includes('reset-password'))) {
            await this.orderService.getCachedOrderByUUID_forTracking(token).then((response) => {
                if (!response) routeTo = `/${this.menuSelector.menuRoute}`;

                params = {'order': response, 'asModal': asModal, 'caller': 'menu'}
                if (isRatePage) {
                    if (!response.askReview || response.ratingAvg)
                        params = null;
                    else 
                        params.asModal = !currentRoute.some(path => path.includes('rate-order'));
                }
            }).catch((error) => {
                console.error(error);
            });
        }        

        const cart = JSON.parse(localStorage.getItem(`cart-${this.restaurantService.restaurant.info.id}`));
		if (this.cartRecoverService.isRecover && cart?.items.length > 0)
			routeTo = '/shopping-cart';

		setTimeout(() => 
			this.navService.push(routeTo, params).then((event) => {
				this.loadingSpinner.dismiss();
			})
		, 500);
		this.idleService.initializeCounter();
	}
    
    proceedAfterPayment(orderID, clientID) {
        this.orderService.getOrderByOrderAndClientID(orderID, clientID, this.restaurantService.restaurant.info.id).then((response) => {
            this.afterPaymentService.open(response);
        }).catch((error) => {
            console.error(error);
        });
    }
}