<ion-header>
  <ion-toolbar color="danger">
      <div class="container-header">
        <ion-title class="text-toolbar" translate>Enter card CVV</ion-title>
      </div>
  </ion-toolbar>
</ion-header>

  <div class="center-div-input">
    <form [formGroup]="cvvForm">
      <input 
        class="inputChange" 
        enterkeyhint="done" 
        inputmode="tel" 
        type="text" 
        placeholder="Ex: 123" 
        formControlName="cvv"
        autocomplete="off"
      >
    </form>
  </div>
  
  <div class="button-confirm">
    <ion-button mode="ios" color="danger" expand="block"  (click)="checkCvv()" [disabled]="cvvForm.get('cvv').invalid">
      <span *ngIf="!checkingCVV" translate>CONFIRM</span>
			<ion-spinner *ngIf="checkingCVV" name="crescent"></ion-spinner>
    </ion-button>
  </div>