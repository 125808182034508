import { Injectable } from '@angular/core';

import { LocalStorageService } from './localStorage.service';
import { RestaurantService }   from './restaurant.service';

@Injectable({
    providedIn: 'root'
})
export class RestaurantLocalStorageService {

    constructor(
        private localStorageService: LocalStorageService,
        private restaurantService:   RestaurantService
    ) {}
    
    setItem(key: string, value: any): boolean {
        return this.localStorageService.setItem(`${key}-${this.restaurantService.restaurant.info.id}`, value);
    }

    getItem(key: string): any {
        let item = this.localStorageService.getItem(`${key}-${this.restaurantService.restaurant.info.id}`);

        if(item === 'null')
            return '';

        return item;
    }

    removeItem(key: string): boolean | void {
        return this.localStorageService.removeItem(`${key}-${this.restaurantService.restaurant.info.id}`);
    }
}