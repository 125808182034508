import { Injectable } from '@angular/core';

@Injectable()
export class MenuSelector {
    public menuRoute = 'menu';

    constructor() {}

	setType(type: 'menu' | 'tablet') {
		this.menuRoute = type;
		return this;
	}

    boot() {
        const type = window.location.pathname.split('/').find(path => path === 'tablet' || path === 'menu');

		if (type === 'tablet' || type === 'menu')
			this.menuRoute = type;
    }

	isTabletLink() {
		return this.menuRoute === 'tablet';
	}
}
