import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-picpay-link-dialog',
  templateUrl: './picpay-link-dialog.page.html',
  styleUrls: ['./picpay-link-dialog.page.scss'],
})
export class PicpayLinkDialogPage implements OnInit {

  @Input('linkPicpay') linkPicpay: string

  constructor(private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  openPicpay(){
    window.open(this.linkPicpay, '_blank')
    this.dismiss()
  }

  async dismiss() {
    await this.modalCtrl.dismiss({
      'dismissed': true
    })
  }

}
