import { Pipe, PipeTransform } from '@angular/core';
import { RestaurantService } from 'src/app/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'formatTime' })
export class FormatTimePipe implements PipeTransform {
	currentDate: Date;

    constructor(private restaurantService: RestaurantService, private datePipe: DatePipe) {
		this.currentDate = new Date();
	}

    transform(time): string {

		if(!time)
			return null;

		const [hours, minutes] = time.split(':');

		this.currentDate.setHours(hours);
		this.currentDate.setMinutes(minutes);

		const languages = [navigator.language, this.restaurantService.restaurant.info.language].filter(Boolean);
		const language = languages.shift();

		if (language.toLowerCase() === 'en' || language.toLowerCase() === 'en-us') {
			return this.datePipe.transform(this.currentDate, 'h:mm a');
		}

		return this.datePipe.transform(this.currentDate, 'HH:mm');
	}
}