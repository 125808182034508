<div class="modal-content">
	<form [formGroup]="convenixForm" >
		<ion-card style="box-shadow: none !important; margin-bottom: 0px;">
			<ion-grid style="padding-left: 10px;">
				<ion-row>
					<ion-col style="padding-bottom: 0px;">
						<span ><b translate>Total Payable</b></span>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col style="padding-left: 15px;">
						<h1 class="grand-total"><span class="bold-statements">{{grandTotal | multiCurrency }}</span></h1>
					</ion-col>
				</ion-row>

				<ion-row *ngIf="loaded" formGroupName="user">
					<ion-col size="6">
						<input 
							style="text-align: center;"
							class="form-control"
							debounce="500"
							placeholder="CPF"
							type="text"
							name="cpf"
							mask="000.000.000-00"
							autocomplete="off"
							inputmode="numeric"
							formControlName="cpf" [ngClass]="{'invalid-input': (cpf.invalid && (cpf.dirty || cpf.touched))}">
						<div *ngIf="(cpf.invalid && (cpf.dirty || cpf.touched))">
							<ion-text class="invalid-input-text">{{'Invalid ID Document' | translate}}</ion-text>
						</div>
					</ion-col>

					<ion-col>
						<ion-row>
							<ion-col style="padding-top: 0px;">
								<button class="button-text payment-choice-button__main-button payment-row" (click)="getConfirmationCode()" [disabled]="cpf.invalid" translate>
									Receber Código
								</button>	
							</ion-col>
						</ion-row>
					</ion-col>
					<p class="text-center" >
						O código de confirmação será enviado via SMS para o telefone cadastrado na conta Convenix
					</p>
				</ion-row>

				<ion-row formGroupName="user" [hidden]="!showConfirmationCode">
					<ion-col size="6">
						<input
							class="form-control"
							debounce="500"
							placeholder="Código de Confirmação"
							type="text"
							name="confirmationCode"
							autocomplete="off"
							formControlName="confirmationCode"
							[ngClass]="{'invalid-input': (confirmationCode.invalid && (confirmationCode.dirty || confirmationCode.touched))}"
						>
					</ion-col>

					<ion-col>
						<button class="button-text payment-choice-button__main-button payment-row" (click)="approveConfirmationCode()" [disabled]="confirmationCode.invalid" >
							Verificar código
						</button>	
					</ion-col>
				</ion-row>

				<ion-row *ngIf="convenixForm.valid">
					<ion-col>
						<button class="button-text payment-choice-button__main-button payment-row" (click)="dismiss()" [disabled]="convenixForm.invalid" translate>
							CONFIRM
						</button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-card>
	</form>
</div>