import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ShoppingCartService } from './shoppingCart.service';
import { NavService } from './nav.service';

@Injectable({
	providedIn: 'root'
})
export class CartGuardService implements CanActivate {

	constructor(
		private cart: ShoppingCartService,
        private navService: NavService,
	) { }

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {

		if (!this.cart.products || this.cart.products.length == 0) {
			this.navService.setRoot('');
			return false;
		}

		return true;
	}
}
