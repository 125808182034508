import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { CreditCardService } from 'src/app/core';
import { CreditCardDTO } from 'src/app/shared/model/dto/CreditCardDTO';

@Component({
  selector: 'app-cvv-card',
  templateUrl: './cvv-card.page.html',
  styleUrls: ['./cvv-card.page.scss'],
})
export class CvvCardPage implements OnInit {

  public creditCard: CreditCardDTO;
  public checkingCVV: boolean = false;
  public cvvForm = this.formBuilder.group({
    cvv: ['', [Validators.required]]
  });

  constructor(
    private creditCardService: CreditCardService,
    private modalCtrl: ModalController,
		private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
  }

  async checkCvv() {
    this.checkingCVV = true;

    const loading = await this.loadingController.create({
      message: this.translatePipe.transform('Checking credit card CVV...',)
    })
    
    await loading.present();

    this.creditCardService.checkCreditCardCVV(this.cvvForm.get('cvv').value).then(result => {
      this.checkingCVV = false;
      loading.dismiss();
      this.dismiss();
    }, error => {
      this.checkingCVV = false;
      loading.dismiss();
      this.dismiss(error);
    });
  }

	async dismiss(error = null) {
		await this.modalCtrl.dismiss({
      'dismissed': true,
			'error': error,
		});
	}
}
