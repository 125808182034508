import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Plugins } from '@capacitor/core';
import { AlertController, IonContent, ModalController } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { CreditCardService, RestaurantService } from 'src/app/core';
import { AfterPaymentService } from 'src/app/core/services/afterPayment.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Events } from 'src/app/core/services/events.service';
import { NavService } from 'src/app/core/services/nav.service';
import { OnesignalService } from 'src/app/core/services/onesignal.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ValidateCpfCnpj } from 'src/app/core/validators/forms/cpf-cnpj.validator';
import { ValidateEmail } from 'src/app/core/validators/forms/email.validator';
import { ValidateFullName } from 'src/app/core/validators/forms/fullname.validator';
import { MultiCurrencyPipe } from 'src/app/shared/pipe/multiCurrency.pipe';
import { AddCardPage } from '../credit-cards/add-card/add-card.page';
import { CvvCardPage } from '../credit-cards/cvv-card/cvv-card.page';
import { CardDialogPage } from './payment-methods-dialogs/card-dialog/card-dialog.page';
import { PagseguroDialogPage } from './payment-methods-dialogs/pagseguro-dialog/pagseguro-dialog.page';
import { PicpayDialogPage } from './payment-methods-dialogs/picpay-dialog/picpay-dialog.page';
import { MessageErrorOrderDialogPage } from './message-error-order-dialog/message-error-order-dialog.page';
import { PushNotificationPage } from './push-notification/push-notification.page';
import { ChangeDialogPage } from './payment-methods-dialogs/change-dialog/change-dialog.page';
import { CouponDialogPage } from './payment-methods-dialogs/coupon-dialog/coupon-dialog.page';
import { ConvenixDialogPage } from './payment-methods-dialogs/convenix-dialog/convenix-dialog.page';
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';
import { LocalStorageService } from "src/app/core/services/localStorage.service";
import { CoupomService } from 'src/app/core/services/coupom.service';
import { RestaurantLocalStorageService } from 'src/app/core/services/restaurantLocalStorage.service';
import { CartRecoverService } from 'src/app/core';
import { FacebookPixelService } from 'src/app/core/services/facebookPixel.service';
import * as uuid from 'uuid';

declare let fbq: any;
declare let gtag: any;

const { Clipboard } = Plugins;

@Component({
	selector: 'app-payment',
	templateUrl: './payment.page.html',
	styleUrls: ['./payment.page.scss'],
})
export class PaymentPage implements OnInit {

	@ViewChild(IonContent, {read: IonContent, static: false}) myContent: IonContent;
	public restaurant = this.restaurantService.restaurant
	public pixSettings = this.restaurant.info.pix_settings
	public saleChannels = this.restaurant.info.sale_channels
	public paymentMethods:any = [];
	public childrenPaymentMethods:any = [];
	
	public sendingOrder = false;

	paymentForm = this.formBuilder.group({
		coupon: this.formBuilder.group({
			id: [''],
			input: [''],
			name: [''],
			value: 0
		}),
		paymentMethod: ['', Validators.required],
		user: this.formBuilder.group({
			name: [this.orderService.order.user.name, [Validators.required, ValidateFullName]],
			cpf: [this.orderService.order.user.cpf, [Validators.required, ValidateCpfCnpj]],
			email: [this.orderService.order.user.email, [Validators.required, ValidateEmail]],
		}),
		cash: this.formBuilder.group({
			changeNeeded: [null, Validators.required],
			change: ['']
		}),
		card: this.formBuilder.group({
			name: [''],
			type: ['', Validators.required],
		}),
		creditCard: this.formBuilder.group({
			creditCardIsValid: ['', Validators.required],
			creditCardBrand: ['', Validators.required],
			creditCardNumber: ['', Validators.required],
			creditCardNickName: ['', Validators.required],
			creditCardUUID: [''],
			creditCardCustomer: [''],
			creditCardToken: [''],
			creditCardIsDefault: [''],
		}),
		convenix: this.formBuilder.group({
			confirmationCode: ['', Validators.required],
			saleID: ['', Validators.required],
		})
	});

	appliedPaymentSetting;
	chosenMethods = [];
	deliveryFee = 0;
	shoppingCarTotal = this.shoppingCartService.total();
	orderTotal = this.shoppingCarTotal;
	grandTotal = this.orderTotal;
	cashTransferMethodsSettings = [];

	private deliveryType: string = null;

	constructor(
		private afterPaymentService: AfterPaymentService,
		private alertController: AlertController,
		private authService: AuthenticationService,
		private coupomService: CoupomService,
		private creditCardService: CreditCardService,
		private events: Events,
		private formBuilder: FormBuilder,
		private keen: KeenTrackingService,
		private localStorageService: LocalStorageService,
		private modalController: ModalController,
		private multiCurrencyPipe: MultiCurrencyPipe,
		private navService: NavService,
		private onesignalService: OnesignalService,
		private orderService: OrderService,
		private restaurantLocalStorageService: RestaurantLocalStorageService,
		private restaurantService: RestaurantService,
		private shoppingCartService: ShoppingCartService,
		private translatePipe: TranslatePipe,
		private utils: UtilsService,
		private cartRecoverService: CartRecoverService,
		private facebookPixelService: FacebookPixelService
	) { }

	get user() { return this.paymentForm.get('user'); }
	get coupon() { return this.paymentForm.get('coupon').value; }
	get cash() { return this.paymentForm.get('cash').value; }
	get card() { return this.paymentForm.get('card').value }
	get email() { return this.paymentForm.get('user.email'); }
	get name() { return this.paymentForm.get('user.name'); }
	get cpf() { return this.paymentForm.get('user.cpf'); }
	get creditCard() { return this.paymentForm.get('creditCard').value; }

	ngOnInit() {
		if (!this.shoppingCartService.hasItems()) {
			this.navService.push('menu');
		}
		this.keen.event('Entered Payment Page');
		
		this.deliveryFee = this.parseDeliveryFee();
		this.orderTotal = this.orderTotal + this.deliveryFee;
		this.grandTotal = this.orderTotal;
		this.parseAvailableMethods();
		this.hasPushNotification();
		this.callPixelInitiateCheckoutEvent(this.orderService.order.user);
	}

	parseDeliveryFee() {
		if (this.orderService.order.details.deliveryType != 'delivery') return 0;

		if (this.orderService.order.details.discountType == 'flat_fee')
			return this.orderService.order.details.deliveryFeeDiscount 
			
		return this.orderService.order.details.deliveryFee - this.orderService.order.details.deliveryFeeDiscount;
	}

	async ionViewWillEnter() {		
		if (this.restaurantService.restaurant.info?.wallet_is_active)
			this.getCreditCard();
	}


	async getCreditCard() {
		let creditCard = this.creditCardService.creditCard;

		if(!creditCard) {
			await this.creditCardService.getCreditCards().then(result => {
				creditCard = this.creditCardService.creditCard;
			});
		};

		this.paymentForm.get('creditCard').patchValue({
			creditCardCustomer: creditCard?.creditCardCustomer,
			creditCardToken: creditCard?.creditCardToken,
			creditCardBrand: creditCard?.creditCardBrand,
			creditCardNumber: creditCard?.creditCardNumber,
			creditCardNickName: creditCard?.creditCardNickName,
			creditCardUUID: creditCard?.creditCardUUID,
			creditCardIsDefault: creditCard?.creditCardIsDefault,
		})
	}

	async parseAvailableMethods() {
		this.paymentMethods = [...this.parseOnlineMethods(), ...this.paymentMethods];

		this.paymentMethods = [...this.paymentMethods, ...this.parseInstantCashTransferMethods()];

		this.paymentMethods = [...this.paymentMethods, ...this.parseConvenix()];

		this.paymentMethods = [...this.parseOfflineMethods(), ...this.paymentMethods];

		this.paymentMethods = this.parsePaymentMethodsWithSaleChannels();
	}

	setDeliveryType() {
		let deliveryType = this.orderService.order.details.deliveryType;
		let schedule = this.orderService.order.details.schedule;

		this.deliveryType = schedule ? 'scheduled' : deliveryType === 'balcony' ? 'takeout' : deliveryType;
	}

	checkSalesChannelConfig(paymentMethod) {
		if (!this.checkHasSaleChannel(paymentMethod))
			return true;

		return this.saleChannels.some(_sc => (_sc.delivery || _sc.takeout || _sc.scheduled || _sc.table) && _sc.payment_type === paymentMethod && _sc[this.deliveryType]);
	}

	filterChildPaymentMethods(children) {
		return children.filter(child => {
			return this.checkSalesChannelConfig(child.value);
		});
	}

	filterPaymentMethods(paymentMethod) {
		return this.checkSalesChannelConfig(paymentMethod.value);
	}

	checkHasSaleChannel(paymentMethod) {
		return this.saleChannels.some(_sl => _sl.payment_type === paymentMethod);
	}

	parsePaymentMethodsWithSaleChannels() {
		this.setDeliveryType();

		return this.paymentMethods.filter(paymentMethod => {
			if (!('children' in paymentMethod)) {
				return this.filterPaymentMethods(paymentMethod);
			} else {
				const filteredChildren = this.filterChildPaymentMethods(paymentMethod.children);
				paymentMethod.children = filteredChildren;
				return filteredChildren.length > 0;
			}
		});
	}

	parseOnlineMethods() {
		let onlinePaymentMethods = [];

		let OnlinePayments = Object.keys(this.restaurant.info?.online_payment_integrations).filter(function(currentIntegration)
		{
			return !['pix', 'zelle', 'convenix', 'pixAsaas'].includes(currentIntegration);
		})

		let multipedidosPaymentEnabled = this.restaurantService.restaurant.info?.wallet_is_active;

		if (multipedidosPaymentEnabled)
			this.getCreditCard();
		
        if (OnlinePayments?.length == 0 && !multipedidosPaymentEnabled) return [];

		onlinePaymentMethods = OnlinePayments.map(method => {
			return {
				name: this.restaurant.info?.online_payment_integrations[method],
				value: method,
				img: this.restaurant.info?.online_payment_integrations[method] + '-icon',
			}
		});

		let inAppPayment = this.restaurant.info?.wallet_is_active;

		if(inAppPayment)
			onlinePaymentMethods.push({name: 'In-app payment', value: 'multipedidos@asaas', icon: 'card-outline'})

		if (onlinePaymentMethods.length == 1) {

			if(inAppPayment) onlinePaymentMethods[0].name = 'Online Payment';
			
			return onlinePaymentMethods; 
		}


		return [{name: 'Online Payment', value: 'online', icon: 'card-outline', children: onlinePaymentMethods}];
	}

	parseOfflineMethods() {
		if(this.orderService.order.details.scheduleTo && this.restaurant.info.scheduleSettings.onlyWithOnlinePayment) return [];

		let offlinePaymentMethods = [];

		if (this.restaurant.info.cash_payment) offlinePaymentMethods.push({name: 'Cash', value: 'money', icon: 'cash-outline'});
		
		if (this.restaurant.info.card_payment) offlinePaymentMethods.push({name: 'Card', value: 'creditCard', icon: 'card-outline'});
		
		if (offlinePaymentMethods.length == 0) return [];

		let offlineName = 'Upon Delivery';
		if (this.orderService.order.details.deliveryType == 'balcony') offlineName = 'Upon Takeout';

		if (this.paymentMethods.length > 0) return [{name: offlineName, value: 'offline', icon: 'wallet-outline', children: offlinePaymentMethods}];

		return offlinePaymentMethods;
	}

	parseInstantCashTransferMethods() {
		let cashTransferMethods = [];

		if (this.restaurant.info.pix_settings) {

			this.cashTransferMethodsSettings['pix'] = this.restaurant.info.pix_settings;

			cashTransferMethods.push({
				name: 'Pix',
				value: 'pix',
				img: 'pix-icone-256'
			});
		}

		if("pixAsaas" in this.restaurant.info.online_payment_integrations && this.restaurant.info.wallet_id){
			cashTransferMethods.push({
				name: 'Pix',
				value: 'pixAsaas',
				img: 'pix-icone-256'
			})
		}

		if (!this.restaurant.info.zelle_settings) return cashTransferMethods;

		this.cashTransferMethodsSettings['zelle'] = this.restaurant.info.zelle_settings;

		cashTransferMethods.push({
			name: 'Zelle',
			value: 'zelle',
			img: 'Zelle-icon'
		});

		if (!this.restaurant.info.mbway_settings) return cashTransferMethods;

		this.cashTransferMethodsSettings['mbway'] = this.restaurant.info.mbway_settings;

		cashTransferMethods.push({
			name: 'MbWay',
			value: 'mbway',
			img: 'mbway-icon'
		});

		return cashTransferMethods;

	}

	parseConvenix() {
		if (!Object.keys(this.restaurant.info?.online_payment_integrations).some(integration => integration == 'convenix')) return [];

		return [{
			name: 'Convenix',
			value: 'convenix',
			img: 'Convenix-icon'
		}];
	}

	async hasPushNotification() {
        if (!this.restaurant.info.use_push_notifications) return;

        let oneSignalSupported = await this.onesignalService.isSupported();
        if (!oneSignalSupported) console.error("OneSignal: Browser not compatible!");

        let oneSignalEnabled = await this.onesignalService.isEnabled();
        if (!oneSignalEnabled) this.openPushNotificationModal();
	}

	async openPushNotificationModal() {
		this.localStorageService.setItem("push", "false");
		const modal = await this.modalController.create({
			component: PushNotificationPage,
			backdropDismiss: false,
			cssClass: "modal-pushNotification-dialog",
		});
		return await modal.present();
	}

	choseMethod(method, index) {
		this.keen.event('Choosed payment method ' + method.value);

		this.paymentForm.get('paymentMethod').setValue(null);
		this.chosenMethods.splice(index);
		this.chosenMethods.push(method.value);

		if (index == 0) this.childrenPaymentMethods = [];

		let hasChildren = method.children ;

		if (hasChildren) this.childrenPaymentMethods = method.children;
		else this.paymentForm.get('paymentMethod').setValue(method.value);
		
		this.checkPaymentMethodOperation(method.value);
		this.updateFormValidation();
		this.scrollToBottom();
	}

	updateFormValidation() {
		this.disableValidations();
		this.enableNeededValidations();
		this.paymentForm.updateValueAndValidity();
		this.findInvalidControlsRecursive(this.paymentForm);
	}

	disableValidations() {
		this.paymentForm.get('user').disable();
		this.paymentForm.get('cash').disable();
		this.paymentForm.get('card.type').disable();
		this.paymentForm.get('creditCard').disable();
		this.paymentForm.get('convenix').disable();
	}

	enableNeededValidations() {
		if (this.chosenMethods.includes('money')) {
			this.paymentForm.get('cash').enable();
			this.openChangeModal();
		}
		if (this.chosenMethods.includes('creditCard') && this.restaurant.info.card_types?.length > 0) {
			this.paymentForm.get('card.type').enable();
			this.chooseCardMethodOperation();
		}

		if (this.chosenMethods.includes('picpay')) {
			this.paymentForm.get('user.email').enable();
			this.paymentForm.get('user.cpf').enable();
			this.showPicpayDialog();
		}
		
		if (this.chosenMethods.includes('pagseguro')) {
			this.paymentForm.get('user.name').enable();
			this.showPagSeguroDialog();
		}

		if (this.chosenMethods.includes('multipedidos@asaas')) {
			this.paymentForm.get('creditCard').enable();
			this.verifyCardsIntegrity();
		}

		if (this.chosenMethods.includes('convenix')) {
			this.paymentForm.get('convenix').enable();
			this.showConvenixDialog();
		}
	}

	async openChangeModal() {
		const modal = await this.modalController.create({
			component: ChangeDialogPage,
			componentProps: {
				grandTotal: this.grandTotal,
				paymenSettingstOperation: this.appliedPaymentSetting?.operationName,
				paymenSettingstValue: this.appliedPaymentSetting?.calculatedValue
			},
			cssClass: 'change-address-shipping-modal'
		});
		modal.onDidDismiss().then((data) => {
			
			this.paymentForm.get('cash.change').setValue(0);
			if (data["role"] == "backdrop") {
				this.keen.event('Closed change modal');
				this.paymentForm.get('cash.changeNeeded').setValue(null);
				return;
			}

			if (data?.data?.changeValue) {
				this.keen.event('Asked for change');
				this.paymentForm.get('cash.change').setValue(data['data'].changeValue);
				this.paymentForm.get('cash.changeNeeded').setValue(true);
				return this.scrollToBottom();
			}

			this.keen.event('No change needed');
			this.paymentForm.get('cash.changeNeeded').setValue(false);
			return this.scrollToBottom();
		});

		return await modal.present();
    }

	async chooseCardMethodOperation() {
		const modal = await this.modalController.create({
			component: CardDialogPage,
			componentProps: {
				orderTotal: this.orderTotal,
				grandTotal: this.grandTotal,
				restaurantPaymentSettings: this.restaurant.info.paymentSettings,
				cardTypes: this.restaurant.info.card_types,
			},
			cssClass: 'change-address-shipping-modal'
		});
		modal.onDidDismiss().then((data) => {			
			if (data["role"] != "backdrop") {
				this.appliedPaymentSetting = data["data"].appliedPaymentSetting;
				this.paymentForm.get('card.type').setValue(data["data"].choosedCard.type);
				this.paymentForm.get('card.name').setValue(data["data"].choosedCard.name);
				this.keen.event(`Choosed card ${data["data"].choosedCard.type} - ${data["data"].choosedCard.name}`);
				this.checkPaymentMethodOperation(this.paymentForm.get('card.type').value);
				this.scrollToBottom();
				return;
			}

			this.paymentForm.get('card.type').setValue(null);
			this.paymentForm.get('card.name').setValue(null);
		});

		return await modal.present();
	}

	async showPicpayDialog() {
		if (this.email.valid && this.cpf.valid) return;

		const modal = await this.modalController.create({
			component: PicpayDialogPage,
			componentProps: {
				grandTotal: this.grandTotal,
				userInput: {
					cpf: this.cpf.value,
					email: this.email.value,
				}
			},
			cssClass: 'change-address-shipping-modal'
		});
		modal.onDidDismiss().then((data) => {
			if (data["role"] == "backdrop") return;
			this.paymentForm.get('user').patchValue({
				cpf: data['data'].user.cpf,
				email: data['data'].user.email,
			});
			this.scrollToBottom();
		});

		return await modal.present();
	}

	async showPagSeguroDialog() {
		if (this.name.valid) return;

		const modal = await this.modalController.create({
			component: PagseguroDialogPage,
			componentProps: {
				grandTotal: this.grandTotal,
				user: {
					name: this.name.value
				}
			},
			cssClass: 'change-address-shipping-modal'
		});
		modal.onDidDismiss().then((data) => {
			if (data["role"] == "backdrop") return;
			this.paymentForm.get('user').patchValue({
				name: data['data'].name,
			});
			this.scrollToBottom();
		});

		return await modal.present();
	}

	async showConvenixDialog() {
		const modal = await this.modalController.create({
			component: ConvenixDialogPage,
			componentProps: {
				grandTotal: this.grandTotal,
				userInput: {
					cpf: this.cpf.value
				}
			},
			cssClass: 'change-address-shipping-modal'
		});
		modal.onDidDismiss().then((data) => {
			if (data["role"] == "backdrop") return;

			this.paymentForm.get('user').patchValue({
				cpf: data['data'].cpf,
			});

			this.paymentForm.get('convenix.confirmationCode').setValue(data['data'].convenixConfirmationCode);
			this.paymentForm.get('convenix.saleID').setValue(data['data'].saleID);
			this.findInvalidControlsRecursive(this.paymentForm);
			this.scrollToBottom();
		});

		return await modal.present();
	}

	async verifyCardsIntegrity() {
		if(!this.creditCardService.creditCard || this.creditCardService.creditCard.creditCardToken) return;
		this.keen.event('Verifying card integrity');

		await this.creditCardService.checkCreditCardFingerprint()
			.then(result => {
				this.paymentForm.get('creditCard.creditCardIsValid').setValue(true);
				this.scrollToBottom();
				return;
			}, error => {
				this.paymentForm.get('creditCard.creditCardIsValid').setValue(null);
				this.askCreditCardCVV();
			});
	}

	async askCreditCardCVV() {
		this.keen.event('Asked for cvv');

		const modal = await this.modalController.create({
			component: CvvCardPage,
			cssClass: "modal-change-dialog"
		});

		modal.onDidDismiss().then(result => {

			if(result.data !== undefined && result.data.dismissed && result.data.error) {
				let message = result.data.error.error;
				this.utils.createToaster(this.translatePipe.transform(message));

				this.keen.event(message);
				if(message == 'Credit Card erased.') return this.paymentForm.get('creditCard').setValue({});

				this.askCreditCardCVV();
			};

			if(result.data !== undefined && result.data.dismissed && !result.data.error) {
				this.keen.event('Credit Card CVV checked');
				this.utils.createToaster(this.translatePipe.transform('Credit Card CVV checked!'));
				this.paymentForm.get('creditCard.creditCardIsValid').setValue(true);
				this.scrollToBottom();
			}
		});

		return await modal.present();
	}

	findInvalidControlsRecursive(formToInvestigate:FormGroup | FormArray):string[] {
        var invalidControls:string[] = [];
        let recursiveFunc = (form:FormGroup | FormArray) => {
          Object.keys(form.controls).forEach(field => { 
            const control = form.get(field);
            if (control.invalid) {
                invalidControls.push(field);
				console.group('invalid field');
				console.log('field', field);
                console.log('value', JSON.stringify(control.value));
                console.log('error', JSON.stringify(control.errors));
				console.groupEnd();
            }
            if (control instanceof FormGroup) {
              recursiveFunc(control);
            } else if (control instanceof FormArray) {
              recursiveFunc(control);
            }        
          });
        }
        recursiveFunc(formToInvestigate);
        return invalidControls;
      }    

	checkPaymentMethodOperation(method) {
		this.grandTotal = this.orderTotal - this.paymentForm.get('coupon.value').value;

		this.appliedPaymentSetting = this.restaurant.info.paymentSettings.filter(setting => setting.paymentType == method && setting.isActive && setting.value > 0)[0];

		if (!this.appliedPaymentSetting) return;

		let value = this.appliedPaymentSetting.value;
		if (this.appliedPaymentSetting.valueType == 'percent') value = (this.orderTotal * this.appliedPaymentSetting.value) / 100;

		this.appliedPaymentSetting.calculatedValue = value;

		this.appliedPaymentSetting.operationName = 'Addition'
		if (this.appliedPaymentSetting.operation == 'discount') {
			value = -value;
			this.appliedPaymentSetting.operationName = 'Discount';
		}

		this.keen.event('Discount or addition by method applied');
		this.grandTotal = this.orderTotal - this.paymentForm.get('coupon.value').value + value;
	}

	async addCreditCard() {
		const modal = await this.modalController.create({
			component: AddCardPage,
			componentProps: {
				shouldStoreCard: false,
			}
		});

		modal.onDidDismiss().then(response => {
			if (response.data == undefined) return;

			let creditCard = this.creditCardService.creditCard;
			this.paymentForm.get('creditCard').patchValue({
				creditCardIsValid: true,
				creditCardCustomer: creditCard.creditCardCustomer,
				creditCardToken: creditCard.creditCardToken,
				creditCardBrand: creditCard.creditCardBrand,
				creditCardNumber: creditCard.creditCardNumber,
				creditCardNickName: creditCard.creditCardNickName,
				creditCardUUID: creditCard.creditCardUUID,
			})
		});

		return await modal.present();
	}

	scrollToBottom() {
		setTimeout(() => {
			this.myContent.scrollToBottom(300);
		 }, 300);	  
	}

	backToMenu() {
		this.keen.event('Clicked to return to menu');
		this.navService.navigateBackward(`delivery`);
	}

	checkCoupon() {
		if (this.coupon.input == this.coupon.name) {
			this.openCouponModal(
				"This coupon has already been applied!",
				"You have already applied this coupon and will not be able to apply again.",
				false,
				true
			);
			return this.paymentForm.get('coupon.input').setValue("");
		}

		this.coupomService.checkCoupon(this.coupon.input).then((response) => {
			if (!response) {
				return this.openCouponModal(
					"Invalid coupon!",
					"This coupon is invalid, check if it is correct or still available.",
					false,
					false
				);
			}
			if (!response?.enableUseFeaturedItems) {
				const hasSomeFeaturedItem = this.orderService.order.orders.some(item => item.featured);
				if (hasSomeFeaturedItem) {
					this.openCouponModal(
						"Could not apply coupom!",
						"This coupom could not be applied on featured items.",
						false,
						true
					);
					return this.paymentForm.get('coupon.input').setValue("");
				}
			}

			this.applyCoupon(response);
		}).catch(error => {
			this.openCouponModal(
				"Error!",
				error.error,
				false,
				true
			);
			this.paymentForm.get('coupon.input').setValue("");
		});
	}

	applyCoupon(coupon) {
		if (this.shoppingCarTotal < coupon.minOrderValue) {
			this.openCouponModal(
				"Value less than the minimum!",
				"To use this coupon, the minimum order amount must be",
				false,
				true,
				coupon.minOrderValue
			);
			return;
		}

		this.keen.event('Applied coupon');

		let discountValue = coupon.fixDiscount;
		if (coupon.type == "percentDiscount") discountValue = this.shoppingCarTotal * (coupon.percentDiscount / 100);

		if(!coupon.minOrderValue && coupon.type == "fixDiscount" && this.shoppingCarTotal < discountValue)
			discountValue = this.shoppingCarTotal;

		this.openCouponModal(
			"Coupon successfully applied!",
			"Congratulations! You have successfully applied your coupon.",
			true,
			false
		);

		this.paymentForm.get('coupon').setValue({
			id: coupon.id,
			input: "",
			value: discountValue,
			name: coupon.code
		})

		this.grandTotal = this.orderTotal - discountValue;
		this.resetPaymentInfo();
	}

	async openCouponModal(header, message, isSuccess: boolean, isWarning: boolean, value: any = "") {
		const modal = await this.modalController.create({
			component: CouponDialogPage,
			componentProps: {
				header: this.translatePipe.transform(header),
				message: this.translatePipe.transform(message),
				value: value ? this.multiCurrencyPipe.transform(value) : 0,
				isSuccess: isSuccess,
				isWarning: isWarning,
			},
			cssClass: "modal-coupon-dialog",
		});
		return await modal.present();
	}

	resetPaymentInfo() {
		this.chosenMethods = [];
		this.appliedPaymentSetting = null;
	}

	isItemMatch(item, targetName){
		if (item.name === targetName) {
			return true;
		}
		if (item.extras) {
			return item.extras.some(extra => extra.name === targetName);
		}
		return false;
	};

	async sendOrder() {
		if (this.sendingOrder) return;
		this.sendingOrder = true;

		this.orderService.order.orders = this.orderService.sanitizeOrderToUpload(this.orderService.order.orders);

		this.keen.event('Sent order. Itens length => ' + this.orderService.order.orders.length);
		this.parseFormToOrderService();

		let userIsLogged = this.authService.isLoggedIn();

		if (userIsLogged) {
			if (this.authService.user) {
				this.orderService.order.user.id = this.authService.user.id;
			} else {
				await this.authService.getUser().then((response) => {
					this.orderService.order.user.id = response.id;
				});
			}
		}

		this.keen.event('sendOrder', this.orderService.order);

		this.orderService.sendOrder()
			.then(response => {
				this.callPixelPurchaseEvent(response);

				gtag('event','purchase',{
					'value': response.totalNetValue,
					'currency': this.restaurant.info.currency,
				});

				return response;
			})
			.then(response => {
				this.updateOrdersLocalStorage(response.uuid);
				this.resetOrderContext(false);
				response = this.checkIsTransferMethod(response);

				this.afterPaymentService.redirectPage(response);
				this.restaurantLocalStorageService.removeItem('cart');
				if (this.cartRecoverService.uuid)
					this.cartRecoverService.delete().subscribe();

			})
			.catch(error => {
				let formatedError = this.generateMessage(error);

				if(formatedError.action) {
					formatedError.action();
					return;
				}

				const productsInCart = this.shoppingCartService.products;
				this.openMessageErrorOrderModal(formatedError);

				if(productsInCart.length){
					return this.navService.push('shopping-cart');
				}

				this.navService.push('');
			})
    }

	checkIsTransferMethod(response) {
		if(response.paymentMethod === 'pix')
			response.paySettings = this.restaurant.info?.pix_settings

		if(response.paymentMethod === 'zelle')
			response.paySettings = this.restaurant.info?.zelle_settings

		if(response.paymentMethod === 'mbway')
			response.paySettings = this.restaurant.info?.mbway_settings

		return response;
	}

	generateMessage(error): any {

		let errosType = {
			"Generic" : {
				"title" : "Error!",
				"text" : "An error occurred while trying to place the order. Please try again.",
				"data" : null
			},
			"Already Ordered" : {
				"title": "Attention! Your order has been placed previously.",
				"text": "The establishment has already received your order. If you want to make any changes, contact the establishment to cancel your previous order. Click the button below to follow your order.",
				"data": error,
			},
			"This coupom could not be used with this hours template" : {
				"title": "Error!",
				"text": "This coupom could not be used with this hours template",
				"data": null,
			},
			"Restaurant Closed" : {
				"title" : "Closed Store!",
				"text" : "We were unable to place your order because the store is currently closed.",
				"data" : error,
			},
			"No Cashier were found" : {
				"title" : "Closed Store!",
				"text" : "We were unable to place your order because the store is currently closed.",
				"data" : null
			},
			"Client is blocked" : {
				"title" : "Error!",
				"text" : "An error occurred while trying to place the order. Please try again. CX000019",
				"data" : null
			},
			"Online Payment Method is Unavaiable" : {
				"action" : () => {
					this.showAlertToChooseAnotherPayment(this.orderService.order.details.paymentType);
				}
			},
			"is not available" : {
				"action" : () => {
					this.events.publish('reloadRestaurant');
					this.showAlertToChooseAnotherProduct(error.itemID);
				}
			},
			"Without Stock" : {
				"title" : "Item sem estoque",
				"text" : error.error,
				"data" : null
			},
			"Coupom Use Limit Exceeded" : {
				"title" : "Error!",
				"text" : "This coupon has reached the maximum usage quantity.",
				"data" : null
			}
		}

		if(("error" in error) && error.error.includes("is not available"))
			return errosType['is not available'];

		if(("error" in error) && error.error.includes("possui quantidade suficiente para atender esse pedido")){
			const itemsErrors = JSON.parse(error.error);

			this.removeItemsFromCart(itemsErrors);

			errosType['Without Stock'].text = itemsErrors.join('');

			return errosType['Without Stock'];
		}

		if(!("error" in error) || !(error.error in errosType)){
			this.keen.event('error', error);
			this.keen.commit();
		}
			
		return errosType[error.error] ?? errosType['Generic'];
	}

	removeItemsFromCart(itemsErrors){
		itemsErrors.map(error => {
			const itens = this.shoppingCartService.products;

			const itemName = error.replace('O item ', '').split(' não possui')[0];

			let foundItemIndex = -1;

			itens.forEach((item, index) => {
				if (this.isItemMatch(item, itemName)) {
					foundItemIndex = index;
					return;
				}
			});

			if (foundItemIndex !== -1) {
				this.shoppingCartService.removeProductByIndex(foundItemIndex);
			}
		})
	}

	parseFormToOrderService() {
		if (this.chosenMethods.includes('money')) {
			this.orderService.order.details.needChange = this.paymentForm.get('cash.changeNeeded').value;
			this.orderService.order.details.changeValue = this.paymentForm.get('cash.change').value;
		}

		if (this.chosenMethods.includes('creditCard')) {
			this.orderService.order.details.cardType = this.paymentForm.get('card.name').value;
			this.orderService.order.details.cardMethod = this.paymentForm.get('card.type').value;
		}

		if (this.chosenMethods.includes('multipedidos@asaas')) {
			this.orderService.order.details.creditCardCustomer = this.creditCardService.creditCard.creditCardCustomer;
			this.orderService.order.details.creditCardUUID = this.creditCardService.creditCard.creditCardUUID;
			this.orderService.order.details.creditCardToken = this.creditCardService.creditCard.creditCardToken;
		}

		if (this.chosenMethods.includes('convenix')) {
			this.orderService.order.details.onlinePaymentReference = this.paymentForm.get('convenix.saleID').value;	
		}

		if (this.appliedPaymentSetting) {
			this.orderService.order.details.operatedValue = this.appliedPaymentSetting.value;
			this.orderService.order.details.paymenthMethodOperation = this.appliedPaymentSetting.operationName;
			this.orderService.order.details.paymenthMethodValueType = this.appliedPaymentSetting.valueType;
		}

		let coupon = this.paymentForm.get('coupon').value;
		if (coupon) {
			this.orderService.order.details.discountID = coupon.id;
			this.orderService.order.details.discountValue = coupon.value;
		}

		this.orderService.order.details.total = this.shoppingCarTotal;
		this.orderService.order.details.grandTotal = this.grandTotal;
		this.orderService.order.details.paymentType = this.paymentForm.get('paymentMethod').value;

		this.orderService.order.user.email = this.email.value ?? this.orderService.order.user.email;
		this.orderService.order.user.cpf = this.cpf.value ?? this.orderService.order.user.cpf;
		this.orderService.order.user.name = this.name.value ?? this.orderService.order.user.name;
	}

	updateOrdersLocalStorage(newOrderUUID) {
		let actualOrders = [];

		if (this.restaurantLocalStorageService.getItem("deviceOrders")) {
			actualOrders = JSON.parse(this.restaurantLocalStorageService.getItem("deviceOrders"));
		}

		actualOrders.push(newOrderUUID);

        this.restaurantLocalStorageService.setItem("deviceOrders", JSON.stringify(actualOrders));
        this.restaurantLocalStorageService.setItem("lastOrderToken", newOrderUUID);        
	}

	resetOrderContext(needReturnUserPoints = true) {
        this.shoppingCartService.clearCart(needReturnUserPoints);
        this.orderService.initizalizeOrder();
    }


	async showAlertToChooseAnotherPayment(paymentMethod) {
		const alert = await this.alertController.create({
			header: "Método Indisponível",
			message: `Pagamentos através do ${paymentMethod} estão indisponíveis. Gostaria de refazer o pagamento?`,
			buttons: [
				{
					text: "Cancel",
					role: "cancel",
					cssClass: "secondary",
					handler: () => {
                        this.resetOrderContext();
					},
				},
				{
					text: "Escolher outro método de pagamento",
					handler: () => {
                        this.chosenMethods = [];
					},
				},
			],
		});

		await alert.present();
	}

	async showAlertToChooseAnotherProduct(itemID) {
		const product = this.orderService.order.orders.filter((item) => {
			return item.id == itemID;
		})[0];

		const alert = await this.alertController.create({
			header: "Produto Indisponível",
			message: `${product.name} está indisponível no momento.`,
			buttons: [
				{
					text: "Remover item indisponível",
					handler: () => {

						this.shoppingCartService.removeProductByID(itemID);

						if (this.orderService.order.orders.length > 0) {
							this.navService.push('');
							return;
						}

						this.resetOrderContext();
					},
				},
			],
		});

		await alert.present();
	}

	async openMessageErrorOrderModal(error) {
		const modal = await this.modalController.create({
			component: MessageErrorOrderDialogPage,
			componentProps: {
				header: this.translatePipe.transform(error.title),
				message: this.translatePipe.transform(error.text),
				error: error.data,
			},
			backdropDismiss: false,
			cssClass: "modal-messageErrorOrder-dialog",
		});
		return await modal.present();
	}

	manageCreditCards() {
		this.navService.push('credit-cards');
	}
	
	callPixelInitiateCheckoutEvent(user) {
		const eventID = uuid.v4();
		fbq('track', 'InitiateCheckout', {}, { event_id: eventID });

		if(!this.restaurant.info.fb_pixel_id)
			return;

		const userData = {
			'name': user.name,
			'email': user.email,
			'phone': user.phone,
		};

		try {
			this.facebookPixelService.initiateCheckoutEvent(eventID, userData).subscribe();
		} catch (error) {
			console.error('erro ao iniciar evento FB Pixel', error);
		}
	}

	callPixelPurchaseEvent(order) {
		const eventID = uuid.v4();

		fbq('track', 'Purchase', { 'value': order.totalNetValue, 'currency': this.restaurant.info.currency}, { 
			event_id: eventID 
		});

		if(!this.restaurant.info.fb_pixel_id)
			return;

		const userData = {
			'value': order.totalNetValue,
			'currency': this.restaurant.info.currency,
			'name': order.client.name,
			'email': order.client.email,
			'phone': order.client.phone,
		};

		try {
			this.facebookPixelService.purchaseEvent(eventID, userData).subscribe();
		} catch (error) {
			console.error('erro ao enviar evento FB Pixel', error);
		}
	}
}
