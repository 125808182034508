import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavService } from 'src/app/core/services/nav.service';
import { MultiCurrencyPipe } from 'src/app/shared/pipe/multiCurrency.pipe';

@Component({
	selector: 'app-min-value-alert',
	templateUrl: './min-value-alert.page.html',
	styleUrls: ['./min-value-alert.page.scss'],
})
export class MinValueAlertPage implements OnInit {

	@Input() minimalValue;

	constructor(private navService: NavService,
				private modalCtrl: ModalController,
				private currencyPipe: MultiCurrencyPipe) { }

	ngOnInit() {
		this.minimalValue = this.currencyPipe.transform(this.minimalValue);
	}

	goToMenu() {
		this.navService.push('menu');
		this.dismissModal();
	}

	dismissModal() {
		this.modalCtrl.dismiss({
			'dismissed': true
		});
	}
}
