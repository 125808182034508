import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CloudwatchMetricsService {
    constructor(
        private http: HttpClient,
    ) { }

    location_services_accepted() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "LocationServices",
                    "Dimensions": [{
                        "Name": "OldLocationServicesStatus",
                        "Value": "ACCEPTED"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    location_services_denied() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "LocationServices",
                    "Dimensions": [{
                        "Name": "OldLocationServicesStatus",
                        "Value": "DENIED"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    photon_location_services_accepted() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "PhotonLocationServices",
                    "Dimensions": [{
                        "Name": "PhotonLocationServicesStatus",
                        "Value": "ACCEPTED"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    photon_location_services_denied() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "PhotonLocationServices",
                    "Dimensions": [{
                        "Name": "PhotonLocationServicesStatus",
                        "Value": "DENIED"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    used_use_my_location_button() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "UseMyLocationButton",
                    "Dimensions": [{
                        "Name": "UseMyLocationButtonWasPressed",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    used_type_your_address() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "UseMyLocationButton",
                    "Dimensions": [{
                        "Name": "UseMyLocationButtonWasPressed",
                        "Value": "FALSE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    old_address_dialog_started() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "OldAddresDialog",
                    "Dimensions": [{
                        "Name": "OldAddresDialogWasStarted",
                        "Value": "TRUE"
                    }, {
                        "Name": "OldAddresDialogWasFinished",
                        "Value": "FALSE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    old_address_dialog_finished() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "OldAddresDialog",
                    "Dimensions": [{
                        "Name": "OldAddresDialogWasStarted",
                        "Value": "TRUE"
                    }, {
                        "Name": "OldAddresDialogWasFinished",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    new_address_dialog_started() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "NewAddresDialog",
                    "Dimensions": [{
                        "Name": "NewAddresDialogWasStarted",
                        "Value": "TRUE"
                    }, {
                        "Name": "NewAddresDialogWasFinished",
                        "Value": "FALSE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    new_address_dialog_finished() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "NewAddresDialog",
                    "Dimensions": [{
                        "Name": "NewAddresDialogWasStarted",
                        "Value": "TRUE"
                    }, {
                        "Name": "NewAddresDialogWasFinished",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics2"
        });
    }

    photon_selected_photon_address() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "PhotonUsage",
                    "Dimensions": [{
                        "Name": "PhotonUsageSelectedPhotonAddress",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics3"
        });   
    }

    photon_selected_this_is_not_my_address() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "PhotonUsage",
                    "Dimensions": [{
                        "Name": "PhotonUsageSelectedThisIsNotMyAddress",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics3"
        });   
    }

    photon_selected_address_not_found() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "PhotonUsage",
                    "Dimensions": [{
                        "Name": "PhotonUsageSelectedAddressNotFound",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics3"
        });   
    }

    photon_selected_heremaps_address() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "PhotonUsage",
                    "Dimensions": [{
                        "Name": "PhotonUsageSelectedHereMapsAddress",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics3"
        });
    }

    user_needs_to_confirm_coordinates() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "CoordinatesConfirmation2",
                    "Dimensions": [{
                        "Name": "UserNeedsToConfirmCoordinates",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics4"
        });
    }

    user_has_coordinates() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "CoordinatesConfirmation2",
                    "Dimensions": [{
                        "Name": "UserHasCoordinates",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics4"
        });
    }

    confirm_coordinates_dialog_started() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "ConfirmCoordinatesDialog",
                    "Dimensions": [{
                        "Name": "ConfirmCoordinatesDialogWasStarted",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics4"
        });
    }

    confirm_coordinates_dialog_finished() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "ConfirmCoordinatesDialog",
                    "Dimensions": [{
                        "Name": "ConfirmCoordinatesDialogWasFinished",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics4"
        });
    }

    confirm_coordinates_locations_accepted() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "ConfirmCoordinatesLocationsStatus",
                    "Dimensions": [{
                        "Name": "ConfirmCoordinatesLocationsStatusAccepted",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics4"
        });
    }

    confirm_coordinates_locations_denied() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "ConfirmCoordinatesLocationsStatus",
                    "Dimensions": [{
                        "Name": "ConfirmCoordinatesLocationsStatusDenied",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "DeliveryPageMetrics4"
        });
    }

    try_complete_order_after_restaurant_opens_but_show_closed_dialog() {
        return this.post({
            "metricData": [
                {
                    "MetricName": "TryCompleteOrderAfterRestaurantOpensButShowClosedDialog",
                    "Dimensions": [{
                        "Name": "TryCompleteOrderAfterRestaurantOpensButShowClosedDialog",
                        "Value": "TRUE"
                    }],
                    "Unit": "Count",
                    "Value": 1.0
                }
            ],
            "namespace": "ShoppingCartPageMetrics"
        });
    }

    post(metrics) {
        if(!environment.production){
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            this.http.post(`https://cjzdwzoqmbnajef5ppqm4cgugi0dbhqq.lambda-url.us-east-1.on.aws/insert-metric`, metrics).subscribe(response => {
                resolve(response);
            });
        });
    }
}