import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CreditCardService } from 'src/app/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NavService } from 'src/app/core/services/nav.service';
import { LoginPage } from 'src/app/pages/login/login.page';

@Component({
	selector: 'app-save-card-dialog',
	templateUrl: './save-card-dialog.page.html',
	styleUrls: ['./save-card-dialog.page.scss'],
})
export class SaveCardDialogPage implements OnInit {

	self;

	constructor(
		private modalCtrl: ModalController,
		private navService: NavService,
		private authService: AuthenticationService,
		private creditCardService: CreditCardService
	) { }

	async ngOnInit() {
		this.self = await this.modalCtrl.getTop();
		this.creditCardService.wantsToStoreCard = false;
	}

	async save() {
		this.creditCardService.wantsToStoreCard = true;


		if (this.authService.isLoggedIn()) {
			this.closeModal();
			return;
		}

		const nav = document.querySelector('ion-nav');
		nav.push(LoginPage);
	}

	closeModal() {
		this.modalCtrl.dismiss();
	}

}
