<cart></cart>
<ion-content scroll-y="true" [scrollEvents]="true" (ionScroll)="onScroll()">
    <app-not-found *ngIf="invalidURL"></app-not-found>
    <div *ngIf="loaded && !invalidURL" class="menu">
        <app-header [restaurant]="restaurant"></app-header>

        <div *ngIf="restaurantIsBlocked" class="container text-center" style="width: 100%;">
            <ion-icon name="alert-circle" style="font-size: 40px; margin-top: 70px; color: #AAA;"></ion-icon>
            <h4 class="mt-0 font-weight-bold" style="color: #AAA;" translate>Menu currently unavailable</h4>
        </div>

        <div *ngIf="!restaurantIsBlocked">
            <show-recommended-products 
                *ngIf="recommendedProducts.length > 0"
                [recommendedProducts]="recommendedProducts"
            ></show-recommended-products>
            <category-nav
                [categories]="categories"
                [itemsToHide]="itemsToHide"
                [comboCategoryName]="restaurant.info.combo_category_name"
                [pizzaCategoryName]="restaurant.info.pizza_category_name"
                (scrollToCategory)="scrollToElement($event)"
            ></category-nav>
            <ion-item>
                <ion-label position="floating" translate>
                    <ion-icon name="search-outline"></ion-icon> Search
                </ion-label>
                <ion-input #ionicInput enterkeyhint="search" type="search" inputmode="search" debounce="500" [(ngModel)]="searchString" (ionChange)="filterMenu($event.target.value)"></ion-input>
            </ion-item>
            <app-menu-list [menu]="searchResults" [info]="restaurant.info"></app-menu-list>
        </div>
    </div>

    <ion-footer class="footer-MP" *ngIf="!invalidURL">
        <p translate>Developed by</p>

        <a href="https://multipedidos.com.br/pedirdelivery-nv-preco/?utm_source=Pedir.Delivery&utm_medium=SoftwareMP&utm_id=Rodape+Pedir.Delivery" target="_blank">
            <img class="MP-logo" src="assets/icon/mp-footer-horizontal.png">
        </a>

        <p>Multipedidos Soluções de Software LTDA</p>
    </ion-footer>
</ion-content>