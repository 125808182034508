import { Component, OnInit, Input } from "@angular/core";
import { WhatsappService } from "src/app/core/services/whatsapp.service";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-redirect-whatsapp",
	templateUrl: "./redirect-whatsapp.page.html",
	styleUrls: ["./redirect-whatsapp.page.scss"],
})
export class RedirectWhatsappPage implements OnInit {
	@Input("whatsappText") whatsappText;
    @Input("restaurant") restaurant;
    isIFrame = window.self !== window.top;

	constructor(
		private modalCtrl: ModalController,
	) {}

    ngOnInit() {}
    
    onClick() {
        const href = `https://api.whatsapp.com/send?phone=${this.restaurant.info.whatsapp}&text=${this.whatsappText}`;

        if (this.isIFrame)
            window.open(href, '_blank');
        else
            window.location.href = href;

        this.dismiss();
    }

	async dismiss() {
		await this.modalCtrl.dismiss({
			dismissed: true,
		});
	}
}
