<div class="side-bar">
    <ul id="tablet-category-list">
        <li *ngIf="restaurant.menu.featuredItems.length > 0" (click)="selectCategory('featuredItems')" [ngClass]="{ selected: selectedCategoryID == 'featuredItems' }" translate>On Sale</li>
        <li *ngIf="restaurant.menu.combos.length > 0" (click)="selectCategory('combos')" [ngClass]="{ selected: selectedCategoryID == 'combos' }">Combos</li>
        <li *ngIf="restaurant.menu.pizzas.sizes.length > 0" (click)="selectCategory('pizzas')" [ngClass]="{ selected: selectedCategoryID == 'pizzas' }">Pizzas</li>
        <li (click)="selectCategory(category)" *ngFor="let category of categories" [ngClass]="{ selected: selectedCategoryID == category.id }">
            {{ category.name }}
        </li>
    </ul>
</div>
