import { NgModule } from '@angular/core';

import { cpfValidatorDirective } from './directives/cpfValidator';
import { emailValidatorDirective } from './directives/emailValidator';
import { nameValidatorDirective } from './directives/nameValidator';
import { cpfCnpjValidatorDirective } from './directives/cpfCnpjValidator';
import {NormalizeInputDirective} from './directives/textFormated';  

@NgModule({
    imports: [],
    declarations: [ cpfValidatorDirective, emailValidatorDirective, nameValidatorDirective, cpfCnpjValidatorDirective, NormalizeInputDirective ],
    exports: [cpfValidatorDirective, emailValidatorDirective, nameValidatorDirective, cpfCnpjValidatorDirective, NormalizeInputDirective ]
})
export class ValidatorsModule { }