import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CardPageRoutingModule } from './card-routing.module';

import { CardPage } from './card.page';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';
import { MpImgPageModule } from '../image-tag/image-tag.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CardPageRoutingModule,
    NgxIonicImageViewerModule,
    TranslateModule,
    PipesModule,
    MpImgPageModule
  ],
  declarations: [CardPage],
  exports: [
    CardPage
  ]
})
export class CardPageModule {}
