<ion-header>
    <ion-toolbar color="danger" style="text-align: center;">
        <ion-buttons slot="start">
            <ion-button (click)="dismissModal()">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px;" translate text-wrap>
            {{'Delivery Address' | translate}}
        </ion-label>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <div id="map" [class]="mapState"></div>

    <ion-spinner name="dots" color="danger" duration="800" class="custom-spinner" *ngIf="isMapInitializing"></ion-spinner>

    <ion-button class="mt-8" *ngIf="mapState == 'show'" mode="ios" color="danger" expand="block" (click)="dismissModal()" data-cy="confirm-customer-coordinates" translate>
        {{'Confirm Location' | translate}}
    </ion-button>
</ion-content>