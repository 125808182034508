import { Injectable } from '@angular/core';
import { MenuDTO } from 'src/app/shared/model/dto/MenuDTO';
import { RestaurantService } from './restaurant.service';
import { RestaurantLocalStorageService } from './restaurantLocalStorage.service';
import { OrderService } from './order.service';

@Injectable({
    providedIn: 'root'
})
export class recommendProductService {

    private restaurant: MenuDTO;
    private cachedOrders: any = [];
    private recommendedProducts: any = [];
    private recommendedItem: any;
    private availableProducts: any;

    constructor(
        private restaurantService: RestaurantService,
		private restaurantLocalStorageService: RestaurantLocalStorageService,
		private orderService: OrderService,
    ) {
        this.restaurant = this.restaurantService.restaurant;
    }

    setAvailableProducts(availableProducts) {
        this.availableProducts = availableProducts;
    }

	async getRecommendedProducts() {
		await this.loadCachedOrders();
		this.setRecomendedProducts();

		return this.recommendedProducts;
    }

	async loadCachedOrders() {
		const deviceOrders = JSON.parse(this.restaurantLocalStorageService.getItem("deviceOrders"));
		if (deviceOrders) {
			const ordersUUID = deviceOrders.reverse();
			let orderCont = 1;

			for (const orderUUID of ordersUUID) {
				if (orderCont > 5)
					break;

				await this.orderService.getCachedOrderByUUID(orderUUID).then((order: string) => {
					this.cachedOrders.push(order);
					orderCont++;
				});
			}
		}
	}

	setRecomendedProducts() {
		this.recommendedProducts = [];

		this.cachedOrders.forEach(order => {
			let foundProduct = null;

			if (this.recommendedProducts.length >= 5)
				return;

			order.items.forEach(item => {
				this.recommendedItem = item;

				if (!this.canRecommendThisProduct() || item.type != 'general')
					return;

				foundProduct = this.availableProducts.find(ap => ap.id === item.menu_item_id && ap.hasStock);

				if (foundProduct) {
					this.recommendedItem.allExtrasUnavailable = false;
					this.recommendedItem.image = foundProduct.image ?? null;
					this.recommendedItem.price = foundProduct.price;
					this.setExtras(foundProduct);

					if(this.recommendedItem.allExtrasUnavailable){
						return;
					}

					this.recommendedProducts.push(this.genericItemParse());
				}
			});
		});
	}

	checkCanShowRecommendedProducts() {
		return this.restaurant.info.show_recommended_products;
	}

    canRecommendThisProduct() {
		const recommendProductByMinimumPrice = this.restaurant.info.show_recommended_products_by_minimum_price;
		const recommendProductMinimumValue = this.restaurant.info.show_recommended_products_minimum_value;
		const itemAlreadyExists = this.recommendedProducts.find(p => p.id === this.recommendedItem.menu_item_id) ? true : false;

		return (!itemAlreadyExists && (!recommendProductByMinimumPrice || recommendProductMinimumValue === null || (recommendProductByMinimumPrice && this.recommendedItem.subTotal >= recommendProductMinimumValue)));
	}

    setExtras(foundedProduct) {
		let extras = [];
		let extraInfo = [];

		if (foundedProduct.extras) {
			foundedProduct.extras.forEach(extraID => {
				extraInfo.push(this.restaurant.menu.extras.find(e => e.id === extraID));
			});
		}

		if (this.recommendedItem.extras) {
			this.recommendedItem.extras.forEach(extra => {
				let foundedExtra = this.getOptionFromMenuByID(extra.menu_item_id);
				let extraCategory = this.getExtraCategoryFromMenuByExtraID(extra.menu_item_id);

				if(!foundedExtra)
					return;

				extras.push({...extra, ...{
					isAvailable: foundedExtra['available'],
					parent: extraCategory,
					price: foundedExtra['price']
				}});
			});

			if(!extras.length)
				this.recommendedItem.allExtrasUnavailable = true;

			this.recommendedItem.extras = extras;
			this.recommendedItem.extraInfo = extraInfo;
		}
	}

    baseParseItem() {
		return {
			id: this.recommendedItem.menu_item_id,
			name: this.recommendedItem.name,
			qty: this.recommendedItem.quantity,
			subTotal: this.recommendedItem.subTotal,
			price: this.recommendedItem.price,
			type: this.recommendedItem.type,
			notes: this.recommendedItem.notes,
			extraInfo: this.recommendedItem.extraInfo,
			image: this.recommendedItem.image,
		}
	}

	genericItemParse() {
		let extras = [];
		let totalExtra = 0;

		if (this.recommendedItem.extras.length > 0) {
			this.recommendedItem.extras.forEach(extra => {
				extras.push({
					id: extra.menu_item_id,
					menu_item_id: extra.menu_item_id,
					name: extra.name,
					price: extra.price,
					qty: extra.quantity,
					isAvailable: extra.isAvailable,
					parent: extra.parent
				});

				totalExtra += (extra.quantity * extra.price);
			});
		}

		this.recommendedItem.subTotal = (this.recommendedItem.price * this.recommendedItem.quantity) + totalExtra;

		return {...this.baseParseItem(), ...{
			extras: extras
		}}
	}

	getOptionFromMenuByID(id) {
		let foundedExtra = null;

		this.restaurant.menu.extras.find(extraCategory => {
			let wasFoundedExtra = extraCategory.options.find(option => option.id == id && option.hasStock);
			
			if (wasFoundedExtra)
				foundedExtra = wasFoundedExtra;
		});

		return foundedExtra;
	}

	getExtraCategoryFromMenuByExtraID(extraID) {
		return this.restaurant.menu.extras.find(e => {
			return e.options.find(o => o.id === extraID);
		});
	}
}