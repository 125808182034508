import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-pagseguro-dialog',
	templateUrl: './pagseguro-dialog.page.html',
	styleUrls: ['./pagseguro-dialog.page.scss'],
})
export class PagseguroDialogPage implements OnInit {

	@Input() user;
	@Input() grandTotal;

	constructor(
		private modalCtrl: ModalController
	) { }

	ngOnInit() {
	}

	async dismiss() {
		await this.modalCtrl.dismiss({
			'dismissed': true,
			'name': this.user.name
		});
	}


}
