<ion-header>
	<ion-toolbar color="danger" style="text-align: center;">
		<ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px;" translate text-wrap><strong translate>Away for too long</strong></ion-label>
	</ion-toolbar>
</ion-header>

<ion-content>
	<div class="text-center my-2" style="font-size: 20px; width: 100%;">
		<ion-icon name="time-outline" style="font-size: 30vh;"></ion-icon>
		<br>
		<span translate>It looks like you've been idle for a long time...</span>
		<br><br>
		<span translate>Please reload the page.</span>
	</div>	

	<ion-button expand="block" mode="ios" color="danger" (click)="reloadPage()" translate>Reload</ion-button>
</ion-content>