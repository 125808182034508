import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';


@Injectable()
export class ApiService {
    private base_headers: any;

    constructor(
        private http: HttpClient,
    ) {}

    private formatErrors(error: any) {
        return throwError(error.error);
    }

    private parseHeaders(url, headers){
        let parsed_headers = {};

        return {'headers': new HttpHeaders(parsed_headers)};
    }

    private isBlackListed(url){
        let blacklisted_domains = ['viacep'];

        for (let i = 0; i < blacklisted_domains.length; i++) {
            if(url.includes(blacklisted_domains[i])) return true;
        }

        return false;
    }

    get(path: string, headers: Object = {}): Observable<any> {
        headers = this.parseHeaders(`${environment.apiURL}${path}`, headers)

        return this.http.get(`${environment.apiURL}${path}`, headers)
            .pipe(catchError(this.formatErrors));
    }

    put(path: string, body: Object = {}, headers: Object = {}): Observable<any> {
        headers = this.parseHeaders(`${environment.apiURL}${path}`, headers)

        return this.http.put(
            `${environment.apiURL}${path}`,
            body,
            headers
        ).pipe(catchError(this.formatErrors));
    }

    post(path: string, body: Object = {}, headers: Object = {} ): Observable<any> {
        headers = this.parseHeaders(`${environment.apiURL}${path}`, headers)

        return this.http.post(
            `${environment.apiURL}${path}`,
            body,
            headers
        ).pipe(catchError(this.formatErrors));
    }

    delete(path, headers: Object = {}): Observable<any> {
        headers = this.parseHeaders(`${environment.apiURL}${path}`, headers)

        return this.http.delete(
            `${environment.apiURL}${path}`,
            headers
        ).pipe(catchError(this.formatErrors));
    }
}