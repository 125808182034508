import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PasswordStrengthMeter } from './password-strength-meter.page';
import { PasswordStrengthMeterRoutingModule } from "./password-strength-meter-routing.module";

import { IonicSelectableModule } from 'ionic-selectable';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    IonicSelectableModule,
    PasswordStrengthMeterRoutingModule,
    TranslateModule,
    PipesModule
  ],
  declarations: [PasswordStrengthMeter],
  exports: [PasswordStrengthMeter]
})
export class PasswordStrengthMeterModule {}
