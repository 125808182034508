<div class="modal-content">
	<form [formGroup]="picpayForm" >
		<ion-card style="box-shadow: none !important; margin-bottom: 0px;">
			<ion-grid style="padding-left: 10px;">
				<ion-row>
					<ion-col style="padding-bottom: 0px;">
						<span ><b translate>Total Payable</b></span>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col style="padding-left: 15px;">
						<h1 class="grand-total"><span class="bold-statements">{{grandTotal | multiCurrency }}</span></h1>
					</ion-col>
				</ion-row>

				<ion-row *ngIf="loaded" formGroupName="user">
					<ion-col>
						<p class="message-email-picpay" [hidden]="!userInput.email && !userInput.cpf" translate>
							Para fazer pagamentos através do Picpay, precisamos de seu CPF e Email
						</p>
			
						<input class="form-control" debounce="500" placeholder="E-mail" type="text" name="email" formControlName="email" [ngClass]="{'invalid-input': (email.invalid && (email.dirty || email.touched))}">
						<div *ngIf="(email.invalid && (email.dirty || email.touched))">
							<ion-text class="invalid-input-text">{{'Invalid Email' | translate}}</ion-text>
						</div>
			
						<input 
							class="form-control"
							debounce="500"
							placeholder="CPF"
							type="text"
							name="cpf"
							mask="000.000.000-00"
							autocomplete="off"
							inputmode="numeric"
							formControlName="cpf" [ngClass]="{'invalid-input': (cpf.invalid && (cpf.dirty || cpf.touched))}">
						<div *ngIf="(cpf.invalid && (cpf.dirty || cpf.touched))">
							<ion-text class="invalid-input-text">{{'Invalid ID Document' | translate}}</ion-text>
						</div>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col>
						<button class="button-text payment-choice-button__main-button payment-row" (click)="dismiss()" [disabled]="picpayForm.invalid" translate>
							CONFIRM
						</button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-card>
	</form>
</div>