import { Component, OnInit, Input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Menu } from 'src/app/shared/model/Menu';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.page.html',
  styleUrls: ['./menu-list.page.scss'],
})
export class MenuListPage implements OnInit {

	@Input() menu: Menu;
	@Input() info:any;

	public pizzaCategoryName;
	public comboCategoryName;
	public featuredItemsCategoryName;

	private generalProducts: any[];

	constructor(
		private translatePipe: TranslatePipe,
        private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.generalProducts = this.menu.general;
		this.pizzaCategoryName = this.info.pizza_category_name.length > 0 ? this.info.pizza_category_name : 'Pizzas';
		this.comboCategoryName = this.info.combo_category_name.length > 0 ? this.info.combo_category_name : 'Combos';		
		this.featuredItemsCategoryName = this.translatePipe.transform('On Sale');
	}

	showCategory(items) {
		let show = false;
		if(!items) return show;

		items.forEach(item => {
			if(!item.unavailable && !item.unavailableByTemplate) show = true;
		});
		return show;
	}

	logScrolling(evento) {
		console.log(evento);
	}

	convertToSafeHtml(rawHtml: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(rawHtml);
    }

    getSafeHtml(value: any) {
        try {
            return this.convertToSafeHtml(JSON.parse(value));
        } catch (error) {
            return value;
        }
    }

}
