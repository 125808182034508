import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES, EventTargetInterruptSource } from '@ng-idle/core';
import { ModalController } from '@ionic/angular';
import { TimeoutPage } from 'src/app/pages/timeout/timeout.page';

@Injectable()
export class idleTimeoutService {
    idleState = 'Not started.';
	timedOut = false;
	lastPing?: Date = null;

    constructor(private idle: Idle, private modalController: ModalController) { }

    initializeCounter() {
        this.idle.setIdle(7200); // 2 hours
		this.idle.setTimeout(10);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.checkIdle();
    }

    checkIdle() {
        this.idle.onIdleEnd.subscribe(() => {
			this.idleState = 'No longer idle.'
			console.log(this.idleState);
			this.reset();
		});

		this.idle.onTimeout.subscribe(() => {
			this.idleState = 'Timed out!';
			this.timedOut = true;
            console.log(this.idleState);
            this.timeout();
		});

		this.idle.onIdleStart.subscribe(() => {
			this.idleState = 'You\'ve gone idle!'
			console.log(this.idleState);
		});

		this.idle.onTimeoutWarning.subscribe((countdown) => {
			this.idleState = 'You will time out in ' + countdown + ' seconds!'
			console.log(this.idleState);
		});

		this.reset();
    }

    reset() {
		this.idle.watch();
		this.idleState = 'Started.';
		this.timedOut = false;
    }

    async timeout() {
        const modal = await this.modalController.create({
            component: TimeoutPage
        });
        return await modal.present();
    }
}