import { Injectable } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { MultiCurrencyPipe } from 'src/app/shared/pipe/multiCurrency.pipe';
import { RestaurantService } from './restaurant.service';
import { UtilsService } from './utils.service';
import { FormatTimePipe } from 'src/app/shared/pipe/formatTime.pipe';

@Injectable({
    providedIn: 'root'
})
export class WhatsappService {

    orderText: string
    restaurant;
    baseUrl = new URL(window.location.href).origin;

    constructor(private multiCurrencyPipe: MultiCurrencyPipe,
                private translatePipe: TranslatePipe,
                private restaurantService: RestaurantService,
                private utilsService: UtilsService,
                private formatTime: FormatTimePipe
    ) {
        this.restaurant = this.restaurantService.restaurant;
    }


    generateText(order) {
        let itemsPrizes = []

        this.orderText = `✅ *NOVO PEDIDO* \n`
        this.orderText += "-----------------------------\n"
        this.orderText += `▶ *RESUMO DO PEDIDO* \n`
        this.orderText += `\n Pedido #${order.orderNoText}\n`
        this.orderText += `\n Link para acompanhar status do pedido:`
        this.orderText += `\n ${this.getTrackRoute(order.uuid)} \n`;

        //ITENS DO PEDIDO
        order.items.forEach(order => {
            if (order.type == 'prize'){
                itemsPrizes.push(order)
            }

            if (order.type == "general") {

                var total = 0;

                total += parseFloat((order.price));

                if (order.price > 0) {
                    this.orderText += "\n*" + order.quantity + "x* _" + order.name + "_ *(" + `${this.currency(order.price * order.quantity)}` + ")*\n";
                } else {
                    this.orderText += "\n*" + order.quantity + "x* _" + order.name + "_ \n";
                }

                order.extras.forEach(extra => {

                    total += extra.price * extra.quantity;

                    if (extra.price > 0) {
                        this.orderText += "  (" + extra.quantity + "x) " + extra.name + " (" + `${this.currency(extra.price * extra.quantity)}` + ")\n";
                    } else {
                        this.orderText += "  (" + extra.quantity + "x) " + extra.name + "\n";
                    }
                });

                total = total * order.quantity;

                this.orderText += `\n \n *Subtotal do item: ${this.currency(order.subTotal)}*`;


            } else if (order.type == "pizza") {

                var pizzaTotal = order.price;
                if (order.priceBehavior == "incremental") {
                    this.orderText += "\n*" + order.quantity + "x* _" + order.name + "_ *(" + `${this.currency(order.price * order.quantity)}` + ")*";
                } else {
                    this.orderText += "\n*" + order.quantity + "x* _" + order.name + "_";
                }
                if (order.crust) {
                    this.orderText += "\n -" + order.crust.name;
                    if (order.crust.price > 0) {
                        pizzaTotal += order.crust.price
                        this.orderText += " (" + `${this.currency(order.crust.price)}` + ")";
                    }
                }

                if (order.dough) {
                    this.orderText += "\n -" + order.dough.name;
                    if (order.dough.price > 0) {
                        pizzaTotal += order.dough.price
                        this.orderText += " (" + `${this.currency(order.dough.price)}` + ")";
                    }
                }

                order.flavors.forEach(flavor => {
                    if (flavor.price > 0) {

                        let flavorFullPrice = flavor.price;
                        if (order.priceBehavior == "incremental") {
                            flavorFullPrice = flavor.price * flavor.quantity
                            pizzaTotal += flavorFullPrice;
                        }

                        this.orderText += "\n -" + flavor.name + " (" + `${this.currency(flavorFullPrice)}` + ")";
                    } else {
                        this.orderText += "\n -" + flavor.name;
                    }

                    if (flavor.notes) {
                        this.orderText += ` _(${flavor.notes})_`
                    }

                    let additionals = '';

                    if ('additionalToppings' in flavor && flavor.additionalToppings.length > 0) {
                        additionals = ` -- Adicionar: `;

                        flavor.additionalToppings.forEach(function (additionalTopping, index) {
                            if (index == 0) {
                                additionals += additionalTopping.name;
                            }

                            if (index > 0) {
                                additionals += ', ' + additionalTopping.name;
                            }

                            additionals += ' (' + `${this.currency(additionalTopping.price)}` + ')';
                        }, this);
                    }

                    this.orderText += additionals;
                });

                pizzaTotal = pizzaTotal * order.quantity;

                this.orderText += `\n \n *Subtotal do item: ${this.currency(order.subTotal)}*`;
                this.orderText += "\n";

            } else if (order.type == 'combo') {

                this.orderText += "\n*" + order.quantity + "x* _" + order.name + "_ *(" + `${this.currency(order.price * order.quantity)}` + ")* \n";

                // for each pizza in the combo
                order.comboItems.forEach(comboItem => {

                    this.orderText += "\n  >>> *" + comboItem.name + "*";

                    if (comboItem.crust) {
                        this.orderText += "\n\n -" + comboItem.crust.name;
                        if (comboItem.crust.price > 0) {
                            this.orderText += " (" + `${this.currency(comboItem.crust.price)}` + ")";
                        }
                    }

                    if (comboItem.dough) {
                        this.orderText += "\n\n -" + comboItem.dough.name;
                        if (comboItem.dough.price > 0) {
                            this.orderText += " (" + `${this.currency(comboItem.dough.price)}` + ")";
                        }
                    }

                    comboItem.flavors.forEach(flavor => {
                        if (flavor.price > 0) {
                            
                            let flavorFullPrice = flavor.price;
                            if (comboItem.priceBehavior == "incremental") {
                                flavorFullPrice = flavor.price * flavor.quantity
                                pizzaTotal += flavorFullPrice;
                            }
    
                            this.orderText += "\n -" + flavor.name + " (" + `${this.currency(flavorFullPrice)}` + ")";
                        } else {
                            this.orderText += "\n -" + flavor.name;
                        }

                        if (flavor.notes) {
                            this.orderText += ` _(${flavor.notes})_`
                        }

                        let additionals = '';

                        if ('additionalToppings' in flavor && flavor.additionalToppings.length > 0) {
                            additionals = ` -- Adicionar: `;

                            flavor.additionalToppings.forEach(function (additionalTopping, index) {
                                if (index == 0) {
                                    additionals += additionalTopping.name;
                                }

                                if (index > 0) {
                                    additionals += ', ' + additionalTopping.name;
                                }

                                additionals += ' (' + `${this.currency(additionalTopping.price)}` + ')';
                            }, this);
                        }

                        this.orderText += additionals;
                    });

                    this.orderText += "\n\n";
                });

                order.extras.forEach(extra => {
                    this.orderText += "\n >" + extra.quantity + "x *" + extra.name + "*";

                    if (extra.price > 0) {
                        this.orderText += " *(" + `${this.currency(extra.price)}` + ")* \n";
                    }
                })


            }

            if (order.notes) {
                this.orderText += `\n -- _Observações: ${order.notes}_\n`;
            }

            this.orderText += "\n -  -  -  -  -  -  -  -  -  -  -\n";
        });

        if (itemsPrizes.length > 0) {
            this.orderText += `\n*Prêmios*\n`
        }

        itemsPrizes.forEach(item => {
            this.orderText += "\n*" + item.quantity + "x* _" + item.name + "_ *(" + `${item.pointsSpent}` + ` Pontos` + ")*";
        })

        if (itemsPrizes.length > 0) {
            this.orderText += "\n -  -  -  -  -  -  -  -  -  -  -\n";
        }

        // Total ou Subtotal (se for para retirada será total, se for para entrega é subtotal)
        if (order.deliveryType == "balcony") {

            if (order.paymentMethodOperationValue > 0) {
                if (order.paymentMethodOperation == "increase") {
                    order.total += order.paymentMethodOperationValue
                } else {
                    order.total -= order.paymentMethodOperationValue
                }

            }

            if (order.discountValue > 0) {
                this.orderText += `\n*Pedido:* ${this.currency(order.total)}`;
                this.orderText += `\n*Desconto:* ${this.currency(order.discountValue)} `;

                if(order.paymentMethodOperationValue > 0){
                    if(order.paymentMethodOperation == "increase")
                        this.orderText += `\n*Acréscimo pela forma de pagamento:* ${this.currency(order.paymentMethodOperationValue)} `;
                    else
                        this.orderText += `\n*Desconto pela forma de pagamento:* ${this.currency(order.paymentMethodOperationValue)} `;
                }

                this.orderText += "\n*TOTAL:* " + `${this.currency(order.total - order.discountValue)}`;
            } else {
                if(order.paymentMethodOperationValue > 0){
                    if(order.paymentMethodOperation == "increase")
                        this.orderText += `\n*Acréscimo pela forma de pagamento:* ${this.currency(order.paymentMethodOperationValue)} \n`;
                    else
                        this.orderText += `\n*Desconto pela forma de pagamento:* ${this.currency(order.paymentMethodOperationValue)} \n`;
                }

                this.orderText += "\n*TOTAL:* " + `${this.currency(order.total)}`;
            }

        } else if (order.deliveryType == "delivery") {

            this.orderText += "\n*SUBTOTAL:* " + `${this.currency(order.total)}`;
        }

        this.orderText += "\n\n ------------------------------------------";

        // DADOS DO USUÁRIO PARA RETIRADA OU ENTREGA
        if (order.deliveryType == "balcony") {
            this.orderText += "\n▶ *DADOS PARA RETIRADA* \n \n";
            if (order.carTakeout) {
                this.orderText += "\n*Retirada no carro* \n";
                this.orderText += "\n*Cor / Modelo: " + `${order.carModelColor}` + "* \n";
                this.orderText += "\n*Placa: " + `${order.carPlate}` + "* \n";
            }
            this.orderText += "*Nome:* " + order.name;
            this.orderText += "\n*Telefone:* " + order.phone;

            if (this.restaurant.info.takeout_time > 0 && !order.scheduleTo) {
                this.orderText += `\n \n🕙 *Tempo de Retirada:* aprox. ${this.utilsService.parseOrderPrevision(order.createdAt, this.restaurant.info.takeout_time)}`;
            }

        } else if (order.deliveryType == "delivery") {

            this.orderText += "\n▶ *Dados para entrega* \n \n";
            this.orderText += "*Nome:* " + order.name;
            this.orderText += "\n*Endereço:* " + order.address + ", nº: " + order.streetNumber;
            this.orderText += "\n*Bairro:* " + order.bairro;


            if (order.complemento) {
                this.orderText += "\n*Complemento:* " + order.complemento;
            }

            if (order.addressRef) {
                this.orderText += "\n*Ponto de Referência:* " + order.addressRef;
            }

            this.orderText += "\n*Telefone:* " + order.phone;
            this.orderText += "\n\n*Taxa de Entrega:* " + `${this.currency(order.deliveryFee)}`;

            if (this.restaurant.info.delivery_time_min > 0 && !order.scheduleTo) {
                this.orderText += "\n\n 🕙 *Tempo de Entrega:* " + `aprox. ` +  this.utilsService.parseOrderPrevision(order.createdAt, this.restaurant.info.delivery_time_min) + ` a ` + this.utilsService.parseOrderPrevision(order.createdAt, this.restaurant.info.delivery_time_max);
            }
        }

        if(order.scheduleTo)
            this.orderText += "\n\n 📆 *Agendado para:* " + this.utilsService.scheduleOrderPrevision(order.scheduleTo, this.restaurant.info.scheduleSettings.intervalBetweenTimes);

        this.orderText += "\n\n ------------------------------- \n";

        if (order.discountValue > 0) {
            this.orderText += `▶ *Desconto* = `;
            this.orderText += "*" + `${this.currency(order.discountValue)}` + "*";
            this.orderText += "\n";
        }

        if (order.deliveryType == "delivery") {

            if(order.paymentMethodOperationValue > 0){
                if(order.paymentMethodOperation == "increase")
                    this.orderText += `\n*Acréscimo pela forma de pagamento:* ${this.currency(order.paymentMethodOperationValue)} \n\n`;
                else
                    this.orderText += `\n*Desconto pela forma de pagamento:* ${this.currency(order.paymentMethodOperationValue)} \n\n`;
            }

            this.orderText += "▶ *TOTAL* = ";
            this.orderText += "*" + `${this.currency(order.totalNetValue)}` + "*";
            this.orderText += "\n ------------------------------ \n\n";
        }

        // PAGAMENTO
        this.orderText += "▶ *PAGAMENTO* \n \n";

        const PAYMENT_ENUM = {
            'pixAsaas': 'Pix Automático',
            'picpay': 'PicPay',
            'mercadopago': 'Mercado Pago',
            'stripe' : 'Stripe',
            'pagseguro': 'PagSeguro',
            'multipedidos@asaas': 'Cartão de crédito',
        }

        if (order.paymentMethod == "money") {

            this.orderText += "Pagamento em Dinheiro \n";
            if (order.needChange) {
                this.orderText += "Troco para *" + `${this.currency(order.change)}` + "*";
            }

        } else if (order.paymentMethod == "creditCard") {
            this.orderText += "Pagamento no cartão \n";
            if (order.cardType) {
                this.orderText += `Cartão: ` + order.cardType;
            }

        } else if (order.paymentMethod == "pix") {
            this.orderText += "Pagamento com Pix \n";
            this.orderText += `Chave Pix: ${order.paySettings.key} \n`;
            this.orderText += `Tipo: ${this.translatePipe.transform(order.paySettings.key_type)} \n`
            this.orderText += `Beneficiário: ${order.paySettings.beneficiary}`
        } else if (order.onlinePaymentReference) {
            const paymentMethod = PAYMENT_ENUM[order.onlinePaymentReference] || order.onlinePaymentReference;
            this.orderText += `Pagamento via ${paymentMethod} \n`;
        } else if (order.paymentMethod == "deferredPayment") {
            this.orderText += "Pagamento no Crediário \n";
        }

        // CODIFICAÇÃO DO TEXTO PARA URL
        let whatsAppText = encodeURIComponent(this.orderText);


        return whatsAppText;
    }

    generateOrderLink(order){

        let text = `Olá! Este é o meu pedido \n \n`;
        text += `Pedido #` + order.orderNoText + "\n \n"
        text += this.getTrackRoute(order.uuid);
        text += `\n \n (link para detalhes e acompanhamento do pedido)`
        return encodeURIComponent(text);
    }

    sendOrderToWhatsapp(order) {
        const href = "https://api.whatsapp.com/send?phone=" + this.restaurant.info.whatsapp + "&text=" + this.generateText(order);

        if (window.self !== window.top)
            window.open(href, '_blank');
        else
            window.location.href = href;
    }

    getTrackRoute(orderUUID) {
        return `${this.baseUrl}/app/${this.restaurant.info.restaurant_url}/track?token=${orderUUID}`;
    }

    currency(value) {
        return this.multiCurrencyPipe.transform(value)
    }
}
