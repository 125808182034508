<ion-header>
	<ion-toolbar color="danger">
		<div class="container-header">
			<ion-label class="title text-wrap" translate>Invalid delivery type</ion-label>
		</div>
	</ion-toolbar>
</ion-header>


<div class="center-div-message">
	<p>{{ 'Delivery is only available on orders over' | translate : {value: minimalValue} }}.</p>
</div>

<ion-button class="mb-1" color="success" expand="block"  mode="ios"
	style="--padding-bottom: 5px; --padding-top: 5px; font-weight: 800; font-size: 1.0em;" (click)="goToMenu()"
	translate>Order More<ion-icon name="arrow-forward-outline" style="margin-left: 10px;"></ion-icon>
</ion-button>
<ion-button class="mb-1" color="danger" expand="block" size="small" mode="ios"
	style="--padding-bottom: 5px; --padding-top: 5px; font-weight: 800; font-size: 1.0em;" (click)="dismissModal()">
	{{'Choose another delivery type' | translate}}
</ion-button>