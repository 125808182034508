<ion-header>
	<ion-toolbar color="danger" style="text-align: center;">
		<ion-buttons slot="start">
			<ion-button (click)="dismissModal()">
				<ion-icon name="chevron-back-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px;" translate text-wrap>Delivery Address</ion-label>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<div style="width: 100%;" class="text-center">
		<div id="map" [class]="mapState"></div>
	</div>

	<ion-grid>
		<div *ngIf="showDeliveryOptions">
			<ion-row style="margin-top: 50px;">
				<ion-col size="12">
					<ion-button expand="block" size="large" color="danger" mode="ios" fill="outline" class="mt-3" (click)="useLocation()"> <ion-icon name="location-outline"></ion-icon> {{'Use my location' | translate}}</ion-button>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col size="12">
					<ion-text class="refusal-text" style="text-align: center; display: block; text-decoration: underline;"  color="danger" mode="ios" data-cy="search-address"  class="mt-4" (click)="searchAddress()" translate><ion-icon name="search-outline" style="margin-left: 4px;"></ion-icon> Digite o seu endereço </ion-text>
				</ion-col>
			</ion-row>
		</div>

		<ion-row *ngIf="mapState == 'show'">
			<ion-col size="12">
				<ion-button mode="ios" color="danger" expand="block" (click)="confirmLocation()" translate>Confirm Location</ion-button>
			</ion-col>
		</ion-row>

		<ion-spinner name="dots" color="danger" duration="800" class="custom-spinner" *ngIf="mapState == 'decreased' && !addressLoaded"></ion-spinner>

        <div [hidden]="!addressLoaded && !askAddress">
            <form #addressForm="ngForm" >
                <ion-row *ngIf="needCity">
                    <ion-col size="12">
                        <div class="manual-neighborhood-select">
                            <ion-label translate>Select Your City</ion-label>
                            <ionic-selectable
                                #city="ngModel"
                                name="city"
                                [(ngModel)]="selectedCity"
                                [items]="cities"
                                itemValueField="id"
                                itemTextField="name"
                                [canSearch]="true"
                                (onChange)="cityChange($event)"
                                closeButtonText="{{'CANCEL' | translate}}"
                                [searchFailText]="searchFailText()"
                                searchPlaceholder="{{'Search' | translate}}"
                                required="true"
                                [ngClass]="{'invalid-input': (city.invalid && (city.dirty || city.touched))}"
                            >
                            </ionic-selectable>
                            <div *ngIf="(city.invalid && (city.dirty || city.touched))">
                                <ion-text class="invalid-input-text">{{'City is Required' | translate}}</ion-text>
                            </div>	                        
                        </div>
                    </ion-col>
                </ion-row>
            </form>

            <div [hidden]="needCity && !address.city">

                <ion-row>
                    <ion-col size="12">
                        <input normalizeInput name="addressStreet" id="addressStreet" autocomplete="off" enterkeyhint="done" data-cy="street" type="text" (ngModelChange)="askAddress = true" [placeholder]="streetInputPlaceholder" [(ngModel)]="address.street">
                    </ion-col>
                </ion-row>

                <ion-row *ngIf="!manualZoneSelection">
                    <ion-col size="12">
                        <input normalizeInput autocomplete="off" name="addressZone" id="addressZone" enterkeyhint="done" type="text" data-cy="zone" (ngModelChange)="askAddress = true" placeholder="{{'Neighborhood' | translate}} {{country != 'brazil' ? '('+translate('optional')+')' : ''}}" [(ngModel)]="address.zone">
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="manualZoneSelection">
                    <ion-col size="12">
                        <div class="manual-neighborhood-select">
                            <ion-label translate>Select Your Neighborhood</ion-label>
                            <ionic-selectable
                                [(ngModel)]="selectedZone"
                                [items]="zones"
                                itemValueField="id"
                                itemTextField="zone"
                                [canSearch]="true"
                                (onChange)="zoneChange($event)"
                                closeButtonText="{{'CANCEL' | translate}}"
                                [searchFailText]="searchFailText()"
                                searchPlaceholder="{{'Search' | translate}}">
                            </ionic-selectable>
                        </div>
                    </ion-col>
                </ion-row>

                <ion-row>
                    <ion-col size="12">
                        <input normalizeInput name="addressNumber" id="addressNumber" autocomplete="off" maxlength="10" enterkeyhint="done" data-cy="number" type="text" placeholder="{{'Number' | translate}}" [(ngModel)]="address.number">
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <input normalizeInput name="addressComplement" id="addressComplement" autocomplete="off" enterkeyhint="done" data-cy="complement" type="text" placeholder="{{'Complement' | translate}}" [(ngModel)]="address.complemento">                    
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <input normalizeInput  name="addressReference" id="addressReference" autocomplete="off" enterkeyhint="done" data-cy="reference" type="text" placeholder="{{'Reference' | translate}} {{referenceIsMandatory ?  '('+translate('required')+')' : '' }}" [(ngModel)]="address.address_ref">
                    </ion-col>
                </ion-row>
            </div>
        </div>
	</ion-grid>
</ion-content>

<ion-footer style="padding: 10px; box-shadow: 0px 0px 17px rgba(0,0,0,0.3); background-color: #FFF;">
	<ion-row style="margin-bottom: -5px;" *ngIf="allSet() && deliveryFee">
		<ion-col size="9" class="pt-0 pb-0">
			<label translate>Delivery Fee</label>:
		</ion-col>

		<ion-col size="3" class="pt-0 pb-0" style="text-align: right;">
			{{deliveryFee | multiCurrency}}
		</ion-col>
	</ion-row>

	<ion-row style="margin-bottom: -5px;" *ngIf="allSet() && deliveryFee && discount">
		<ion-col size="9" class="pt-0 pb-0">
			<label translate>{{discountType == 'flat_fee' ? 'Flat fee' : 'Discount'}}</label>:
		</ion-col>

		<ion-col size="3" class="pt-0 pb-0" style="text-align: right; color: red;">
			{{discountType == 'flat_fee' ? (discount | multiCurrency) : ('-' + (discount | multiCurrency))}}
		</ion-col>
	</ion-row>

	<ion-row style="margin-bottom: -5px;" *ngIf="allSet() && deliveryFee && discount">
		<ion-col size="9" class="pt-0 pb-0">
			<label translate>Total</label>:
		</ion-col>

		<ion-col size="3" class="pt-0 pb-0" style="text-align: right;">
			{{discountType == 'flat_fee' ? (discount | multiCurrency) : (deliveryFee - discount) | multiCurrency}}
		</ion-col>
	</ion-row>

	<ion-row style="font-size: 19px;" class="footerButton">
		<ion-col size="12" style="text-align: center;">
			<button data-cy="btnConfirm" class="confirm-button" [class]="allSet() ? '' : 'disabled-button'" [ladda]="searchingAddress" [disabled]="!allSet()" (click)="finishAddress()"><ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> {{'CONFIRM' | translate}}</button>
		</ion-col>
	</ion-row>
</ion-footer>