import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonInput } from '@ionic/angular';
import { MenuDTO } from 'src/app/shared/model/dto/MenuDTO';
import { Menu } from 'src/app/shared/model/Menu';
import { RestaurantService } from 'src/app/core/services/restaurant.service';
import { Events } from 'src/app/core/services/events.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-tablet',
	templateUrl: './tablet.page.html',
	styleUrls: ['./tablet.page.scss'],
})
export class TabletPage implements OnInit {
	@ViewChild(IonContent) content: IonContent;
	@ViewChild('ionicInput') ionicInput: IonInput;

	restaurant: MenuDTO
	restaurantUrl: String
	loaded: boolean = this.restaurantService.loaded
	restaurantIsBlocked: boolean = false;

	highlight = [];
	imgURL = environment.imgURL;

	searchString: String = "";
	searchResults: Menu
	categories = [];
	itemsToHide = { combos: true, pizzas: true, featuredItems: true };
	invalidURL = false

	constructor(
		private restaurantService: RestaurantService,
		private events: Events,
	) { }

	async ngOnInit() {
		if(!this.restaurantService.restaurant){
			this.invalidURL = true
			return
		}

		this.restaurant = this.restaurantService.restaurant;

		this.highlight = this.restaurantService.restaurant.info.highlightImages;

		this.checkCanUseMenu();

		this.getProductsOfRestaurant();
	}

	ngOnDestroy() {
		this.events.destroy('onClickSameTab');
		this.events.destroy('reloadRestaurant');
	}

	checkCanUseMenu() {
		if(this.restaurant.info.blocked || !this.restaurant.info.modules.digital_menu)
			this.restaurantIsBlocked = true;
	}

	getProductsOfRestaurant() {
		this.searchResults = this.restaurant.menu;

		this.itemsToHide.featuredItems = this.restaurant.menu.featuredItems.length == 0;

		this.itemsToHide.combos = true;
		this.restaurant.menu.combos.forEach(combo => {
			if (!combo.unavailable && !combo.unavailableByTemplate) this.itemsToHide.combos = false;
		});

		this.itemsToHide.pizzas = true;
		this.restaurant.menu.pizzas.sizes.forEach(pizzas => {
			if (!pizzas.unavailable && !pizzas.unavailableByTemplate) this.itemsToHide.pizzas = false;
		});

		this.categories = this.restaurant.menu.general.filter(category => {
			const everyProductsUnavailable = category.products.every(product => product.unavailable);

			if (category.unavailable || category.unavailableByTemplate || everyProductsUnavailable)
				return;

			return category;
		})
	}

	async scrollToElement(categoryElementID) {
		const element = document.getElementById(categoryElementID);
		const rect = element.getBoundingClientRect();
		const scrollElement = await this.content.getScrollElement();
		this.content.scrollToPoint(0, rect.top + scrollElement.scrollTop - 50, 1000);
	}

	filterMenu(str: String) {
		this.searchResults = this.restaurantService.filterMenu(str, this.restaurant.menu);
	}
}
