import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Map, tileLayer } from 'leaflet';
import { RestaurantService } from 'src/app/core';
import { AddressDTO } from 'src/app/shared/model/dto/AddressDTO';
import { ModalController, AlertController } from '@ionic/angular';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { OrderService } from 'src/app/core/services/order.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { TranslatePipe } from '@ngx-translate/core';
import { DeliveryService } from 'src/app/core/services/delivery.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { NgForm } from '@angular/forms';
import { RestaurantLocalStorageService } from "src/app/core/services/restaurantLocalStorage.service";
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';
import { CloudwatchMetricsService } from 'src/app/core/services/cloudwatchMetrics.service';

declare let google: any;

@Component({
	selector: 'app-address',
	templateUrl: './address.page.html',
	styleUrls: ['./address.page.scss'],
})
export class AddressPage implements OnInit {
    addressForm: NgForm;
    @ViewChild('addressForm') currentForm: NgForm;

	map: any;
	mapState = 'hidden';
	latitude: string;
	longitude: string;
	distance: number;

	@Input() storageAddress: any;
	address: AddressDTO = {
		street: '',
		number: '',
		zone: '',
		complemento: '',
		address_ref: '',
		city: '',
		region: '',
		deliveryFeeID: null,
		coordinates: {
			latitude: null,
			longitude: null
		}
	};

	showDeliveryOptions = true;
	askAddress = false;
	manualZoneSelection = false;
	deliveryFeeWithGPS = false;
	zones = [];
	selectedZone: any;

	cities = [];
	needCity = false;
	selectedCity: any;
	cityZonesAssoc = false;

	regions = [];
	selectedRegion: any;

	addressLoaded = false;
	searchingAddress = false;
	deliveryFee: any;
	deliveryFeeID: any;
	avaiableDeliveryAddress = false;
	discount = 0;
	discountType: any;
	country = ""

    successDelivery = false;
    
    referenceIsMandatory = this.restaurantService.restaurant.info.reference_is_mandatory;
    streetInputPlaceholder: string = this.translatePipe.transform('Street');

	constructor(
		private restaurantService: RestaurantService,
		private cartService: ShoppingCartService,
		private deliveryService: DeliveryService,
		private orderService: OrderService,
		private modalCtrl: ModalController,
		private alertCtrl: AlertController,
		private translatePipe: TranslatePipe,
		private utils: UtilsService,
		private restaurantLocalStorageService: RestaurantLocalStorageService,
		private keen: KeenTrackingService,
		private cloudwatchMetrics: CloudwatchMetricsService
	) { }

	ngOnInit() {
		this.deliveryFeeWithGPS = this.restaurantService.restaurant.info.delivery_fee_with_gps;

		if(this.storageAddress) this.address = this.storageAddress;
		this.needCity = this.restaurantService.restaurant.info.deliveryCities.length > 0;

		if(this.needCity) {
			this.cities = this.restaurantService.restaurant.info.deliveryCities;
			this.cityZonesAssoc = this.cities.filter(city => city.zones.length > 0).length > 0;
			this.selectedCity = this.cities.find(city => city.name == this.address.city);
		}

        this.manualZoneSelection = this.restaurantService.restaurant.info.delivery_fee_method == 'BAIRRO' && this.restaurantService.restaurant.info.manual_zone_selection;        
		if(this.manualZoneSelection || !this.deliveryFeeWithGPS) {
            this.streetInputPlaceholder = this.translatePipe.transform('Street');
		
			if(this.storageAddress) {
				try {
					this.selectedZone = JSON.parse(this.restaurantLocalStorageService.getItem(`zone_object`));
				} catch (error) {
					console.error('Stored zone error:', error);
				}
			}

			this.checkAddress();
			this.searchAddress();
			this.setZones();
		}

		this.country = this.restaurantService.restaurant.info.country
	}

	setZones() {
		if(!this.manualZoneSelection)
			return;

		const getOnlyAvailableZones = zones => {
			return zones.filter(zone => zone.available);
		}

		if(this.needCity && this.cityZonesAssoc) {
			if(!this.selectedCity) return;
			return this.zones = getOnlyAvailableZones(this.selectedCity.zones);
		}

		this.deliveryService.getDeliveryZones().then(result => {
			this.zones = getOnlyAvailableZones(result);
		}).catch(error => {
			let message = 'Error trying to fetch zones';
			this.utils.createToaster(message);
			this.dismissModal();
		});
	}

	checkAddress() {
		if(this.address.street){
			this.calculateDeliveryFee(this.address, 'zone')
		}
	}

	zoneChange(event: {
		component: IonicSelectableComponent,
		value: any
	}) {
		this.selectedZone = event.value;
		this.address.zone = this.selectedZone.zone;
		this.calculateDeliveryFee(this.address, 'zone');
	}

	cityChange(event: {
		component: IonicSelectableComponent,
		value: any
	}) {
		this.selectedCity = event.value;
		this.address.city = this.selectedCity.name;
		this.setZones();
	}

	regionChange(event: {
		component: IonicSelectableComponent,
		value: any
	}) {
		this.selectedRegion = event.value;
		this.deliveryFee = this.selectedRegion.fee + this.selectedRegion.returnFee;
		this.address.region = this.selectedRegion.region_id;
	}

	searchFailText() {
		return this.translatePipe.transform('No zones found.');
	}

	searchAddress() {
		this.cloudwatchMetrics.used_type_your_address();
		this.showDeliveryOptions = false;
		this.askAddress = true;
	}

	confirmLocation() {
        this.makeMapSmaller();

		let address = {
			coordinates: {
				'latitude': this.latitude,
				'longitude': this.longitude
			}
        };
        
        this.calculateDeliveryFee(address, 'map');
    }

    makeMapSmaller() {
		this.mapState = 'decreased';

		if(this.restaurantService.restaurant.info.maps_provider === 'google_maps') {
			this.map.zoomControl = false;
		}else {
			this.map.zoomControl.remove();
			setTimeout(() => {
				this.map.invalidateSize();
			}, 500);
		}        
    }

    finishAddress() {
		if(this.askAddress && !this.manualZoneSelection) {
            this.calculateDeliveryFee(this.address, 'userInput').then(() => {
				this.dismissModal();
			});
		}else {
			this.dismissModal();
		}
	}
    
    calculateDeliveryFee(address, type) {
        this.searchingAddress = true;
        this.addressLoaded = false;
        this.address.distance = null;
		this.address.deliveryFeeID = null;

		return this.deliveryService.calculateDeliveryFee(address).then(result => {
            if(result.found && result.delivery) {
				this.successDelivery = true;
                this.deliveryFee = (result.deliveryFee > 0) ? result.deliveryFee : '0';
                this.deliveryFeeID = result.deliveryFeeID;
                if(result.distance) this.distance = result.distance;

				let isCep = this.address.street.replace(/\D/g, "").length == 8;
				let shouldChangeUserInput = type == 'map' || isCep;
				
				if (shouldChangeUserInput) {
					this.address.street = result.street ?? this.address.street;
				}

                if(!this.manualZoneSelection && shouldChangeUserInput) {
                    this.address.zone = result.zone ?? this.address.zone;
                }

				this.address.city = result.city ?? this.address.city;
                this.address.number = result.number ?? this.address.number;
                this.selectedCity = this.cities.filter(city => city.name == this.address.city)[0] ?? '';
                if (this.needCity)
                    this.currentForm.form.controls["city"].markAsTouched();
                this.avaiableDeliveryAddress = true;
				
				this.keen.event('addAddress', {
					'address': this.address,
					'deliveryFee':  this.deliveryFee,
					'deliveryFeeID': this.deliveryFeeID
				});		
				this.orderService.addAddress(this.address, this.deliveryFee, this.deliveryFeeID);
                return this.calculateDeliveryFeeDiscount();
            } else {
				this.successDelivery = false;
				this.addressErrorAlert(!result.found);
				this.avaiableDeliveryAddress = false;
				this.dismissModal();
            }
        }).catch(error => {
			this.successDelivery = false;
            let message = 'Error trying to calculate delivery fee.'
            this.utils.createToaster(message);
            this.dismissModal();
        }).finally(() => {
            this.searchingAddress = false;
            this.addressLoaded = true;
        })
    }

	calculateDeliveryFeeDiscount() {
		let orderTotal = this.cartService.total();
		return this.deliveryService.calculateDeliveryFeeDiscount(orderTotal, this.deliveryFee).then(discount => {
			this.discount = discount['discountValue'];
			this.discountType = discount['discountType'];
			this.orderService.order.details.deliveryFeeDiscount = this.discount;
			this.orderService.order.details.discountType = this.discountType;
		});
    }

    saveDataInLocalStorage() {
		this.restaurantLocalStorageService.setItem('deliveryFeeID', this.deliveryFeeID);
        this.restaurantLocalStorageService.setItem(`street`, this.address.street);
        this.restaurantLocalStorageService.setItem(`zone`, this.address.zone);
        this.restaurantLocalStorageService.setItem(`complemento`, this.address.complemento);
		this.restaurantLocalStorageService.setItem(`address_ref`, this.address.address_ref);

        if(this.address.number) this.restaurantLocalStorageService.setItem(`number`, this.address.number);
        else this.restaurantLocalStorageService.setItem(`number`, '');

        if(this.manualZoneSelection) this.restaurantLocalStorageService.setItem(`zone_object`, JSON.stringify(this.selectedZone));
        else this.restaurantLocalStorageService.setItem(`zone_object`, '');

        if(this.address.city) this.restaurantLocalStorageService.setItem(`city`, this.address.city);
        else this.restaurantLocalStorageService.setItem(`city`, '');

        if(this.latitude) this.restaurantLocalStorageService.setItem(`latitude`, this.latitude);
        else this.restaurantLocalStorageService.setItem(`latitude`, '');

        if(this.longitude) this.restaurantLocalStorageService.setItem(`longitude`, this.longitude);
        else this.restaurantLocalStorageService.setItem(`longitude`, '');

        if(this.distance) this.restaurantLocalStorageService.setItem(`distance`, this.distance.toString());
        else this.restaurantLocalStorageService.setItem(`distance`, '');
    }

    dismissModal() {
		this.address.coordinates.latitude = this.latitude ?? null;
		this.address.coordinates.longitude = this.longitude ?? null;

		if(this.successDelivery)
			this.saveDataInLocalStorage();

		this.modalCtrl.dismiss({
            'dismissed': true,
            'address': this.address,
            'deliveryFee': this.deliveryFee,
            'deliveryFeeDiscount': this.discount,
			'deliveryFeeID': this.deliveryFeeID,
			'successDelivery': this.successDelivery,
			'discountType' : this.discountType
        });
    }
    
	async addressErrorAlert(notFound=false) {
		let error = notFound ? 'Address not found' : 'Unfortunately your address is not in our delivery area';

		const alert = await this.alertCtrl.create({
			header: this.translatePipe.transform('Warning') + '!',
			message: this.translatePipe.transform(error) + ' :(',
			buttons: ['OK']
		});

		await alert.present();
	}

	useLocation() {
		this.cloudwatchMetrics.used_use_my_location_button();

		this.mapState = 'show';
		this.showDeliveryOptions = false;

		if (!navigator.geolocation) {
			this.locationDenied();
		}

		navigator.geolocation.getCurrentPosition(position => {
			this.cloudwatchMetrics.location_services_accepted();
			this.initializeMap(position.coords.latitude, position.coords.longitude);
		}, () => {
			this.locationDenied();
		});
	}

	locationDenied() {
		this.cloudwatchMetrics.location_services_denied();
		this.mapState = 'hidden';
		this.askAddress = true;
	}

	initializeMap(latitude, longitude) {
		if (this.restaurantService.restaurant.info.maps_provider === 'google_maps')
			this.initializeGoogleMap(latitude, longitude);
		else
			this.initializeLeafletMap(latitude, longitude);
	}

	initializeLeafletMap(latitude, longitude) {
		this.map = new Map("map").setView([latitude, longitude], 18);
		tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			{ attribution: 'Map data © <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY- SA</a>' })
			.addTo(this.map);

		this.latitude = this.map.getCenter().lat;
		this.longitude = this.map.getCenter().lng;

		this.map.on('moveend', () => {
			this.latitude = this.map.getCenter().lat;
			this.longitude = this.map.getCenter().lng;
		});
	}

	initializeGoogleMap(latitude, longitude) {
		let mapOptions = {
			zoom: 18,
			center: new google.maps.LatLng(latitude, longitude),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true,
			zoomControl: true,
			gestureHandling: 'cooperative'
		}
		this.map = new google.maps.Map(document.getElementById('map'), mapOptions);

		this.latitude = this.map.getCenter().lat();
		this.longitude = this.map.getCenter().lng();

		google.maps.event.addListener(this.map, 'dragend', () => {
			this.latitude = this.map.getCenter().lat();
			this.longitude = this.map.getCenter().lng();
		});
	}

	allSet() {
		if(!this.successDelivery && this.manualZoneSelection) return false;
		if(!this.askAddress && this.mapState == 'hidden') return false;
		if(!this.avaiableDeliveryAddress && this.mapState != 'hidden') return false;
		if(!this.address) return false;
		if(!this.address.street || !this.address.street.match("[^ ]")) return false;
		if(!this.address.zone && this.country == 'brazil' && !this.address.zone.match("[^ ]")) return false;
		if(!this.address.city && this.needCity && !this.address.city.match("[^ ]")) return false;
		if(this.needCity && !this.cities.some(city => city.name == this.address.city)) return false;
		if(this.restaurantService.restaurant.info.street_number_is_mandatory && !this.address.number.match("[^ ]")) return false;
		if(this.referenceIsMandatory && !this.address.address_ref.match("[^ ]")) return false;
		return true;
	}

	translate(phrase) {
		return this.translatePipe.transform(phrase)
	}
}
