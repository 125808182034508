import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NavService } from './nav.service';

@Injectable({
	providedIn: 'root'
})
export class DataGuardService implements CanActivate {

	constructor(private navService: NavService, private actRoute: ActivatedRoute) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {		
		if (!this.navService.data || this.navService.data.length == 0){
			this.navService.setRoot('');
			return false;
		}

		return true;
	}
}
