import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
	selector: 'password-strength-meter',
	templateUrl: './password-strength-meter.page.html',
	styleUrls: ['./password-strength-meter.page.scss'],
})
export class PasswordStrengthMeter implements OnInit, OnChanges {

	@Input() password : any;
	@Output() strengthChange = new EventEmitter<number>();
	passwordLevel = 0
	passwordMeterStage = ''
	passwordStrengthText = ''

	strengthAdvice = [
		this.translatePipe.transform("Too Weak"),
		this.translatePipe.transform("Weak"),
		this.translatePipe.transform("Good"),
		this.translatePipe.transform("Strong"),
		this.translatePipe.transform("Very Strong"),
	];

	constructor(
		private translatePipe: TranslatePipe
	) { }

	ngOnInit() {
		document.querySelector('.strength-meter-fill').setAttribute('data-strength', '');
	}

	ngOnChanges(changes: SimpleChanges) {
		if(!changes.password) return;

		this.checkPasswordLevel(changes.password.currentValue)
		this.passwordMeterStage = this.password ? this.passwordLevel.toString() : ''
		document.querySelector('.strength-meter-fill').setAttribute('data-strength', this.passwordMeterStage);
		this.passwordStrengthText = this.strengthAdvice[this.passwordMeterStage]
	}

	checkPasswordLevel(password){
		let passwordLevel = 0;
		let lettersRegex = /[a-zA-Z]/g;
		let numbersRegex = /[0-9]/g;
		let especialCharactersRegex = /[!@#$%\^&*)(+=._-]/g;

		if (password.length >= 8) passwordLevel++;
		if (lettersRegex.test(password)) passwordLevel++;
		if (numbersRegex.test(password)) passwordLevel++;
		if (especialCharactersRegex.test(password) && passwordLevel >= 3) passwordLevel++;		

		this.passwordLevel = passwordLevel;
		this.strengthChange.emit(this.passwordLevel);
	}
}
