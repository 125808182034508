import { Component, Input, OnInit } from '@angular/core';
import { AnimationController, ModalController } from '@ionic/angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { NavService } from 'src/app/core/services/nav.service';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { ViewerModalComponent } from 'ngx-ionic-image-viewer';

import { MenuProduct } from 'src/app/shared/model/MenuProduct';
import { Extra } from 'src/app/shared/model/Extra';
import { Option } from 'src/app/shared/model/Option';
import { ComboDTO } from 'src/app/shared/model/dto/ComboDTO';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/core/services/utils.service';
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';

import { TranslatePipe } from '@ngx-translate/core';
import { RestaurantService } from 'src/app/core';
import { ShoppingCartPage } from 'src/app/pages/shopping-cart/shopping-cart.page';
import { MenuSelector } from 'src/app/core/services/menuSelector.service';

@Component({
	selector: 'app-extra',
	templateUrl: './extra.page.html',
	styleUrls: ['./extra.page.scss'],
})
export class ExtraPage implements OnInit {

	combo: ComboDTO;
	@Input() extras: Extra[] = [];
	@Input() item: MenuProduct;

	itemTemplate: MenuProduct;
	imgURL = environment.imgURL;

	public selectedExtras: Option[] = [];
	public minQtyIncrementByFive: number;
	public minQtyIncrementByTen: number;

	constructor(
		private navService: NavService,
		private cartService: ShoppingCartService,
		private modalController: ModalController,
		private utilsService: UtilsService,
		private translatePipe: TranslatePipe,
		private keen: KeenTrackingService,
		private rService: RestaurantService,
		private menuSelector: MenuSelector,
		private animationCtrl: AnimationController,
        private sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		if (this.menuSelector.menuRoute === 'menu') {
			this.extras = this.navService.get('extras');

			if (!this.extras) this.navService.pop();

			this.item = this.navService.get('item');
			if(this.navService.get('combo')) this.combo = this.navService.get('combo');
		}

		this.minQtyIncrementByFive = 50;
		this.minQtyIncrementByTen = 100;
		this.extras = this.extras.filter(extra => extra.options.length > 0).map(itemExtra => {
			itemExtra.qty = 0;
			itemExtra.options.forEach(option => option.qty = 0);
			itemExtra.radioGroup = 0;
			return itemExtra;
		});
	}

	onImgError(item) {
		item.image = '';
	}

	checkboxBeyondLimit(index) {
		return this.extras[index].options.filter(item => item.isChecked).length >= this.extras[index].qty_max;
	}

	scrollToCategory(extraElementId, smooth = false) {
		var categoryElement = document.getElementById(extraElementId);
		categoryElement.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto', block: 'start' });
	}

	jumpToNextExtra(extraIndex) {
		let nextExtraID = this.extras[extraIndex + 1].id;
		setTimeout(() => {
			this.scrollToCategory('extra-' + nextExtraID, true);
		}, 50);
	}

	changeInputQuantity(extra, optionSelected) {

		let inputValue = optionSelected.qty?.toString();

		optionSelected.qty = this.parseQuantity(inputValue);

		let totalExtra = extra.options.reduce((total,option) => {
			if(option.id !== optionSelected.id)
				return total += option.qty;
			return total;
		}, 0)

		if (optionSelected.qty > extra.qty_max || totalExtra >= extra.qty_max || (totalExtra + optionSelected.qty > extra.qty_max) || (extra.qtyPerItem && (optionSelected.qty > extra.qtyPerItem)))
			this.utilsService.createToaster(this.translatePipe.transform("Maximum amount reached"));

		extra.qty = (totalExtra + optionSelected.qty);
	}

	parseQuantity(inputValue) {
		if (!inputValue)
			return null

		inputValue = inputValue.replace(/[^0-9,.]/g, "");

		if (inputValue.includes(',') || inputValue.includes('.'))
			inputValue = inputValue.split(/[,\.]/)[0][0];

		const parsedValue = parseInt(inputValue, 10);

		if (isNaN(parsedValue) || parsedValue < 0)
			return null

		return parsedValue;
	}


	firstIncrement(extraIndex, optionIndex) {
		let extra = this.extras[extraIndex];
		let selectedOption = extra.options[optionIndex];
		let isUniqueExtra = extra.type == 1;
		let isQttyExtra = extra.type == 2;
		let isMultipleExtra = extra.type == 3;

		if (selectedOption.asTitle) return;

		if (isUniqueExtra) {
			extra.radioGroup = selectedOption.id;
			extra.options.forEach(option => {
				option.qty = 0
			})
			selectedOption.qty = 1;

		} else if (isMultipleExtra && (extra.qtyPerItem == 1 || !extra.qtyPerItem)) {
			selectedOption.isChecked = !selectedOption.isChecked;
			selectedOption.qty = selectedOption.isChecked ? 1 : 0;
		} else if (selectedOption.qty == 0) {
			this.incrementQty(extraIndex, optionIndex);
		}

		let hasNextExtra = this.extras.length - 1 > extraIndex;
		if (hasNextExtra) {
			let extraIsFull = false;

			if (isUniqueExtra) extraIsFull = extra.radioGroup != 0;
			else if (isQttyExtra) extraIsFull = extra.qty == extra.qty_max;

			if (extraIsFull) {
				this.jumpToNextExtra(extraIndex);
			}
		}
	}

	incrementQty(extraIndex, optionIndex) {
		let extra = this.extras[extraIndex];
		if (extra.qty >= extra.qty_max || extra.qtyPerItem && extra.options[optionIndex].qty >= extra.qtyPerItem)
			return this.utilsService.createToaster(this.translatePipe.transform("Maximum amount reached"));
		let qty = 1;
		if (extra.qty_min >= this.minQtyIncrementByFive) qty = 5;
		if (extra.qty_min >= this.minQtyIncrementByTen) qty = 10;
		if (extra.qty + qty > extra.qty_max) qty = extra.qty_max - extra.qty;
		if (extra.qtyPerItem && extra.options[optionIndex].qty + qty > extra.qtyPerItem) qty = extra.qtyPerItem - extra.qty;
		extra.qty += qty;
		extra.options[optionIndex].qty += qty;
	}

	convertToSafeHtml(rawHtml: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(rawHtml);
    }

    getSafeHtml(value: any) {
        try {
            return this.convertToSafeHtml(JSON.parse(value));
        } catch (error) {
            return value;
        }
    }

	decrementQty(extraIndex, optionIndex, event) {
		event.stopPropagation();
		let extra = this.extras[extraIndex];
		if (extra.options[optionIndex].qty == null || extra.options[optionIndex].qty == 0) return
		let qty = 1;
		extra.qty -= qty;
		extra.options[optionIndex].qty -= qty;
	}

	getSelectedExtras() {
		this.selectedExtras = [];

		this.extras.forEach(extra => {
			extra.options.forEach(option => {
				let price = 0;
				if (extra.type == 1 && option.id == extra.radioGroup) {
					price = option.price
					option.total = price;
					this.selectedExtras.push(option);
				}
				else if ((extra.type == 2 || extra.type == 3) && option.qty > 0) {
					price = (option.qty * option.price);
					option.total = price;
					this.selectedExtras.push(option);
				}
			})
		})

		return this.selectedExtras;
	}

	calculateTotalPrice() {
		let totalPrice = this.item.price ? this.item.price : 0;

		totalPrice += this.selectedExtras.reduce((extraPrice, extra) => {
			return extraPrice += extra.total;
		}, 0);

		return totalPrice;
	}

	validateMinimumAndMaximumQuantity(extra){
		let qty = extra.options.reduce((total, option) => total += option.qty, 0);
		return qty >= extra.qty_min && qty <= extra.qty_max;
	}

	isDisabled() {

		let disabled = this.extras.some(extra => {
			let valid = false;
			if (extra.type == 1) {
				valid = extra.required ? extra.options.some(option => option.id == extra.radioGroup) : true
			} else 	if ((extra.type == 2 || extra.type == 3)) {
				valid = this.validateQuantityForExtraItem(extra);
			} else {
				let qty = extra.options.filter(option => option.isChecked).length;
				valid = qty >= extra.qty_min && qty <= extra.qty_max;
			}

			return !valid;
		})

		return disabled;
	}

	validateQuantityForExtraItem(extra){
		if (!extra.qtyPerItem) {
			return this.validateMinimumAndMaximumQuantity(extra);
		}

		const validQuantityPerItem = extra.options.every(option => option.qty <= extra.qtyPerItem);
		return validQuantityPerItem && this.validateMinimumAndMaximumQuantity(extra);
	}

	addToShoppingCart() {
		if(this.combo) {
            this.combo.extras = this.selectedExtras;
            this.combo.totalPrice += this.calculateTotalPrice();
			this.cartService.addProductToCart(this.combo);
		}else {
			this.item.extras = this.selectedExtras;
			this.item.qty = 1;
			console.log('item', this.item);
			this.cartService.addProductToCart(this.item);
		}

		this.modalController.dismiss();
		this.goToShoppingCart();
	}

	async goToShoppingCart() {
		if (this.menuSelector.isTabletLink()) {
			return this.modalController.dismiss();
		}

		this.navService.push('shopping-cart');
	}

	async openViewer(item) {

		const modal = await this.modalController.create({
			component: ViewerModalComponent,
			componentProps: {
				src: this.imgURL+'/products/'+item.image+'/lg_'+item.image+'.jpg',
				scheme: 'dark',
				swipeToClose: true,
				title: item.name,
				text: item.description ? item.description : ''
			},
			cssClass: 'ion-img-viewer',
			keyboardClose: true,
			showBackdrop: true
		});
	
		return await modal.present();
	}

	back() {
		this.keen.event('navigateBackward', {
			'from': 'pizza',
			'to': 'menu'
		});

		if (this.menuSelector.menuRoute === 'tablet')
			return this.modalController.dismiss();

		this.navService.navigateBackward("menu");
	}

	showStockPausedItems() {
		return this.rService.restaurant.info.stock_configuration.display_paused_items_as_sold_out;
	}
}
