import { Injectable } from '@angular/core';
import { OrderDTO } from 'src/app/shared/model/dto/OrderDTO';
import { AddressDTO } from 'src/app/shared/model/dto/AddressDTO';
import { ApiService } from 'src/app/core/services/api.service';
import { RestaurantService } from 'src/app/core/services/restaurant.service';
import { FingerprintService } from './fingerprint.service';
import { LocalStorageService } from './localStorage.service';
import { CartRecoverService } from './cartRecover.service';
import { ModalController } from '@ionic/angular';
import { SuccessfullyOrderDialogPage } from 'src/app/pages/delivery/successfully-order-dialog/successfully-order-dialog.page';
import { TranslatePipe } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OrderService {
    public order: OrderDTO;
    private fingerprint: string;

    private sharedProperties = [
        'id', 'type', 'notes', 'qty'
    ];

    private extrasProperties = {
        'extras': [
            'id', 'qty'
        ],
    };

    private pizzaProperties = [...this.sharedProperties, ...[
        {
            'crust': [
                'id',
                'sizeID'
            ]
        },
        {
            'dough': [
                'id',
                'sizeID'
            ]
        }, {
            'flavors': {
                'selected': [
                    'id',
                    'quantity',
                    'notes',
                    {
                        'additionalToppings': [
                            'id',
                            'sizeID'
                        ]
                    }
                ]
            },
        },
        {
            'extras': [
                'id', 'qty'
            ]
        }
    ]];

    private orderItemsProperties = {
        'combo':  [...this.sharedProperties, ...[
            this.extrasProperties,
            {'comboItems': this.pizzaProperties}
        ]],
        'pizza': this.pizzaProperties,
        'general': [...this.sharedProperties, ...[this.extrasProperties]],
        'prize': this.sharedProperties,
    };

    private orderCacheURL = environment.orderCacheURL;
    private orderCacheURLTracking = environment.orderCacheURLTracking;

    constructor(
        private apiService: ApiService,
        private restaurantService: RestaurantService,
        private fingerprintService: FingerprintService,
        private localStorageService: LocalStorageService,
        private cartRecoverService: CartRecoverService,
        private modalController: ModalController,
        private translatePipe: TranslatePipe,
        private http: HttpClient
    ) { 
        this.fingerprintService.getFingerPrint().then(() => {
            this.fingerprint = this.fingerprintService.fingerprint;
        });
    }

    initizalizeOrder() {
        this.order = {
            details: {
                deliveryType: '',
                deliveryFee: 0,
                deliveryFeeID: 0,
                deliveryFeeDiscount: 0,
                discountType: '',
                carTakeout: false,
                source: 'web',
                status: 'CREATED',
                cardType: '',
                cardMethod: '',
                paymentType: '',
                paymenthMethodOperation: '',
                paymenthMethodValue: 0,
                paymenthMethodValueType: '',
                operatedValue: 0,
                needChange: false,
                discountID: '',
                discountValue: 0,
                grandTotal: 0,
                total: 0,
                table: '',
                isTable: 0,
                schedule: false,
                guestCheckPad: null
            },
            user: {
                id: null,
                name: '',
                CEP: '',
                city: '',
                address: '',
                number: '',
                complemento: '',
                address_ref: '',
                zone: '',
                phone: '',
                email: '',
                cpf: '',
                region: '',
                one_signal_id: '',
                car_plate: '',
                car_model_color: '',
                debugMode: parseInt(this.localStorageService.getItem('debug_mode')),
                birthday: '',
            },
            orders: [],
            ratingAvg: null,
            ratingNote: '',
            askReview: null,
            fingerprint: this.fingerprint,
        }
    }

    isObject(item) {
        return typeof item === 'object' && item !== null && !(item instanceof Array);
    }

    removeTrashFromProduct(properties: any, productItems) {
        let sanitazedItems : any = [];

        let items = JSON.parse(JSON.stringify(productItems));

        let itemIsObject = this.isObject(items);

        if (itemIsObject) items = [items]

        items.forEach(currentItem => {

            let sanitazedItem : any = {};

            if (this.isObject(properties)) properties = [properties]
    
            properties.forEach(property => {    
                if (!this.isObject(property)) return sanitazedItem[property] = currentItem[property];
    
                let propertyName = Object.keys(property)[0];
                let propertyItem = currentItem[propertyName];
    
                if (!propertyItem || Object.keys(propertyItem).length === 0) return;

                let objectProperties: any = Object.values(property)[0];
                sanitazedItem[propertyName] = this.removeTrashFromProduct(objectProperties, propertyItem);
            });

            sanitazedItems.push(sanitazedItem);
            if (itemIsObject) sanitazedItems = sanitazedItem
        });

        return sanitazedItems;
    }

    sanitizeOrderToUpload(orders) {
        let sanitazedOrders = [];

        let currentOrder = JSON.parse(JSON.stringify(orders));

        currentOrder.forEach((order: any) => {
            if (!order.type) return;
            
            let properties = this.orderItemsProperties[order.type];
            
            if (!properties) return;

            let sanitazedItem = this.removeTrashFromProduct(properties, order);

            sanitazedOrders.push(sanitazedItem)
        })

        return sanitazedOrders;
    }

    addProduct(product) {
        if(!this.order) this.initizalizeOrder();

        if(product.type == 'pizza') {
            product.flavors = {
                selected: product.selectedFlavors
            }
        }

        if(product.type == 'combo') {
            product.comboItems.forEach(size => {
                size.flavors = {
                    selected: size.selectedFlavors
                }
            });
        }

        this.order.orders.push(product);
    }

    setDeliveryType(type: string) {        
        this.order.details.deliveryType = type;
        if(type != 'delivery') {
            this.order.details.deliveryFee = 0;
            this.order.details.deliveryFeeDiscount = 0;
        }
    }

    addAddress(address: AddressDTO, deliveryFee: number, deliveryFeeID: number) {
        this.order.details.deliveryType = 'delivery';
        this.order.details.deliveryFee = deliveryFee;
        this.order.details.deliveryFeeID = deliveryFeeID;
        this.order.details.coordinates = address.coordinates;

        this.order.user.city = address.city;
        this.order.user.address = address.street;
        this.order.user.number = address.number ? address.number : '';
        this.order.user.complemento = address.complemento;
        this.order.user.address_ref = address.address_ref;
        this.order.user.zone = address.zone;
        this.order.user.region = address.region;
        this.order.user.coordinates = address.coordinates;
    }

    setTable(table: string) {
        this.order.details.table = table;
    }

    addUser(user, carTakeout=false) {
        this.order.user.name = user.name;
        this.order.user.phone = user.phone;
        this.order.user.cpf = user.cpf;
        this.order.user.email = user.email;
        this.order.user.birthday = user.birthday;

        if(carTakeout) {
            this.order.details.carTakeout = true;
            this.order.user.car_plate = user.car_plate;
            this.order.user.car_model_color = user.car_model_color;
        }else {
            this.order.details.carTakeout = false;
        }
    }

    sendOrder() : Promise<any>{
        this.order.user.one_signal_id = this.localStorageService.getItem('OneSignalID');
        this.order.details.externalSource = this.restaurantService.externalSourceDetails.src;
        this.order.details.campaign = this.restaurantService.externalSourceDetails.campaign;

        this.order.details.linkUUID = this.cartRecoverService.uuid;
		this.order.details.isCartRecover = !!this.cartRecoverService.isRecover;

        return new Promise((resolve, reject) => {
            this.apiService.post(`/restaurant/${this.restaurantService.restaurant.info.id}/order`, this.order).subscribe(response => {
                resolve(response);
            }, error => {reject(error)})
        });
    }

    sendTableOrder() : Promise<any>{
        return new Promise((resolve, reject) => {
            this.apiService.post(`/restaurant/${this.restaurantService.restaurant.info.id}/order/table`, this.order)
                .subscribe(response => resolve(response), error => reject(error))
        });
    }

    getLoggedUserOrders(userID, sortingParams, offset, limit): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/restaurant/${this.restaurantService.restaurant.info.id}/public/client/${userID}/orders/paginate/${offset}/${limit}`, {
                sortSettings: sortingParams
            }).subscribe(data => {
                resolve(data);
            }, error => {
                reject(error);
            })
        })
    }

    getOrdersByUUID(ordersUUID, sortingParams, offset, limit): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/restaurant/${this.restaurantService.restaurant.info.id}/orders/get/${offset}/${limit}`, {
                ordersUUIDs: ordersUUID,
                sortSettings: sortingParams
            }).subscribe(data => {
                resolve(data);
            }, error => {
                reject(error);
            })
        })
    }

    toggleDebugMode() {
        if(!this.order) this.initizalizeOrder();
        this.order.user.debugMode = this.order.user.debugMode ? 0 : 1;
    }

    getOrderByToken(orderToken): Promise<OrderDTO> {
        return new Promise((resolve, reject) => {   
            this.apiService.get(`/order/uuid/${orderToken}`).subscribe(data => {
                resolve(data);
            }, error => {
                reject(error);
            })
        })
    }

    getCachedOrderByUUID_forTracking(orderUUID): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.orderCacheURLTracking}/${orderUUID}`).subscribe(data => {
                resolve(data);
            }, error => {
                reject(error);
            })
        })
    }

    rateOrder(orderRating) {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/order/${orderRating.orderID}/review`, orderRating).subscribe(data => {
                resolve(data.order);
            }, error => {
                reject(error);
            })
        })
    }

    getOrderByOrderAndClientID(orderID, clientID, restaurantID) : Promise<OrderDTO>{
        return new Promise((resolve, reject) => {
            this.apiService.post(`/order/track`, {orderID: orderID, clientID: clientID, restaurantID: restaurantID}).subscribe(data => {
                resolve(data.order);
            }, error => {
                reject(error);
            })
        })
    }

    async openSuccessfullyOrderModal(header, message) {
		const modal = await this.modalController.create({
			component: SuccessfullyOrderDialogPage,
			componentProps: {
				header: this.translatePipe.transform(header),
				message: this.translatePipe.transform(message),
			},
			backdropDismiss: false,
			cssClass: 'modal-successfullyOrder-dialog',
		});
		return await modal.present();
	}

    getCachedOrderByUUID(orderUUID) {
        if(!environment.production)
            return this.getByLocalhost(orderUUID);

       return this.getByProduction(orderUUID);
    }

    getByProduction(orderUUID){
        return new Promise((resolve, reject) => {
            this.http.get(`${this.orderCacheURL}/${orderUUID}.json`).subscribe(data => {
                resolve(data);
            }, error => {
                reject(error);
            })
        })
    }

    getByLocalhost(orderUUID){
        return new Promise((resolve, reject) => {
            this.http.get(`${this.orderCacheURL}/${orderUUID}`).subscribe(data => {
                resolve(data);
            }, error => {
                reject(error);
            })
        })
    }

}