import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from "@angular/common";
import { ModalController, AlertController } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { MultiCurrencyPipe } from 'src/app/shared/pipe/multiCurrency.pipe';
import { RestaurantService } from 'src/app/core';

@Component({
	selector: 'app-change-dialog',
	templateUrl: './change-dialog.page.html',
	styleUrls: ['./change-dialog.page.scss']
})
export class ChangeDialogPage implements OnInit {

	changeValue: number;
	grandTotal: number;
	needChange: boolean = false;
	paymenSettingstValue: number;
	paymenSettingstOperation: string;
	currency: string = this.restaurantService.restaurant.info.currency || "BRL"

	constructor(private cp: CurrencyPipe,
		private modalCtrl: ModalController,
		private alertController: AlertController,
		private translatePipe: TranslatePipe,
		private multiCurrencyPipe: MultiCurrencyPipe,
		private restaurantService: RestaurantService
	) { }

	ngOnInit() {}

	setNeedChange(boolean) {
		if (!boolean) return this.dismiss();

		this.needChange = boolean;
		this.focusOnChangeInput();
	}

	focusOnChangeInput() {
		setTimeout(() => {
			let element = document.getElementById("changeValue");
			element.focus()
		}, 400)
	}

	setOptionsCurrencyMask() {
		if (this.currency == "BRL") {
			return { prefix: 'R$ ', thousands: '.', decimal: ',', align: 'center' }
		} else if (this.currency == "PYG") {
			return { prefix: '₲ ', thousands: '.', decimal: '', precision: 0, align: 'center' }
		} else if (this.currency == "ARS") {
			return { prefix: '$ ', thousands: '.', decimal: '', precision: 0, align: 'center' }
		} else if (this.currency == "USD") {
			return { prefix: '$ ', thousands: ',', decimal: '.', align: 'center' }
		} else if (this.currency == "EUR") {
			return { suffix: ' €', prefix: '', thousands: '.', decimal: ',', align: 'center' }
		} else if (this.currency == "MZN") {
			return { suffix: ' MT', prefix: '', thousands: '.', decimal: '', precision: 0, align: 'center' }
		}
	}

	async dismiss() {

		await this.modalCtrl.dismiss({
			'dismissed': true,
			'changeValue': this.changeValue
		});
	}

}
