import { Injectable } from '@angular/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';

import { RestaurantService } from './restaurant.service';
import { WhatsappService } from './whatsapp.service';
import { FollowOrderPage } from 'src/app/pages/payment/follow-order/follow-order.page';
import { RedirectWhatsappPage } from 'src/app/pages/payment/redirect-whatsapp/redirect-whatsapp.page';
import { PicpayLinkDialogPage } from 'src/app/pages/payment/picpay-link-dialog/picpay-link-dialog.page';
import { NavService } from './nav.service';

@Injectable({
	providedIn: 'root'
})
export class AfterPaymentService {

    restaurant = this.restaurantService.restaurant;
	public whatsappText;

    constructor(
        private restaurantService: RestaurantService,
        private whatsappService: WhatsappService,
        private modalController: ModalController,
        private loadingController: LoadingController,
        private translatePipe: TranslatePipe,
        private alertController: AlertController,
		private navService: NavService
    ) { }

    open(order) {
        if (this.restaurant.info.order_status_page) {
            this.openFollowOrderModal(order);
            return;
        }

        this.whatsappText = this.restaurant.info.order_link ? this.whatsappService.generateOrderLink(order) : this.whatsappService.generateText(order);

        if (this.restaurant.info.has_automatic_whatsapp_message) {
            this.loadingMessageWhatsApp();
            return;
        }

        this.openRedirectWhatsAppModal();
    }

	redirectPage(order){
        const isOnlinePayment = ['picpay', 'mercadopago', 'pagseguro', 'stripe'].includes(order.paymentMethod) || order.paymentMethod.startsWith('pix');
        
        if (this.restaurant.info.has_automatic_whatsapp_message && !this.restaurant.info.order_status_page && !isOnlinePayment)
			this.whatsappService.sendOrderToWhatsapp(order);

		this.navService.push('/payment/success', order);
	}

    openPaymentReference(order) {
        if (order.paymentMethod == "picpay") {
            this.openPicpayModal(order);
            return;
        }

        this.openOnlinePaymentRedirect(order["onlinePaymentReference"]);        
    }

	async openFollowOrderModal(order) {
		const modal = await this.modalController.create({
			component: FollowOrderPage,
			componentProps: {
				order: order,
			},
			backdropDismiss: false,
			cssClass: "modal-followOrder-dialog",
		});
		return await modal.present();
	}

	async loadingMessageWhatsApp() {
		const loading = await this.loadingController.create({
			message: `${this.translatePipe.transform(
				"Redirecting to Whatsapp"
			)}...`,
		});
        await loading.present();

        const href = "https://api.whatsapp.com/send?phone=" + this.restaurant.info.whatsapp + "&text=" + this.whatsappText;

        let isIFrame = window.self !== window.top;
        if (isIFrame)
            window.open(href, '_blank');
        else 
            window.location.href = href;
            
        loading.dismiss();
    }
    
	async openRedirectWhatsAppModal() {
		const modal = await this.modalController.create({
			component: RedirectWhatsappPage,
			componentProps: {
                restaurant: this.restaurant,
                whatsappText: this.whatsappText
			},
			backdropDismiss: false,
			cssClass: "modal-redirectWhatsApp-dialog",
		});
		return await modal.present();
    }    
    
	async openPicpayModal(response) {
		const modal = await this.modalController.create({
			component: PicpayLinkDialogPage,
			componentProps: {
				linkPicpay: response.onlinePaymentReference,
			},
			backdropDismiss: false,
			cssClass: "modal-linkPicpay-dialog",
		});
		return await modal.present();
    }
    
	async openOnlinePaymentRedirect(onlinePaymentReference) {
		const alert = await this.alertController.create({
			header: "Redirecionamento",
			message:
				"Iremos te redirecionar para realizar o pagamento! Só um momento",
			buttons: [
				{
					text: "OK",
					handler: () => {
						window.location.href = onlinePaymentReference;
					},
				},
			],
		});

		await alert.present();
	}    
}