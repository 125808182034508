import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { MenuListPageRoutingModule } from "./menu-list-routing.module";

import { MenuListPage } from "./menu-list.page";
import { CardPageModule } from "../card/card.module";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		MenuListPageRoutingModule,
		CardPageModule,
	],
	declarations: [MenuListPage],
	exports: [MenuListPage],
})
export class MenuListPageModule {}
