<ion-header>
  <ion-toolbar *ngIf="!isWarning" [color]="isSuccess ? 'success' : 'danger'" style="text-align: center;">
      <div>
        <ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px; padding: 7px;"
        text-wrap>{{header}}</ion-label>
      </div>
  </ion-toolbar>
  <ion-toolbar *ngIf="isWarning" color="warning" style="text-align: center;">
    <div>
      <ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px; padding: 7px;"
      text-wrap>{{header}}</ion-label>
    </div>
</ion-toolbar>
</ion-header>

<div *ngIf="isSuccess" style="margin: auto; margin-bottom: 7px; margin-top: 4px;">
  <img src="assets/icon/check.svg" width="60">
</div>

<div *ngIf="!isSuccess" style="margin: auto; margin-bottom: 7px; margin-top: 4px;">
  <img *ngIf="!isWarning" src="assets/icon/close.svg" width="60">
  <img *ngIf="isWarning" src="assets/icon/warning.svg" width="60">
</div>


<div *ngIf="!value">
  <p style="text-align: center; font-size: 16px; padding: 2px 30px 0px 30px;">{{message}}</p>
</div>

<div *ngIf="value">
  <p style="text-align: center; font-size: 16px; padding: 2px 30px 0px 30px;">{{message}} {{value}}</p>
</div>

<div>
  <ion-button *ngIf="!isWarning" mode="ios" [color]="isSuccess ? 'success' : 'danger'" expand="block"  (click)="dismiss()" style="font-weight: 900;" translate>
    <ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> OK
  </ion-button>

  <ion-button *ngIf="isWarning" mode="ios" color="warning" expand="block"  (click)="dismiss()" style="font-weight: 900;" translate>
    <ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> OK
  </ion-button>
</div>