import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { OnesignalService } from "src/app/core/services/onesignal.service";

@Component({
	selector: "app-push-notification",
	templateUrl: "./push-notification.page.html",
	styleUrls: ["./push-notification.page.scss"],
})
export class PushNotificationPage implements OnInit {
	constructor(
		private modalCtrl: ModalController,
		private onesignalService: OnesignalService
	) {}

	ngOnInit() {}

	subscribePushNotifications() {
		this.onesignalService.registerForPushNotifications();
		this.dismiss();
	}

	async dismiss() {
		this.onesignalService.dismissPushNotifications();
		await this.modalCtrl.dismiss({
			dismissed: true,
		});
    }
}
