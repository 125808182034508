import { Injectable } from '@angular/core';

class NoopStorage {
    clear(): boolean {
        return false;
    }

    setItem(key: string, value: any){
        return null;
    }
    
    getItem(key: string): any {
        return null;
    }

    removeItem(key: string): false { 
        return false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private storage: Storage | NoopStorage;

    constructor() {
        try {
            this.storage = window.localStorage;
        } catch (e) {
            this.storage = new NoopStorage();  
        }
    }

    setItem(key: string, value: any): boolean {
        try {
            return this.storage.setItem(key, value);
        } catch (e) {
            if(e.message.includes('exceeded the quota')){
                this.delete_all_cardapios_from_local_storage();
                return this.setItem(key, value);
            }

            throw e;
        }
    }

    getItem(key: string): any {
        return this.storage.getItem(key);
    }

    removeItem(key: string): boolean | void {
        return this.storage.removeItem(key);
    }

    clear(): boolean | void {
        return this.storage.clear();
    }

    delete_all_cardapios_from_local_storage() {
        let cardapio_keys = Object.keys(window.localStorage).filter(k => k.startsWith('cardapio.'));

        for (let cardapio_key of cardapio_keys){
            window.localStorage.removeItem(cardapio_key);
        }
    }
}