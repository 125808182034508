import { Component, OnInit, Input } from '@angular/core';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { AnimationController, ModalController } from '@ionic/angular';
import { NavService } from 'src/app/core/services/nav.service';
import { ShoppingCartPage } from 'src/app/pages/shopping-cart/shopping-cart.page';
import { MenuSelector } from 'src/app/core/services/menuSelector.service';

@Component({
	selector: 'cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {

	products = [];


	constructor(
		private cartService: ShoppingCartService,
		private navService: NavService,
		private modalController: ModalController,
		private menuSelector: MenuSelector,
		private animationCtrl: AnimationController,
	) { }

	ngOnInit() {
		this.products = this.cartService.products;
		this.cartService.cartProductsChanged.subscribe(result => this.products = result);
	}

	async openShoppingCart() {
		if (this.menuSelector.isTabletLink()) {
			const modal = await this.modalController.create({
				component: ShoppingCartPage,
				cssClass: 'tablet-modal',
				leaveAnimation: (baseEl) => {
					const backdropAnimation = this.animationCtrl.create()
					  .addElement(baseEl.querySelector('ion-backdrop'))
					  .fromTo('opacity', '0.4', '0.01');

					const wrapperAnimation = this.animationCtrl.create()
					  .addElement(baseEl.querySelector('.modal-wrapper'))
					  .fromTo('transform', 'translateX(0)', 'translateX(100%)');

					return this.animationCtrl.create().addElement(baseEl)
					  .easing('ease-in-out')
					  .duration(300)
					  .addAnimation([backdropAnimation, wrapperAnimation]);
				},
			});
			return modal.present()
		}
		this.navService.push('shopping-cart');
	}

}
