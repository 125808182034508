<ion-card *ngIf="item.type != 'general' || item.hasStock === true || item.hasStock === false && showStockPausedItems()" data-cy="menuItem" class="product-card hover-anim" [ngClass]="{'card-product__without-stock' : item.hasStock === false}" mode="md" (click)="goToItemPage(item)">
	<ion-grid>
		<ion-row>
			<ion-col size="3" class="p-0 centralize" *ngIf="item.image">
				<mp-img class="item-image" imgURL="{{imgURL}}/products/{{item.image}}/thumb_{{item.image}}.jpg" (click)="openViewer(item); $event.stopPropagation();"></mp-img>
			</ion-col>
			<ion-col size="{{item.image ? '9' : '12'}}" class="p-0">
				<ion-card-header class="pb-0">
					<ion-card-title class="text-wrap item-name">{{item.name}}</ion-card-title>
					<ion-label class="text-wrap text-right" *ngIf="item.hasStock === false" data-cy="hasStock">Indisponível no momento</ion-label>
				</ion-card-header>
				<ion-card-content>
					<ion-label [innerHTML]="getSafeHtml(item.description)" class="text-wrap"></ion-label>
					<br>
                    <ion-label class="product-card-label" *ngIf="item.price > 0 && !item.fromPrice">
                        {{item.fromPrice}}
						<b *ngIf="item.oldPrice"><del style="color:grey;">{{item.oldPrice | multiCurrency}}</del>&nbsp;</b>
						<b>{{item.price | multiCurrency}}</b>
					</ion-label>
					<ion-label class="product-card-label" *ngIf="item.fromPrice">
						<b>{{'From_price' | translate}} </b>
						<del style="color:grey;" *ngIf="item.oldPrice">{{item.oldPrice | multiCurrency}} </del>
						<b>{{item.fromPrice | multiCurrency}}</b>
					</ion-label>
				</ion-card-content>
			</ion-col>
		</ion-row>
		<ion-row *ngIf="item.tag && !isTabletMenu">
			<ion-col size="12" class="text-center">
				<ion-text class="product-tag">{{item.tag}}</ion-text>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-card>