import { Component, OnInit, Input } from '@angular/core';
import {
    featureGroup,
    latLng,
    Map,
    marker,
    tileLayer,
} from 'leaflet';
import { RestaurantService } from 'src/app/core';
import { AddressDTO } from 'src/app/shared/model/dto/AddressDTO';
import { ModalController, AlertController } from '@ionic/angular';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { OrderService } from 'src/app/core/services/order.service';
import { TranslatePipe } from '@ngx-translate/core';
import { DeliveryService } from 'src/app/core/services/delivery.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { RestaurantLocalStorageService } from 'src/app/core/services/restaurantLocalStorage.service';
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';
import { PhotonService } from 'src/app/core/services/photon.service';
import { GeocodeToAddress, TransformedPhotonFeature } from 'src/app/shared/pipe/geocodeToAddress.pipe';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CloudwatchMetricsService } from 'src/app/core/services/cloudwatchMetrics.service';
import { IonicSelectableComponent } from 'ionic-selectable';

declare let google: any;

@Component({
    selector: 'app-address-lat-lng',
    templateUrl: './address-lat-lng.page.html',
    styleUrls: ['./address-lat-lng.page.scss'],
})
export class AddressLatLngPage implements OnInit {
    @Input() storageAddress: any;
    address: AddressDTO = {
        street: '',
        number: '',
        zone: '',
        complemento: '',
        address_ref: '',
        city: '',
        region: '',
        deliveryFeeID: null,
        coordinates: {
            latitude: null,
            longitude: null,
        },
    };
    deliveryFee: number;
    deliveryFeeID: any;
    discount = 0;
    discountType: any;
    distance: number;

    map: Map | any;
	mapState = 'hidden';
    isMapInitializing = false;
    addressForm: FormGroup;

    suggestedLocations: TransformedPhotonFeature[] = [];
    isFetchingSuggestedLocations = false;
    isSearchingAddress = false;

    isCalculatingDeliveryFee = false;
    isGoogleMapsProvider = false;
    showFullAddressForm = false;
    successDelivery = false;

    zones = [];
    cities = [];
	needCity = false;
	selectedCity: any;
    selectedZone: any;
    zoneInput: string;
	cityZonesAssoc = false;
    manualZoneSelection = false;
    district: string;
    searchedStreet: string = '';
    referenceIsRequired: boolean = false;
    isNotMyAddress: boolean = false;
    addressNotFound: boolean = false;
    isSelectingAddressSearch: boolean = true;

    constructor(
        private restaurantService: RestaurantService,
        private cartService: ShoppingCartService,
        private deliveryService: DeliveryService,
        private orderService: OrderService,
        private modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private translatePipe: TranslatePipe,
        private utils: UtilsService,
        private restaurantLocalStorageService: RestaurantLocalStorageService,
        private keen: KeenTrackingService,
        private photonService: PhotonService,
        private geocodeToAddress: GeocodeToAddress,
        private formBuilder: FormBuilder,
        private cloudwatchMetrics: CloudwatchMetricsService
    ) {}

    ngOnInit() {
        this.isGoogleMapsProvider =
            this.restaurantService.restaurant.info.maps_provider ===
            'google_maps';

        if (this.storageAddress) {
            this.address = this.storageAddress;
        }

        this.setAddressForm();
        this.manualZoneSelection = this.restaurantService.restaurant.info.delivery_fee_method == 'BAIRRO' && this.restaurantService.restaurant.info.manual_zone_selection;

        if (!this.manualZoneSelection) {
            this.zoneInput = this.address.zone
        }

        this.referenceIsRequired = this.restaurantService.restaurant.info.reference_is_mandatory;

        this.setZones();

        if (!this.restaurantService.restaurant.info.use_photon) {
            this.isSelectingAddressSearch = false;
            this.searchInMap();
        }
    }

    async handleChangeSearchbar(event: any) {
        const query = event.target.value.toLowerCase();
        const minCharacters = 3
        if (query.length < minCharacters) {
            return;
        }
        this.searchedStreet = query;
        this.suggestedLocations = [];
        this.isFetchingSuggestedLocations = true;
        try {
            const data = await this.photonService.listSuggestedLocations(
                this.restaurantService.restaurant.info.id,
                query
            );

            this.suggestedLocations = data.map(this.geocodeToAddress.transform);
            const typedStreetNumber = this.utils.findStreetNumber(query);
            if (typedStreetNumber) {
                this.addressForm.controls.addressNumber.patchValue(typedStreetNumber);
            }
        } catch (err) {
            console.error(err);
            this.addressNotFound = true;
        } finally {
            this.isFetchingSuggestedLocations = false;
        }
    }

    async handleClickManualAddress({
        lat,
        lng,
        street,
        district,
        city,
    }: TransformedPhotonFeature) {
        this.isMapInitializing = true;
        this.isSearchingAddress = false;

        this.address.street = street;
        this.address.city = city;
        this.district = district;
        this.address.zone = district;

        if (!lat || !lng) {
            this.cloudwatchMetrics.photon_selected_heremaps_address();

            let coordinates = await this.hereMapsGeocode(`${street}, ${district}, ${city}, ${this.restaurantService.restaurant.info.uf}`);
            lat = coordinates.lat;
            lng = coordinates.lng;
        }else{
            this.cloudwatchMetrics.photon_selected_photon_address();
        }

        this.setAddressForm();
        this.initMap(lat, lng);
    }

    initMap(lat, lng) {
        this.cloudwatchMetrics.photon_selected_photon_address();
        this.initializeMap(lat, lng);
    }

    async hereMapsGeocode(query) {
        const coordinates = await this.photonService.hereMapsGeocode(this.restaurantService.restaurant.info.id, query);

        return {
            lat: coordinates.lat,
            lng: coordinates.lng,
        }
    }

    confirmMapLocation() {
        this.setNeighborhood();
        this.resizeMap();
        this.showFullAddressForm = true;
    }

    async calculateDeliveryFee() {
        this.isCalculatingDeliveryFee = true;
        this.address.distance = null;
        this.address.deliveryFeeID = null;

        const { zoneInput, street, selectedZone, addressNumber, complement, reference } = this.addressForm.value;

        if (!this.manualZoneSelection) {
            this.address.zone = zoneInput ?? this.district;
        } else {
            this.address.zone = selectedZone.zone;
        }

        this.address.street = street ?? this.address.street;
        this.address.number = addressNumber;
        this.address.complemento = (complement && complement.length > 0) ? complement : null;
        this.address.address_ref = reference.length > 0 ? reference : null;

        let result: any;
        try {
            result = await this.deliveryService.calculateDeliveryFee(
                this.address
            );

            if (!result.delivery)
                throw 'Unfortunately your address is not in our delivery area';

            if (!result.found)
                throw 'Address not found';
        } catch (err) {
            this.addressErrorAlert(
                typeof err === 'string' ? err : 'An unexpected error occurred'
            );
            this.invalidateDeliveryForm();
            return;
        }

        if (result.distance) {
            this.distance = result.distance;
        }
        this.address.street = result.street ?? this.address.street;
        this.address.city = result.city ?? this.address.city;
        this.deliveryFee = result.deliveryFee;
        this.deliveryFeeID = result.deliveryFeeID;

        this.keen.event('addAddress', {
            address: this.address,
            deliveryFee: this.deliveryFee,
            deliveryFeeID: this.deliveryFeeID,
        });

        this.orderService.addAddress(
            this.address,
            this.deliveryFee,
            this.deliveryFeeID
        );

        try {
            const { discountValue, discountType } =
                await this.deliveryService.calculateDeliveryFeeDiscount(
                    this.cartService.total(),
                    this.deliveryFee
                );
            this.discount = discountValue;
            this.discountType = discountType;
            this.orderService.order.details.deliveryFeeDiscount = discountValue;
            this.orderService.order.details.discountType = discountType;
            this.successDelivery = true;
        } catch {
            this.invalidateDeliveryForm();
            this.utils.createToaster('Error trying to calculate delivery fee.');
        } finally {
            this.dismissModal();    
        }
    }

    resizeMap() {
        this.mapState = 'decreased';
        
        // Resize Google Map
        if (this.isGoogleMapsProvider) {
            this.map.zoomControl = false;
            return;
        }

        // Resize Leaflet
        (this.map as Map).zoomControl.remove();
        (this.map as Map).dragging.disable();
        setTimeout(() => {
            this.map.invalidateSize();
        }, 500);
    }

    invalidateDeliveryForm() {
        this.isCalculatingDeliveryFee = false;
        this.successDelivery = false;
    }

    saveDataInLocalStorage() {
		this.restaurantLocalStorageService.setItem('deliveryFeeID', this.deliveryFeeID);
        this.restaurantLocalStorageService.setItem(`street`, this.address.street);
        this.restaurantLocalStorageService.setItem(`zone`, this.address.zone);
        this.restaurantLocalStorageService.setItem(`complemento`, this.address.complemento);
		this.restaurantLocalStorageService.setItem(`address_ref`, this.address.address_ref);

        if(this.address.number) this.restaurantLocalStorageService.setItem(`number`, this.address.number);
        else this.restaurantLocalStorageService.setItem(`number`, '');

        if(this.address.city) this.restaurantLocalStorageService.setItem(`city`, this.address.city);
        else this.restaurantLocalStorageService.setItem(`city`, '');

        if(this.address.coordinates.latitude) this.restaurantLocalStorageService.setItem(`latitude`, this.address.coordinates.latitude);
        else this.restaurantLocalStorageService.setItem(`latitude`, '');

        if(this.address.coordinates.longitude) this.restaurantLocalStorageService.setItem(`longitude`, this.address.coordinates.longitude);
        else this.restaurantLocalStorageService.setItem(`longitude`, '');

        if(this.distance) this.restaurantLocalStorageService.setItem(`distance`, this.distance.toString());
        else this.restaurantLocalStorageService.setItem(`distance`, '');
    }

    dismissModal() {
        if (this.successDelivery) {
            this.saveDataInLocalStorage();
        }

        this.modalCtrl.dismiss({
            dismissed: true,
            address: this.address,
            deliveryFee: this.deliveryFee,
            deliveryFeeDiscount: this.discount,
            deliveryFeeID: this.deliveryFeeID,
            successDelivery: this.successDelivery,
            discountType: this.discountType,
        });
    }

    async addressErrorAlert(errorMsg: string) {
        const alert = await this.alertCtrl.create({
            header: this.translatePipe.transform('Warning') + '!',
            message: this.translatePipe.transform(errorMsg) + ' :(',
            buttons: ['OK'],
        });

        await alert.present();
    }

    initializeMap(lat: number, lng: number) {
        this.isGoogleMapsProvider
            ? this.initializeGoogleMap(lat, lng)
            : this.initializeLeafletMap(lat, lng);
        
        setTimeout(function () {
            window.dispatchEvent(new Event("resize"));
        }, 500);

        this.mapState = 'show';
    }

    initializeLeafletMap(lat: number, lng: number) {
        this.address.coordinates.latitude = String(lat);
        this.address.coordinates.longitude = String(lng);

        this.map = new Map('map', {
            center: { lat, lng },
            zoom: 18,
            minZoom: 11
        });

        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution:
                'Map data © <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY- SA</a>',
        }).addTo(this.map);

        
        const fg = featureGroup();
        fg.addLayer(marker(latLng(lat, lng)));

        this.map.fitBounds(fg.getBounds());

        this.map.on('moveend', () => {
            this.address.coordinates.latitude = String(
                this.map.getCenter().lat
            );
            this.address.coordinates.longitude = String(
                this.map.getCenter().lng
            );
        });
        this.isMapInitializing = false;
    }

    initializeGoogleMap(lat: number, lng: number) {
        let mapOptions = {
            zoom: 18,
            center: new google.maps.LatLng(lat, lng),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
            gestureHandling: 'cooperative',
        };
        this.map = new google.maps.Map(
            document.getElementById('map'),
            mapOptions
        );

        this.address.coordinates.latitude = this.map.getCenter().lat();
        this.address.coordinates.longitude = this.map.getCenter().lng();

        google.maps.event.addListener(this.map, 'dragend', () => {
            this.address.coordinates.latitude = this.map.getCenter().lat();
            this.address.coordinates.longitude = this.map.getCenter().lng();
        });
        this.isMapInitializing = false;
    }

    translate(phrase) {
        return this.translatePipe.transform(phrase);
    }

    setZones() {
        if(!this.manualZoneSelection)
			return;
            
        const getOnlyAvailableZones = zones => {
            return zones.filter(zone => zone.available);
        }
        
        if(this.needCity && this.cityZonesAssoc) {
            if(!this.selectedCity) return;
            return this.zones = getOnlyAvailableZones(this.selectedCity.zones);
        }

		this.deliveryService.getDeliveryZones().then(result => {
			return this.zones = getOnlyAvailableZones(result);
		}).catch(error => {
			let message = 'Error trying to fetch zones';
			this.utils.createToaster(message);
			this.dismissModal();
		});
	}

    cityChange(event: {
		component: IonicSelectableComponent,
		value: any
	}) {
		this.selectedCity = event.value;
		this.address.city = this.selectedCity.name;
		this.setZones();
	}

    searchFailText() {
		return this.translatePipe.transform('No zones found.');
	}

    setNeighborhood() {
        this.needCity = this.restaurantService.restaurant.info.deliveryCities.length > 0;

		if(this.needCity) {
			this.cities = this.restaurantService.restaurant.info.deliveryCities;
			this.cityZonesAssoc = this.cities.filter(city => city.zones.length > 0).length > 0;
			this.selectedCity = this.cities.find(city => city.name == this.address.city);
		}

        if (this.manualZoneSelection) {
            const searchZone = this.zones.find(zone => zone.zone == this.district);
            if (searchZone) {
                this.setAddressForm(searchZone.zone);
                this.address.zone = searchZone.zone;
            }
        }
    }

    setAddressForm(selectedZone = '', street = this.address.street) {
        let zone = null;
        let zoneInput = this.district;        
        let zoneInputAddressForm = [zoneInput, Validators.required];

        if (!this.manualZoneSelection)
            zoneInputAddressForm = [zoneInput];

        if (selectedZone)
            zone = {zone: selectedZone};

        this.addressForm = this.formBuilder.group({
            street: [
                street,
                Validators.required
            ],
            selectedZone: [zone],
            addressNumber: [
                this.address.number,
                Validators.required,
            ],
            complement: [
                this.address.complemento,
            ],
            reference: [
                this.address.address_ref,
                this.restaurantService.restaurant.info.reference_is_mandatory ? Validators.required : [],
            ],
            zoneInput: zoneInputAddressForm,
            city: [
                this.address.city,
                this.restaurantService.restaurant.info.deliveryCities.length > 0 ? Validators.required : [],
            ]

        });
    }

    searchInMapAddressNotFound() {
        this.cloudwatchMetrics.photon_selected_address_not_found();
        return this.searchInMap();
    }

    searchInMapThisIsNotMyAddress() {
        this.cloudwatchMetrics.photon_selected_this_is_not_my_address();
        return this.searchInMap();
    }

    searchInMap() {
        const address = {
            lat: this.restaurantService.restaurant.info.latitude,
            lng: this.restaurantService.restaurant.info.longitude,
            district: this.restaurantService.restaurant.info.bairro,
            city: this.restaurantService.restaurant.info.city,
        }

        this.isMapInitializing = true;
        this.isSearchingAddress = false;
        this.address.street = this.searchedStreet;
        this.address.city = address.city;
        this.district = address.district;
        this.isNotMyAddress = true;
        this.initializeMap(address.lat, address.lng);
        this.setAddressForm('', this.searchedStreet);
    }

    geolocationPopup(fallbackCallback) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.cloudwatchMetrics.photon_location_services_accepted();

                const { latitude, longitude } = position.coords;
                this.initializeMap(latitude, longitude);
            },
            (err) => {
                this.cloudwatchMetrics.photon_location_services_denied();
                fallbackCallback(err);
            }
        );
    }

    useGeolocation() {
        this.isSelectingAddressSearch = false;
        this.isMapInitializing = true;
        this.isNotMyAddress = true;

        this.geolocationPopup(() => {
            this.initializeMap(
                this.restaurantService.restaurant.info.latitude, 
                this.restaurantService.restaurant.info.longitude
            );
        });
    }

    searchAddress() {
        this.isSelectingAddressSearch = false;
        this.isSearchingAddress = true;
        this.isMapInitializing = false;
    }
}
