import { Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UtilsService } from '../../services/utils.service';

export function ValidateEmail(control: AbstractControl): {[key: string]: boolean} {
    let injector = Injector.create([ { provide: UtilsService, useClass:UtilsService,deps: []}])
    let utilsService = injector.get(UtilsService);

    let value = control.value;
    if(utilsService.validateEmail(value))
        return null;
    return {invalidEmail: true};
}