import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { RestaurantService } from 'src/app/core';


@Pipe({ name: 'multiCurrency' })

export class MultiCurrencyPipe implements PipeTransform {

    constructor(private restaurantService: RestaurantService, private currencyPipe: CurrencyPipe) { }

    currency: string = this.restaurantService.restaurant ? (this.restaurantService.restaurant.info.currency || "BRL") : "BRL"

    transform(value: number) {

        value = Number(value);

        if (!value && typeof (value) !== 'number') return value;

        if (this.currency == "BRL") {
            return this.commaDecimal(Number(value));

        } else if (this.currency == "PYG") {
            let roundValue = Math.round(value);
            return "₲ " + this.numberWithDotsInThousandUnit(roundValue);

        } else if (this.currency == "ARS") {
            let roundValue = Math.round(value);
            return "$ " + this.numberWithDotsInThousandUnit(roundValue);

        } else if (this.currency == 'USD') {
            return "$ " + Number(value).toFixed(2);

        } else if (this.currency == 'EUR') {
            return Number(value).toFixed(2) + " €";

        } else if (this.currency == 'MZN') {
            return this.numberWithDotsInThousandUnit(value) + " MT";
            
        } else if (this.currency == 'BOB') {
            return Number(value).toFixed(2) + " Bs";
        }
        
    }

    commaDecimal(value) {
        return value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    numberWithDotsInThousandUnit(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
}