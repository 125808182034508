import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TabletPageRoutingModule } from './tablet-routing.module';

import { TabletPage } from './tablet.page';
import { CartComponent } from '../../menu/components/cart/cart.component';
import { CategoryNavComponent } from '../../menu/components/category-nav/category-nav.component';
import { PizzaPageModule } from '../components/pizza/pizza.module';
import { AdditionalToppingsPageModule } from '../components/pizza/additional-toppings/additional-toppings.module';
import { HeaderPageModule } from '../../header/header.module';
import { ExtraPageModule } from '../components/extra/extra.module';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';
import { MenuListPageModule } from '../components/menu-list/menu-list.module';
import { TranslateModule } from '@ngx-translate/core';
import { NotFoundPageModule } from '../../notFound/not-found.module';
import { SaveCardDialogPageModule } from '../../credit-cards/add-card/save-card-dialog/save-card-dialog.module';
import { SideBarComponent } from '../components/side-bar/side-bar.component';
import { SharedModule } from '../shared.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TabletPageRoutingModule,
	SharedModule,
    ExtraPageModule,
    PizzaPageModule,
    AdditionalToppingsPageModule,
    HeaderPageModule,
    PipesModule,
    MenuListPageModule,
    TranslateModule,
    NotFoundPageModule,
  ],
  declarations: [
    TabletPage,
    SideBarComponent
  ],
  entryComponents: [
  ]
})
export class TabletPageModule {}
