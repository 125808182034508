import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ConvenixService } from 'src/app/core/services/convenix.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ValidateCpfCnpj } from 'src/app/core/validators/forms/cpf-cnpj.validator';

@Component({
	selector: 'app-convenix-dialog',
	templateUrl: './convenix-dialog.page.html',
	styleUrls: ['./convenix-dialog.page.scss'],
})
export class ConvenixDialogPage implements OnInit {

	@Input() grandTotal;
	@Input() userInput;
	convenixForm;
	loaded = false;
	showConfirmationCode = false;

	constructor(
		private formBuilder: FormBuilder,
		private modalCtrl: ModalController,
		private convenixService: ConvenixService,
		private utilsService: UtilsService,
	) { }

	ngOnInit() {
		this.convenixForm = this.formBuilder.group({
			user: this.formBuilder.group({
				cpf: [this.userInput?.cpf, [Validators.required, ValidateCpfCnpj]],
				confirmationCode: ['', Validators.required],
				saleID: ['', Validators.required],
				codeIsValid: ['', Validators.required]
			}),
		});
		this.loaded = true;
	}

	getConfirmationCode() {
		let decimalGrandTotal = Math.round((this.grandTotal * 100) * 1e12) / 1e12;


		this.convenixService.getConfirmationCode(this.cpf.value, {value: decimalGrandTotal}).then((response) => {
			this.convenixForm.get('user.saleID').setValue(response['saleID']);
			this.showConfirmationCode = true;
		}).catch(error => {
			this.utilsService.createToaster('Erro ao tentar criar um código de confirmação.');
		})
	}

	approveConfirmationCode() {

		this.convenixService.approveConfirmationCode(this.saleID.value, this.confirmationCode.value).then((response) => {
			this.convenixForm.get('user.codeIsValid').setValue(true);
		}).catch(e => {
			this.convenixForm.get('user.codeIsValid').setValue(null);
			this.utilsService.createToaster('Código Inválido');
		})
	}


	async dismiss() {
		await this.modalCtrl.dismiss({
			'dismissed': true,
			'cpf': this.convenixForm.get('user.cpf').value,
			'convenixConfirmationCode': this.convenixForm.get('user.confirmationCode').value,
			'saleID': this.convenixForm.get('user.saleID').value
		});
	}

	get confirmationCode() { return this.convenixForm.get('user.confirmationCode'); }
	get cpf() { return this.convenixForm.get('user.cpf'); }
	get saleID() { return this.convenixForm.get('user.saleID'); }
}
