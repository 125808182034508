import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IonSlides, ModalController } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CreditCardService } from 'src/app/core/services/credit-card.service';
import { OrderService } from 'src/app/core/services/order.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ValidateCpfCnpj } from 'src/app/core/validators/forms/cpf-cnpj.validator';
import { ValidateCreditCard } from 'src/app/core/validators/forms/credit-card.validator';
import { ValidateEmail } from 'src/app/core/validators/forms/email.validator';
import { ValidateName } from 'src/app/core/validators/forms/name.validator';
import { OnlinePaymentDTO } from 'src/app/shared/model/dto/OnlinePaymentDTO';
import { ModalPage } from '../../modal/modal.page';
import { SaveCardDialogPage } from './save-card-dialog/save-card-dialog.page';

@Component({
	selector: 'app-add-card',
	templateUrl: './add-card.page.html',
	styleUrls: ['./add-card.page.scss'],
})
export class AddCardPage implements OnInit {

	shouldStoreCard: boolean;

	onlinePaymentForm = this.fb.group({
		creditCard: this.fb.group({
			number: ['', [Validators.required, ValidateCreditCard]],
			holderName: ['', [Validators.required, ValidateName]],
			expiryDate: ['', Validators.required],
			ccv: ['', Validators.required],
			nickname: ['', Validators.required],
		}),
		holder: this.fb.group({
			cpfCnpj: ['', [Validators.required, ValidateCpfCnpj]],
			phone: ['', Validators.required],
			email: ['', [Validators.required, ValidateEmail]],
			cep: ['', Validators.required],
			streetNumber: ['', Validators.required],
		})
	});

	journey = [
		{stage: 'number', form: 'creditCard'},
		{stage: 'cpfCnpj', form: 'holder'},
	];

	@ViewChild('slides', {static: true}) slides: IonSlides;
	constructor(private authenticationService: AuthenticationService,
				private orderService: OrderService,
				private creditCardService: CreditCardService,
				private fb: FormBuilder,
				private modalCtrl: ModalController,
				private utilsService: UtilsService,
				private translatePipe: TranslatePipe
	) { }

	ngOnInit() {
		this.creditCardService.setShouldStoreCard(false);
	}

	currentJourneyIndex: number = 0;
	isFirstStage: boolean;
	isDelivery: boolean;
	ionViewDidEnter() {
		this.isFirstStage = true;
		this.currentJourneyIndex = 0;
		this.isDelivery = this.orderService.order.details.deliveryType == 'delivery';
	}

	nextStage() {
		this.currentJourneyIndex += 1;
		this.isFirstStage = false;
	}

	previousStage() {
		this.currentJourneyIndex -= 1;
	}

	useDeliveryInfo() {
		this.onlinePaymentForm.patchValue({
			holder: {
				cpfCnpj: this.orderService.order.user.cpf,
				phone: this.orderService.order.user.phone,
				email: this.orderService.order.user.email
			}
		});
	}

	useDeliveryAddress() {
		this.onlinePaymentForm.patchValue({
			holder: {
				cep: this.orderService.order.user.CEP,
				streetNumber: this.orderService.order.user.number,
				complement: this.orderService.order.user.complemento
			}
		});
	}

	async askToSaveCard() {

		if(this.shouldStoreCard) 
			return this.finish();

		const saveCardModal = await this.modalCtrl.create({
			component: ModalPage,
			// cssClass: 'useLoyalty-modal',
			componentProps: {
				"root": SaveCardDialogPage
			}
		})
		
		await saveCardModal.present();

		saveCardModal.onDidDismiss().then((data) => {
			this.shouldStoreCard = this.creditCardService.wantsToStoreCard && this.authenticationService.isLoggedIn();
			this.finish();
		});
	}

	creatingCard = false;
	parsedData: OnlinePaymentDTO;
	finish() {
		console.log('shouldStoreCard', this.shouldStoreCard);
		this.parsedData = {
			creditCard: {
				"creditCardIsDefault": true,
				"creditCardHolderName": this.onlinePaymentForm.get(['creditCard', 'holderName']).value,
				"creditCardExpiryMonth": this.onlinePaymentForm.get(['creditCard', 'expiryDate']).value.substr(0, 2),
				"creditCardNumber": this.onlinePaymentForm.get(['creditCard', 'number']).value,
				"creditCardExpiryYear": "20"+this.onlinePaymentForm.get(['creditCard', 'expiryDate']).value.substr(2, 2),
				"creditCardCcv": this.onlinePaymentForm.get(['creditCard', 'ccv']).value,
				"creditCardNickname": this.onlinePaymentForm.get(['creditCard', 'nickname']).value
			},
			creditCardHolder: {
				name: this.onlinePaymentForm.get(['creditCard', 'holderName']).value,
				email: this.onlinePaymentForm.get(['holder', 'email']).value,
				phone: this.onlinePaymentForm.get(['holder', 'phone']).value,
				cpfCnpj: this.onlinePaymentForm.get(['holder', 'cpfCnpj']).value,
				postalCode: this.onlinePaymentForm.get(['holder', 'cep']).value,
				addressNumber: this.onlinePaymentForm.get(['holder', 'streetNumber']).value
			},
			client: {
				name: this.orderService.order.user.name,
				phone: this.orderService.order.user.phone,
				cpf: this.orderService.order.user.cpf,
				email: this.orderService.order.user.email,
				car_plate: this.orderService.order.user.car_plate,
				car_model_color: this.orderService.order.user.car_model_color,
			}
		}

		this.parsedData.shouldStoreCard = this.shouldStoreCard;

		this.creatingCard = true;
		this.creditCardService.createCreditCard(this.parsedData).then(result => {
			if (this.shouldStoreCard) this.utilsService.createToaster(this.translatePipe.transform("Card succesfully added!"));
			this.creatingCard = false;
			this.closeModal();
		}).catch((error) => {
			this.utilsService.createToaster(this.translatePipe.transform("Error trying to add card. Please try again later!"));
		});
	}

	showBack = false;
	flipCard() {
		this.showBack = !this.showBack;
	}

	save() {

	}

	closeModal() {
		this.modalCtrl.dismiss({
			'dismissed': true,
			'creditCard': this.creditCardService.creditCard,
		});
	}

	get invalid() { 
		return this.onlinePaymentForm.get(this.journey[this.currentJourneyIndex].form).invalid; 
	}
}
