import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.page.html',
  styleUrls: ['./timeout.page.scss'],
})
export class TimeoutPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  reloadPage() {
    location.reload();
  }
}
