<ion-header>
  <ion-toolbar color="danger" style="text-align: center; padding: 5px;">
      <div>
        <ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px; padding: 7px;" text-wrap translate>Join Our Loyalty Program</ion-label>
      </div>
  </ion-toolbar>
</ion-header>

<div style="margin: auto; margin-bottom: 7px; margin-top: 2px;">
  <img src="assets/icon/cartao-de-fidelidade.svg" alt="Loyalty card" width="80">
</div>

<div>
  <p style="text-align: center; font-size: 16px; padding: 0px 30px 0px 30px;" translate>
    By signing up to our loyalty program, you can earn points that can be redeemed for prizes!
  </p>
</div>

<div class="div-useLoyalty">
  <ion-button class="useLoyalty-button" color="success" mode="ios" expand="block" style="--padding-bottom: 10px; --padding-top: 10px; font-weight: 800; font-size: 1.1em;" 
  (click)="useLoyalty()" translate>Sign up<ion-icon name="arrow-forward-outline" style="margin-left: 10px;"></ion-icon></ion-button>
  <ion-text  class="refusal-text" color="danger" mode="ios"  (click)="goToDelivery()" translate>Maybe later...</ion-text>
  
</div>

