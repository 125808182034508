import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'credit-card',
	templateUrl: './credit-card.page.html',
	styleUrls: ['./credit-card.page.scss'],
})
export class CreditCardPage implements OnInit {
	@Input() card;
	@Input() showBack = false;

	// number: '5234214392548370',
	// holderName: 'Lucas E B Ferreira',
	// expiryDate: '11/26',
	// ccv: '832'

	constructor() { }

	ngOnInit() {
		console.log(this.card);
		
	}

	flip() {
		this.showBack = !this.showBack;
	}

	getCardBrand(digits) {
		var re = new RegExp("^4");
		if (digits.match(re) != null)
			return "Visa";

		if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(digits)) 
			return "Mastercard";

		re = new RegExp("^3[47]");
		if (digits.match(re) != null)
			return "AMEX";

		re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
		if (digits.match(re) != null)
			return "Discover";

		re = new RegExp("^36");
		if (digits.match(re) != null)
			return "Diners";

		re = new RegExp("^35(2[89]|[3-8][0-9])");
		if (digits.match(re) != null)
			return "JCB";

		if (/^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/.test(digits))
			return "Maestro";

		if (/^(606282\d{10}(\d{3})?)|(3841\d{15})$/.test(digits))
			return "Hipercard";

		return "";
	}
}
