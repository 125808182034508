import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[normalizeInput]'
})
export class NormalizeInputDirective {

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const value = this.el.nativeElement.value;
    const normalizedValue = this.normalizeText(value);
    this.el.nativeElement.value = normalizedValue;

    if (this.control && this.control.control) {
      this.control.control.setValue(normalizedValue, { emitEvent: false });
    }
  }

  normalizeText(value: string): string {
    if(!value){
      return '';
    }

    return value.normalize('NFKC');
  }
}
