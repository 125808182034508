import { Validator, NG_VALIDATORS, FormControl, ValidationErrors } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[nameValidator][formControlName],[nameValidator][formControl],[nameValidator][ngModel]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: nameValidatorDirective, multi: true }
    ],
})

export class nameValidatorDirective implements Validator {

    constructor() {}

    validate(formControl: FormControl) : ValidationErrors {

        if (!(/[a-zA-Z]/.test(formControl.value))) {
            return {'invalidName': true};
        }

        return null;
    }

}