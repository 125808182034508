import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DeliveryPageRoutingModule } from './delivery-routing.module';

import { DeliveryPage } from './delivery.page';
import { AddressPageModule } from './address/address.module';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { MinValueAlertPage } from './min-value-alert/min-value-alert.page';
import { SuccessfullyOrderDialogPage } from './successfully-order-dialog/successfully-order-dialog.page';
import { LaddaModule } from 'angular2-ladda';
import { MessageErrorOrderDialogPage } from '../payment/message-error-order-dialog/message-error-order-dialog.page';
import { MinValueAlertPageModule } from './min-value-alert/min-value-alert.module';
import { AddressPage } from './address/address.page';
import { SuccessfullyOrderDialogPageModule } from './successfully-order-dialog/successfully-order-dialog.module';
import { MessageErrorOrderDialogPageModule } from '../payment/message-error-order-dialog/message-error-order-dialog.module';
import { SchedulePageModule } from './schedule/schedule.module';
import { ValidatorsModule } from 'src/app/core/validators/validators.module.';
import { AddressLatLngPageModule } from './address-lat-lng/address-lat-lng.module';
import { ConfirmAddressLatLngPageModule } from './confirm-address-lat-lng/confirm-address-lat-lng.module';
import { AddressLatLngPage } from './address-lat-lng/address-lat-lng.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    DeliveryPageRoutingModule,
    PipesModule,
    NgxMaskModule.forRoot(),
    TranslateModule,
    LaddaModule.forRoot({
      style: "zoom-out"
    }),
    ValidatorsModule,

    AddressPageModule,
    SuccessfullyOrderDialogPageModule,
    MinValueAlertPageModule,
    MessageErrorOrderDialogPageModule,
    SchedulePageModule,
    AddressLatLngPageModule,
    ConfirmAddressLatLngPageModule
  ],
  declarations: [DeliveryPage],
  entryComponents: [
    MinValueAlertPage,
    SuccessfullyOrderDialogPage,
    AddressPage,
    MessageErrorOrderDialogPage,
    AddressLatLngPage
  ],
})
export class DeliveryPageModule {}
