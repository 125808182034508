import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShoppingCartPage } from './shopping-cart.page';

const routes: Routes = [
  {
    path: '',
    component: ShoppingCartPage
  },
  {
    path: 'notes-dialog',
    loadChildren: () => import('./notes-dialog/notes-dialog.module').then( m => m.NotesDialogPageModule)
  },
  {
    path: 'use-loyalty-dialog',
    loadChildren: () => import('./use-loyalty-dialog/use-loyalty-dialog.module').then( m => m.UseLoyaltyDialogPageModule)
  },
  {
    path: 'restaurant-closed-dialog',
    loadChildren: () => import('./restaurant-closed-dialog/restaurant-closed-dialog.module').then( m => m.RestaurantClosedDialogPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShoppingCartPageRoutingModule {}
