import { Component, HostListener, OnInit } from '@angular/core';
import { RestaurantService } from 'src/app/core';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
    public categories = [];
	public selectedCategoryID = null;
	public restaurant;

	private pauseScrollListener = false;

    constructor(private restaurantService: RestaurantService) {
		this.restaurant = this.restaurantService.restaurant;
	}

	ngOnInit() {
        this.categories = this.restaurant.menu.general.filter(category => {
			const everyProductsUnavailable = category.products.every(product => product.unavailable);

			if (category.unavailable || category.unavailableByTemplate || everyProductsUnavailable)
				return;

			return category;
		})

		document.querySelector('#new-menu-list').addEventListener('scroll', () => {
			if (this.pauseScrollListener)
				return;

			this.setCurrentCategoryOnView();
		});
    }

	ngAfterContentInit() {
		this.setCurrentCategoryOnView();
	}

    selectCategory(category) {
		this.pauseScrollListener = true;

		this.selectedCategoryID = category.id;
		(document.getElementById(this.selectedCategoryID) as any).scrollIntoViewPromise({behavior: "smooth"}).then(()=> this.pauseScrollListener = false);
	}

	setCurrentCategoryOnView() {
		const categoryId = Array.from(document.querySelectorAll('ion-item-group')).find((el) => el.getBoundingClientRect().bottom >= 0).querySelector('h1')?.id;

		if (!categoryId && this.restaurant.menu.general.length > 0) {
			this.selectedCategoryID = this.restaurant.menu.general.find(generic => generic.products.length > 0).id;
			return;
		}

		if (categoryId == this.selectedCategoryID)
			return;

		this.selectedCategoryID = categoryId;
	}
}

