import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { CoupomStorageService } from './coupomLocalStorage.service';
import { RestaurantLocalStorageService } from './restaurantLocalStorage.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { RestaurantService } from './restaurant.service';

@Injectable({
    providedIn: 'root',
})
export class CoupomService {
    constructor(
        private apiService: ApiService,
        private coupomLocalStorage: CoupomStorageService,
        private restaurantLocalStorageService: RestaurantLocalStorageService,
        private restaurantService: RestaurantService,
    ) {}

    hasUserUsedCouponAlready(code: string): boolean {
        if (this.coupomLocalStorage.getUsedUniqueCoupon(code)) {
            return true;
        }
        return false;
    }

    checkCouponByServer(code: string): any {
        return new Promise((resolve, reject) => {
            const userPhone =
                this.restaurantLocalStorageService.getItem('phone');

            this.apiService
                .post(`/restaurant/${this.restaurantService.restaurant.info.id}/coupom/check`, {
                    code: code,
                    phone: userPhone,
                })
                .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                )
                .subscribe(
                    (response) => {
                        if (response.onlyOneUsePerClient) {
                            this.coupomLocalStorage.pushUniqueUseCoupon(code);
                        }
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    checkCoupon(code: string): Promise<any> {
        if (this.hasUserUsedCouponAlready(code)) {
            return Promise.reject({
                error: 'This coupon has already been used.',
                alertMethod: 'toaster',
            });
        }

        return this.checkCouponByServer(code)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }
}
