import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DeliveryAddress } from 'src/app/shared/model/DeliveryAddress';
import { RestaurantService } from './restaurant.service';

@Injectable()
export class DeliveryService {

    constructor(
        private apiService: ApiService,
        private restaurantService: RestaurantService
    ) { }

    calculateDeliveryFee(data): Promise<DeliveryAddress> {
        return new Promise((resolve, reject) => {
            data['restaurantID'] = this.restaurantService.restaurant.info.id;
            return this.apiService.post(`/restaurant/deliveryfees`, data).subscribe(result => {
                resolve(result)
            }, error => {
                reject(error)
            });
        })
    }

    calculateDeliveryFeeDiscount(total, deliveryFee): Promise<Record<string, any>> {
        return new Promise((resolve, reject) => {
            return this.apiService.post(`/restaurant/deliveryfees/discount/by-value`, {
                'value': total,
                'restaurantID': this.restaurantService.restaurant.info.id
            }).subscribe(result => {
                let discount = 0;
                let discountType = '';
                if(result && result.value >=0 && this.restaurantService.restaurant.info.delivery_fee_discount_type) {
                    if (result.discountType == 'fixed') {
                        discount = result.value;
                    } else if (result.discountType == 'percent') {
                        discount = Number( Number(deliveryFee * result.value) / 100);
                    } else if (result.discountType == 'flat_fee') {
                        discount = result.value;
                    }
                    if(discount > deliveryFee && result.discountType != 'flat_fee') discount = deliveryFee;
                    
                    discountType = result.discountType
                }
                resolve({"discountValue":discount, "discountType":discountType});
            }, error => {
                reject(error);
            });
        });
    }

    getBeeRegions(): Promise<any> {
        return new Promise((resolve, reject) => {     
            return this.apiService.post(`/restaurant/bee/regions`, {
                'restaurantID': this.restaurantService.restaurant.info.id
            }).subscribe(result => {
                resolve(result);
            }, error => {
                reject(error);
            });
        })
    }

    getDeliveryZones(): Promise<any> {
        return new Promise((resolve, reject) => {
            return this.apiService.put(`/restaurant/deliveryfees`, {
                'restaurantID': this.restaurantService.restaurant.info.id
            }).subscribe(result => {
                resolve(result)
            }, error => {
                reject(error);
            });
        })
    }
}