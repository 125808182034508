import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FlavorDTO } from 'src/app/shared/model/dto/FlavorDTO';
import { PizzaDTO } from 'src/app/shared/model/dto/PizzaDTO';

@Component({
	selector: 'app-additional-toppings',
	templateUrl: './additional-toppings.page.html',
	styleUrls: ['./additional-toppings.page.scss'],
})
export class AdditionalToppingsPage implements OnInit {

	@Input() additionalToppings;
	@Input() size: PizzaDTO;

	flavors: FlavorDTO[];
	selectedFlavorIndex: number;
	checkTopping = [];

	constructor(private modalCtrl: ModalController) { }

	ngOnInit() {
		this.flavors = this.size.selectedFlavors;
	}

	onSelectFlavor(flavorIndex) {
		this.selectedFlavorIndex = flavorIndex;

		this.additionalToppings.forEach(topping => {
			this.isToppingAdded(topping);
		});

		this.additionalToppings = JSON.parse(JSON.stringify(this.additionalToppings));
	}

	onSelectTopping(toppingIndex) {
		let selectedFlavor = this.flavors[this.selectedFlavorIndex];
		let selectedTopping = this.additionalToppings[toppingIndex];
		if(this.isToppingAdded(selectedTopping)) {
			let flavorToppingIndex = selectedFlavor.additionalToppings.findIndex(topping => {
				return topping.id == selectedTopping.id;
			});

			if(flavorToppingIndex != -1) {
                selectedFlavor.additionalToppings.splice(flavorToppingIndex, 1);
                this.size.totalPrice -= this.additionalToppings[toppingIndex].price;            
			}
		}else {
            selectedFlavor.additionalToppings.push(this.additionalToppings[toppingIndex]);
            this.size.totalPrice += this.additionalToppings[toppingIndex].price;
		}
	}

	isToppingAdded(topping): boolean {
		this.checkTopping[topping.id] = false;
		this.flavors[this.selectedFlavorIndex].additionalToppings.forEach(_topping => {
			if(_topping.id == topping.id)
				this.checkTopping[topping.id] = true;
		});

		return this.checkTopping[topping.id];
	}

	finishToppings() {
		this.modalCtrl.dismiss({
			'dismissed': true,
			'flavors': this.flavors,
			'finishSize': true
		});
	}

	dismissModal() {
		this.modalCtrl.dismiss({
			'dismissed': true,
			'flavors': this.flavors,
			'finishSize': false
		});
	}

}
