import { Injectable } from '@angular/core';
import { PhotonFeatureDTO } from 'src/app/shared/model/dto/PhotonFeatureDTO';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class PhotonService {
    constructor(private apiService: ApiService) {}

    listSuggestedLocations(
        restaurantId: string,
        query: string
    ): Promise<PhotonFeatureDTO[]> {
        const path = `/restaurant/${restaurantId}/photon/v2/geocode`;
        return this.apiService.post(path, { query }).toPromise();
    }

    hereMapsGeocode(
        restaurantId: string,
        query: string
    ): Promise<{
        lat: number,
        lng: number,
    }> {
        const path = `/restaurant/${restaurantId}/photon/geocodeHereMaps`;
        return this.apiService.post(path, { query }).toPromise();
    }
}
