<ion-header>
  <ion-toolbar color="danger">
    <div class="container-header">
      <ion-buttons>
        <ion-button (click)="goBack()">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title class="text-toolbar" translate>Access or create your account</ion-title>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="content-color">
  <section class="body-login">
    <div class="container">
      <div class="center-div-login">
        <input autocomplete="off" data-cy='inputLoginEmail' inputmode="email" placeholder="E-mail" type="text" class="input-email" name="email" [(ngModel)]="email" (keyup.enter)="loginByEmail()">
        <input autocomplete="off" data-cy='inputLoginPassword' placeholder="{{ 'Password' | translate }}" type="password" name="password" [(ngModel)]="password" (keyup.enter)="loginByEmail()">
      </div>
      <div class="entry-buttons">
        <re-captcha (resolved)="resolvedRecaptcha($event)" siteKey="6Lfm_IYmAAAAACPNpNM7Qx2op-knVM5AjpP_5DTk" style="display: flex; justify-content: center;"></re-captcha>
        <ion-button data-cy="btnUserLogin" color="danger" expand="block" class="button-login" strong="true" mode="ios" translate (click)="loginByEmail()">Login</ion-button>
        <div class="center-div-forgot-password">
          <a color="Dark" class="forgot-password" translate (click)="goToForgotPassword()">I forgot my password</a>
          <h6 class="or" translate>or</h6>
        </div>
        <div>
          <!-- <ion-button expand="block" class="button-facebook" strong="true" mode="ios" translate (click)="loginWithFacebook()">
            Login with Facebook
          </ion-button> -->
          <ion-button data-cy="btnUserLoginWithGoogle" expand="block" class="button-google" (click)="loginWithGoogle()" strong="true" mode="ios" translate>
            Login with Google
          </ion-button>
          <ion-button color="danger" expand="block" class="button-login" strong="true" mode="ios" translate (click)="goToRegister()">Create account with E-mail</ion-button>
        </div>
      </div>
    </div>
  </section>
</ion-content>
