<ion-header>
  <ion-toolbar [color]="error ? 'warning' : 'danger'" style="text-align: center; padding: 2px;">
      <div>
        <ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px; padding: 7px;" text-wrap translate>
          {{header}}
        </ion-label>
      </div>
  </ion-toolbar>
</ion-header>

<div *ngIf="error" style="margin: auto; margin-bottom: 7px; margin-top: 4px;">
  <img src="assets/icon/warning.svg" width="60">
</div>

<div *ngIf="!error" style="margin: auto; margin-bottom: 7px; margin-top: 4px;">
  <img src="assets/icon/close.svg" width="60">
</div>

<div>
  <p style="text-align: center; font-size: 16px; padding: 0px 30px 0px 30px;" translate>
    {{message}}
  </p>
</div>

<div *ngIf="error">
  <ion-button mode="ios" color="warning" expand="block"  (click)="followOrder()" style="font-weight: 900;" translate>
    View Order
    <ion-icon name="arrow-forward-circle-outline" style="margin-left: 4px; font-size: 27px;"></ion-icon>
  </ion-button>
</div>

<div *ngIf="!error">
  <ion-button mode="ios" color="danger" expand="block"  (click)="dismiss()" style="font-weight: 900;" translate>
    <ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> OK
  </ion-button>
</div>
