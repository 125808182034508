import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PizzaPageRoutingModule } from './pizza-routing.module';

import { PizzaPage } from './pizza.page';
import { FooterPage } from './footer/footer.page';
import { FooterPageModule } from './footer/footer.module';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { CurrentSizeDialogPage } from './current-size-dialog/current-size-dialog.page';
import { CurrentSizeDialogPageModule } from './current-size-dialog/current-size-dialog.module';
import { MpImgPageModule } from '../image-tag/image-tag.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PizzaPageRoutingModule,
    FooterPageModule,
    TranslateModule,
    PipesModule,
    CurrentSizeDialogPageModule,
    MpImgPageModule
  ],
  declarations: [PizzaPage],
  entryComponents: [
    CurrentSizeDialogPage
  ]
})
export class PizzaPageModule {}
