<ion-header>
	<ion-toolbar color="danger" style="text-align: center">
		<div>
			<ion-label
				class="ion-text-wrap"
				style="font-weight: 800; font-size: 16px; padding: 7px"
				text-wrap
				translate
				>Order notifications</ion-label
			>
		</div>
	</ion-toolbar>
</ion-header>

<div
	style="
		margin: auto;
		margin-bottom: 7px;
		margin-top: 2px;
		padding-left: 20px;
	"
>
	<img src="assets/icon/notification.svg" width="80" />
</div>

<div>
	<p
		style="text-align: center; font-size: 16px; padding: 0px 30px 0px 30px"
		translate
	>
		Turn on notifications to get updates of your order
	</p>
</div>

<div class="div-push">
	<ion-button
		class="push-button"
		color="success"
		mode="ios"
		expand="block"
		style="
			--padding-bottom: 5px;
			--padding-top: 5px;
			font-weight: 800;
			font-size: 1.1em;
		"
		(click)="subscribePushNotifications()"
		translate
		>
		<ion-icon name="notifications-outline" style="margin-right: 10px"> </ion-icon>
		Turn On Notifications
	</ion-button>
	<ion-text
		data-cy="dismissNotifications"
		class="refusal-text"
		color="danger"
		mode="ios"
		expand="block"
		(click)="dismiss()"
		translate
		>No, thank you...</ion-text
	>
</div>