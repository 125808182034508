import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { AddressLatLngPage } from './address-lat-lng.page';
import { IonicSelectableModule } from 'ionic-selectable';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { LaddaModule } from 'angular2-ladda';
import { ReactiveFormsModule } from '@angular/forms';
import { ValidatorsModule } from 'src/app/core/validators/validators.module.';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        IonicSelectableModule,
        PipesModule,
        TranslateModule,
        ValidatorsModule,
        LaddaModule.forRoot({
            style: 'zoom-out',
        }),
        ReactiveFormsModule,
    ],
    declarations: [AddressLatLngPage],
})
export class AddressLatLngPageModule {}
