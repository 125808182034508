<ion-header>
  <ion-toolbar style="text-align: center; --background: #10dc60; --color: white; font-weight: 800;">
      <div>
        <ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px; padding: 7px;" text-wrap translate>
          Make payment by Picpay
        </ion-label>
      </div>
  </ion-toolbar>
</ion-header>


<div>
  <p style="text-align: center; font-size: 16px; padding: 30px 30px 0px 30px;" translate>Click the button below to open Picpay</p>
</div>


<div>
  <ion-button mode="ios" expand="block"  (click)="openPicpay()" style="font-weight: 900; --background:#10dc60; --color: white;" translate>
    <ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> Open Picpay
  </ion-button>
</div>


