import { Component, OnInit, Input } from '@angular/core';
import { MenuDTO } from 'src/app/shared/model/dto/MenuDTO';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { User } from 'src/app/shared/model/User';
import { UtilsService } from 'src/app/core/services/utils.service';
import { NavService } from 'src/app/core/services/nav.service';
import { DebugModeService } from 'src/app/core/services/debugMode.service';
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';
import { ModalPage } from '../modal/modal.page';
import { LoginPage } from '../login/login.page';
import { SocialAuth } from '../../core/services/SocialAuth.service'
import { LocalStorageService } from "src/app/core/services/localStorage.service";
import { TableService } from 'src/app/core';

declare let FB: any

@Component({
	selector: 'app-header',
	templateUrl: './header.page.html',
	styleUrls: ['./header.page.scss'],
})

export class HeaderPage implements OnInit {

	@Input() restaurant: MenuDTO;
	user: User
	isLoggedIn: boolean
	loginMode;
	debugMode: number;
	hasMisingPayment: boolean

	imgURL = environment.imgURL;
	imgCover;
	imgProfile;
	highlightImages;

	public currentTable;

	constructor(private sanitizer: DomSanitizer,
				private navService: NavService,
				private authService: AuthenticationService,
				private debugModeService: DebugModeService,
				private alertController: AlertController,
				private loadingController: LoadingController,
				private utils: UtilsService,
				private modalController: ModalController,
				private keen: KeenTrackingService,
				private socialAuthService: SocialAuth,
				private localStorageService: LocalStorageService,
				private tableService: TableService,
	) { }


	ngOnInit() {
		this.imgCover = this.sanitizer.bypassSecurityTrustStyle(`url(${this.imgURL + '/covers/' + this.restaurant.info.cover_pic + '.jpg'})`);
		this.imgProfile = this.sanitizer.bypassSecurityTrustUrl(this.imgURL + '/profiles/' + this.restaurant.info.profile_pic + '.jpg');
		this.highlightImages = this.restaurant.info.highlightImages
		this.isLoggedIn = this.authService.isLoggedIn();
		this.loginMode = this.authService.getLoginMode();
		this.authService.changeStatus.subscribe(response => {
			this.isLoggedIn = response;
		});
		this.authService.changeLoginMode.subscribe(response => {
			this.loginMode = response;
		});

		this.debugMode = this.debugModeService.getMode();
		this.hasMisingPayment = this.checkMissingMercadopagoPayments();

        this.currentTable = this.tableService.currentTable;
	}

	async goToLogin() {
		const loginModal = await this.modalController.create({
			component: ModalPage,
			componentProps: {
				"root": LoginPage
			}
		})

		loginModal.present();
	}

	async confirmLogout() {
		const alert = await this.alertController.create({
			message: 'Tem certeza que deseja sair?',
			buttons: [
				{
					text: 'NÃO',
				},
				{
					text: 'SIM',
					handler: () => {
						this.loadingLogout(this.loginMode);
					}
				}
			]
		});
		await alert.present();
	}

	async loadingLogout(mode: string) {
		const loading = await this.loadingController.create({
			message: 'Saindo...'
		});
		await loading.present();

		if (mode == 'google'){
			await this.socialAuthService.logoutWithGoogle();
		}
		
		if (this.authService.logout()) {
			this.navService.push(`/`);
			await loading.dismiss();
		}

		this.keen.setEventBase().finally(() => {
			this.keen.event('logout');
		})
	}

	timeConvert(minutes) {
		return this.utils.timeConvert(minutes);
	}

	openReviews() {
		this.navService.push('/reviews');
	}

	openPayments() {
		this.navService.push('/payments', {'restaurant': this.restaurant.info});
	}

	openHours() {
		this.navService.push('/hours', {'hours': this.restaurant.hours.hours});
	}

	openInfo() {
		this.navService.push('/info', {'restaurant': this.restaurant.info});
	}

	checkMissingMercadopagoPayments() {
		let keys = Object.keys(this.localStorageService),
        index = 0, key;

		for (; key = keys[index]; index++) {
			if (key.indexOf('external_reference_') === 0) {
				return true
			}
		}
	}

}
