<ion-grid class="ion-padding-horizontal">
	<ion-row style="margin: auto; margin-bottom: 7px; margin-top: 30px;">
		<ion-col class="ion-text-center">
			<img src="assets/icon/credit-card.svg" alt="Save credit card" height="90px">
		</ion-col>
	</ion-row>
	
	<ion-row>
		<ion-col class="text-center">
			<ion-row>
				<h1 style="font-weight: bold !important;" translate>Want to save for future purchases?</h1>
			</ion-row>

			<ion-row>
				<ion-col class="ion-no-padding ion-no-margin ion-text-center">
					<p translate>
						Save your card for future purchases
					</p>
				</ion-col>
			</ion-row>
		</ion-col>
	</ion-row>		
	
	<div>
		<ion-button class="subscribe-button" color="danger" mode="ios" expand="block" (click)="save()" translate>
			Save
		</ion-button>
		<ion-text class="refusal-text" color="danger" mode="ios" (click)="closeModal()" translate>
			Maybe later...
		</ion-text>
	</div>
</ion-grid>