import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { SocialAuthService, GoogleLoginProvider } from "angularx-social-login";

@Injectable()
export class SocialAuth {

    constructor(
        private authService: AuthenticationService,
        private socialAuthService: SocialAuthService
    ) { }

    login() {
        return new Promise((resolve, reject) => {
            this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {

                let user = {
                    access_token: res.response.access_token,
                    loginMode: "gmail",
                };

                this.authService.login(user, "gmail")
                resolve(res);
            }).catch(err => reject(err));
        })
    }

    logoutWithGoogle() {
        this.socialAuthService.signOut();
    }
}

