import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FooterPageRoutingModule } from './footer-routing.module';

import { FooterPage } from './footer.page';
import { PizzaPage } from '../pizza.page';
import { AdditionalToppingsPage } from '../additional-toppings/additional-toppings.page';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    FooterPageRoutingModule,
    PipesModule
  ],
  declarations: [FooterPage],
  exports: [FooterPage],
  providers: [PizzaPage, AdditionalToppingsPage]
})
export class FooterPageModule {}
