
import { RestaurantService } from './restaurant.service';
import { environment } from 'src/environments/environment';
import { Injectable, ViewChild, Injector } from '@angular/core';
import { Events } from './events.service';
import { IonContent, ModalController, ToastController } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { FormatTimePipe } from 'src/app/shared/pipe/formatTime.pipe';
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class UtilsService {
	@ViewChild(IonContent) content: IonContent;
	
	constructor(
		private injector: Injector,
		private events: Events,
		private toastController: ToastController,
		private translatePipe: TranslatePipe,
		private modalController: ModalController,
		private restaurantService: RestaurantService,
		private formatTime: FormatTimePipe,
		private datePipe: DatePipe

	) {
		if(!this.events) return;

		this.windowsResizesEvent();
	}

	windowsResizesEvent() {
		this.events.subscribe('windowResized', () => {

			let activeInputElement = document.activeElement;
			if (activeInputElement && activeInputElement.tagName.match(/INPUT|/g)) {
				let enterKeyHint = activeInputElement.getAttribute('enterkeyhint')
				if (enterKeyHint) {
					let keyboardShouldDismissOnKeyEnter = enterKeyHint.match(/done|search/g);

					if (keyboardShouldDismissOnKeyEnter) {
						activeInputElement.addEventListener('keydown', (event:any) => {
							if(event.keyCode == 13) this.hideKeyboard(activeInputElement);
						}, false);
					}
				}

				this.handleFooterSize(activeInputElement);
			}
		})

	}

	handleFooterSize(activeInputElement) {
		let shouldHideFooter = false;
		let currentFooter;
		let currentContent;

		document.querySelectorAll('ion-content')
		.forEach((content) => {
			if (content.offsetHeight > 0 && content.offsetHeight < 320) {
				currentContent = content;
				shouldHideFooter = true;
			}
		});

		document.querySelectorAll('ion-footer')
		.forEach((footer) => {

			var elems = footer.querySelectorAll(".hiddenFooterElement");
			let aarray:any = Array.from(elems).map((element) => element)

			for (let i = 0; i < aarray.length; i++) { 
				let element = aarray[i]								
				
				element.classList.remove('hiddenFooterElement');
				

			}			

			if (footer.offsetHeight > 0 && footer.childElementCount > 0 && shouldHideFooter) {
				activeInputElement.scrollIntoView({
					behavior: 'auto',
					block: 'center',
					inline: 'center'
				});
					

				this.recursiveSearchForButton(footer.children);		
			}
		});
	}

	recursiveSearchForButton(elementChildren) {
		let selectedChild;

		let childrenArray:any = Array.from(elementChildren).map((element) => element);

		for (let i = 0; i < childrenArray.length; i++) {
			let child:any = childrenArray[i];
			if(child.classList.contains('footerButton')){
				selectedChild = childrenArray.splice(i,1)[0];
				break;
			} 
		}		

		if (selectedChild) {
			if(selectedChild.childElementCount > 0)
				this.recursiveSearchForButton(selectedChild.children)

			for (let i = 0; i < childrenArray.length; i++) { 
				let element = childrenArray[i]				
				element.classList.add('hiddenFooterElement');

			}
		}

		return;
	}

	validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase()) && email != "";
	}

	validateCPF(strCPF) {
		if (strCPF) {
			const regex = /\b(\d)\1+\b/;
			let repeatedNumbers = regex.test(strCPF);
			if(repeatedNumbers) return false;
			
			const properLenght = 11;
			if (strCPF.length != properLenght) return false;

			var Soma;
			var Resto;
			Soma = 0;

			for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
			Resto = (Soma * 10) % 11;

			if ((Resto == 10) || (Resto == 11)) Resto = 0;
			if (Resto != parseInt(strCPF.substring(9, 10))) return false;

			Soma = 0;
			for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
			Resto = (Soma * 10) % 11;

			if ((Resto == 10) || (Resto == 11)) Resto = 0;
			if (Resto != parseInt(strCPF.substring(10, 11))) return false;
			return true;
		} else {
			return false
		}
	}

	validateCNPJ(cnpj) {
		if(!cnpj) return false
        cnpj = cnpj.replace(/[^\d]+/g,'');
        if(cnpj == '') return false;
        if(cnpj.length != 14) return false;

        if(cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999")
            return false;

        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0,tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for(let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if(resultado != digitos.charAt(0))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for(let i = tamanho; i >= 1; i--) {
          	soma += numeros.charAt(tamanho - i) * pos--;
			if(pos < 2) pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if(resultado != digitos.charAt(1))
            return false;

        return true;
    }

	validateCPFCNPJ(value) {
		if(value && value.length <= 11)
			return this.validateCPF(value);

		return this.validateCNPJ(value);
	}

	phoneMask() {
		if(this.restaurantService.restaurant.info.country === 'brazil')
            return '(00) 00000-0000';

		if(this.restaurantService.restaurant.info.country === 'portugal')
        	return '000 000 000';

        if(this.restaurantService.restaurant.info.country === 'United States of America')
            return '(000) 000-0000';

		return '';
	}

	prefixMask() {
		if(this.restaurantService.restaurant.info.country === 'United States of America')
			return '+(1) '

		return '';
	}

	parseOrderPrevision(baseDate, minutesToAdd) {
		var t = baseDate.split(/[- :]/);
		var date = new Date( (new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5])) );

		date.setMinutes(date.getMinutes()+minutesToAdd);
		return this.formatTime.transform(date.getHours() + ':' + ("0"+date.getMinutes()).substr(-2));
	}

	timeConvert(minutes){
		if (minutes < 60) return `${minutes}min`;

		let hours = (minutes / 60);
		let parsedHours = Math.floor(hours);
		let min = (hours - parsedHours) * 60;
		let parsedMinutes = Math.round(min);

		return `${parsedHours}h ${parsedMinutes}min`;
	}

	hideKeyboard(activeInputElement) {
		activeInputElement.setAttribute('readonly', 'readonly');
		activeInputElement.setAttribute('disabled', 'true');
		setTimeout(function() {
			activeInputElement.blur(); 
			activeInputElement.removeAttribute('readonly');
			activeInputElement.removeAttribute('disabled');
			activeInputElement.removeEventListener('keydown');
		}, 100);
    }
    
    async createToaster(message = 'teste', durationInMilliseconds = 2000, color = 'dark') {
		const toast = await this.toastController.create({
			message: this.translatePipe.transform(message),
			position: 'top',
			color,
			duration: durationInMilliseconds
		});
		toast.present();        
    }

	copyToClipboard(text) {
		var textArea = document.createElement("textarea");
		textArea.value = text;

		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);

		this.createToaster('Copiado com sucesso!');
	}

	immediatelyOrderPrevision(order) {
		let minutes = this.restaurantService.restaurant.info.delivery_time_max;

		if (order.deliveryType == 'balcony')
			minutes = this.restaurantService.restaurant.info.takeout_time;

		return this.parseOrderPrevision(order.createdAt, minutes);
	};

	scheduleOrderPrevision(dateTime, minutesToAdd) {
        var dateMin = new Date(dateTime.replace(/-/g, "/"));    
        var dateMax = new Date(dateTime.replace(/-/g, "/"));
    
        dateMax.setMinutes(dateMax.getMinutes()+minutesToAdd);

		return this.datePipe.transform(dateMin,this.translatePipe.transform('dateFormatWithoutYearWithHour')) + " - " +  
			this.datePipe.transform(dateMax,this.translatePipe.transform('dateFormatWithoutYearWithHour'));
    }

	findStreetNumber(searchInput: string): string | null {
		// Try to find a number at the end of the string
		const regex = /(\d+)$/;
		const match = regex.exec(searchInput);
		if (match) {
			return match[1];
		}

		// If no number is found at the end, search for the rightmost number
		const lastNumber = searchInput.match(/\d+/g)?.slice(-1)[0];
		if (lastNumber) {
			return lastNumber;
		}

		// If no number is found, return null
		return null;
	}

	normalizeText(value: string): string {
		if (!value) {
		  return '';
		}

		return value.normalize('NFKC');
	  }
}