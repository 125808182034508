import { RestaurantService } from './restaurant.service';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class FacebookPixelService {

    constructor(
        private restaurantService: RestaurantService,
        private apiService: ApiService,
    ) {}

    initiateCheckoutEvent(eventID: string, userData: Object): any {
        const restaurantID = this.restaurantService.restaurant.info.id;

        return this.apiService.post(`/restaurant/${restaurantID}/facebook-pixel/initiate-checkout-event`, { eventID, userData })
    }

    purchaseEvent(eventID: string, userData: Object): any {
        const restaurantID = this.restaurantService.restaurant.info.id;

        return this.apiService.post(`/restaurant/${restaurantID}/facebook-pixel/purchase-event`, { eventID, userData })
    }
}
