<ion-header>
  <ion-toolbar color="success" style="text-align: center;">
      <div>
        <ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px; padding: 7px;"
        text-wrap>{{header}}</ion-label>
      </div>
  </ion-toolbar>
</ion-header>

<div style="margin: auto; margin-bottom: 7px; margin-top: 4px;">
  <img src="assets/icon/check.svg" width="60">
</div>

<div>
  <p style="text-align: center; font-size: 16px; padding: 2px 30px 0px 30px;">{{message}}</p>
</div>

<ion-button mode="ios" color="success" expand="block"  (click)="dismiss()" style="font-weight: 900;" translate>
  <ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> OK
</ion-button>