<ion-footer mode="md" style="padding: 20px 10px 5px 10px; box-shadow: 0px 0px 17px rgba(0,0,0,0.3); background-color: #FFF;">
    <ion-grid>
        <ion-row *ngIf="size.priceBehavior != 'incremental'">
            <ion-col class="ion-text-wrap">
                <ion-label>
                    <b>
                        {{howManyFlavorsToSelect}}
                    </b>
                </ion-label>
            </ion-col>
        </ion-row>

        <ion-row style="padding: 0 5px; line-height: 1;" *ngIf="size">
            <ion-grid style="max-height: 20vh; overflow-y: scroll; overflow-x: hidden; padding-right: 0px;">
                <ion-row *ngFor="let selectedFlavor of size.selectedFlavors" >
                    <ion-col style="padding-top: 0; padding-left: 0;">
                        <ion-row style="display: flex; align-items: center; justify-content: space-between;">
                            <!-- <ion-col style="padding-top: 0; padding-left: 0; font-size: 0.9em;">  -->
                                <span class="vertical-align-selected-flavors">
                                    <b data-cy="flavorFraction">{{flavorFractionText(selectedFlavor)}}</b>
                                    {{(selectedFlavor.name.length>15)? (selectedFlavor.name | slice:0:15)+'...':(selectedFlavor.name)}}
                                </span>
                            <!-- </ion-col> -->
    
                            <!-- <ion-col style="text-align: right;"> -->
                                <span data-cy="pizzaValue" class="vertical-align-selected-flavors" *ngIf="selectedFlavor.slicePrice * selectedFlavor.quantity > 0"> 
                                    <span *ngIf="size.priceBehavior == 'incremental'">+</span>{{selectedFlavor.slicePrice * (size.priceBehavior == 'incremental' ? selectedFlavor.quantity : 1) | multiCurrency}}
                                </span>
                            <!-- </ion-col> -->
                        </ion-row>
                        <ion-row *ngFor="let topping of selectedFlavor.additionalToppings" style="margin-top: -1px;">
                            <ion-col size="9" style="padding: 1px">
                                <small class="ml-4">- {{topping.name}}</small>
                            </ion-col>
                            <ion-col size="3" style="text-align: right;">
                                <span class="vertical-align-selected-flavors" *ngIf="topping.price > 0">
                                    +{{topping.price | multiCurrency}}
                                </span>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
    
                <ion-row *ngIf="size?.crust?.name" style="padding-top: 5px;">
                    <ion-col size="9" style="padding-top: 0; padding-left: 0;">
                        <span class="vertical-align-selected-flavors"> <small><b translate>CRUST</b>:</small>
                            {{size?.crust?.name}}</span>
                    </ion-col>
        
                    <ion-col size="3" style="text-align: right;">
                        <span data-cy="crustValue" class="vertical-align-selected-flavors"
                            *ngIf="size?.crust?.price > 0">
                            +{{size?.crust?.price | multiCurrency}}
                        </span>
                    </ion-col>
                </ion-row>
        
                <ion-row *ngIf="size?.dough?.name">
                    <ion-col size="9" style="padding-top: 0; padding-left: 0;">
                        <span class="vertical-align-selected-flavors"> <small><b translate>DOUGH</b>:</small>
                            {{size?.dough?.name}}</span>
                    </ion-col>
        
                    <ion-col data-cy="doughValue" size="3" style="text-align: right;">
                        <span class="vertical-align-selected-flavors"
                            *ngIf="size?.dough?.price > 0">
                            +{{size?.dough?.price | multiCurrency}}
                        </span>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-row>

        <ion-row *ngIf="size.selectedFlavors.length > 0 && size.minFlavors > this.size.qttyOfFlavorsSelected">
            <ion-col>
                <label class="description text-danger font-weight-bold">
                    <b *ngIf="size.minFlavors == size.maxFlavors">
                        <div *ngIf="size.minFlavors - this.size.qttyOfFlavorsSelected > 1">
                            {{'Choose x more flavors' | translate : {x: size.minFlavors - this.size.qttyOfFlavorsSelected} }}
                        </div>

                        <div *ngIf="size.minFlavors - this.size.qttyOfFlavorsSelected == 1">
                            {{'Choose x more flavor' | translate : {x: size.minFlavors - this.size.qttyOfFlavorsSelected} }}
                        </div>
                    </b>

                    <b *ngIf="size.minFlavors >= 1 && size.maxFlavors > 1 && size.minFlavors != size.maxFlavors">
                        <div *ngIf="size.minFlavors - this.size.qttyOfFlavorsSelected > 1">
                            {{'Choose at least y more flavors' | translate : {x: size.minFlavors - this.size.qttyOfFlavorsSelected} }}
                        </div>

                        <div *ngIf="size.minFlavors - this.size.qttyOfFlavorsSelected == 1">
                            {{'Choose at least x more flavor' | translate : {x: size.minFlavors - this.size.qttyOfFlavorsSelected} }}
                        </div>
                    </b>          
                </label>
            </ion-col>
        </ion-row>

        <ion-row class="footerButton">
            <ion-col size="6">
                <div *ngIf="showTotal" style="position: relative; top: 9px; text-align: center; font-size: 20px;">
                    <small>Total: </small>
                    <b data-cy="totalValue">{{(size.totalPrice ? size.totalPrice : 0) | multiCurrency}}</b> </div>
            </ion-col>
            <ion-col size="4" offset="2">
                <ion-button data-cy="btnFooterNext" style="display: flex; align-items: center; justify-content: center;" expand="block" color="danger" class="button-text" mode="ios" [disabled]="!allSet()" (click)="toppings ? finishToppings() : nextPizzaPage()">
                    <ion-text translate>
                        NEXT<ion-icon name="chevron-forward-outline" style="margin-left: 3px;"></ion-icon>
                    </ion-text>
                </ion-button>
            </ion-col>
        </ion-row>        
    </ion-grid>
</ion-footer>