import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RestaurantClosedDialogPageRoutingModule } from './restaurant-closed-dialog-routing.module';

import { RestaurantClosedDialogPage } from './restaurant-closed-dialog.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RestaurantClosedDialogPageRoutingModule,
    TranslateModule
  ],
  declarations: [RestaurantClosedDialogPage, RestaurantClosedDialogPage],
  entryComponents: [RestaurantClosedDialogPage]
})
export class RestaurantClosedDialogPageModule {}
