<ion-segment id="categoryNav" class="sticky" color="danger" mode="md" scrollable (ionChange)="changeCategory($event)"
	[(ngModel)]="categoryToScroll">
	<ion-segment-button *ngIf="!itemsToHide.featuredItems" value="featuredItems" id="featuredItems-category" (click)="selectedCategory = 'featuredItems'; categoryToCentralizeInNav = 'featuredItems-category'">
		<ion-label>{{ 'On Sale' | translate }}</ion-label>
	</ion-segment-button>


	<ion-segment-button *ngIf="!itemsToHide.combos" value="combos" id="combos-category" (click)="selectedCategory = 'combos'; categoryToCentralizeInNav = 'combos-category'">
		<ion-label>{{comboCategoryName}}</ion-label>
	</ion-segment-button>

	<ion-segment-button *ngIf="!itemsToHide.pizzas" value="pizzas" id="pizzas-category" (click)="selectedCategory = 'pizzas'; categoryToCentralizeInNav = 'pizzas-category'">
		<ion-label>{{pizzaCategoryName}}</ion-label>
	</ion-segment-button>

	<ng-container *ngFor="let category of categories; let i = index">
		<ion-segment-button  *ngIf="showCategory(category.products)" value="{{category.name}}-{{category.id}}"
			id="general-{{category.id}}" (click)="selectedCategory = i; categoryToCentralizeInNav = 'general-'+category.id">
			<ion-label>{{category.name}}</ion-label>
		</ion-segment-button>
	</ng-container>
</ion-segment>