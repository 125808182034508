<ion-header>
	<ion-toolbar color="danger">
		<div class="container-header">
			<ion-buttons>
				<ion-button (click)="backToLogin()">
					<ion-icon name="chevron-back-outline"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-title class="text-toolbar" translate>Create your account</ion-title>
		</div>
	</ion-toolbar>
</ion-header>

<ion-content class="content-color">
	<section class="body-register">
		<ion-row>
			<ion-col style=" margin: 0 0 10px 0;" size="12" class="ion-text-center">
				<ion-note style="font-size: 13px" translate>A strong password needs at least 8 characters, a letter and a number</ion-note>
			</ion-col>
		</ion-row>

		<ion-form [formGroup]="registerForm">
			<div class="container">
				<div class="center-div-register">
					<ion-row>
						<ion-col size="12">
							<input
								class="form-control"
								type="text"
								placeholder="{{ 'Name' | translate }}"
								type="text"
								name="name"
								autocomplete="off"
								formControlName="name"
								[ngClass]="{'invalid-input': (name.invalid && (name.dirty || name.touched))}"
							>
							<div *ngIf="(name.invalid && (name.dirty || name.touched))">
								<ion-text class="invalid-input-text">{{'Invalid Name' | translate}}</ion-text>
							</div>								
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="12">
							<input
								class="form-control"
								type="email"
								placeholder="E-mail"
								type="email"
								name="email"
								autocomplete="off"
								formControlName="email"
								[ngClass]="{'invalid-input': (email.invalid && (email.dirty || email.touched))}"
							>
							<div *ngIf="(email.invalid && (email.dirty || email.touched))">
								<ion-text class="invalid-input-text">{{'Invalid Email' | translate}}</ion-text>
							</div>							
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col size="12">
							<input
								#passwordInput
								class="form-control spacing-input"
								type="password"
								placeholder="{{ 'Password' | translate }}"
								name="password"
								autocomplete="off"
								formControlName="password"
								[ngClass]="{'invalid-input': (password.invalid && (password.dirty || password.touched))}"
								debounce="500"
							>
							<password-strength-meter [password]="registerForm.get('password').value" (strengthChange)="onPasswordStrengthChanged($event)"></password-strength-meter>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="12">
							<input
								class="form-control"
								type="password"
								placeholder="{{ 'Repeat the password' | translate }}"
								name="repeatPassword"
								autocomplete="off"
								formControlName="repeatPassword"
								[ngClass]="{'invalid-input': (password.value!=repeatPassword.value && (repeatPassword.dirty || repeatPassword.touched))}"
								pattern="{{ password.value }}"
							>
							<div *ngIf="password.value!=repeatPassword.value && (repeatPassword.dirty || repeatPassword.touched)">
								<ion-text class="invalid-input-text">{{'Passwords do not match!' | translate}}</ion-text>
							</div>
						</ion-col>
					</ion-row>
				</div>

				<div class="entry-buttons">
					<ion-button color="danger" expand="block" class="button-register" strong="true" mode="ios" translate [disabled]="registerForm.invalid || invalidPassword()" (click)="createAccount()">Register</ion-button>
				</div>
			</div>
		</ion-form>
	</section>
</ion-content>