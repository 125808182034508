import { Injectable, Output, EventEmitter } from '@angular/core';
import { OrderService } from './order.service';
import { RestaurantService } from './restaurant.service';
import { AuthenticationService } from './authentication.service';
import { TranslatePipe } from '@ngx-translate/core';
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';

declare let gtag: any;
declare let fbq: any;

@Injectable()
export class ShoppingCartService {
    @Output() cartProductsChanged = new EventEmitter();

    public products = [];
    public userPoints: number
    public userIsLogged: boolean = false;

    constructor(
        private orderService: OrderService,
        private authService: AuthenticationService,
        private restaurantService: RestaurantService,
        private translatePipe: TranslatePipe,
        private keen: KeenTrackingService,
    ) {}

    useLocalStorageCart() {
        const cart = JSON.parse(localStorage.getItem(`cart-${this.restaurantService.restaurant.info.id}`));
        if (!cart || cart.length)
            return;

        this.products = [...this.products, ...cart.items];
        this.cartProductsChanged.emit(this.products);
        return cart;
    }

    addProductToCart(product) {
		this.keen.event('addProductToCart', product);
        this.products.push(product);
        this.sendTrackingAnalyticsEvents()
        this.cartProductsChanged.emit(this.products);
    }

    sendTrackingAnalyticsEvents() {
        let totalCart = this.total()

        let cartProducts = this.products.map((product, index) => {
            let priceProduct = product.totalPrice ? product.totalPrice : this.generalPrice(index);
            let totalPriceProduct = this.itemSubtotal(index);

            return {
                id: product.id,
                name: product.name,
                price: priceProduct,
                quantity: product.qty,
                totalPrice: totalPriceProduct
            }
        })


        gtag('event', 'add_to_cart',{
            'currencyCode':  this.restaurantService.restaurant.info.currency,
            'products': cartProducts,
            'totalCart': totalCart,
            'value' : totalCart,
            'currency':  this.restaurantService.restaurant.info.currency,
        })

        fbq('track', 'AddToCart',{
            'currencyCode':  this.restaurantService.restaurant.info.currency,
            'products': cartProducts,
            'totalCart': totalCart,
            'value' : totalCart,
            'currency':  this.restaurantService.restaurant.info.currency,
        })

    }

    removeProductByID(productID) {
		this.keen.event('removeProductByID', productID);

        this.products = this.products.filter((item) => {
            return item.id != productID
        });
        this.cartProductsChanged.emit(this.products);

        this.orderService.order.orders = this.products;
    }

    removeProductByIndex(itemIndex) {
		this.keen.event('removeProductByIndex', itemIndex);
        this.products.splice(itemIndex, 1);
    }

    prizeSubTotal(itemIndex): string {
        let product = this.products[itemIndex];
        return this.translatePipe.transform('Points:') + ` ${product.points_needed}`;

    }

    generalPrice(itemIndex): number {
        let product = this.products[itemIndex];
        let total = product.price ? product.price : 0;

        if(!('extras' in product))
            return total;

        product.extras.forEach(extra => {
            total += extra.price
        });

        return total
    }

    itemSubtotal(itemIndex): number {
        let product = this.products[itemIndex];
		if (!product)
			return;

        let total = product.price ? product.price : 0;

        switch (product.type) {
            case 'general':
                product.extras.forEach(extra => {
                    total += extra.price * extra.qty
                });
                break;
            case 'pizza':
                total = product.totalPrice;
                if(product.extras) {
                    product.extras.forEach(extra => {
                        total += extra.price * extra.qty
                    });
                }
                break;
            default:
                total = product.totalPrice;
        }

        return total * product.qty; 
    }


    clearCart(needReturnUserPoints: boolean = true) {
		this.keen.event('clearCart');

        this.verifyNeedReturnUserPoints(needReturnUserPoints)
        this.products = []
        this.cartProductsChanged.emit(this.products);
    }

    verifyNeedReturnUserPoints(needReturnUserPoints){
        if (needReturnUserPoints) {
            this.products.forEach(product => {
                if (product.type == 'prize') {
                    while(product.qty > 0) {
                        this.returnUserPoints(product.points_needed)
                        product.qty -= 1
                    }
                }
            })
        }
    }

    returnUserPoints(pointsToReturn){
        this.setUser();
        this.authService.updateUserPoints(this.userPoints + pointsToReturn)
    }

    returnByDecrementUserPoints(points){
        this.setUser();
        this.authService.updateUserPoints(this.userPoints + points)
    }

    setUser() {
        this.userIsLogged= this.authService.isLoggedIn();

        if(this.userIsLogged) {
            if(this.authService.user) {
                this.userPoints =  this.authService.user.points;
            } else {
                this.authService.getUser().then(response => {
                    this.userPoints =  this.authService.user.points;
                    this.keen.setEventBase();
                })
            }
        }

    }

    total(): number {
        let total = 0;

        this.products.forEach((item, index) => {
            if(!item.points_needed) {
                total += this.itemSubtotal(index);
            }
        });

        if (total < 0) {
            total = 0;
        }

        return total;
    }

	countTotalFlavorsQuantitySelected(pizzaProduct) {
		return pizzaProduct.selectedFlavors.reduce((total, flavor) => {
			return total + flavor.quantity;
		}, 0);
	}

	reduceFraction(fraction: number[]): number[] {
		for (var i = fraction[0]; i > 0; i--) {
			if (0 != fraction[0] % i && 0 != fraction[1] % i) continue;
            return [fraction[0], fraction[1]];
		}
	}

    hasItems() {
        return this.products.length > 0;
    }

    saveLocalStorage(products = this.products) {
        const cartName = `cart-${this.restaurantService.restaurant.info.id}`;

        const currentCart = JSON.parse(localStorage.getItem(cartName)) || {};
        currentCart.items = products;

        localStorage.setItem(cartName, JSON.stringify(currentCart));
    }
}