<div class="modal-content" [ngClass]="{'height-change': card, 'discount': appliedPaymentSetting?.id}">
	<ion-card class="card-bordless">

		<app-message-method-operation-dialog [grandTotal]="grandTotal" [paymenSettingstValue]="appliedPaymentSetting?.calculatedValue" [paymenSettingstOperation]="appliedPaymentSetting?.operationName"></app-message-method-operation-dialog>

		<ion-grid style="padding-left: 10px;" [hidden]="appliedPaymentSetting?.id">
			<ion-row>
				<ion-col>
					<h5 [hidden]="card" style="margin-top: 0px;"><b class="bold-statements" translate>Select the card brand</b></h5>
					<h5 [hidden]="!card" style="margin-top: 0px;"><b class="bold-statements" translate>Debit or Credit?</b></h5>
				</ion-col>
			</ion-row>

			<ion-row [hidden]=" card">
				<ion-col size="6" *ngFor="let currentCard of cardTypes; let cardIndex = index">
					<input type="radio" [id]="'card-' + cardIndex" name="card" class="d-none radio">
					<label [for]="'card-' + cardIndex" class="d-block">
						<button class="button-text payment-choice-button payment-row" style="padding-top: 15px; padding-bottom: 15px;"  (click)="chooseCard(currentCard)" translate>
							<span class="bold-statements" translate>
								{{currentCard.name}}
							</span>
						</button>
					</label>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="methods">
				<ion-col style="padding-bottom: 0px;" size="6" *ngFor="let method of methods; let methodIndex = index">
					<input type="radio" [id]="'method-' + methodIndex" name="method" class="d-none radio">
					<label [for]="'method-' + methodIndex" class="d-block">
						<button class="button-text payment-choice-button payment-row" style="padding-top: 15px; padding-bottom: 15px;" (click)="chooseMethod(method)" translate>
							<span class="bold-statements" translate>
								{{method}}
							</span>
						</button>
					</label>
				</ion-col>
			</ion-row>
		</ion-grid>

		<ion-row *ngIf="appliedPaymentSetting?.id">
			<ion-col>
				<button class="button-text payment-choice-button__main-button payment-row" (click)="dismiss()" translate>
					OK
				</button>
			</ion-col>
		</ion-row>
	</ion-card>
</div>