import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ValidateCpfCnpj } from 'src/app/core/validators/forms/cpf-cnpj.validator';
import { ValidateEmail } from 'src/app/core/validators/forms/email.validator';
import { ValidateFullName } from 'src/app/core/validators/forms/fullname.validator';

@Component({
	selector: 'app-picpay-dialog',
	templateUrl: './picpay-dialog.page.html',
	styleUrls: ['./picpay-dialog.page.scss'],
})
export class PicpayDialogPage implements OnInit {

	@Input() userInput;
	@Input() grandTotal;
	picpayForm;
	loaded = false;

	constructor(
		private formBuilder: FormBuilder,
		private modalCtrl: ModalController
	) { }

	ngOnInit() {
		this.picpayForm = this.formBuilder.group({
			user: this.formBuilder.group({
				cpf: [this.userInput?.cpf, [Validators.required, ValidateCpfCnpj]],
				email: [this.userInput?.email, [Validators.required, ValidateEmail]],
			}),
		});
		this.loaded = true;
	}

	async dismiss() {
		await this.modalCtrl.dismiss({
			'dismissed': true,
			'user': this.picpayForm.get('user').value
		});
	}

	get email() { return this.picpayForm.get('user.email'); }
	get cpf() { return this.picpayForm.get('user.cpf'); }

}
