import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-credit-card-brand-icons',
	templateUrl: './credit-card-brand-icons.page.html',
	styleUrls: ['./credit-card-brand-icons.page.scss'],
})
export class CreditCardBrandIconsPage implements OnInit {

	@Input() creditCardBrand;

	constructor() { }

	ngOnInit() {
		this.creditCardBrand = this.creditCardBrand.toLowerCase();		
	}

}
