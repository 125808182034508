<ion-header>
	<ion-toolbar color="danger">
		<div class="container-header">
			<ion-label class="title text-wrap" translate>Establishment is closed</ion-label>
		</div>
	</ion-toolbar>
</ion-header>


<div class="center-div-message">
	<p>{{ closedMessage }}</p>
</div>
<ion-button class="mb-1" color="danger" expand="block" size="small" mode="ios"
	style="--padding-bottom: 5px; --padding-top: 5px; font-weight: 800; font-size: 1.0em;" (click)="dismiss()">
	Ok
</ion-button>