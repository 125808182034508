<cart></cart>

<ion-content>
    <app-not-found *ngIf="invalidURL"></app-not-found>

    <div style="width: 100%; height: 100%; overflow: auto; overflow-x: hidden;" id="new-menu-list">

        <div style="width: 20%;">
            <app-side-bar></app-side-bar>
        </div>
    
        <div style="width: 80%;">
            <div *ngIf="loaded && !invalidURL" class="menu">
                <!-- <header [restaurant]="restaurant"></header> -->
    
                <div *ngIf="restaurantIsBlocked" class="container text-center" style="width: 100%;">
                    <ion-icon name="alert-circle" style="font-size: 40px; margin-top: 70px; color: #AAA;"></ion-icon>
                    <h4 class="mt-0 font-weight-bold" style="color: #AAA;" translate>Menu currently unavailable</h4>
                </div>
    
                <div *ngIf="!restaurantIsBlocked">
					<ion-row class="highlight-image-slider" *ngIf="highlight.length > 0">
						<ion-col size="12" class="ion-no-padding">
							<ion-slides data-cy="highlightImageSlider" style="height: 50vh; width: calc(100vw - 300px);" pager [options]="{ autoplay: { delay: 10000 } }">
								<ion-slide *ngFor="let image of highlight">
									<img class="highlight-image image-scroll" src="{{imgURL}}/highlightImages/{{image.image}}/lg_{{image.image}}.jpg"/>
								</ion-slide>
							</ion-slides>
						</ion-col>
					</ion-row>
                    <div>
						<ion-item>
							<ion-label position="floating" translate>
								<ion-icon name="search-outline"></ion-icon> Search
							</ion-label>
							<ion-input #ionicInput enterkeyhint="search" type="search" inputmode="search" debounce="500" [(ngModel)]="searchString" (ionChange)="filterMenu($event.target.value)"></ion-input>
						</ion-item>
						<app-menu-list [menu]="searchResults" [info]="restaurant.info"></app-menu-list>
					</div>
                </div>
            </div>
        </div>

    </div>
</ion-content>


