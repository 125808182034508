import { Validator, NG_VALIDATORS, FormControl, ValidationErrors } from '@angular/forms';
import { Directive } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Directive({
    selector: '[cpfCnpjValidator][formControlName],[cpfCnpjValidator][formControl],[cpfCnpjValidator][ngModel]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: cpfCnpjValidatorDirective, multi: true }
    ],
})

export class cpfCnpjValidatorDirective implements Validator {

    constructor(private utilsService: UtilsService) {}

    validate(formControl: FormControl) : ValidationErrors {

        if (!this.utilsService.validateCPFCNPJ(formControl.value)) {
            return {'invalidCPF': true};
        }

        return null;
    }

}