import { Injectable, Injector } from '@angular/core';
import { LocalStorageService } from './localStorage.service';
@Injectable()
export class DebugModeService {
    mode = 0;

    constructor(
        private localStorageService: LocalStorageService,
        // private themeService: ThemeService,
        private injector: Injector) {
            this.mode = parseInt(this.localStorageService.getItem('debug_mode'));
    }

    debugMessage() {

        if(this.mode) {
    
            console.log(`%c
            ________________________________________
            <           Modo debug ativado         >
            ----------------------------------------
                         __  __ ____  
                        |  \\/  |  _ \\
                        | |\\/| | |_) |
                        | |  | |  __/ 
                        |_|  |_|_|    `, "font-family:monospace");
        }else {
            console.log(`%c
            ________________________________________
            <           Modo debug desativado      >
            ----------------------------------------`, "font-family:monospace");
        }
    }

    debug() {
        if(this.mode == 1){
            this.mode = 0;
        }else{
            this.mode = 1;
        }

        this.localStorageService.setItem('debug_mode', this.mode.toString());

        this.debugMessage();
    }

    getMode() {
        return this.mode;
    }
}