import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingSpinnerService {

    isLoading = false;

    constructor(public loadingController: LoadingController) { }

    async present() {
        this.isLoading = true;
        return await this.loadingController.create({
        }).then(loadingModal => {
            loadingModal.present().then(() => {
                if (!this.isLoading) {
                    loadingModal.dismiss();
                }
            });
        });
    }

    async dismiss() {
        this.isLoading = false;
        return await this.loadingController.dismiss();
    }
}