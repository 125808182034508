import { Injectable } from "@angular/core";
import { DebugModeService } from './debugMode.service';
import { TranslatePipe } from '@ngx-translate/core';
import { OrderService } from './order.service';
import { RestaurantLocalStorageService } from "./restaurantLocalStorage.service";

@Injectable()
export class UserService {

    constructor(
        private debugModeService: DebugModeService,
        private orderService: OrderService,
        private translate: TranslatePipe,
		private restaurantLocalStorageService: RestaurantLocalStorageService,
    ) {}

    getData() {
        let properties = ['name', 'phone', 'cpf', 'email'];
        let user: any = {
            'id': 0
        };

        properties.forEach(p => {user[p] = this.getFromOrderService(p) || this.restaurantLocalStorageService.getItem(p)}, this)

        if(this.debugModeService.getMode())
            user.name = this.translate.transform("***TEST DONT MAKE IT***");

        return user;
    }

    getFromOrderService(property: string) {
        let value: string = this.orderService?.order?.user[property];
        if(value == '') return false

        return value;
    }

    getDeviceInfo() {
        return {
            platform: window.navigator.platform,
            browser: window.navigator.appVersion,
        }
    }
}