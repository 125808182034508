import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { NavService } from "src/app/core/services/nav.service";

@Component({
	selector: "app-message-error-order-dialog",
	templateUrl: "./message-error-order-dialog.page.html",
	styleUrls: ["./message-error-order-dialog.page.scss"],
})
export class MessageErrorOrderDialogPage implements OnInit {
	header: string;
	message: string;
	error: any;

	constructor(
		private modalCtrl: ModalController,
		private navService: NavService
	) {}

	ngOnInit() {}

	followOrder() {
		this.navService.push("/track", { token: this.error.lastOrderUUID });
		this.dismiss();
	}

	async dismiss() {
		await this.modalCtrl.dismiss({
			dismissed: true,
		});
	}
}
