import { Component, OnInit } from '@angular/core';
import { NavController, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/core/services/utils.service'
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Injectable } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidateName } from 'src/app/core/validators/forms/name.validator';
import { ValidateEmail } from 'src/app/core/validators/forms/email.validator';
import { KeenTrackingService } from "src/app/core/services/keenTracking.service";

@Component({
	selector: 'app-register',
	templateUrl: './register.page.html',
	styleUrls: ['./register.page.scss'],
})

@Injectable()
export class RegisterPage implements OnInit {

	constructor(
		private utils: UtilsService,
		private authService: AuthenticationService,
		private alertController: AlertController,
		private loadingController: LoadingController,
		private translatePipe: TranslatePipe,
		private formBuilder: FormBuilder,
		private modalController: ModalController,
		private keen: KeenTrackingService,
	) { }

	registerForm;
	restaurantUrl: string
	passwordLevel = 0

	get email() { return this.registerForm.get('email'); }
	get name() { return this.registerForm.get('name'); }
	get password() { return this.registerForm.get('password'); }
	get repeatPassword() { return this.registerForm.get('repeatPassword'); }

	async ngOnInit() {
		this.registerForm = this.formBuilder.group({
			password: ['', Validators.required],
			repeatPassword: ['', Validators.required],
			email: ['', [Validators.required, ValidateEmail]],
			name: ['', [Validators.required, ValidateName]],
		})
	}

	onPasswordStrengthChanged(passwordLevel){
		this.passwordLevel = passwordLevel

		let errors = null;

		if (passwordLevel < 3) errors = {'tooWeak': true}

		this.password.setErrors(errors);
	}

	invalidPassword(){
		return this.passwordLevel < 3 || (this.registerForm.value.password != this.registerForm.value.repeatPassword)
	}

	showPassword(input: any): any {
		input.type = input.type === 'password' ?  'text' : 'password';
	}

	async createAccount() {
		const loading = await this.loadingController.create({
			message: `${this.translatePipe.transform('Wait')}...`
		})
		await loading.present()

		this.authService.register({
			email: this.registerForm.value.email,
			name: this.registerForm.value.name,
			password: this.registerForm.value.password,
			passwordConfirm: this.registerForm.value.repeatPassword
		}).then(response => {
			this.utils.createToaster("Conta Criada com Sucesso", 2000, 'success');
			loading.dismiss()
			this.modalController.dismiss();

			this.keen.setEventBase().then(() => {
				this.keen.event('register');
			})
		}, error => {
			if (error.error == 'user_already_exists') {
				loading.dismiss()
				this.pageRegisterAlert(this.translatePipe.transform("Email is already registered!"))
			} else {
				loading.dismiss()
				this.pageRegisterAlert(this.translatePipe.transform(error.error))
			}
		})
	}

	async pageRegisterAlert(message) {
		const alert = await this.alertController.create({
			cssClass: 'my-custom-class',
			header: message,
			buttons: ['OK']
		});

		await alert.present();
	}

	backToLogin() {
		const nav = document.querySelector('ion-nav');
		nav.pop();
	}
}
