import { Injectable } from '@angular/core';
import { RestaurantService } from './restaurant.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable()
export class MotoboyTrackingService {
    private baseURL:string = 'https://ig7uqqbhe5r7wlggsmmrwl2rxe0zzqtf.lambda-url.us-east-1.on.aws'

    constructor(
        private restaurantService: RestaurantService,
        private apiService: ApiService,
        private httpClient: HttpClient
    ) {}

    getOrderCoordinates(orderUUID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const restaurantID = this.restaurantService.restaurant.info.id;

            return this.httpClient
                .get(
                    `${this.baseURL}/get-by-order?restaurant-id=${restaurantID}&order-uuid=${orderUUID}`
                )
                .subscribe(
                    (result) => {
                        resolve(result);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }
}
