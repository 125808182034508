import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { ModalController, NavController } from '@ionic/angular';
import { RestaurantService, TableService, CartRecoverService } from 'src/app/core';
import { NotesDialogPage } from './notes-dialog/notes-dialog.page';
import { NavService } from 'src/app/core/services/nav.service';
import { OrderService } from 'src/app/core/services/order.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { UseLoyaltyDialogPage } from './use-loyalty-dialog/use-loyalty-dialog.page';
import { DebugModeService } from 'src/app/core/services/debugMode.service';
import { RestaurantClosedDialogPage } from './restaurant-closed-dialog/restaurant-closed-dialog.page';
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';
import { ModalPage } from '../modal/modal.page';
import { LocalStorageService } from "src/app/core/services/localStorage.service";
import { MenuSelector } from 'src/app/core/services/menuSelector.service';
import RestaurantOperations from  'restaurantoperations';
import { CloudwatchMetricsService } from 'src/app/core/services/cloudwatchMetrics.service';

declare let gtag: any;

@Component({
	selector: 'app-shopping-cart',
	templateUrl: './shopping-cart.page.html',
	styleUrls: ['./shopping-cart.page.scss'],
})
export class ShoppingCartPage implements OnInit {

	products = [];
	public fidelityEnabled: boolean = false;
	public allowNotes: boolean = true;
	allSet: boolean;
	notAppliedDiscount: any
	discount = 0
	amountToDeliveryDiscount: any
	discountMessage: any
	fidelitySettings: any
	prizeMessage: any
	pointsEarned: any
	userIsLogged: boolean = false
	restaurantId: number
	public sendingTableOrder = false;


	constructor(
		private cartService: ShoppingCartService,
		private restaurantService: RestaurantService,
		private navService: NavService,
		private debugMode: DebugModeService,
		private modalCtrl: ModalController,
		public navCtrl: NavController,
		private orderService: OrderService,
		private authService: AuthenticationService,
		private localStorageService: LocalStorageService,
		private keen: KeenTrackingService,
		private cartRecoverService: CartRecoverService,
		private tableService: TableService,
		private shoppingCartService: ShoppingCartService,
		public menuSelector: MenuSelector,
		private cloudwatchMetrics: CloudwatchMetricsService
	) { }

	ngOnInit() {
		this.fidelityEnabled = this.restaurantService.restaurant.info.fidelity.enablePoints;
		this.allowNotes = this.restaurantService.restaurant.info.allow_order_comments;
		this.fidelitySettings = this.restaurantService.restaurant.info.fidelity;
		this.restaurantId = this.restaurantService.restaurant.info.id;

        if (this.cartRecoverService.isRecover && !this.cartRecoverService.addInCart){
			this.cartRecoverService.addInCart = true;
            this.cartService.useLocalStorageCart();
		}

        if (this.cartRecoverService.uuid)
            this.cartRecoverService.updateTimestamp().subscribe();

        if (!this.cartService.products.length) {
            this.cartRecoverService.isRecover = 0;
            return this.navService.push('/');
        }
	}

	async ionViewWillEnter() {
		this.products = this.cartService.products;
		this.allSet = this.products.length > 0;

		this.cartService.saveLocalStorage();
	}

	increment(productIndex) {
		this.products[productIndex].qty += 1;
		this.keen.event('increment', this.products[productIndex]);
		this.cartService.saveLocalStorage(this.products);

		this.cartService.sendTrackingAnalyticsEvents()
	}

	decrement(productIndex) {
		if(this.products[productIndex].type == 'prize'){			
			this.cartService.returnByDecrementUserPoints(this.products[productIndex].points_needed)
		}
		
		this.products[productIndex].qty -= 1;

		if (this.products[productIndex].qty == 0) {
			this.products.splice(productIndex, 1);
			if (this.products.length == 0) {
				this.navService.pop();
				this.modalCtrl.dismiss();
			}
		}

		this.keen.event('decrement', this.products[productIndex]);
		this.cartService.saveLocalStorage(this.products);
	}

	async openNotesModal(product) {
		this.keen.event('openNotesModal', product);	
		
		const modal = await this.modalCtrl.create({
			component: NotesDialogPage,
			componentProps: {
				notes: product.notes,
				flavors: product.flavors?.selected,
			},
			cssClass: 'notes-modal',
		});
		modal.onDidDismiss()
			.then((response) => {
				if(response.data && typeof response.data.notes != 'undefined'){
					product.notes = response.data.notes;
					this.updateFlavorNotes(product, response);	
				}
			});

		return await modal.present();
	}

	updateFlavorNotes(product, response) {
		if(!product.flavors)
			return;

		product.flavors.selected.forEach(selectedObj => {
			const matchingObj = response.data.flavors.find(obj => obj.id === selectedObj.id);
			selectedObj.notes = matchingObj?.notes;
		});
	}


	prizeSubTotal(itemIndex): string {
		return this.cartService.prizeSubTotal(itemIndex);
	}

	itemSubtotal(itemIndex): number {
		return this.cartService.itemSubtotal(itemIndex);
	}

	total(): number {
		let total = this.cartService.total();
		this.setPrizeMessage(total);
		this.searchDeliveryFeeDiscounts(total)
		return total;
	}

	sendTableOrder() {
		this.sendingTableOrder = true;
		this.orderService.setTable(this.tableService.currentTable);
		this.orderService.setDeliveryType('table');

		return this.orderService.sendTableOrder().then((response) => {
			this.orderService.openSuccessfullyOrderModal('Order successfully placed!', 'Your order has been received on the system. Soon you will receive it at your table.');

			this.tableService.setTableOrderUUID(response.uuid);
			this.orderService.initizalizeOrder();
			this.shoppingCartService.clearCart();

			if (this.menuSelector.isTabletLink()) {
				document.querySelector('.side-bar > ul > li').scrollIntoView();
				document.querySelector('ion-slide').scrollIntoView();
			}

			this.navService.push(this.menuSelector.menuRoute);
			this.tableService.clearTableExpiration();
		}).finally(() => this.sendingTableOrder = false);
	}

	goToDeliveryPage() {
		this.keen.event('goToDeliveryPage');
		if (this.menuSelector.menuRoute === 'tablet')
			this.modalCtrl.dismiss();

		if (this.orderService.order)
			this.orderService.order.orders = [];

		this.products.forEach((product) => {
			this.keen.event('addProduct', product);
			this.orderService.addProduct(product);
		})

		if(this.debugMode.getMode()) {
			this.navService.push(`/delivery`);
			return;
		}

		let cantMakeOrdersNow = this.restaurantService.restaurant.info.is_closed;
		cantMakeOrdersNow = cantMakeOrdersNow && (!this.restaurantService.restaurant.info.scheduleSettings.enable || !this.restaurantService.restaurant.info.scheduleSettings.allowSchedulingWhileClosed);
		
		if(cantMakeOrdersNow) {
			this.registerIsClosedMetric();
			this.openRestaurantClosedAlert();
			return;
		}

        if (this.tableService.currentTable)
			return this.sendTableOrder();

		if (!this.authService.isLoggedIn() && this.verifyDismissLoyaltyDialog()) {
			if (this.fidelityEnabled == false) {
				this.navService.push(`/delivery`);
			} else {
				this.openUseLoyaltyModal()
			}
		} else {
			this.navService.push(`/delivery`);
		}
	}

	verifyDismissLoyaltyDialog() {
		var now = new Date().getTime();
		var userDate = this.localStorageService.getItem(`dismissFidelity-${this.restaurantId}`);

		if(!userDate) return true;
		
		return parseInt(userDate) < now ? true : false
	}

	setPrizeMessage = (total: number) => {
		this.fidelitySettings.pointMode == "perMoneySpent" ? this.pointsEarned = Math.floor(total / this.fidelitySettings.moneySpent) * this.fidelitySettings.pointsEarnedPerMoneySpent  : this.pointsEarned = this.fidelitySettings.pointsPerPurchase;
		this.prizeMessage = "pointsMessage";
		this.userIsLogged = this.authService.isLoggedIn();

		return this.pointsEarned;
	}

	searchDeliveryFeeDiscounts = function(total){

        if(!this.restaurantService.restaurant.info.delivery_fee_discount_type) return 

		let deliveryDiscounts = this.restaurantService.restaurant.info.deliveryFeeDiscounts;

		if(!(deliveryDiscounts.length > 0)) return

		this.notAppliedDiscount = deliveryDiscounts.find(discount => {
			if(total < discount.discountMinValue) return discount;
		})
		
		if(!this.notAppliedDiscount) return 

		this.amountToDeliveryDiscount = this.notAppliedDiscount.discountMinValue - total;

		this.discount = this.notAppliedDiscount.discountType != 'flat_fee' ? (this.notAppliedDiscount.discountType == 'percent' ? this.notAppliedDiscount.value + '%' : this.discount = this.notAppliedDiscount.value) : this.notAppliedDiscount.value;
		
		this.discountMessage = this.notAppliedDiscount.discountType == 'flat_fee' ? this.discount == 0 ? "freeDeliveryFeeMessage" : "flatFeeMessage" : "discountMessage"
	}  
	
	isString(value){
		if(typeof value === 'string') return true
		return false
	}

	async openUseLoyaltyModal() {

		const modal = await this.modalCtrl.create({
			component: ModalPage,
			// cssClass: 'useLsoyalty-modal',
			componentProps: {
				"root": UseLoyaltyDialogPage
			}
		})

		await modal.present();
	}

	async openRestaurantClosedAlert() {
		const modal = await this.modalCtrl.create({
			component: RestaurantClosedDialogPage,
			cssClass: 'restaurant-closed-modal',
		});
		return await modal.present();
	}

	back() {
		this.keen.event('navigateBackward', {
			'from': 'shopping-cart',
			'to': 'menu' 
		});

		if (this.menuSelector.menuRoute === 'tablet')
			return this.modalCtrl.dismiss();

		this.navService.navigateBackward("menu");
	}

	registerIsClosedMetric() {
		const data = (new RestaurantOperations(this.restaurantService.restaurant)).checkIfIsOpenByHours();

		if(data.isOpen) {
			this.cloudwatchMetrics.try_complete_order_after_restaurant_opens_but_show_closed_dialog();
		}
	}
}
