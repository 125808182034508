<ion-header>
	<ion-toolbar color="danger">
		<ion-buttons slot="start">
			<ion-button (click)="back()">
				<ion-icon name="chevron-back-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-title translate>Selected Products</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-list>
		<ion-item *ngFor="let item of products; let i = index" [attr.data-index]="i">
			<ion-label>
				<ion-row>
					<ion-col size="8" class="ion-text-wrap">
						<b>{{item.name}}</b>
						<div *ngFor="let extra of item.extras">
							{{extra.qty}}x - {{extra.name}}
						</div>

						<div *ngIf="item.comboItems">
							<div *ngFor="let comboItem of item.comboItems">

								<b>{{comboItem.name}}</b>

								<div *ngFor="let flavor of comboItem.selectedFlavors">
									- <b>{{flavor.fractionText}}</b> {{flavor.name}} <span *ngIf="flavor.price">({{flavor.price | multiCurrency}})</span>
									<br>
									<small *ngIf="flavor.additionalToppings">
										<span class="ml-3" *ngFor="let topping of flavor.additionalToppings; let last = last">
											{{topping.name}}
											<span *ngIf="topping.price">({{topping.price | multiCurrency}})</span>
											<br>
										</span>
									</small>
								</div>

								<div *ngIf="comboItem.crust.name">
									<b translate>Crust</b>: {{comboItem.crust.name}} 
									<span *ngIf="comboItem.crust.price">({{comboItem.crust.price | multiCurrency}})</span>
								</div>

								<div *ngIf="comboItem.dough.name">
									<b translate>Dough</b>: {{comboItem.dough.name}}
									<span *ngIf="comboItem.dough.price">({{comboItem.dough.price | multiCurrency}})</span>
								</div>

							</div>
						</div>

						<div *ngIf="item.selectedFlavors">
							<div *ngFor="let flavor of item.selectedFlavors">
								- <b>{{flavor.fractionText}}</b> {{flavor.name}} <span *ngIf="flavor.price">({{flavor.price | multiCurrency}})</span>
								<br>
								<small *ngIf="flavor.additionalToppings">
									<span class="ml-3" *ngFor="let topping of flavor.additionalToppings; let last = last">
										{{topping.name}}
										<span *ngIf="topping.price">({{topping.price | multiCurrency}})</span>
										<br>
									</span>
								</small>
								<div *ngIf="flavor.notes">
									<i data-cy="textFlavorNotes"><b translate>Notes_abbreviation</b>: {{flavor.notes}}</i>
								</div>
							</div>
						</div>

						<div *ngIf="item.crust && item.crust.name">
							<b translate>Crust</b>: {{item.crust.name}}
							<span *ngIf="item.crust.price">({{item.crust.price | multiCurrency}})</span>
						</div>

						<div *ngIf="item.dough && item.dough.name">
							<b translate>Dough</b>: {{item.dough.name}}
							<span *ngIf="item.dough.price">({{item.dough.price | multiCurrency}})</span>
						</div>

						<div *ngIf="item.notes" class="ion-padding-top">
							<i data-cy="textOrderNotes"><b translate>Order notes abbreviation</b>: {{item.notes}}</i>
						</div>

						<div>
							<b *ngIf="item.points_needed" translate>{{prizeSubTotal(i)}}</b>
							<b *ngIf="!item.points_needed">{{itemSubtotal(i) | multiCurrency }}</b>
						</div>
					</ion-col>
					<ion-col size="4">
						<ion-icon name="remove-circle" style="font-size: 40px;  color:rgb(153, 13, 8);"
							(click)="decrement(i)">
						</ion-icon>
						<span style="font-size: 20; margin: 5px; position: relative ; top:-11px;">{{item.qty}}</span>
						<ion-icon name="add-circle" style="font-size: 40px; color:rgb(6, 139, 32);"
							(click)="increment(i)"
							*ngIf="!item.points_needed"
						>
						</ion-icon>
					</ion-col>
				</ion-row>
				<ion-row *ngIf="allowNotes">
					<ion-button *ngIf="!item.notes" size="small" color="danger" mode="ios" fill="outline" (click)="openNotesModal(item)" data-cy="btnAddNotes">
						<ion-icon name="chatbox-outline" style="margin-right: 4px;"></ion-icon> {{'Add notes' | translate}}
					</ion-button>
					<ion-button *ngIf="item.notes" size="small" color="danger" mode="ios" (click)="openNotesModal(item)" data-cy="btnEditNotes">
						<ion-icon name="create-outline" style="margin-right: 4px;"></ion-icon> {{'Edit notes' | translate}}
					</ion-button>
				</ion-row>
			</ion-label>
		</ion-item>
	</ion-list>
	<ion-item class="fade-in-from-top" *ngIf="userIsLogged && fidelityEnabled" [hidden]="fidelitySettings.pointMode == 'perSale' && total() < fidelitySettings.purchaseMinimum || pointsEarned < 1">
		<ion-label>
			<ion-row>
				<ion-col>
					<small>
						<b class="font-weight-bold">{{ 'Loyalty' | translate }}:</b>
						<p class="text-success">{{ prizeMessage | translate: {pointsEarned: pointsEarned, plural: pointsEarned > 1 ? 's' : ''} }}</p>
					</small>
				</ion-col>
			</ion-row>
		</ion-label>
	</ion-item>
</ion-content>

<ion-footer>
	<ion-toolbar>
		<h4 style="text-align: center;"><small>TOTAL:</small> <b data-cy="totalCart">{{total() | multiCurrency}}</b></h4>
		<ion-row *ngIf="!menuSelector.isTabletLink()">
			<ion-col size="12" *ngIf="notAppliedDiscount && amountToDeliveryDiscount > 0" class="ion-no-padding">
				<ion-card class="restaurantMessage ion-no-margin">
					<ion-card-content class="ion-padding" style="text-align: center;">
						<ion-text style="font-size: 14px;">
							{{ discountMessage | translate: {amountToDeliveryDiscount: (amountToDeliveryDiscount | multiCurrency), discount: isString(discount) ? discount : discount | multiCurrency} }}
						</ion-text>
					</ion-card-content>
				</ion-card>	
			</ion-col>
		</ion-row>
		<ion-row class="footerButton">
			<ion-col size="6">
				<ion-button color="danger" mode="ios" expand="block" style="--padding-bottom: 10px; --padding-top: 10px; font-weight: 800; font-size: 0.9em;"
					(click)="back()"><ion-icon name="add-outline" style="margin-right: 4px;" data-cy="orderMore"></ion-icon> {{'ORDER MORE' | translate}}</ion-button>
			</ion-col>
			<ion-col size="6">
				<ion-button data-cy="btnFinishCart" class="table-ladda-button" [ladda]="sendingTableOrder" mode="ios" expand="block" style="--padding-bottom: 10px; --padding-top: 10px; font-weight: 800; font-size: 0.9em;"
					(click)="goToDeliveryPage()" [disabled]="!allSet"><ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> {{'FINISH ORDER' | translate}}</ion-button>
			</ion-col>
		</ion-row>
	</ion-toolbar>
</ion-footer>