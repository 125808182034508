import { Injectable } from "@angular/core";
import { RestaurantService } from "./restaurant.service";
import { environment } from "src/environments/environment";
import { LocalStorageService } from './localStorage.service';

declare let OneSignal: any;

@Injectable({
	providedIn: "root",
})
export class OnesignalService {
	isSubscribed: boolean = false;
	isInitied: boolean = false;

	constructor(
		public restaurantService: RestaurantService,
		private localStorageService: LocalStorageService,
		) {}

	initOneSignal(): Promise<Boolean> {
        return new Promise((resolve) => {
            OneSignal.push(() => {
                OneSignal.init({
                    allowLocalhostAsSecureOrigin: true,
                    appId: `${environment.onesignalAppID}`,
                    autoRegister: false,
                    notifyButton: {
                        enable: false,
                    },
                    welcomeNotification: {
                        disable: true,
                    },
                });
                this.isInitied = true;
                resolve(true);
            });
        })
	}

	getUserID(): Promise<any> {
		return new Promise((resolve, reject) => {
			OneSignal.push(() => {
				OneSignal.getUserId(
					(response) => {
						if (response && response != null) {
							resolve(response);
						}
					},
					(error) => {
						reject(error);
					}
				);
			});
		});
	}

	isSupported(): Promise <Boolean> {
        return new Promise((resolve) => {
            OneSignal.push(() => {
                resolve(OneSignal.isPushNotificationsSupported());
            });
        })
    }
    
	isEnabled(): Promise <Boolean> {
        return new Promise((resolve) => {
            OneSignal.push(() => {
                OneSignal.isPushNotificationsEnabled((response) => {
                    resolve(response);
                });
            });
        })
	}

	sendTags() {
		let restaurant = this.restaurantService.restaurant;

		OneSignal.push(() => {
			OneSignal.sendTags({
				restaurant_id: restaurant.info.id,
				batch: 1,
			}).then(function (tagsSent) {
				// Callback called when tags have finished sending
			});
		});
	}

	registerForPushNotifications() {
		this.localStorageService.setItem("push", "false");
		OneSignal.push(() => {
			OneSignal.registerForPushNotifications();
			OneSignal.on("subscriptionChange", (isSubscribed) => {
				this.isSubscribed = true;
				console.log(
					"The user's subscription state is now:",
					isSubscribed
				);
				this.getUserID().then(
					(userID) => {
						this.localStorageService.setItem("OneSignalID", userID);
					},
					(error) => {
						console.log("Error:", error.error);
					}
				);
			});
		});
	}

	dismissPushNotifications() {
		this.localStorageService.setItem("push", "false");
	}
}
