<ion-grid
    style="
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    "
    >
    <ion-row>
        <ion-col size="12">
            <ion-grid>
                <ion-row>
                    <ion-col size="12" class="ion-text-center">
                        <ion-icon
                            size="large"
                            name="close-circle-outline"
                        ></ion-icon>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12" class="ion-text-center">
                        <ion-text
                            class="ion-text-center"
                            justify-content-center
                            align-items-center
                            style="height: 100%"
                            translate
                        >
                            Store Couldn't be found! Check the URL
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-col>
    </ion-row>
</ion-grid>  
