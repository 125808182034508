<ion-header>
  <ion-toolbar color="success" style="text-align: center;">
      <div>
        <ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px; padding: 7px;" text-wrap translate>
          Follow your order
        </ion-label>
      </div>
  </ion-toolbar>
</ion-header>

<div style="margin: auto; margin-bottom: 7px; margin-top: 4px;">
  <img src="assets/icon/check.svg" width="60">
</div>

<div>
  <p style="text-align: center; font-size: 16px; padding: 2px 30px 0px 30px;" translate>
    Congratulations! You have successfully completed your order. Click the button below to track the order
  </p>
</div>

<div>
  <ion-button mode="ios" color="success" expand="block"  (click)="followOrder()" style="font-weight: 900;" translate>
    Follow Order
    <ion-icon name="arrow-forward-circle-outline" style="margin-left: 4px; font-size: 27px;"></ion-icon>
  </ion-button>
</div>
