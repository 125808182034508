import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from "@angular/common";
import { CartGuardService } from './core/services/cart-guard.service';
import { DataGuardService } from './core/services/data-guard.service';

const routes: Routes = [

  {
    path: ':restaurant',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
      },
      {
        path: 'hours',
        loadChildren: () => import('./pages/header/hours/hours.module').then( m => m.HoursPageModule)
      },
      {
        path: 'info',
        loadChildren: () => import('./pages/header/info/info.module').then( m => m.InfoPageModule)
      },
      {
        path: 'delivery',
        canActivate: [CartGuardService],
        loadChildren: () => import('./pages/delivery/delivery.module').then( m => m.DeliveryPageModule)
      },
      {
        path: 'shopping-cart',
        loadChildren: () => import('./pages/shopping-cart/shopping-cart.module').then( m => m.ShoppingCartPageModule)
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
      },
      {
        path: 'payment',
        canActivate: [CartGuardService],
        loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule)
      },  
      {
        path: 'pizza',
        canActivate: [DataGuardService],
        loadChildren: () => import('./pages/menu/components/pizza/pizza.module').then( m => m.PizzaPageModule)
      },
      {
        path: 'extra',
        canActivate: [DataGuardService],
        loadChildren: () => import('./pages/menu/components/extra/extra.module').then( m => m.ExtraPageModule)
      },
      {
        path: 'track',
        canActivate: [DataGuardService],
        loadChildren: () => import('./pages/track-order/track-order.module').then(m => m.TrackOrderPageModule)
      },
      {
        path: 'rate-order',
        canActivate: [DataGuardService],
        loadChildren: () => import('./pages/rate-order/rate-order.module').then(m =>m.RateOrderPageModule)
      },
      {
        path: 'reviews',
        loadChildren: () => import('./pages/reviews/reviews.module').then( m => m.ReviewsPageModule)
      },
      {
        path: 'tablet/scan',
        loadChildren: () => import('./pages/menu/tablet-scan-qrcode/tablet-scan-qrcode-routing.module').then( m => m.TabletScanQrcodePageRoutingModule)
      },
      {
        path: 'credit-cards',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/credit-cards/credit-cards.module').then( m => m.CreditCardsPageModule),
          },
          {
            path: 'add-card',
            loadChildren: () => import('./pages/credit-cards/add-card/add-card.module').then( m => m.AddCardPageModule),
          }
        ]
      },
    ]
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, {
    initialNavigation: true
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {}