import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ShoppingCartPageRoutingModule } from './shopping-cart-routing.module';

import { ShoppingCartPage } from './shopping-cart.page';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { RestaurantClosedDialogPage } from './restaurant-closed-dialog/restaurant-closed-dialog.page';
import { UseLoyaltyDialogPage } from './use-loyalty-dialog/use-loyalty-dialog.page';
import { RestaurantClosedDialogPageModule } from './restaurant-closed-dialog/restaurant-closed-dialog.module';
import { UseLoyaltyDialogPageModule } from './use-loyalty-dialog/use-loyalty-dialog.module';
import { ValidatorsModule } from 'src/app/core/validators/validators.module.';
import { LaddaModule } from 'angular2-ladda';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ShoppingCartPageRoutingModule,
    PipesModule,
    TranslateModule,
    UseLoyaltyDialogPageModule,
    RestaurantClosedDialogPageModule,
    ValidatorsModule,
	LaddaModule.forRoot({
		style: "zoom-out"
	  }),
  ],
  declarations: [
    ShoppingCartPage,
  ]
})
export class ShoppingCartPageModule {}
