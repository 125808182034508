import { Injectable } from '@angular/core';

import { LocalStorageService } from './localStorage.service';
import { RestaurantService } from './restaurant.service';

@Injectable({
    providedIn: 'root'
})

export class CoupomStorageService {

    constructor(
        private localStorageService: LocalStorageService,
        private restaurantService:   RestaurantService
    ) {}

    initCouponsQuery = (): string[] => {
        let uniqueUseCoupons = JSON.parse(localStorage.getItem(`uniqueUseCoupom-${this.restaurantService.restaurant.info.id}`));

        if (uniqueUseCoupons === null) {
            uniqueUseCoupons = [];
        }

        return uniqueUseCoupons;
    }
    
    pushUniqueUseCoupon(typedCoupon: string): void {
        let uniqueUseCoupons = this.initCouponsQuery();

        uniqueUseCoupons = [...uniqueUseCoupons, typedCoupon]

        this.localStorageService.setItem(`uniqueUseCoupom-${this.restaurantService.restaurant.info.id}`, JSON.stringify(uniqueUseCoupons));
    }

    getUsedUniqueCoupon(typedCoupon: string): boolean {
        let uniqueUseCoupons = this.initCouponsQuery();

        return uniqueUseCoupons.some((coupon) => coupon === typedCoupon);
    }
}