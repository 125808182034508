<ion-header class="header" [ngStyle]="{height: journey[currentJourneyIndex].form == 'creditCard' ? '150px' : 'unset'}">
	<ion-toolbar color="danger" >
		<ion-buttons slot="start">
			<ion-button routerDirection="back" (click)="closeModal()">
				<ion-icon name="chevron-back-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-title *ngIf="journey[currentJourneyIndex].form == 'creditCard'">Cadastro de Dados do Cartão</ion-title>
		<ion-title *ngIf="journey[currentJourneyIndex].form == 'holder'">Cadastro de Dados do Titular</ion-title>
	</ion-toolbar>

	<div class="credit-card px-4" *ngIf="journey[currentJourneyIndex].form == 'creditCard'">
		<credit-card [card]="onlinePaymentForm.value.creditCard" [showBack]="showBack"></credit-card>
	</div>
</ion-header>

<ion-content>
	<form [formGroup]="onlinePaymentForm" [ngStyle]="{'margin-top': journey[currentJourneyIndex].form == 'holder' ? '10px' : '38%'}" class="mx-2">
		<ion-row formGroupName="creditCard" *ngIf="journey[currentJourneyIndex].form == 'creditCard'">
			<ion-col size="12">
				<input
					id="number"
					name="number"
					type="text"
					autocomplete="off"
					placeholder="{{'Card Number' | translate}}"
					formControlName="number"
					mask="0000 0000 0000 0000"
					inputmode="numeric"
				>
			</ion-col>
			<ion-col size="12">
				<input
					id="holderName"
					name="holderName"
					type="text"
					autocomplete="off"
					placeholder="{{'Holder Name' | translate}}"
					formControlName="holderName"
				>
			</ion-col>
			<ion-col size="6">
				<input
					id="expiryDate"
					name="expiryDate"
					type="text"
					autocomplete="off"
					placeholder="{{'Expiry Date' | translate}}"
					formControlName="expiryDate"
					mask="00/00"
					[showMaskTyped] = "true"
					[validation]="true"
					inputmode="numeric"
				>
			</ion-col>
			<ion-col size="6">
				<input
					id="ccv"
					name="ccv"
					type="text"
					autocomplete="off"
					placeholder="{{'Card Verification Value' | translate}}"
					formControlName="ccv"
					inputmode="numeric"
					(blur)="flipCard()"
					(focus)="flipCard()"
				>
			</ion-col>
			<ion-col size="12">
				<input
					id="nickname"
					name="nickname"
					type="text"
					autocomplete="off"
					placeholder="{{'Nickname' | translate}}"
					formControlName="nickname"
				>
			</ion-col>
		</ion-row>
		<ion-row class="holder-info" formGroupName="holder" *ngIf="journey[currentJourneyIndex].form == 'holder'">
			
			<ion-col size="12" class="text-left">
				<p class="mb-0 form-title" translate>Holder data</p>
				<div class="form-check mb-2">
					<input class="form-check__input" type="checkbox" value="" id="delivery-data" (click)="useDeliveryInfo()">
					<label class="form-check-label" for="delivery-data" translate>
						Use delivery data
					</label>
				</div>	
			</ion-col>
			<ion-col size="12">
				<input
					id="cpfCnpj"
					name="cpfCnpj"
					type="text"
					autocomplete="off"
					placeholder="{{'CPF/CNPJ' | translate}}"
					formControlName="cpfCnpj"
					mask="000.000.000-00||00.000.000/0000-00"
					inputmode="numeric"
				>
			</ion-col>
			<ion-col size="12">
				<input
					id="phone"
					name="phone"
					type="text"
					autocomplete="off"
					placeholder="{{'Phone' | translate}}"
					formControlName="phone"
					mask="(00) 00000-0000"
					[showMaskTyped] = "true"
					[validation]="true"
					inputmode="numeric"
				>
			</ion-col>
			<ion-col size="12">
				<input
					id="email"
					name="email"
					type="text"
					autocomplete="off"
					placeholder="{{'Email' | translate}}"
					formControlName="email"
				>
			</ion-col>
			<ion-col size="12" class="text-left">
				<p class="mb-0 form-title" translate>Holder address</p>
				<div class="form-check mb-2" *ngIf="isDelivery">
					<input class="form-check__input" type="checkbox" value="" id="delivery-address" (click)="useDeliveryAddress()">
					<label class="form-check-label" for="delivery-address" translate>
						The delivery and bill address are the same
					</label>
				</div>
			</ion-col>

			<ion-col size="12">
				<input
					id="cep"
					name="cep"
					type="text"
					autocomplete="off"
					placeholder="{{'CEP' | translate}}"
					formControlName="cep"
					mask="00000-000"
					[validation]="true"
					inputmode="numeric"
				>
			</ion-col>
			<ion-col size="12">
				<input
					id="streetNumber"
					name="streetNumber"
					type="text"
					autocomplete="off"
					placeholder="{{'Number' | translate}}"
					formControlName="streetNumber"
				>
			</ion-col>
		</ion-row>
	</form>
</ion-content>


<ion-footer class="ion-no-border gradient-footer" style="height: 74px">
	<div class="stage-buttons">
		<div class="stage-buttons__previous btn" *ngIf="currentJourneyIndex != 0" (click)="previousStage()">
			<span translate>Previous</span>
		</div>
		<div class="stage-buttons__next">
			<ion-button color="danger" (click)="nextStage()" [disabled]="invalid" *ngIf="currentJourneyIndex != 1" translate>Next</ion-button>
			<ion-button color="danger" (click)="askToSaveCard()" [disabled]="invalid" *ngIf="currentJourneyIndex == 1">
				<span *ngIf="!creatingCard" translate>Finish</span>
				<ion-spinner *ngIf="creatingCard" name="crescent"></ion-spinner>
			</ion-button>
		</div>
	</div>
</ion-footer>