<div class="menu-header pb-1">
	<div [style.background-image]="restaurant.info.cover_pic ? imgCover : ''" [ngClass]="{'overlay cover-photo': restaurant.info.cover_pic}">
		<div *ngIf="!currentTable">
			<ion-icon *ngIf="!isLoggedIn" size="large" class="icon-login" [style.color]="restaurant.info.cover_pic ? '#FFF' : '#000'" name="person-circle-outline" slot="start" (click)="goToLogin()"></ion-icon>
			<ion-icon *ngIf="isLoggedIn" size="large" class="icon-login" [style.color]="restaurant.info.cover_pic ? '#FFF' : '#000'" name="person-circle-outline" slot="start" (click)="confirmLogout()"></ion-icon>
			<label data-cy="userLogin" *ngIf="!isLoggedIn" class="pointer" class="label-login" [style.color]="restaurant.info.cover_pic ? '#FFF' : '#000'" (click)="goToLogin()" translate>Login</label>
			<label data-cy="userLogout" *ngIf="isLoggedIn" class="pointer" class="label-logout" [style.color]="restaurant.info.cover_pic ? '#FFF' : '#000'" (click)="confirmLogout()" translate>Logout</label>
		</div>
		<div class="profile-image" *ngIf="restaurant.info.profile_pic">
			<img class="profile" [style.margin-top]="restaurant.info.cover_pic ? '100px' : '10px'" [src]="imgProfile">
		</div>
	</div>
	<div class="info-box p-3" [ngClass]="{'mt-5': restaurant.info.cover_pic}">

		<div class="main-info" [ngClass]="{'image-margin-top': restaurant.info.profile_pic || restaurant.info.cover_pic}">
			<h3 class="m-1 font-weight-bold">{{restaurant.info.name}}</h3>

			<p class="mb-2" data-cy="takeoutInfo" *ngIf="!restaurant.info.no_takeout">
				<a target="_blank" href="https://www.google.com/maps/search/?api=1&query='{{restaurant.info.street}}, {{restaurant.info.street_number}} - {{restaurant.info.bairro}} - {{restaurant.info.city}} / {{restaurant.info.uf}}'">
					{{restaurant.info.street}}, {{restaurant.info.street_number}} - {{restaurant.info.bairro}} - {{restaurant.info.city}}/{{restaurant.info.uf}}
				</a>
			</p>
		</div>

		<div class="extra-info">
			<ion-row style="font-size: 15px;" class="ion-justify-content-center">
				<ion-col (click)="openReviews()" size="4" class="ion-no-padding ion-align-items-center ion-justify-content-center" *ngIf="restaurant.info.review_settings.enabled && restaurant.info.review_settings.show_in_order_page && restaurant.info.rating_average">
					<p style="margin-bottom: 0px;" data-cy="reviewsLink" translate>Reviews</p>
					<p style="margin-bottom: 0px !important; font-size: 16px; font-weight: 600; font-family: Arial, Helvetica, sans-serif;" class="alignStar">{{ restaurant.info.rating_average }} <ion-icon class="pl-1" size="small" name="star-outline"></ion-icon></p>
				</ion-col>
				<ion-col size="4" data-cy="deliveryTime" class="ion-no-padding" *ngIf="restaurant.info.delivery_time_min>0 && !restaurant.info.no_delivery">
					<p style="margin-bottom: 0px;" translate>Delivery</p>
					<p class="description mb-1">{{timeConvert(restaurant.info.delivery_time_min)}} - {{timeConvert(restaurant.info.delivery_time_max)}}</p>
				</ion-col>
				<ion-col size="4" data-cy="takeoutTime" class="ion-no-padding" *ngIf="!restaurant.info.no_takeout && restaurant.info.takeout_time>0">
					<p style="margin-bottom: 0px;" translate>Takeout</p>
					<p class="description mb-1">{{timeConvert(restaurant.info.takeout_time)}}</p>
				</ion-col>
			</ion-row>
			<ion-row style="font-size: 15px;" class="ion-justify-content-center ion-align-items-center">
				<ion-col size="4">
					<div  (click)="openPayments()">
						<p style="margin-bottom: 0px;" data-cy="paymentsLink" translate>Payments</p>
						<ion-icon name="card-outline" class="icon"></ion-icon>
					</div>
				</ion-col>
				<ion-col size="4">
					<div  (click)="openHours()">
						<p style="margin-bottom: 0px;" data-cy="hoursLink" translate>Hours</p>
						<ion-icon name="time-outline" class="icon"></ion-icon>
					</div>
				</ion-col>
				<ion-col size="4">
					<div  (click)="openInfo()">
						<p style="margin-bottom: 0px;" data-cy="infoLink"  translate>Information</p>
						<ion-icon name="add-circle-outline" class="icon"></ion-icon>
					</div>
				</ion-col>
			</ion-row>

			<ion-row>
				<ion-col size="12" class="text-center">
					<p class="title mb-0" translate>Operation</p>
					<label *ngIf="!restaurant.info.is_closed || debugMode" class="description text-success font-weight-bold" style="letter-spacing: 1.5px;" translate>OPEN</label>
					<label *ngIf="restaurant.info.is_closed && !debugMode && 
								  (!restaurant.info.scheduleSettings.allowSchedulingWhileClosed || 
								  !restaurant.info.scheduleSettings.enable)" 
					class="description text-danger font-weight-bold" style="letter-spacing: 1.5px;" translate>CLOSE</label>
					<label *ngIf="restaurant.info.is_closed && 
								  restaurant.info.scheduleSettings.allowSchedulingWhileClosed && 
								  restaurant.info.scheduleSettings.enable" 
					class="description fort-weight-bold" style="letter-spacing: 1.5px; color: #ffbe00;" translate>OPEN FOR SCHEDULE</label>
				</ion-col>
			</ion-row>

			<ion-row class="highlight-image-slider" *ngIf="highlightImages">
				<ion-col size="12" class="text-center">
					<ion-slides data-cy="highlightImageSlider" pager style="--bullet-background: white; --bullet-background-active: rgb(255, 60, 0);" [options]="{autoplay:true}">
						<ion-slide *ngFor="let image of highlightImages">
							<img class="highlight-image" src="{{imgURL}}/highlightImages/{{image.image}}/lg_{{image.image}}.jpg"/>
						</ion-slide>
					</ion-slides>
				</ion-col>
			</ion-row>

			<ion-row *ngIf="restaurant?.info?.message">
				<ion-col size="12" class="ion-no-padding">
					<ion-card class="restaurantMessage ion-no-margin">
						<ion-card-content class="ion-padding">
							<ion-text data-cy="restaurantMessage" style="font-size: 14px; white-space: pre-line;">
								{{restaurant.info.message}}
							</ion-text>
						</ion-card-content>
					</ion-card>	
				</ion-col>
			</ion-row>		
			<ion-row *ngIf="hasMisingPayment">
				<ion-col size="12" class="ion-no-padding">
					<ion-card class="restaurantMessage ion-no-margin">
						<ion-card-content class="ion-padding">
							<ion-text style="font-size: 14px;" translate>
								Order has missing Mercadopago payment 
							</ion-text>
						</ion-card-content>
					</ion-card>	
				</ion-col>
			</ion-row>
		</div>
	</div>
</div>
