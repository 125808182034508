import { AbstractControl } from '@angular/forms';

export function ValidateCreditCard(control: AbstractControl): {[key: string]: boolean} {
    let digits = control.value;
    if(digits.length != 16) return {incompleteCreditCard: true};
    let sum = 0;
    for(let i = 0; i < digits.length; i++) {
        let cardNum = parseInt(digits[i]);
        if((digits.length - i) % 2 === 0) {
            cardNum = cardNum * 2;
            if (cardNum > 9) cardNum = cardNum - 9;
        }
        sum += cardNum;
    }
    return sum % 10 === 0 ? null : {invalidCreditCard: true};
}