import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-message-method-operation-dialog',
  templateUrl: './message-method-operation-dialog.page.html',
  styleUrls: ['./message-method-operation-dialog.page.scss'],
})
export class MessageMethodOperationDialogPage implements OnInit {

  @Input() paymenSettingstOperation: string;
  @Input() paymenSettingstValue: number;
  @Input() grandTotal: number;
  @Output() dismiss = new EventEmitter<number>();

  constructor() { }

  ngOnInit() { }

}
