import { Pipe, PipeTransform } from '@angular/core';
import { RestaurantService } from 'src/app/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {

    constructor(private restaurantService: RestaurantService) {}

    transform(phone: String): String {
        if(!phone)
            return '';

        var p = phone.split("");

        if(this.restaurantService.restaurant.info.country === 'brazil') {
            switch (phone.length) {
                case 10:
                    return '('+p[0]+p[1]+') '+p[2]+p[3]+p[4]+p[5]+'-'+p[6]+p[7]+p[8]+p[9];
                    break;

                case 11:
                    return '('+p[0]+p[1]+') '+p[2]+p[3]+p[4]+p[5]+p[6]+'-'+p[7]+p[8]+p[9]+p[10];
                    break;

                case 13:
                    return '('+p[3]+p[4]+') '+p[5]+p[6]+p[7]+p[8]+'-'+p[9]+p[10]+p[11]+p[12];
                    break;

                case 14:
                    return '('+p[3]+p[4]+') '+p[5]+p[6]+p[7]+p[8]+p[9]+'-'+p[10]+p[11]+p[12]+p[13];
                    break;
            }
        }

        if(this.restaurantService.restaurant.info.country === 'United States of America') {
            switch (phone.length) {
                case 10:
                    return '('+p[0]+p[1]+p[2]+') '+p[3]+p[4]+p[5]+'-'+p[6]+p[7]+p[8]+p[9];
                    break;

                case 11:
                    return '('+p[1]+p[2]+p[3]+') '+p[4]+p[5]+p[6]+'-'+p[7]+p[8]+p[9]+p[10];
                    break;
            }
        }

        if(this.restaurantService.restaurant.info.country === 'portugal' || this.restaurantService.restaurant.info.country === 'paraguay') {
            switch (phone.length) {
                case 12:
                    return p[3]+p[4]+p[5]+' '+p[6]+p[7]+p[8]+' '+p[9]+p[10]+p[11];
                    break;

                case 9:
                    return p[0]+p[1]+' '+p[2]+p[3]+p[4]+' '+p[5]+p[6]+p[7]+p[8];
                    break;
            }
        }

        if(this.restaurantService.restaurant.info.country === 'bolivia') {
            switch (phone.length) {
                case 11:
                    return p[3]+' '+p[4]+p[5]+p[6]+' '+p[7]+p[8]+p[9]+p[10];
                    break;

                case 8:
                    return p[0]+' '+p[1]+p[2]+p[3]+' '+p[4]+p[5]+p[6]+p[7];
                    break;
            }
        }

        return phone;
    }
}