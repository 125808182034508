import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';

import CryptoJS from 'crypto-js'
import { MenuSelector } from './menuSelector.service';

const FOUR_HOURS_MS = 14400000;

@Injectable()
export class TableService {
    public restaurantInfo = null;

    public currentTable = null;
    public tableItems = [];
    public orderUUID = null;

    constructor(
        private apiService: ApiService,
        private menuSelector: MenuSelector,
    ) {}

    setRestaurantInfo(restaurantInfo) {
        this.restaurantInfo = restaurantInfo;
        return this;
    }

    async validateTableLink(): Promise<boolean> {
		const localTabletTable: string | null = JSON.parse(localStorage.getItem(`tablet-${this.restaurantInfo.id}`));
        const localTable: string | null = JSON.parse(localStorage.getItem(`table-${this.restaurantInfo.id}`));

		const isTabletLink = this.menuSelector.menuRoute === 'tablet';
        const tableFromUrl = this.decodeTableFromUrl(new URLSearchParams(window.location.search).get('t'));

		const expirationTableDateLocal = JSON.parse(localStorage.getItem(`tableExp-${this.restaurantInfo.id}`));
        const table = localTable || tableFromUrl || localTabletTable;

        if (!table || this.restaurantInfo.is_closed || !this.restaurantInfo.table_self_service_enabled)
            return false;

		if (localTabletTable) {
			const tableNumber = localTabletTable.replace('tablet-', '');
			this.setTabletTable(tableNumber);
			this.menuSelector.setType('tablet');
			return true;
		}

		if (isTabletLink && tableFromUrl) {
			this.setTabletTable(tableFromUrl);
			this.menuSelector.setType('tablet');
			return true;
		}
        const orderUUID = localStorage.getItem(`table-uuid-${this.restaurantInfo.id}`);
        this.orderUUID = orderUUID;

        if (orderUUID) {
            const order = await this.apiService.get(`/order/table-uuid/${orderUUID}`).toPromise();

            if (!order || ['CANCELED', 'OVER'].includes(order.status)) {
                localStorage.removeItem(`table-uuid-${this.restaurantInfo.id}`);

                if (!tableFromUrl) {
                    return false;
                }
            }
        }

        const response = localTable ? await this.getTable(localTable) : null;

		if (localTable && !tableFromUrl && expirationTableDateLocal && !response) {
			const now = new Date().getTime();

			if (now >= expirationTableDateLocal) {
				this.clearTableExpiration();
				return false;
			}

			this.currentTable = table;
			return true;
		}

        if (!response && tableFromUrl) {
			this.setTable(tableFromUrl);
			localStorage.setItem(`tableExp-${this.restaurantInfo.id}`, String(new Date().getTime() + FOUR_HOURS_MS));
            return true;
        }

        if (!response) {
			this.removeTable();
            return false;
        }

		this.setTable(response.table);
		localStorage.removeItem(`tableExp-${this.restaurantInfo.id}`);
		this.clearTableExpiration();
        return true;
    }

    public async getTable(table: string = this.currentTable) {
        try {
            return await this.apiService.get(`/restaurant/${this.restaurantInfo.id}/orders/table/${table}`).toPromise();
        } catch {
            return null;
        };
    }

	public clearTableExpiration() {
		localStorage.removeItem(`tableExp-${this.restaurantInfo.id}`);
    }

    public setTableOrderUUID(uuid) {
        this.orderUUID = uuid;
		localStorage.setItem(`table-uuid-${this.restaurantInfo.id}`, uuid);
    }

    public invalidateTable() {
		this.currentTable = null;
        this.orderUUID = null;
        this.updateTableLocalStorage();
        localStorage.removeItem(`table-uuid-${this.restaurantInfo.id}`);
    }

    private updateTableLocalStorage() {
        const restaurantId = this.restaurantInfo.id;

        localStorage.setItem(`table-${restaurantId}`, JSON.stringify(this.currentTable));
    }

    public decodeTableFromUrl(url: string): string | null {
        if (!url)
            return null;

        return CryptoJS.AES.decrypt(decodeURIComponent(url), `tablesecret-${this.restaurantInfo.id}`).toString(CryptoJS.enc.Utf8);
    }

	public setTable(table) {
		this.currentTable = table;
		this.updateTableLocalStorage();
	}

	public removeTable() {
		this.currentTable = null;
		this.updateTableLocalStorage();
	}

	public removeTabletTable() {
		this.currentTable = null;
		localStorage.removeItem(`tablet-${this.restaurantInfo.id}`);
	}

	public setTabletTable(table) {
		const restaurantId = this.restaurantInfo.id;
		this.currentTable = table;
		localStorage.setItem(`tablet-${restaurantId}`, JSON.stringify(this.currentTable));
	}

    public async getOpenTableByUUID() {
        if (!this.orderUUID)
            return null;

        const order = await this.apiService.get(`/order/table-uuid/${this.orderUUID}`).toPromise();
        if (!order || ['CANCELED', 'OVER'].includes(order.status)) {
            return null
        }
        return order;
    }
}
