<ion-header>
	<ion-toolbar color="danger">
		<ion-buttons slot="start">
			<ion-button (click)="back()">
				<ion-icon name="chevron-back-outline"></ion-icon>
			</ion-button>
		</ion-buttons>	
		<ion-title class="p-2 ion-text-center">{{item.name}}</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content style="display: flex">
	<ion-list *ngFor="let itemExtra of extras; let extraIndex = index" [id]="'extra-'+itemExtra.id">
		<ion-radio-group [(ngModel)]="itemExtra.radioGroup">
			<ion-list-header class="ion-no-padding ion-text-center">
				<ion-title class="p-2" size="small">
					<h3>{{itemExtra.name}}</h3>
					<ion-row *ngIf="itemExtra.description">
						<ion-col>
							<ion-card color="light" class="ion-no-margin ion-margin-bottom">
								<ion-card-content [innerHTML]="getSafeHtml(itemExtra.description)"> </ion-card-content>
							</ion-card>
						</ion-col>
					</ion-row>

					<ion-text *ngIf="itemExtra.type != 1">
						<span translate>Min</span>: {{itemExtra.qty_min}}
						|
						<span translate>Max</span>: {{itemExtra.qty_max}}
					</ion-text>

					<ion-text *ngIf="itemExtra.required || (itemExtra.type != 1 && itemExtra.qty_min > 0)">
						<p translate>Mandatory</p>
					</ion-text>							
				</ion-title>
			</ion-list-header>
			
			<span *ngFor="let item of itemExtra.options; let optionIndex = index">
				<ion-item *ngIf="item.available && item.hasStock === true || item.hasStock === false && showStockPausedItems()" lines="inset" (click)="firstIncrement(extraIndex, optionIndex)" class="ion-activatable" [ngClass]="{'extra-extra__without-stock' : item.hasStock === false}" style="position: relative;" [color]="item.qty > 0 ? 'danger' : ''">
					<ion-ripple-effect *ngIf="!item.asTitle "></ion-ripple-effect>
					<ion-grid [ngClass]="{ 'unavailable': !item.hasStock }">
						<ion-row *ngIf="item.asTitle">
							<ion-col>
								<ion-text class="ion-text-center ion-text-wrap"> <h3>{{item.name}}</h3> </ion-text>
							</ion-col>
						</ion-row>

						<ion-row *ngIf="!item.asTitle" class="ion-align-items-stretch">
							<ion-col size="3" class="p-0 centralize" *ngIf="item.image">
								<mp-img class="item-image" imgURL="{{imgURL}}/products/{{item.image}}/thumb_{{item.image}}.jpg" (error)="onImgError(item)" (click)="openViewer(item); $event.stopPropagation();"></mp-img>
							</ion-col>

							<ion-col [size]="item.image ? 5 : 8">
								<div class="text-wrap">
									{{item.name}}
									<br />
									<label *ngIf="item.description" class="text-wrap" style="line-height: 1;">
										<small [innerHTML]="getSafeHtml(item.description)"> </small>
									</label>

									<ion-label *ngIf="item.price > 0" [ngClass]="{'extra-price-active': item.qty > 0, 'extra-price-label': item.qty <= 0}">
										<b> {{item.price | multiCurrency}} </b>
									</ion-label>
								</div>
							</ion-col>

							<ion-col size="4">
								<ion-radio color="light" [disabled]="!item.hasStock" *ngIf="itemExtra.type == 1" [value]="item.id" class="ion-float-right"></ion-radio>

								<span *ngIf="itemExtra.type == 2 || (itemExtra.type == 3 && itemExtra.qtyPerItem > 1)" class="ion-float-right">

									<ion-row>
										<ion-icon name="remove-outline" [hidden]="item.qty == 0" class="quantity-button" style="font-size: 25px;" (click)="decrementQty(extraIndex, optionIndex, $event)" [class.white-quantity-button]="item.qty > 0"></ion-icon>
										<ion-input #ionicInput type="number" [disabled]="!item.hasStock" [hidden]="item.qty == 0" inputmode="number" class="text-center extra-input-quantity" [(ngModel)]="item.qty" (ionChange)="changeInputQuantity(itemExtra, item)"></ion-input>
										<ion-icon name="add-outline" class="quantity-button" style="font-size: 25px;" (click)="incrementQty(extraIndex, optionIndex)" [class.white-quantity-button]="item.qty > 0"></ion-icon>
									</ion-row>

								</span>

								<span *ngIf="itemExtra.type == 3 && (itemExtra.qtyPerItem == 1 || !itemExtra.qtyPerItem)" class="ion-float-right">
									<ion-checkbox color="light" [(ngModel)]="item.isChecked" (click)="firstIncrement(extraIndex, optionIndex)" [disabled]="!item.isChecked && checkboxBeyondLimit(extraIndex) || !item.hasStock"></ion-checkbox>
								</span>
							</ion-col>
						</ion-row>
						<ion-badge *ngIf="!item.hasStock" data-cy="hasStock">Item indisponível no momento.</ion-badge>
					</ion-grid>
				</ion-item>
			</span>
		</ion-radio-group>
	</ion-list>		
</ion-content>

<ion-footer color="">
	<ion-toolbar>
		<ion-grid>
			<ion-row>
				<ion-grid style="max-height: 20vh; overflow-y: scroll; overflow-x: hidden; padding-right: 0px;">
					<ion-row *ngIf="item.price > 0">
						<ion-col size="9">
							<!-- <ion-icon name="more" (click)="presentFlavorOptions(selectedFlavor)"></ion-icon> -->
							<span class="vertical-align-selected-flavors">
								<strong>{{(item.name.length>20)? (item.name | slice:0:20)+'...':(item.name)}}</strong>
							</span>
						</ion-col>
		
						<ion-col size="3" style="text-align: right;">
							<span class="vertical-align-selected-flavors">
								<strong>{{item.price |  multiCurrency}}</strong>
							</span>
						</ion-col>
					</ion-row>

					<ion-row *ngFor="let extra of getSelectedExtras()">
						<ion-col size="9">
							<!-- <ion-icon name="more" (click)="presentFlavorOptions(selectedFlavor)"></ion-icon> -->
							<span class="vertical-align-selected-flavors">
								<b *ngIf="extra.qty > 1">{{extra.qty}}x</b>
								{{(extra.name.length>20)? (extra.name | slice:0:20)+'...':(extra.name)}}
							</span>
						</ion-col>

						<ion-col size="3" style="text-align: right;">
						<span class="vertical-align-selected-flavors" *ngIf="extra.total > 0"> 
								{{extra.price * extra.qty | multiCurrency}}
							</span>
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-row>

			<ion-row class="ion-text-center footerButton">
				<ion-col>
					<ion-label *ngIf="selectedExtras.length > 0" style="position: relative; top: 10px;">
						<ion-text translate>TOTAL: </ion-text>
						<ion-text style="font-weight: bold!important">{{calculateTotalPrice() | multiCurrency}} </ion-text>
					</ion-label>
				</ion-col>

				<ion-col>
					<ion-button color="danger" expand="block" mode="ios" class="ion-no-margin" (click)="addToShoppingCart()" [disabled]="isDisabled()" data-cy="extraAdd">
						<ion-text style="font-weight: 800; font-size: 0.9em;" translate><ion-icon name="cart-outline" style="margin-right: 4px;"></ion-icon> ADD TO CART</ion-text>
					</ion-button>
				</ion-col>					
			</ion-row>
		</ion-grid>
	</ion-toolbar>
</ion-footer>
