import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DeliveryPage } from './delivery.page';

const routes: Routes = [
  {
    path: '',
    component: DeliveryPage
  },
  {
    path: 'min-value-alert',
    loadChildren: () => import('./min-value-alert/min-value-alert.module').then( m => m.MinValueAlertPageModule)
  },
  {
    path: 'successfully-order-dialog',
    loadChildren: () => import('./successfully-order-dialog/successfully-order-dialog.module').then( m => m.SuccessfullyOrderDialogPageModule)
  },
  {
    path: 'schedule',
    loadChildren: () => import('./schedule/schedule.module').then( m => m.SchedulePageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeliveryPageRoutingModule {}
