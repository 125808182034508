import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PhonePipe } from './phone.pipe';
import { CpfCnpjPipe } from './cpfCnpj.pipe';
import { FormatTimePipe } from './formatTime.pipe';
import { MultiCurrencyPipe } from './multiCurrency.pipe';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { manualTranslate } from './manualTranslate.pipe';
import { ToDateObjPipe } from './toDateObj.pipe';
import { GeocodeToAddress } from './geocodeToAddress.pipe';

@NgModule({
    imports: [IonicModule],
    declarations: [
        PhonePipe,
        MultiCurrencyPipe,
        manualTranslate,
        CpfCnpjPipe,
        FormatTimePipe,
        ToDateObjPipe,
    ],
    exports: [
        PhonePipe,
        MultiCurrencyPipe,
        manualTranslate,
        CpfCnpjPipe,
        FormatTimePipe,
        ToDateObjPipe,
    ],
    providers: [
        CurrencyPipe,
        MultiCurrencyPipe,
        manualTranslate,
        TranslatePipe,
        GeocodeToAddress,
        FormatTimePipe,
        DatePipe,
    ],
})
export class PipesModule {}
