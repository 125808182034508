import { Injectable } from "@angular/core";
import FingerprintJS from "@fingerprintjs/fingerprintjs"

@Injectable()
export class FingerprintService {

    public fingerprint = null;

    constructor(
    ) {
    }

    async getFingerPrint() {
        if(this.fingerprint) return;

        let fpPromise = await FingerprintJS.load();
        let fp = fpPromise;
        let result = await fp.get();

        this.fingerprint = result.visitorId;
    }
}