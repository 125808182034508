import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddressPageRoutingModule } from './address-routing.module';
import { ValidatorsModule } from 'src/app/core/validators/validators.module.';

import { AddressPage } from './address.page';
import { IonicSelectableModule } from 'ionic-selectable';
import { PipesModule } from 'src/app/shared/pipe/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { LaddaModule } from 'angular2-ladda';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddressPageRoutingModule,
    IonicSelectableModule,
    PipesModule,
    TranslateModule,
    ValidatorsModule,
    LaddaModule.forRoot({
      style: "zoom-out"
    })
  ],
  declarations: [AddressPage]
})
export class AddressPageModule {}
