import { Injectable } from '@angular/core';
import { reject, resolve } from 'cypress/types/bluebird';
import { CreditCardDTO } from 'src/app/shared/model/dto/CreditCardDTO';

import { ApiService } from './api.service';
import { FingerprintService } from './fingerprint.service';
import { RestaurantService } from './restaurant.service';
import { UtilsService } from './utils.service';

@Injectable()
export class CreditCardService {

    public fingerprint: string = null;
    public creditCard: CreditCardDTO = null;
    public creditCards: CreditCardDTO[] = [];
    public baseUrl: string = `/restaurant/${this.restaurantService.restaurant.info.id}/asaas/payment/credit-card`;
    public wantsToStoreCard: boolean = true;
    private shouldStoreCard: boolean = false;

    constructor(
        private apiService: ApiService,
        private restaurantService: RestaurantService,
        private fingerprintService: FingerprintService
    ) { 
        this.fingerprintService.getFingerPrint().then(() => {
            this.fingerprint = this.fingerprintService.fingerprint;
        });
    }

    getCreditCards(): Promise<object> {
        return new Promise((resolve, reject) => {
            return this.apiService.get(this.baseUrl).subscribe(result => {
                this.creditCards = result;
                this.creditCard = this.creditCards.find(card => card.creditCardIsDefault);
                if(!this.creditCard) this.creditCard = this.creditCards[0];
                resolve(result);
            }, error => {
                reject(error);
            });
        });
    }

    createCreditCard(card): Promise<object> {
        card.fingerprint = this.fingerprint;
        return new Promise((resolve, reject) => {
            return this.apiService.post(this.baseUrl, card).subscribe(result => {
                this.creditCard = result;
                this.creditCards.push(this.creditCard);
                resolve(result);
            }, error => {
                reject(error);
            });
        });
    }

    checkCreditCardFingerprint(): Promise<object> {
        return new Promise((resolve, reject) => {
            return this.apiService.post(`${this.baseUrl}/${this.creditCard.creditCardUUID}/fingerprint`, 
                { fingerprint: this.fingerprint }).subscribe(result => {
                    resolve(result);
                }, error => {
                    reject(error);
                });
        });
    }

    checkCreditCardCVV(cvv): Promise<object> {
        return new Promise((resolve, reject) => {
            return this.apiService.post(`${this.baseUrl}/${this.creditCard.creditCardUUID}/cvv`, {
                fingerprint: this.fingerprint,
                creditCardCVV: cvv,
            }).subscribe(result => {
                    resolve(result);
                }, error => {
                    reject(error);
                })
        })
    }

    deleteCreditCard(creditCardUUID): Promise<object> {
        return new Promise((resolve, reject) => {
            return this.apiService.delete(`${this.baseUrl}/${creditCardUUID}`).subscribe(result => {
                    this.creditCards = this.creditCards.filter(card => card.creditCardUUID != creditCardUUID);
                    if(this.creditCard.creditCardUUID == creditCardUUID) this.creditCard = null;
                    resolve(result);
                }, error => {
                    reject(error);
                })
        })
    }

    setShouldStoreCard(storeIt: boolean) {
        this.shouldStoreCard = storeIt;
    }

    getShouldStoreCard() {
        return this.shouldStoreCard
    }
}