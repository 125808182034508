<ion-header>
  <ion-toolbar class="color-toolbar" style="text-align: center;">
      <div class="container-header">
        <ion-label style="font-weight: 700;" class="ion-text-wrap" text-wrap translate>Redirect to WhatsApp</ion-label>
      </div>
  </ion-toolbar>
</ion-header>


<div class="center-div-message">
  <p translate>Click the button below to send order to WhatsApp</p>
</div>

<div class="div-redirectWhatsApp">
    <ion-button
        style="font-weight: 800;"
        mode="ios"
        color="success"
        expand="block"
        class="ion-text-wrap"
        text-wrap
        (click)="onClick()"
        translate
    >
        <ion-icon name="logo-whatsapp" style="margin-right: 4px;"></ion-icon> Send order to WhatsApp
    </ion-button>
</div>