import { Validator, NG_VALIDATORS, FormControl, ValidationErrors } from '@angular/forms';
import { Directive } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Directive({
    selector: '[emailValidator][formControlName],[emailValidator][formControl],[emailValidator][ngModel]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: emailValidatorDirective, multi: true }
    ],
})

export class emailValidatorDirective implements Validator {

    constructor(private utilsService: UtilsService) {}

    validate(formControl: FormControl) : ValidationErrors {

        if (!this.utilsService.validateEmail(formControl.value)) {
            return {'invalidEmail': true};
        }

        return null;
    }

}