<ion-header>
	<ion-toolbar color="danger">
		<div class="container-header">
			<ion-title class="text-toolbar" translate>Comments</ion-title>
		</div>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-row class="ion-padding-start ion-padding-end">
		<ion-col class="ion-text-center">
			<strong translate>Notes</strong>
			<textarea normalizeInput type="text" autocomplete="off" id="notes" placeholder="Ex: remover a cebola" data-cy="textAreaOrderNotes"
				class="note-flavor-text-area" [(ngModel)]="notes" rows="3"></textarea>
		</ion-col>
	</ion-row>

	<ion-row class="ion-padding-start ion-padding-end" *ngIf="flavors">
		<ion-col>
			<strong translate>Note on pizza flavors</strong>
		</ion-col>
	</ion-row>
	<ion-row class="ion-padding-start ion-padding-end" *ngFor="let flavor of flavors; let i = index">
		<ion-col size="12" (click)="toggleCollapse(i)" data-cy="btnFlavorNotes">
			{{ flavor.name }}
			<ion-icon [name]="isCollapsed[i] ? 'chevron-down' : 'chevron-up'"></ion-icon>
		</ion-col>
		<ion-col size="12" [hidden]="isCollapsed[i]">
			<textarea type="text" autocomplete="off" id="flavorNotes" placeholder="Ex: remover a cebola" data-cy="textAreaFlavorNotes"
				class="note-flavor-text-area" [(ngModel)]="flavor.notes" rows="3"></textarea>
		</ion-col>
	</ion-row>
</ion-content>
<ion-footer>
	<ion-button mode="ios" color="danger" expand="block" class="custom-button" (click)="confirm()" translate data-cy="btnConfirmNotes">
		<ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> CONFIRM
	</ion-button>
</ion-footer>