import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NotesDialogPageRoutingModule } from './notes-dialog-routing.module';

import { NotesDialogPage } from './notes-dialog.page';
import { TranslateModule } from '@ngx-translate/core';
import { ValidatorsModule } from 'src/app/core/validators/validators.module.';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NotesDialogPageRoutingModule,
    TranslateModule,
    ValidatorsModule
  ],
  declarations: [NotesDialogPage]
})
export class NotesDialogPageModule {}
