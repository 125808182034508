<ion-header>
	<ion-toolbar color="danger">
		<div class="container-header">
			<ion-buttons>
				<ion-button routerDirection="back" (click)="backToMenu()">
					<ion-icon name="chevron-back-outline"></ion-icon>
				</ion-button>
			</ion-buttons>
			<ion-title class="text-toolbar" translate>Checkout</ion-title>
		</div>
	</ion-toolbar>
</ion-header>

<ion-content class="content-color">
	<form [formGroup]="paymentForm" class="payment-form" >

        <ion-card class="cards" style="margin-top: 10px;">
			<ion-grid >
				<div style="padding-right: 13px; padding-left: 13px;">
					<ion-row>
						<ion-col size="7">
							<h6><b translate>Order</b></h6>
						</ion-col>
	
						<ion-col size="5" class="ion-text-right">
							<h5><b>{{shoppingCarTotal | multiCurrency }}</b></h5>
						</ion-col>
					</ion-row>
	
					<ion-row *ngIf="deliveryFee">
						<ion-col size="7">
							<h6><b translate>Delivery fee</b></h6>
						</ion-col>
	
						<ion-col size="5" class="ion-text-right">
							<h5><b>{{deliveryFee | multiCurrency }}</b></h5>
						</ion-col>
					</ion-row>
	
					<ion-row [hidden]="!coupon.value">
						<ion-col size="7">
							<h6><b translate>Discount</b></h6>
						</ion-col>
	
						<ion-col size="5" class="ion-text-right">
							<h5><b>{{coupon.value | multiCurrency }}</b></h5>
						</ion-col>
					</ion-row>
	
					<ion-row *ngIf="appliedPaymentSetting">
						<ion-col size="7">
							<h6>
								<b translate>
									{{appliedPaymentSetting.operationName | translate }} {{'by payment method' | translate}}:
								</b>
							</h6>
						</ion-col>
	
						<ion-col size="5" class="ion-text-right">
							<h5>
								<b>
									<span [hidden]="appliedPaymentSetting.valueType != 'percent'">{{appliedPaymentSetting.value}}%</span>
									<span [hidden]="appliedPaymentSetting.valueType != 'fix'">{{appliedPaymentSetting.value | multiCurrency}}</span>
								</b>
							</h5>
						</ion-col>
					</ion-row>
				</div>

                <ion-row style="padding-right: 10px; padding-left: 10px;">
                    <ion-col size="7" style="padding-top: 0rem;">
                        <h3><span class="bold-statements" translate>Total Payable</span></h3>
					</ion-col>

                    <ion-col size="5" class="ion-text-right" style="padding-top: 0rem;">
                        <h3><span class="bold-statements" data-cy="orderTotal">{{grandTotal | multiCurrency }}</span></h3>
					</ion-col>
                </ion-row>

				<ion-grid data-cy="couponGrid" *ngIf="restaurant.info.coupoms_active" class="ion-no-padding ion-no-margin">
					<ion-row formGroupName="coupon">
						<ion-col style="margin-right: 3px; margin-left: 3px;">
							<input data-cy="couponInput" type="text" autocomplete="off" placeholder="{{ 'Coupon' | translate }}" style="text-align: center; text-transform: uppercase; margin-bottom: 10px!important;" formControlName="input">
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col>
							<ion-button data-cy="couponButton" mode="ios" color="danger" class="button-text coupom-button" expand="block" [disabled]="!coupon.input" (click)="checkCoupon()" translate>
								<ion-icon src="assets/icon/ticket-outline.svg" style="margin-right: 4px;"></ion-icon>
								APPLY COUPON
							</ion-button>
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-grid>
        </ion-card>

		<ion-row>
			<ion-col class="ion-text-center">
				<h4 class="ion-no-margin" translate>What is the payment method?</h4>
			</ion-col>
		</ion-row>

		<ion-grid style="padding-right: 0px; padding-left: 0px;">
			<ion-row class="d-flex flex-row justify-content-center flex-wrap">
				<ion-col [size]="paymentMethods.length > 3 ? 6 : 4" [id]="method"  *ngFor="let method of paymentMethods;">
					<input type="radio" class="d-none radio" [checked]="chosenMethods.includes(method.value)">

					<label [for]="method" class="d-block">
						<button class="payment-choice-button" style="height: 90px; padding: 0px 10px;" (click)="choseMethod(method, 0)" data-cy="parentPaymentMethod">
							<img *ngIf="method.img" class="button-online-payment" [src]="'assets/icon/'+method.img+'.png'">
							<ion-icon *ngIf="method.icon" [name]="method.icon"></ion-icon>
							<p translate>{{method.name}}</p>
						</button>
                        <span *ngIf="method.value === 'pixAsaas'" class="powered-by-asaas"></span>
					</label>
				</ion-col>
			</ion-row>

			<ion-grid *ngIf="childrenPaymentMethods">
				<ion-row *ngFor="let method of childrenPaymentMethods">
					<ion-col size="12" class="px-3">
						<input type="radio" class="d-none radio" [checked]="chosenMethods.includes(method.value)">

						<label [for]="method" class="d-block">
							<button class="button-text payment-choice-button payment-row" (click)="choseMethod(method, 1)" data-cy="childPaymentMethod">
								<img *ngIf="method.img" class="button-online-payment" [src]="'assets/icon/'+method.img+'.png'">
								<ion-icon *ngIf="method.icon" [name]="method.icon"></ion-icon>
								<p style="margin-left: 5px;" translate>{{method.name}}</p>
							</button>
						</label>

						<h4 *ngIf="method.value == 'money' && chosenMethods.includes('money')" [hidden]="cash.invalid" class="text-center" >
							<span [hidden]="!cash.change">{{ 'Change for' | translate }} {{cash.change | multiCurrency }}</span>
							<span [hidden]="cash.changeNeeded" translate>Change not needed</span>
						</h4>

						<h4 *ngIf="method.value == 'credit' && chosenMethods.includes('credit') && this.restaurant.info.card_types?.length > 0" [hidden]="card.invalid" class="text-center" >
							<span>
								{{card.name}}
								<span *ngIf="card.type != card.name">
									- {{card.type | translate}}
								</span>
							</span>
						</h4>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-grid>

		<ion-grid *ngIf="chosenMethods.includes('multipedidos@asaas')">
			<ion-row [hidden]="creditCard.creditCardBrand">
				<ion-col class="text-center" size="12">
					<ion-button color="danger" (click)="addCreditCard()" translate>Add card</ion-button>
				</ion-col>
			</ion-row>

			<div *ngIf="creditCard.creditCardBrand">
				<ion-item
					detail="true"
					lines="full"
				>
					<ion-label>
						<ion-row>
							<ion-col size="2">
								<app-credit-card-brand-icons [creditCardBrand]="creditCard.creditCardBrand"></app-credit-card-brand-icons>
							</ion-col>
	
							<ion-col size="10" class="ion-text-wrap">
								<ion-row>
									<ion-col class="ion-no-padding ion-no-margin" style="font-weight: 500;">
										<p>{{creditCard.creditCardNickName}}</p>   
										<p style="text-transform:capitalize">{{creditCard.creditCardBrand.toLowerCase()}} - {{creditCard.creditCardNumber}}</p>
									</ion-col>
	
									<ion-col size="3" class="ion-text-right centralize" (click)="manageCreditCards()">
										<ion-text class="refusal-text my-0" color="danger" mode="ios" translate>
											Change
										</ion-text>
									</ion-col>
								</ion-row>
							</ion-col>
	
						</ion-row>
					</ion-label>
				</ion-item>
			</div>
		</ion-grid>	

		<ion-grid [hidden]="paymentForm.invalid" style="box-shadow: none !important; margin-bottom: 0px; color: #7e7e7e;">
				<ion-row class="ion-no-padding">
					<ion-col class="ion-no-padding ion-text-center">
						<h6 style="margin-bottom: 0px;" class="bold-statements">{{'Total Payable' | translate}} {{grandTotal | multiCurrency}}</h6>
					</ion-col>
				</ion-row>
			
				<ion-row>
					<ion-col>
						<button [ladda]="sendingOrder" class="whatsapp-button footerButton bold-statements" (click)="sendOrder()" data-cy="sendOrderButton">
							<ion-text style="display: flex; align-items: center; justify-content: center;" >
								<ion-icon name="checkmark-outline" style="margin-right: 4px; font-size: 25px;"></ion-icon> {{'CONFIRM ORDER' | translate}}
							</ion-text>
						</button>
					</ion-col>
				</ion-row>
		</ion-grid>
	</form>
</ion-content>
