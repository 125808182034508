<div class="modal-content">
	<form #pagseguroForm="ngForm" >
		<ion-card style="box-shadow: none !important; margin-bottom: 0px;">
			<ion-grid style="padding-left: 10px;">
				<ion-row>
					<ion-col style="padding-bottom: 0px;">
						<span ><b translate>Total Payable</b></span>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col style="padding-left: 15px;">
						<h1 class="grand-total"><span class="bold-statements">{{grandTotal | multiCurrency }}</span></h1>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col class="ion-text-center">
						<ion-text translate class="message-email-picpay" translate>
							To make payments through PagSeguro, we need your Name and Last Name
						</ion-text>
					</ion-col>
				</ion-row>
	
				<ion-row>
					<ion-col class="ion-text-center">
						<input
							class="form-control"
							#name="ngModel"
							[(ngModel)]="user.name"
							[ngModelOptions]="{updateOn: 'blur'}"
							required
							pattern="^(\w.+\s).+$"
							debounce="500"
							name="name"
							type="text"
							autocomplete="off"
							placeholder="{{'Full Name' | translate}}"
							enterkeyhint="done"
							[ngClass]="{'invalid-input': (name.invalid && (name.dirty || name.touched))}"
						>
						<div *ngIf="(name.invalid && (name.dirty || name.touched))">
							<ion-text class="invalid-input-text">{{'Invalid Name' | translate}}</ion-text>
						</div>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col>
						<button class="button-text payment-choice-button__main-button payment-row" (click)="dismiss()" [disabled]="pagseguroForm.invalid" translate>
							CONFIRM
						</button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-card>
	</form>
</div>