import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonInput } from '@ionic/angular';
import { MenuDTO } from 'src/app/shared/model/dto/MenuDTO';
import { Menu } from 'src/app/shared/model/Menu';
import { RestaurantService } from 'src/app/core/services/restaurant.service';
import { Title } from '@angular/platform-browser';
import { Events } from 'src/app/core/services/events.service';
import { OnesignalService } from 'src/app/core/services/onesignal.service';
import { LocalStorageService } from "src/app/core/services/localStorage.service";
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';
import { OrderService } from 'src/app/core/services/order.service';
import { RestaurantLocalStorageService } from 'src/app/core/services/restaurantLocalStorage.service';
import { recommendProductService } from 'src/app/core/services/recommendProducts.service';

declare let fbq: any

@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.html',
	styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
	@ViewChild(IonContent) content: IonContent;
	@ViewChild('ionicInput') ionicInput: IonInput;
  	@ViewChild('categoryNav') categoryNav: any;

	restaurant: MenuDTO
	restaurantUrl: String
	loaded: boolean = this.restaurantService.loaded
	restaurantIsBlocked: boolean = false;

	searchString: String = "";
	searchResults: Menu
	categories = [];
	itemsToHide = { combos: true, pizzas: true, featuredItems: true };
	invalidURL = false
	availableProducts = [];
	recommendedProducts = [];

	constructor(
		private restaurantService: RestaurantService,
		private titleService: Title,
		private events: Events,
		private onesignalService: OnesignalService,
		private keen: KeenTrackingService,
		private localStorageService: LocalStorageService,
		private orderService: OrderService,
		private restaurantLocalStorageService: RestaurantLocalStorageService,
		private recommendProductService: recommendProductService
	) { }

	async ngOnInit() {
		if(!this.restaurantService.restaurant){
			this.invalidURL = true
			return
		}

		this.restaurant = this.restaurantService.restaurant;

		this.checkCanUseMenu();

		this.keen.setEventBase().then(() => {
			this.keen.event('loadRestaurant');
		})

		this.getProductsOfRestaurant();

		if (this.restaurant.info.fb_pixel_id) {
			fbq('init', this.restaurant.info.fb_pixel_id);
			fbq('track', 'PageView');
		}

		if (this.restaurant.info.use_push_notifications) {
			this.promptPush()
		}

		this.events.subscribe('onClickSameTab', () => {
			this.content.scrollToTop();
		})

		this.events.subscribe('reloadRestaurant', () => {
			this.loaded = false;
			this.categories = [];
			this.restaurantService.loadByURL(this.restaurant.info.restaurant_url).then((data) => {
				this.loaded = this.restaurantService.loaded;
				this.restaurant = this.restaurantService.restaurant;
				this.titleService.setTitle(this.restaurant.info.name);
				this.getProductsOfRestaurant();
			});
		})

		this.loadRecommendedProducts();
	}

	ngOnDestroy() {
		this.events.destroy('onClickSameTab');
		this.events.destroy('reloadRestaurant');
	}

	checkCanUseMenu() {
		if(this.restaurant.info.blocked || !this.restaurant.info.modules.digital_menu) {
			this.restaurantIsBlocked = true;
		}
	}

	getProductsOfRestaurant() {
		this.searchResults = this.restaurant.menu;

		this.itemsToHide.featuredItems = this.restaurant.menu.featuredItems.length == 0;

		this.itemsToHide.combos = true;
		this.restaurant.menu.combos.forEach(combo => {
			if (!combo.unavailable && !combo.unavailableByTemplate) {
				this.itemsToHide.combos = false;
				this.availableProducts.push(combo);
			}
		});

		this.itemsToHide.pizzas = true;
		this.restaurant.menu.pizzas.sizes.forEach(pizzas => {
			if (!pizzas.unavailable && !pizzas.unavailableByTemplate) {
				this.itemsToHide.pizzas = false;
				this.availableProducts.push(pizzas);
			}
		});

		this.restaurant.menu.general.forEach(category => {
			let showCategory = false;
			category.products.forEach(product => {
				if (!product.unavailable) {
					showCategory = true;
					if (category.available)
						this.availableProducts.push(product);
				}
			});

			if(category.unavailable || category.unavailableByTemplate) showCategory = false;

			if (showCategory) this.categories.push(category);
		});
	}

	async scrollToElement(categoryElementID) {
		const element = document.getElementById(categoryElementID);
		const rect = element.getBoundingClientRect();
		const scrollElement = await this.content.getScrollElement();
		this.content.scrollToPoint(0, rect.top + scrollElement.scrollTop - 50, 1000);
	}

	filterMenu(str: String) {
		this.searchResults = this.restaurantService.filterMenu(str, this.restaurant.menu);
	}

	async promptPush() {
		if(!this.onesignalService.isInitied) await this.onesignalService.initOneSignal();

        let oneSignalSupported = await this.onesignalService.isSupported();
        if (!oneSignalSupported) console.error("OneSignal: Browser not compatible!");

        this.onesignalService.sendTags()

        let oneSignalEnabled = await this.onesignalService.isEnabled();
        if (!oneSignalEnabled) return;

        this.onesignalService.getUserID().then(userID => {
            this.localStorageService.setItem('OneSignalID', userID)
        }, error => {
            console.log('Error:', error.error)
        })
	}

	highlightCategory(categoryId: string) {
		const segmentButtons = document.querySelectorAll('ion-segment-button');
		segmentButtons.forEach(button => {
		  button.classList.remove('segment-button-checked');
		});
		const targetButton = document.getElementById(categoryId);
		if (targetButton) {
		  targetButton.classList.add('segment-button-checked');
		}
	}

	elementScrollCategoryNav(categoryId: string) {
		const categoryNavElement = document.getElementById('categoryNav');
		const element = document.getElementById(categoryId);
		const rect = element.getBoundingClientRect();

		const scrollOptions: ScrollToOptions = {
			left: categoryNavElement.scrollLeft + rect.left - 100,
			behavior: 'smooth',
		};
		categoryNavElement.scrollTo(scrollOptions);
	}

	async onScroll() {
		const elements = [
			{ id: 'featuredItems', category: 'featuredItems-category' },
			{ id: 'combos', category: 'combos-category' },
			{ id: 'pizzas', category: 'pizzas-category' },
		];

		for (const item of elements) {
			const element = document.getElementById(item.id);
			if (element) {
				const rect = element.getBoundingClientRect();
				if (rect.top <= 100 && rect.bottom >= 100) {
					this.highlightCategory(item.category);
					this.elementScrollCategoryNav(item.category);
				}
			}
		}

		for (const item of this.categories) {
			const element = document.getElementById(`${item.name}-${item.id}`);
			if (element) {
				const rect = element.getBoundingClientRect();
				if (rect.top <= 100 && rect.bottom >= 100) {
					this.highlightCategory(`general-${item.id}`);
					this.elementScrollCategoryNav(`general-${item.id}`);
				}
			}
		}
	}

	async loadRecommendedProducts() {
		if (this.recommendProductService.checkCanShowRecommendedProducts()) {
			this.recommendProductService.setAvailableProducts(this.availableProducts);
			await this.recommendProductService.getRecommendedProducts().then((recommendedProducts) => {
				this.recommendedProducts = recommendedProducts;
			});
		}
	}

}
