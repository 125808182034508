import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface LambdaResponse {
    uuid: string;
    client_phone: string;
    is_cart_recovering: string;
    created_at: string;
    updated_at?: string;
    timestamp: string;
    ttl: string;
    restaurant_id: string;
}

@Injectable()
export class CartRecoverService {
    public isRecover: number = 0;

    public addInCart: boolean = false;

    public uuid: string | null = null;

    public baseURL: string = 'https://elr36dzcaobus2w6zt7ps6gozu0vhnox.lambda-url.us-east-1.on.aws';

    constructor(private http: HttpClient) {}

    async checkUuid(uuid) {
        if(!uuid)
            return;

        try {

            const data = await this.getDataFromLambda(uuid).toPromise();
            if(this.recoveryCheckExpired(data))
                return;

            this.uuid = uuid;
            this.isRecover = Number(data.is_cart_recovering);
            // localStorage.setItem(`phone-${data.restaurant_id}`, data.client_phone);

        } catch (error) {
            console.log('deu erro', error);
        }
    }

    recoveryCheckExpired(data) {
        const createdAtTimestamp = parseInt(data.created_at);
        const providedDate = new Date(createdAtTimestamp);
    
        const currentDate = new Date();
    
        providedDate.setHours(providedDate.getHours() + 4);
    
        return providedDate.getTime() < currentDate.getTime();
    }

    updateTimestamp(isRecover = this.isRecover): Observable<any> {
        return this.http.get(`${this.baseURL}/update?uuid=${this.uuid}&is-cart-recovering=${isRecover}`);
    }

    delete(): Observable<any> {
        return this.http.get(`${this.baseURL}/delete?uuid=${this.uuid}`);
    }

    getDataFromLambda(uuid): Observable<LambdaResponse> {
        return this.http.get<LambdaResponse>(`${this.baseURL}/get?uuid=${uuid}`);
    }

}