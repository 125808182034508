import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgIdleModule } from '@ng-idle/core';
import {
    RestaurantService,
    StartupService,
    TableService,
    ApiService,
    ThemeService,
    CreditCardService,
    ConvenixService,
    MotoboyTrackingService,
    JourneyService,
    CartRecoverService
} from './services';
import { AuthenticationService } from './services/authentication.service';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { ShoppingCartService } from './services/shoppingCart.service';
import { idleTimeoutService } from './services/idleTimeout.service';
import { LaddaModule } from 'angular2-ladda';
import { NavService } from './services/nav.service';
import { OrderService } from './services/order.service';
import { PusherService } from './services/pusher.service';
import { Events } from './services/events.service';
import { DebugModeService } from './services/debugMode.service';
import { CartGuardService } from './services/cart-guard.service';
import { DataGuardService } from './services/data-guard.service';
import { DeliveryService } from './services/delivery.service';
import { environment } from 'src/environments/environment';
import { FingerprintService } from './services/fingerprint.service';
import { KeenTrackingService } from './services/keenTracking.service';
import { CloudwatchMetricsService } from './services/cloudwatchMetrics.service';
import { SocialAuth } from './services/SocialAuth.service';
import { UserService } from './services/user.service';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { MenuSelector } from './services/menuSelector.service';
import { FacebookPixelService } from './services/facebookPixel.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
    imports: [
        HttpClientModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        NgIdleModule.forRoot(),
        LaddaModule.forRoot({
            style: "contract",
            spinnerSize: 40,
            spinnerColor: "red",
            spinnerLines: 12
        }),
        SocialLoginModule
    ],
    providers: [
        TranslateModule,
        CartRecoverService,
        ApiService,
        DebugModeService,
        RestaurantService,
        TableService,
        MenuSelector,
        ShoppingCartService,
        ThemeService,
        idleTimeoutService,
        StartupService,
        AuthenticationService,
        NavService,
        OrderService,
        CartGuardService,
        DataGuardService,
        DeliveryService,
        CreditCardService,
        FingerprintService,
        KeenTrackingService,
        CloudwatchMetricsService,
        UserService,
        SocialAuth,
        {
            provide: APP_INITIALIZER,
            useFactory: (startupService: StartupService, restaurantService: RestaurantService) => () => restaurantService.loadByURL(startupService.getRestaurantURL()),
            deps: [StartupService, RestaurantService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        PusherService,
        ConvenixService,
        MotoboyTrackingService,
        JourneyService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
              autoLogin: false,
              providers: [
                {
                  id: GoogleLoginProvider.PROVIDER_ID,
                  provider: new GoogleLoginProvider(
                    '982958682059-e2i6tqngt5ds6rjb5ec1ba7ndtkn6hg7.apps.googleusercontent.com'
                  )
                }
              ]
            } as SocialAuthServiceConfig,
        },
        FacebookPixelService
    ],
    exports: [
        TranslateModule
    ],
    declarations: []
})
export class CoreModule {
    constructor(
        platform: Platform,
        private events: Events,
        private debugModeService: DebugModeService,
    ) {
        platform.ready().then(() => {            

            platform.resize.subscribe(async () => {
                this.events.publish('windowResized');                
            });

            if(this.debugModeService.getMode()) this.debugModeService.debugMessage();

            window['debugMode'] = () => {
                this.debugModeService.debug();
            };

            var head = document.getElementsByTagName('head')[0];
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.defer = true;
            const googleMapsKey = environment.googleMapsKey;
            
            script.src = 'https://maps.googleapis.com/maps/api/js?key=' + googleMapsKey;
            head.appendChild(script);        
        });
    }
}