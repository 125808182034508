import { Injectable, Output, EventEmitter } from '@angular/core';

import { ApiService } from './api.service'
import { RestaurantService } from './restaurant.service'
import { LoginDTO } from 'src/app/shared/model/dto/LoginDTO'
import { tap } from 'rxjs/operators'
import { User } from 'src/app/shared/model/User';
import { LocalStorageService } from 'src/app/core/services/localStorage.service'

@Injectable()
export class AuthenticationService {

    public user: User
    public mockedCreditCards = [];

    @Output() changeStatus: EventEmitter<boolean> = new EventEmitter();
    @Output() changeLoginMode: EventEmitter<string> = new EventEmitter();

    constructor(
        private apiService: ApiService,
        private restaurantService: RestaurantService,
        private localStorageService: LocalStorageService,

    ) { }

    getUser(): Promise<User> {
        if(!this.isLoggedIn()) return Promise.reject('not logged');

        let promise = this.apiService.get(`/restaurant/${this.getRestaurantID()}/public/client/auth`).toPromise();
        
        promise.then(response => {
            this.user = response
        }).catch((r) => {});

        return promise;
    }

    getLoginMode(){
        return this.localStorageService.getItem(`loginMode-${this.getRestaurantID()}`)
    }

    isLoggedIn(): boolean {
        return this.localStorageService.getItem(`token-${this.getRestaurantID()}`) != undefined;
    }

    refreshToken() {
        return this.apiService.post(`/restaurant/${this.getRestaurantID()}/public/client/auth/refresh-token`, {
            token: this.localStorageService.getItem(`token-${this.getRestaurantID()}`)
        }).pipe(tap((data: Object) => {
            this.localStorageService.setItem(`token-${this.getRestaurantID()}`, data['token']);
        }));
    }

    getJwtToken(): string {
        if(typeof this.restaurantService.restaurant == 'undefined') return ;
        return this.localStorageService.getItem(`token-${this.getRestaurantID()}`);
    }

    login(user: any, mode): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/restaurant/${this.getRestaurantID()}/public/client/auth`, user).subscribe(response => {
                this.clearUserDataFromLocalStorage();
                this.setLoginInformation({token: response.token, mode});
                resolve("");
            }, error => reject(error))
        })
    }

    forgotPassword(email): any {
        return this.apiService.post(`/restaurant/${this.getRestaurantID()}/public/client/auth/forgot-password`, email).toPromise(); 
    }

    resetPassword(data) {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/restaurant/public/client/auth/reset-password`, data).subscribe(response => {
                this.setLoginInformation({token: response.token, mode: 'email'});
                resolve("");
            }, error => reject(error))
        })
    }

    logout(): boolean {
        this.clearUserDataFromLocalStorage();
        this.localStorageService.removeItem(`token-${this.getRestaurantID()}`);
        this.localStorageService.removeItem(`loginMode-${this.getRestaurantID()}`);
        this.user = null
        this.changeStatus.emit(this.isLoggedIn())
        this.changeLoginMode.emit(this.getLoginMode())
        return true
    }

    register(user: LoginDTO): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.post(`/restaurant/${this.getRestaurantID()}/public/client`, user).subscribe(response => {
                this.clearUserDataFromLocalStorage();
                this.setLoginInformation({token: response.token, mode: 'email'});
                resolve("")
            }, error => reject(error))
        })
    }

    updateUserPoints(updatedPoints) {        
        this.user.points = updatedPoints;
    }

    setLoginInformation(data) {
        this.localStorageService.setItem(`token-${this.getRestaurantID()}`, data.token);
        this.localStorageService.setItem(`loginMode-${this.getRestaurantID()}`, data.mode);
        this.changeStatus.emit(this.isLoggedIn());
        this.changeLoginMode.emit(this.getLoginMode());
    }

    clearUserDataFromLocalStorage() {
        let itemsToRemove = [
            'street',
            'number',
            'zone',
            'complement',
            'reference',
            'city',
            'zone_object',
            'latitude',
            'longitude',
            'distance',
            'name',
            'phone',
            'cpf',
            'email'
        ];

        itemsToRemove.forEach(item => this.localStorageService.removeItem(item));
    }

    getRestaurantID() {
        return this.restaurantService.restaurant.info.id;
    }
}

