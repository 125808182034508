import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AdditionalToppingsPage } from './additional-toppings/additional-toppings.page';
import { AnimationController, IonContent, ModalController } from '@ionic/angular';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { Menu } from 'src/app/shared/model/Menu';
import { Size } from 'src/app/shared/model/Size';
import { PizzaDTO } from 'src/app/shared/model/dto/PizzaDTO';
import { NavService } from 'src/app/core/services/nav.service';
import { RestaurantService } from 'src/app/core';
import { ComboDTO } from 'src/app/shared/model/dto/ComboDTO';
import { CurrentSizeDialogPage } from './current-size-dialog/current-size-dialog.page';
import { TranslatePipe } from '@ngx-translate/core';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/environments/environment';
import { ViewerModalComponent } from 'ngx-ionic-image-viewer';
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';
import { MenuSelector } from 'src/app/core/services/menuSelector.service';
import { ShoppingCartPage } from 'src/app/pages/shopping-cart/shopping-cart.page';
import { ExtraPage } from '../extra/extra.page';

@Component({
	selector: 'app-pizza',
	templateUrl: './pizza.page.html',
	styleUrls: ['./pizza.page.scss'],
})
export class PizzaPage implements OnInit {
    imgURL = environment.imgURL;

	@Input() product: any = [];
	@Input() isCombo: boolean = false;
	@Input() menu: Menu;

	sizes: Size[];
	currentSizeIndex: number = 0;
	currentSize: Size;
	quantityOptions = [];

	comboTemplate: ComboDTO;
	pizzaTemplate: PizzaDTO;

	extraTypes = ['crust', 'dough'];
	extras = {
		crust: {},
		dough: {}
	};
	selectedExtraID = {
		crust: '',
		dought: ''
	};

    private steps = 0;
	public additionalToppings = [];
	public flavorCategories = [];
	public selectedFlavorCategory = 0;
    public flavorsToShow = [];
    public currentStep = 1;

    @ViewChild(IonContent) content: IonContent;
    
	searchString: String = "";

	constructor(
		private restaurantService: RestaurantService,
		private cartService: ShoppingCartService,
		private navService: NavService,
		private modalCtrl: ModalController,
        private translatePipe: TranslatePipe,
		private utils: UtilsService,
		private keen: KeenTrackingService,
		private menuSelector: MenuSelector,
		private animationCtrl: AnimationController,
        private sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		if(!this.menuSelector.isTabletLink()){
			this.product = this.navService.get('product');
			this.isCombo = this.navService.get('isCombo');
			this.menu = this.navService.get('menu');
		}

		if (this.isCombo) {
			this.comboTemplate = {
				id: this.product.id,
				type: 'combo',
				name: this.product.name,
                price: this.product.price,
                totalPrice: this.product.price,
				qty: 1,
				comboItems: []
            };
            

            this.sizes = this.product.pizzaSizes.map(comboSize => {
                return this.menu.pizzas.sizes.filter(menuSize => menuSize.id == comboSize)[0] ?? null;
            })

			this.currentSize = this.sizes[this.currentSizeIndex];
		} else {
			this.currentSize = this.product;
		}
		this.updateSize();
    }

	updateSize() {
        this.currentStep = 1;
        this.steps = 0;

        this.selectedExtraID = {
            crust: '',
            dought: ''
        }; 

		this.extraTypes.forEach(type => {
			if(this.currentSize.items[type].length > 0){
				this.extras[type].options = this.currentSize.items[type];

				this.steps++;
				this.extras[type].step = this.steps;
				return;
			}

			if (this.currentSize[type]) {
				this.extras[type] = {};
				this.extras[type] = JSON.parse(JSON.stringify(this.menu.pizzas.extras[type].filter(crust => {
					return crust.id === this.currentSize[type]
				})[0]));

				this.steps++;
				this.extras[type].step = this.steps;
			}
        });  
        
		this.flavorCategories = this.menu.pizzas.flavorCategories.filter(category => {
			let categoryIDs = this.currentSize.flavors.map(flavor => flavor.flavorCategoryID);
			return categoryIDs.includes(category.id);
        });
        
		this.selectedFlavorCategory = 0;

		this.setAdditionalToppings()

        this.quantityOptions = [];
        for (let index = this.currentSize.minFlavors; index <= this.currentSize.maxFlavors; index++) {
            this.quantityOptions.push(index);
        }

		this.pizzaTemplate = {
			id: this.currentSize.id,
			name: this.currentSize.name,
			basePrice: this.currentSize.price,
			totalPrice: this.currentSize.price,
			priceBehavior: this.currentSize.priceBehavior,
			type: "pizza",
            maxFlavors: this.currentSize.priceBehavior == 'incremental' ? (this.currentSize.maxFlavors == 1 ? 1 : 0) : this.currentSize.maxFlavors,
            minFlavors: this.currentSize.minFlavors,
			crust: {},
			dough: {},
			selectedFlavors: [],
			notes: "",
            qty: 1,
            qttyOfFlavorsSelected: 0,
        };

        if (this.currentSize.minFlavors == this.currentSize.maxFlavors) {
            this.quantityOptions = [];
            this.pizzaTemplate.maxFlavors = this.currentSize.minFlavors;
        }
        
        this.steps++;
        this.pizzaTemplate.step = this.steps;

		if (this.isCombo) {
			this.openCurrentSizeModal(this.currentSize)
			this.pizzaTemplate.totalPrice = this.comboTemplate.totalPrice;   

			if (this.comboTemplate.comboItems[this.currentSizeIndex]) {
				let categoryIndex = this.flavorCategories.findIndex(category => {
					return category.id == this.comboTemplate.comboItems[this.currentSizeIndex].selectedFlavors[0]?.id;
				});

				if (categoryIndex != -1) this.onSelectFlavorCategorySegment(categoryIndex);
			} else {
				this.comboTemplate.comboItems.push(this.pizzaTemplate);
			}
        }

        this.calculateFlavorsPrices();
        this.flavorsToShow = this.flavorCategories[this.selectedFlavorCategory].flavors;        
	}

	setAdditionalToppings() {
		if(this.currentSize.items.additionalTopping.length){
			this.additionalToppings = this.currentSize.items.additionalTopping.filter(topping => topping.available);
			return;
		}

        let toppingsOfCurrentSize = this.menu.pizzas.extras.additionalToppings.filter(topping => {
			return topping.id == this.currentSize.additionalToppings;
		});
		if (toppingsOfCurrentSize.length > 0) {
			return this.additionalToppings = toppingsOfCurrentSize[0].options;
		}
		this.additionalToppings = []
    }

    async openViewer(item) {

		const modal = await this.modalCtrl.create({
			component: ViewerModalComponent,
			componentProps: {
				src: this.imgURL+'/flavors/'+item.image+'/lg_'+item.image+'.jpg',
				scheme: 'dark',
				swipeToClose: true,
				title: item.name,
				text: item.description ? item.description : ''
			},
			cssClass: 'ion-img-viewer',
			keyboardClose: true,
			showBackdrop: true
		});
	
		return await modal.present();
	}

	async openCurrentSizeModal(currentSize) {
		const modal = await this.modalCtrl.create({
			component: CurrentSizeDialogPage,
			componentProps: {
				currentSize: currentSize
			},
			cssClass: 'modal-currentSize-dialog',
		});
		return await modal.present();
	}

    calculateFlavorsPrices() {
        this.flavorCategories = this.flavorCategories.map((category) => {
            category.flavors = category.flavors.map((flavor) => {
                flavor.possiblePrices = this.flavorPrice(flavor, category);
                return flavor;
            })

            return category;
        })
    }

    flavorPrice(flavor, category = null) {
        if (!this.currentSize) return;

        let fractionPrices = [];
        this.quantityOptions.forEach(qtty => {
            fractionPrices[qtty] = 0
        });

		let categoryID = category ? category.id : this.flavorCategories[this.selectedFlavorCategory].id;
		let categoryAssoc = this.currentSize.flavors.find(_flavor => {
			return _flavor.flavorCategoryID == categoryID;
        });

        if (!categoryAssoc.extraPrice) return fractionPrices;
        
        if (this.currentSize.priceBehavior == 'incremental') 
            return this.getIncrementalFractionPrices(categoryAssoc, flavor.id, fractionPrices);
        
        return this.getAverageAndHighestFractionPrices(categoryAssoc, flavor.id, fractionPrices);
    }
    
    getIncrementalFractionPrices(categoryAssoc, flavorID, fractionPrices) {
        categoryAssoc.fractions.forEach(_fraction => {
            const foundException = _fraction.exception.filter(exception => exception.flavorID == flavorID)[0];
            fractionPrices[_fraction.pizzaPart] = foundException?.price ?? _fraction.price;
        })
        return fractionPrices;
    }

    getAverageAndHighestFractionPrices(categoryAssoc, flavorID, fractionPrices) {
        let firstCategoryAssoc = categoryAssoc.fractions[0];
        
        const foundException = firstCategoryAssoc.exception.filter(exception => exception.flavorID == flavorID)[0];
        let flavorPrice = foundException?.price ?? firstCategoryAssoc.price;

        for (let index = 1; index <= this.currentSize.maxFlavors; index++)
            fractionPrices[index] = flavorPrice
        return fractionPrices;
    }

	scrollToElement(elementId) {
		let yOffset = document.getElementById(elementId).offsetTop;
		this.content.scrollToPoint(0, yOffset, 750);
	}

	onSelectExtraRadio(extraType, index) {
        this.pizzaTemplate[extraType] = this.extras[extraType].options[index];

        if(this.extras[extraType].step == this.currentStep) this.currentStep++;

		let nextItem;
		if (extraType === 'crust') nextItem = 'dough';
		if (extraType === 'dough') nextItem = (this.quantityOptions.length > 1 && this.currentSize.priceBehavior == 'incremental') ? 'flavorQuantities' : 'flavorCategories';
        this.calculatePizzaTotal();

		setTimeout(() => {
			this.scrollToElement(nextItem);
		}, 200)
	}

	onSelectFlavorCategorySegment(flavorCategoryIndex) {
        this.searchString = '';
		this.selectedFlavorCategory = flavorCategoryIndex;
		this.flavorsToShow = this.flavorCategories[flavorCategoryIndex].flavors;
		}

	changeFlavorsQuantity(quantity) {
		this.pizzaTemplate.qttyOfFlavorsSelected = 0;
		this.pizzaTemplate.maxFlavors = quantity;
		this.pizzaTemplate.minFlavors = quantity;
		this.pizzaTemplate.selectedFlavors = [];
		this.calculatePizzaTotal();
	}

	flavorQuantity(flavor) {
		let quantity = 0;
		this.pizzaTemplate.selectedFlavors.forEach(selectedFlavor => {
			if (selectedFlavor.id == flavor.id) {
				quantity = selectedFlavor.quantity;
			}
		})
		return quantity;
	}

	decrementFlavor(flavor) {
		let flavorIndex = this.pizzaTemplate.selectedFlavors.findIndex(selectedFlavor => {
			return selectedFlavor.id == flavor.id;
		});

		if (this.flavorQuantity(flavor) > 0) {
			this.pizzaTemplate.selectedFlavors[flavorIndex].quantity--;

			if(this.currentSize.priceBehavior == 'incremental')
                this.pizzaTemplate.selectedFlavors[flavorIndex].price -= this.pizzaTemplate.selectedFlavors[flavorIndex].slicePrice;

			if (this.pizzaTemplate.selectedFlavors[flavorIndex].quantity == 0)
                this.pizzaTemplate.selectedFlavors.splice(flavorIndex, 1);
                
            this.pizzaTemplate.qttyOfFlavorsSelected -= 1;
            this.calculatePizzaTotal();
		}
	}

	firstIncrement(flavor) {
		if (!this.flavorQuantity(flavor)) this.incrementFlavor(flavor);
	}

	incrementFlavor(flavor) {
		let totalFlavorsQuantitySelected = 0;

		this.pizzaTemplate.selectedFlavors.forEach(selectedFlavor => {
			totalFlavorsQuantitySelected += selectedFlavor.quantity;
		});

        if (totalFlavorsQuantitySelected >= this.pizzaTemplate.maxFlavors) {
            this.fullFlavorToast();
            return;
        }
        
        if (this.flavorQuantity(flavor) == 0) {
            flavor.quantity = 1;
            flavor.additionalToppings = [];
            flavor.price = flavor.possiblePrices[this.pizzaTemplate.maxFlavors];
            flavor.slicePrice = flavor.price;
            this.pizzaTemplate.selectedFlavors.push(JSON.parse(JSON.stringify(flavor)));
        } else {
            let flavorIndex = this.pizzaTemplate.selectedFlavors.findIndex(selectedFlavor => {
                return selectedFlavor.id == flavor.id
            });
            this.pizzaTemplate.selectedFlavors[flavorIndex].quantity += 1;
            if (this.currentSize.priceBehavior == 'incremental') this.pizzaTemplate.selectedFlavors[flavorIndex].price += this.pizzaTemplate.selectedFlavors[flavorIndex].slicePrice;
        }
        this.pizzaTemplate.qttyOfFlavorsSelected = totalFlavorsQuantitySelected + 1;
        this.calculatePizzaTotal();
    }
    
    calculatePizzaTotal() {
		let total: number = 0;
            
        total += this.pizzaTemplate?.crust?.price ?? 0;
	
		total += this.pizzaTemplate?.dough?.price ?? 0;

		switch (this.pizzaTemplate.priceBehavior) {
			case 'incremental':
				total += this.pizzaTemplate.basePrice;
				this.pizzaTemplate.selectedFlavors.forEach(selectedFlavor => {
					total += selectedFlavor.slicePrice * selectedFlavor.quantity;
				});
				break;
			case 'highest':
                if (this.pizzaTemplate.selectedFlavors.length == 0) {
                    total += this.pizzaTemplate.basePrice;
                    break;
                }

				total += Math.max.apply(Math, this.pizzaTemplate.selectedFlavors.map(function(flavor) { return flavor.price; }));
				break;
			case 'average':
				total += this.weightedAverage(this.pizzaTemplate.selectedFlavors);
				break;
		}

		this.pizzaTemplate.selectedFlavors.forEach(flavor => {
			if (flavor.additionalToppings.length > 0) {
				flavor.additionalToppings.forEach(topping => {
					total += topping.price;
				});
			}
        });
        this.pizzaTemplate.totalPrice = total;
    }

	weightedAverage(selectedFlavors): number{
        let total = 0;
        let selectedFlavorsQtty = 0;
		selectedFlavors.forEach(selectedFlavor => {
            total += selectedFlavor.price * selectedFlavor.quantity;
            selectedFlavorsQtty += selectedFlavor.quantity;
        });
        
        total /= selectedFlavorsQtty;

		let roundType = this.restaurantService.restaurant.info.round_type;
        if (roundType != null) {
            let roundStep = this.restaurantService.restaurant.info.round_step;
            total = this.roundPizzaValue(total, roundType, roundStep);
        }

		return total;
    }

    roundPizzaValue(value, roundType, roundStep) {
        if(roundStep === 0.0 || roundStep === 0) return value;

        let noNeedToRound = Math.floor(value) === value;
        if(noNeedToRound) return value;

        let roundFunction;
        if (roundType == 'up') 
            roundFunction = Math.ceil
        else if(roundType == 'down') 
            roundFunction = Math.floor
        else 
            roundFunction = Math.round

        value = roundFunction(value / roundStep) * roundStep;

        return parseFloat(value.toFixed(2));
    }    

	async fullFlavorToast() {
		this.utils.createToaster(this.translatePipe.transform('Pizza is Full'));
	}

	next() {
		if (this.additionalToppings.length > 0) {
			this.goToAdditionalToppings();
		} else if (this.isCombo) {
			this.nextSize();
		} else {
			let extras = this.getExtras()
			if (extras.length > 0) {
				this.goToExtrasSections(extras);
			} else {
				this.finishSize();
			}
		}
    }
    
    async goToAdditionalToppings() {
		const modal = await this.modalCtrl.create({
			component: AdditionalToppingsPage,
			componentProps: {
				'additionalToppings': this.additionalToppings,
				'size': this.pizzaTemplate
			}
		});

		modal.onDidDismiss().then(response => {
			if (response.data.finishSize) {
				if (this.isCombo) {
					this.nextSize();
				} else {
					let extras = this.getExtras()
					if (extras.length > 0) {
						this.goToExtrasSections(extras);
					} else {
						this.finishSize();
					}
				}
			}
		});

		return await modal.present();
	}

	nextSize() {
		this.pizzaTemplate.selectedFlavors.forEach(flavor => {
			flavor.fractionText = this.flavorFractionText(flavor);
        });

		this.comboTemplate.comboItems[this.currentSizeIndex] = this.pizzaTemplate;        

        let lastSize = this.sizes.length - 1;        

		if (this.currentSizeIndex != lastSize) {
            this.currentSizeIndex++;
            this.currentSize = this.sizes[this.currentSizeIndex];
			this.content.scrollToTop();
			this.updateSize();
		} else {
            this.calculateComboTotalPrice();

			let extras = this.getExtras()
			if (extras.length > 0) {
				this.goToExtrasSections(extras);
			} else {
				this.finishCombo();
			}
		}
    }

	flavorFractionText(flavor) {
		let totalFlavorsSelectedCount = this.pizzaTemplate.qttyOfFlavorsSelected;
		let reducedFraction = this.cartService.reduceFraction([flavor.quantity, totalFlavorsSelectedCount])
		return `${reducedFraction[0]}/${reducedFraction[1]}`
    }

    calculateComboTotalPrice() {
        let comboItems = JSON.parse(JSON.stringify(this.comboTemplate.comboItems));

        comboItems.forEach((comboItem, comboItemIndex) => {

            this.comboTemplate.totalPrice += comboItem?.crust?.price ?? 0;
            this.comboTemplate.totalPrice += comboItem?.dough?.price ?? 0;

            comboItem.selectedFlavors.forEach(selectedFlavor => {
                this.comboTemplate.totalPrice += selectedFlavor?.additionalToppings.reduce((total, topping) => total + topping.price, 0) ?? 0;
            })

            if (comboItem.priceBehavior == "incremental"){
                comboItem.selectedFlavors.forEach((flavor, flavorIndex) => {
                    this.comboTemplate.totalPrice += flavor.slicePrice * flavor.quantity;
                    this.comboTemplate.comboItems[comboItemIndex].selectedFlavors[flavorIndex].price = 0;
                })
            } else if (comboItem.priceBehavior == "highest") {
                comboItem.selectedFlavors.forEach((flavor, flavorIndex) => {
                    if (flavor.price > comboItem.basePrice) {
                        this.comboTemplate.totalPrice += (flavor.price - comboItem.basePrice);
                        comboItem.basePrice = flavor.price;
                    }
                    this.comboTemplate.comboItems[comboItemIndex].selectedFlavors[flavorIndex].price = 0;
                })
            } else if (comboItem.priceBehavior == "average") {
                comboItem.selectedFlavors.forEach((flavor, flavorIndex) => {
                    this.comboTemplate.comboItems[comboItemIndex].selectedFlavors[flavorIndex].price = 0;
                })

                let averagePrice = this.weightedAverage(comboItem.selectedFlavors);
                
				if(averagePrice > comboItem.basePrice)
					this.comboTemplate.totalPrice += (averagePrice - comboItem.basePrice);
            }            
        });
    }

	async openTableCartView() {
		this.modalCtrl.dismiss();

		const modal = await this.modalCtrl.create({
			component: ShoppingCartPage,
			cssClass: 'tablet-modal',
			leaveAnimation: (baseEl) => {
				const backdropAnimation = this.animationCtrl.create()
					.addElement(baseEl.querySelector('ion-backdrop'))
					.fromTo('opacity', '0.4', '0.01');

				const wrapperAnimation = this.animationCtrl.create()
					.addElement(baseEl.querySelector('.modal-wrapper'))
					.fromTo('transform', 'translateX(0)', 'translateX(100%)');

				return this.animationCtrl.create().addElement(baseEl)
					.easing('ease-in-out')
					.duration(300)
					.addAnimation([backdropAnimation, wrapperAnimation]);
			},
		});
		return modal.present();
	}

    async finishSize() {
		this.cartService.addProductToCart(this.pizzaTemplate);

		if(!this.menuSelector.isTabletLink()) {
			return this.goToShoppingCart();
		}

		await this.modalCtrl.dismiss();
		this.openTableCartView();
	}

	async goToExtrasSections(extras) {
		var item = this.isCombo ? [name =>'Extras'] : this.pizzaTemplate  
		let extraParams = {
			'extras':  JSON.parse(JSON.stringify(extras)),
			'item': item,
			'combo': this.comboTemplate
		};

		if (this.menuSelector.isTabletLink()) {
			const modal = await this.modalCtrl.create({
				component: ExtraPage,
				componentProps: extraParams,
			});

			modal.onDidDismiss().then(async (res) => {
				if(res.role)
					return;

				let currentModal = await this.modalCtrl.getTop();
				await currentModal.dismiss();

				currentModal = await this.modalCtrl.getTop();
				await currentModal.dismiss();

				const modal = await this.modalCtrl.create({
					component: ShoppingCartPage,
					cssClass: 'tablet-modal',
					leaveAnimation: (baseEl) => {
						const backdropAnimation = this.animationCtrl.create()
						  .addElement(baseEl.querySelector('ion-backdrop'))
						  .fromTo('opacity', '0.4', '0.01');
	
						const wrapperAnimation = this.animationCtrl.create()
						  .addElement(baseEl.querySelector('.modal-wrapper'))
						  .fromTo('transform', 'translateX(0)', 'translateX(100%)');
	
						return this.animationCtrl.create().addElement(baseEl)
						  .easing('ease-in-out')
						  .duration(300)
						  .addAnimation([backdropAnimation, wrapperAnimation]);
					},
				});

				return modal.present();
			})

			return modal.present();
		}

		this.navService.push(`extra`, extraParams);
	}

	async finishCombo() {
		this.cartService.addProductToCart(this.comboTemplate);

		if(!this.menuSelector.isTabletLink()) {
			return this.goToShoppingCart();
		}

		await this.modalCtrl.dismiss();
		this.openTableCartView();
	}


	goToShoppingCart() {
		this.navService.push('shopping-cart');
	}

	back() {
		if (this.currentSizeIndex == 0) {
			this.navService.pop();
		} else {
			this.comboTemplate.comboItems[this.currentSizeIndex] = this.pizzaTemplate;
			this.currentSizeIndex--;
			this.currentSize = this.sizes[this.currentSizeIndex];
			this.updateSize();
		}

		this.keen.event('navigateBackward', {
			'from': 'pizza',
			'to': 'menu' 
		});
    }
    
    searchFlavor(userInput)
    {
        if (userInput.length == 0) {
            this.flavorsToShow = this.flavorCategories[this.selectedFlavorCategory].flavors;
        }

        let searchedFlavorName = (userInput).toLowerCase();
        this.flavorsToShow = []

        let filteredFlavors = [];

        this.flavorCategories.forEach(category => {
            category.flavors.filter(flavor => {
                if (flavor.name.toLowerCase().includes(searchedFlavorName)) {
                    flavor.priority = 1;
                    filteredFlavors.push(flavor)
                } else if (flavor.description && flavor.description.toLowerCase().includes(searchedFlavorName)) {
                    flavor.priority = 2
                    filteredFlavors.push(flavor)
                }
            })
        })

        this.flavorsToShow = filteredFlavors.sort((firstFlavor, secondFlavor) => firstFlavor['priority'] > secondFlavor['priority'] ? 1 : firstFlavor['priority'] === secondFlavor['priority'] ? 0 : -1)
    }

	getExtras()
	{
		if(!this.product.extras) return []
		
		return this.menu.extras.filter(extra => {
			return this.product.extras.includes(extra.id);
		});
	}

	convertToSafeHtml(rawHtml: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(rawHtml);
    }

    getSafeHtml(value: any) {
        try {
            return this.convertToSafeHtml(JSON.parse(value));
        } catch (error) {
            return value;
        }
    }

}
