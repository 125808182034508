import { Pipe, PipeTransform } from '@angular/core';
import { PhotonFeatureDTO } from '../model/dto/PhotonFeatureDTO';

export type TransformedPhotonFeature = {
    street: string;
    addr: string;
    lat: number;
    lng: number;
    district: string;
    city: string;
};

@Pipe({
    name: 'geocodeToAddress',
})
export class GeocodeToAddress implements PipeTransform {
    constructor() {}

    transform(feature: PhotonFeatureDTO): TransformedPhotonFeature {
        const [lng, lat] = feature.geometry.coordinates;
        const { name, district, city, state, country } = feature.properties;
        return {
            street: name,
            addr: `${district}, ${city} - ${state}, ${country}`,
            lat,
            lng,
            district,
            city,
        };
    }
}
