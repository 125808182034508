import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-notes-dialog',
	templateUrl: './notes-dialog.page.html',
	styleUrls: ['./notes-dialog.page.scss'],
})
export class NotesDialogPage implements OnInit {

	@Input() notes: string;
	@Input() flavors: any;
	isCollapsed: boolean[];

	constructor(private modalCtrl: ModalController) { }

	ngOnInit() {
		setTimeout(() => {
			let element = document.getElementById("notes");
			element.focus();
		}, 400);

		if(!this.flavors)
			return;

		this.flavors = JSON.parse(JSON.stringify(this.flavors));
		this.isCollapsed = new Array(this.flavors.length).fill(true);
	}

	async confirm() {
		await this.modalCtrl.dismiss({
			'dismissed': true,
			'notes': this.notes,
			'flavors': this.flavors
		});
	}

	toggleCollapse(index: number) {
		this.isCollapsed[index] = !this.isCollapsed[index];
	}
}
