import { Injectable } from "@angular/core";
import { UserService } from './user.service';
import { RestaurantService } from './restaurant.service';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';


@Injectable()
export class KeenTrackingService {

    public events = []
    public event_base: any = {};

    constructor(
        private apiService: ApiService,
		private restaurantService: RestaurantService,
        private userService: UserService,
        private authService: AuthenticationService
    ) {}

    async setEventBase(){
        this.event_base = {
            'user': this.userService.getData(),
            'device': this.userService.getDeviceInfo(),

			'restaurant_id': this.restaurantService.restaurant.info.id, 
			'restaurant_url': this.restaurantService.restaurant.info.restaurant_url,

            'timestamp': new Date().toISOString().slice(0, 19).replace('T', ' ')
        };

        this.event_base.user.id = 0;

        try {
            await this.authService.getUser();
            this.event_base.user.id = this.authService.user?.id;
        } catch (err) { }
    }

    event(name:string , eventData = null){
        let final_eventData: any = {
            name: name,
            timestamp: new Date().toISOString().slice(0, 19).replace('T', ' '),
            data: eventData
        }

        this.events.push(JSON.parse(JSON.stringify(final_eventData)));
    }

    commit() {
        let usage = {
            ...this.event_base, 
            events: this.events
        };

        if(!environment.production){
            console.log('keen commit', usage);
            return;
        }

        return this.post(usage);
    }

    post(usage: any) {
        return new Promise((resolve) => {
            this.apiService.post(`/keen-tracking`, usage).subscribe(response => {
                resolve(response);
            });
        });
    }
}