import Pusher from 'pusher-js';

import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class PusherService {
	pusher: any;
	channel: any;
    journeyXOrderChannel: any;
    journeyOrderChannel: any;

	constructor() {
		Pusher.logToConsole = true;
		this.pusher = new Pusher(environment.pusher.key, {
			cluster: environment.pusher.cluster,
			forceTLS: true,
		});
	}

	subscribe(orderID: string) {
		this.channel = this.pusher.subscribe(`order.${orderID}`);
        this.journeyXOrderChannel = this.pusher.subscribe(
            `journey.order.${orderID}`
        );
	}

    subscribeJourneyOrder(journeyOrderID: string) {
        this.journeyOrderChannel = this.pusher.subscribe(
            `journeyOrder.${journeyOrderID}`
        );
    }
}
