import { Component, OnInit } from '@angular/core';
import { RestaurantService } from 'src/app/core';
import { Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
	selector: 'app-restaurant-closed-dialog',
	templateUrl: './restaurant-closed-dialog.page.html',
	styleUrls: ['./restaurant-closed-dialog.page.scss'],
})
export class RestaurantClosedDialogPage implements OnInit {

	hoursToOpen: string;
	closedMessage: string;

	daysOfWeek = {
		0: 'sunday',
		1: 'monday',
		2: 'tuesday',
		3: 'wednesday',
		4: 'thursday',
		5: 'friday',
		6: 'saturday'
	}

	constructor(private restaurantService: RestaurantService,
				private modalCtrl: ModalController,
				private translatePipe: TranslatePipe) { }

	ngOnInit() {
		let date = new Date();
		let currentHours = ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2) + ':' + ("00" + date.getSeconds()).slice(-2);

		let dayPos = date.getDay();		
		let currentDay = this.daysOfWeek[dayPos];		

		let currentDayWeek = this.restaurantService.restaurant.hours.hours[currentDay];		
		currentDayWeek = currentDayWeek.filter(hour => !hour.isClosed);
		let differences = [];
		if (currentDayWeek.length > 0) {
			currentDayWeek.forEach(hour => {
				if (!hour.isClosed) {
					differences.push(this.differenceBetweenHours(hour.firstTime, currentHours));
				}
			});
			let hoursToOpen = this.getMinHour(differences);

			if (hoursToOpen) {
				this.closedMessage = this.translatePipe.transform('The establishment will open in', {value: hoursToOpen});
			} else {
				this.closedMessage = this.translatePipe.transform('The establishment is closed now');
			}
		} else {
			let nextDay = this.getNextOpenedDay(dayPos);

			if (nextDay) {
				let opensTomorrow = (nextDay[1] - dayPos) == 1;
				if (opensTomorrow)
					this.closedMessage = this.translatePipe.transform('The establishment will open tomorrow');	
				else {
					let formatedDay = this.daysOfWeek[nextDay[1]].charAt(0).toUpperCase() + this.daysOfWeek[nextDay[1]].slice(1);
					this.closedMessage = this.translatePipe.transform('The establishment will open next')+' '+this.translatePipe.transform(formatedDay);	
				}
				let firstHour: string;
				let lastHour: string;
				for (let index = 0; index < nextDay[0].length; index++) {
					const hour = nextDay[0][index];
					if(index == 0) firstHour = hour['firstTime'];
					lastHour = hour['secondTime'];
				}
				this.closedMessage += ': '+firstHour.slice(0, 5)+' - '+lastHour.slice(0, 5);
			}
		}
	}

	getNextOpenedDay(currentDayPosition) {
		let nextDayPosition = currentDayPosition + 1;

		let reachedLimit = this.restaurantService.restaurant.hours.hours.length < nextDayPosition;
		if(reachedLimit) {
			return false;
		}

		let currentDay = this.daysOfWeek[nextDayPosition];
		let currentDayWeek = this.restaurantService.restaurant.hours.hours[currentDay];
		currentDayWeek = currentDayWeek.filter(hour => !hour.isClosed);
		if (currentDayWeek.length > 0) {
			return [currentDayWeek, nextDayPosition];
		} else {
			return this.getNextOpenedDay(nextDayPosition);
		}
	}	

	differenceBetweenHours(hrA, hrB) {
		if (hrA.length != 8 || hrB.length != 8) return "00:00";

		let hour1 = hrA.substr(0, 2) * 1;
		let hour2 = hrB.substr(0, 2) * 1;
		let min1 = hrA.substr(3, 2) * 1;
		let min2 = hrB.substr(3, 2) * 1;

		if (hour1 < hour2 || (hour1 == hour2 && min1 < min2)) {
			return "00:00";
		}

		let time = 0;
		let new_h;
		let new_m;

		time = min1 - min2;
		new_h = 0;
		while (time < 0) {
			new_h++;
			time = time + 60;
		}
		new_m = time.toString().length == 2 ? time : ("0" + time);

		time = hour1 - hour2 - new_h;
		while (time < 0) {
			time = time + 24;
		}
		new_h = time.toString().length == 2 ? time : ("0" + time);

		return new_h + ':' + new_m;
	}

	getMinHour(hours) {
		let minValue = Math.min.apply(Math, hours.map(hour => {
			return parseFloat(hour.substr(0, 2) + '.' + hour.substr(3, 2));
		}));

		let splitedHours = minValue.toString().split('.');
		let hour = splitedHours[0];
		let minute = splitedHours[1];

		return (hour != "0" ? hour + 'h' : '') + (minute ? ' '+minute+'min' : '');
	}

	async dismiss() {
		await this.modalCtrl.dismiss({
			'dismissed': true
		})
	}

}
