import { Component, OnInit } from '@angular/core';
import { NavService } from 'src/app/core/services/nav.service';
import { ModalController } from '@ionic/angular';
import { RestaurantService } from 'src/app/core';
import { ModalPage } from '../../modal/modal.page';
import { LoginPage } from '../../login/login.page';
import { LocalStorageService } from "src/app/core/services/localStorage.service";

@Component({
  selector: 'app-use-loyalty-dialog',
  templateUrl: './use-loyalty-dialog.page.html',
  styleUrls: ['./use-loyalty-dialog.page.scss'],
})
export class UseLoyaltyDialogPage implements OnInit {

  private restaurantId: number;

  constructor(private navService: NavService,
              private modalCtrl: ModalController,
              private restaurantService: RestaurantService,
              private localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    this.restaurantId = this.restaurantService.restaurant.info.id;    
  }

  async useLoyalty(){
		const nav = document.querySelector('ion-nav');
		nav.push(LoginPage);
  }

  goToDelivery() {
    this.setDismissLoyaltyLocalStorage()
    this.navService.push(`/delivery`)
    this.dismiss()
  }
  
  setDismissLoyaltyLocalStorage() {
    var timeStamp = new Date().getTime() + 604800000;

    this.localStorageService.setItem(`dismissFidelity-${this.restaurantId}`, JSON.stringify(timeStamp))
  }

  async dismiss() {
    await this.modalCtrl.dismiss({
      'dismissed': true
    })
  }

}
