import { Injectable } from '@angular/core';
import { RestaurantService } from './restaurant.service';
import { ApiService } from './api.service';

@Injectable()
export class JourneyService {
    private baseURL: string = '/restaurant';
    constructor(
        private restaurantService: RestaurantService,
        private apiService: ApiService
    ) {}

    getJourneyOrderByOrderUuid(orderUUID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService
                .get(
                    `${this.baseURL}/journey-orders/get-by-order-uuid?orderUUID=${orderUUID}`
                )
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }
}
