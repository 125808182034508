import { Component, OnInit, Input } from '@angular/core';
import { PizzaDTO } from 'src/app/shared/model/dto/PizzaDTO';
import { PizzaPage } from '../pizza.page';
import { AdditionalToppingsPage } from '../additional-toppings/additional-toppings.page';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
	selector: 'pizza-footer',
	templateUrl: './footer.page.html',
	styleUrls: ['./footer.page.scss'],
})
export class FooterPage implements OnInit {
	@Input() size: PizzaDTO;
	@Input() toppings: boolean;
	@Input() showTotal: boolean;

	additionalToppings = [];
    howManyFlavorsToSelect = '';
    totalFlavorsQtty = 0;

	constructor(private pizzaPage: PizzaPage,
				private toppingsPage: AdditionalToppingsPage,
                private shoppingCartService: ShoppingCartService,
                private translate: TranslatePipe
    ) { }

	ngOnInit() {
		this.additionalToppings = this.pizzaPage.additionalToppings;

		this.size.selectedFlavors.forEach(flavor => {
			flavor.fractionText = this.flavorFractionText(flavor);
        });

        this.setFlavorsMessage();
    }

	flavorFractionText(flavor) {
		let totalFlavorsSelectedCount = this.size.qttyOfFlavorsSelected;
		let reducedFraction = this.shoppingCartService.reduceFraction([flavor.quantity, totalFlavorsSelectedCount])
		return `${reducedFraction[0]}/${reducedFraction[1]}`
    }
    
	countTotalFlavorsQuantitySelected() {
		this.totalFlavorsQtty = this.size.selectedFlavors.reduce((total, flavor) => {
			return total + flavor.quantity;
        }, 0);

        return this.totalFlavorsQtty;
    }    
    

	finishToppings(){
		this.toppingsPage.finishToppings()
	}

	nextPizzaPage(){
		this.pizzaPage.next();
		this.setFlavorsMessage();
	}

	setFlavorsMessage(){
		this.howManyFlavorsToSelect = this.translate.transform('Choose between min and max flavors', {min: this.pizzaPage.currentSize.minFlavors, max: this.pizzaPage.currentSize.maxFlavors});
        if (this.pizzaPage.currentSize.minFlavors == this.pizzaPage.currentSize.maxFlavors) {
            this.howManyFlavorsToSelect = this.translate.transform('Choose x flavors', {x: this.pizzaPage.currentSize.minFlavors});
        }
        if (this.pizzaPage.currentSize.minFlavors == 1 && this.pizzaPage.currentSize.maxFlavors > 1) this.howManyFlavorsToSelect = this.translate.transform('Choose up to x flavors', {x: this.pizzaPage.currentSize.maxFlavors});
	}

	allSet(): boolean {
		if(this.pizzaPage.currentSize && this.pizzaPage.currentSize.crust && (!this.size.crust || !this.size.crust.id)) return false;
		if(this.pizzaPage.currentSize && this.pizzaPage.currentSize.dough && (!this.size.dough || !this.size.dough.id)) return false;
        if(this.size.maxFlavors == 0) return false;
		if(this.size.maxFlavors && this.size.priceBehavior == 'incremental' &&!(this.size.qttyOfFlavorsSelected == this.size.maxFlavors))
			return false;
    
        if(this.size.priceBehavior != 'incremental' && this.size.qttyOfFlavorsSelected < this.size.minFlavors) return false;

		return true;
	}
}
