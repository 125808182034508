import { Component, EventEmitter, OnInit, Output, ChangeDetectorRef, Input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { RestaurantService } from 'src/app/core';
import { OrderService } from 'src/app/core/services/order.service';
import { HoursTemplate } from 'src/app/shared/model/HoursTemplate';
import { Restaurant } from 'src/app/shared/model/Restaurant';

@Component({
	selector: 'schedule-selector',
	templateUrl: './schedule.page.html',
	styleUrls: ['./schedule.page.scss']
})
export class SchedulePage implements OnInit {
	@Output() emitScheduleType = new EventEmitter<string>();
	@Input() deliveryType;
	scheduleType: string;
	restaurantInfo: Restaurant;
	daysOfWeek = [];
	selectedWeekday;
	selectedWeekdayIndex: number;
	showOrderNow: boolean = true;

	constructor(private restaurantService: RestaurantService,
				private orderService: OrderService,
				public translatePipe: TranslatePipe,
				private cdr: ChangeDetectorRef) { }

	ngOnInit() {
		this.restaurantInfo = this.restaurantService.restaurant.info;
		
		if(this.restaurantInfo.is_closed) this.changeScheduleType('schedule');

		this.hasShowOrderNow(this.deliveryType);
	}

	hoursTemplate: HoursTemplate;
	changeScheduleType(scheduleType) {
		this.scheduleType = scheduleType;
		this.orderService.order.details.schedule = scheduleType == 'schedule';
		this.selectedWeekday = null;

		this.emitScheduleType.emit(scheduleType);
		if(scheduleType == 'now') return;

		this.restaurantService.getScheduleHourTemplateByID(this.restaurantInfo.scheduleSettings.hoursTemplate.id).then(result => {
			this.hoursTemplate = result;
			this.parseDaysOfWeek();
			this.cdr.detectChanges();
		});
	}

	parseDaysOfWeek() {
		this.daysOfWeek = [];
		const scheduleHours = this.hoursTemplate.hours;
		const today = new Date();

		const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
		let weekdayToParse = today.getDay();
		let parsedDaysCount = 0;
		while(parsedDaysCount < this.restaurantInfo.scheduleSettings.daysForward) {
			let day = this.getNextDayByWeekday(parsedDaysCount);
			let hours = this.parseHoursInterval(scheduleHours[weekdays[weekdayToParse]], day.date);
			
			if (this.hoursTemplate.unavailableScheduleDates)
				hours = this.removeUnavailableDates(day.parsedString, hours);

			let dayOfWeek =  scheduleHours[weekdays[weekdayToParse]];
			
			this.daysOfWeek.push({
				'weekday': weekdays[weekdayToParse],
				'day': day.parsedString,
				'hours': hours,
				'disabled': hours.length == 0 || !this.checkDeliveryMethodAvailability(dayOfWeek)
			});

			if(hours.length > 0 && !this.selectedWeekday) this.changeWeekday(parsedDaysCount);

			weekdayToParse++;
			if(weekdayToParse == 7) weekdayToParse = 0;
			parsedDaysCount++;
		}

		this.changeWeekday(0);

		console.table(this.daysOfWeek);
	}

	checkDeliveryMethodAvailability(dayOfWeek){
		if (this.deliveryType === 'delivery') {
			return dayOfWeek.some(day => day.delivery);
		}
	
		if (this.deliveryType === 'balcony' || this.deliveryType === 'carTakeout') {
			return dayOfWeek.some(day => day.balcony);
		}
	
		return true;
	}

	removeUnavailableDates(day, hours) {		
		let unavailableHours =  this.hoursTemplate.unavailableScheduleDates.filter(date => date.includes(day));
		
		if (unavailableHours.length == 0) return hours;

		return hours.filter(hour => {
			return !unavailableHours.some(unavailableHour => {
				hour.firstHour = hour.firstHour.length == 3 ? '0' + hour.firstHour : hour.firstHour;
				return unavailableHour.includes(hour.firstHour)
			});
		});
	}

	getNextDayByWeekday(weekday: number){
		let date = new Date();    
		date.setDate(date.getDate() + weekday);
		let day = String(date.getDate()).padStart(2, '0');
		let mounth = String(date.getMonth() + 1).padStart(2, '0');
		let year = date.getFullYear();
		return {
			date: date,
			parsedString: year+'-'+mounth+'-'+day
		};
	}

	checkTimesIntervalIsAvailable(shiftFromTemplate){
		if(shiftFromTemplate.isClosed)
			return false;

		if(!shiftFromTemplate[this.orderService.order.details.deliveryType] && this.orderService.order.details.deliveryType != 'carTakeout')
			return false;

		if(!this.restaurantInfo.has_car_takeout && this.orderService.order.details.deliveryType == 'carTakeout')
			return false;

		return true;
	}

	parseHoursInterval(timesInterval, day) {
		let hoursInterval = [];

		timesInterval.forEach(shiftFromTemplate => {
			if(!this.checkTimesIntervalIsAvailable(shiftFromTemplate)) return;

			let startTime = this.parseDateTime(day, shiftFromTemplate.firstTime);
			let endTime = this.parseDateTime(day, shiftFromTemplate.secondTime);

			let splitedHours = this.getTimeIntervals(startTime, endTime);
			let now = new Date();
			now.setMinutes(now.getMinutes() + (this.orderService.order.details.deliveryType == 'balcony' ? this.restaurantInfo.takeout_time : this.restaurantInfo.delivery_time_min));
			splitedHours.forEach((hour, index) => {
				let inTime = hour < now;
				let isLastHour = index+1 == splitedHours.length;
				if(inTime || isLastHour) return;

				hoursInterval.push({
					firstHour: hour.getHours() + ':' + ("0"+hour.getMinutes()).substr(-2),
					secondHour: splitedHours[index+1].getHours() + ':' + ("0"+splitedHours[index+1].getMinutes()).substr(-2)
				});
			});
        });
        
		return hoursInterval;
	}

	parseDateTime(day, time){
		let date = new Date(day.getTime())
		date.setHours(time.substring(0,2));
		date.setMinutes(time.substring(3,5));
		return date;
	}

	getTimeIntervals(time1, time2) {
		let hours = [];

		if(time1 > time2){
			time2.setDate(time2.getDate() + 1);
		}

		while(time1 < time2) {
			// let dddTime = time1;
			hours.push(new Date(JSON.parse(JSON.stringify(time1))));
			// hours.push(time1.toTimeString().substring(0,5));
			time1.setMinutes(time1.getMinutes() + this.restaurantInfo.scheduleSettings.intervalBetweenTimes);
		}
		return hours;
	}

	changeWeekday(newWeekdayIndex) {
		this.selectedWeekdayIndex = newWeekdayIndex;
		this.selectedWeekday = this.daysOfWeek[newWeekdayIndex];

		this.orderService.order.details.scheduleTo = '';
		this.emitScheduleType.emit('schedule');
	}

	selectHour(interval) {
		let parsedDateTime = this.selectedWeekday.day + ' ' + interval.firstHour;

		this.orderService.order.details.scheduleTo = parsedDateTime;
		this.emitScheduleType.emit('schedule');
	}

	hasShowOrderNow(deliveryType){
		if (deliveryType === 'balcony')
			return this.showOrderNow =  this.restaurantInfo.shift_has_takeout;
		if (deliveryType === 'delivery') 
			return this.showOrderNow = this.restaurantInfo.shift_has_delivery;
		if (deliveryType === 'carTakeout') 
			return this.showOrderNow = this.restaurantInfo.has_car_takeout;

		return this.showOrderNow = true;
	}
}
