<ion-grid class="ion-no-padding ion-no-margin" >
	<ion-row *ngIf="paymenSettingstValue && paymenSettingstOperation" data-cy="operationDialogValue">
		<ion-col style="padding-bottom: 0px;">
			<span>
				{{ paymenSettingstOperation | translate }} {{ 'by payment method' | translate }}: {{ paymenSettingstValue | multiCurrency }}
			</span>
		</ion-col>
	</ion-row>

	<ion-row>
		<ion-col style="padding-bottom: 0px; padding-top: 0px;">
			<h6 style="margin-bottom: 0px; margin-top: 0px;"><b translate>Total Payable</b></h6>
		</ion-col>
	</ion-row>

	<ion-row data-cy="operationDialogGrandTotal">
		<ion-col style="padding-left: 15px;">
			<h1 class="grand-total"><span class="bold-statements">{{grandTotal | multiCurrency }}</span></h1>
		</ion-col>
	</ion-row>
</ion-grid>