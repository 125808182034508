<div *ngIf="!restaurantInfo.is_closed" class="schedule-select fade-in-from-top" data-cy="scheduleSelection" style="font-size: 20px;">
	<ion-label class="schedule-select__title" translate>Select a option</ion-label>
	<ion-row>
		<ion-col *ngIf="showOrderNow">
			<input type="radio" id="scheduleOption" name="schedule" class="d-none radio"
				[checked]="scheduleType == 'now'">
			<label class="schedule-select__option" for="scheduleOption" class="d-block">
				<button class="schedule-select__option" (click)="changeScheduleType('now')" data-cy="btnOrderNowSelection"
					[ngClass]="{'schedule-select__option--selected': scheduleType == 'now'}">
					<ion-icon class="schedule-select__option-icon" src="assets/icon/bag.svg"
						[ngClass]="{'schedule-select__option-icon--filter': scheduleType == 'now'}">
					</ion-icon>
					<p class="schedule-select__option-text" translate>Order Now</p>
				</button>
			</label>
		</ion-col>
		<ion-col>
			<input type="radio" id="scheduleOption" name="now" class="d-none radio"
				[checked]="scheduleType == 'schedule'">
			<label class="schedule-select__option" for="scheduleOption" class="d-block">
				<button class="schedule-select__option" (click)="changeScheduleType('schedule')" data-cy="btnScheduleSelection"
					[ngClass]="{'schedule-select__option--selected': scheduleType == 'schedule'}">
					<ion-icon class="schedule-select__option-icon" name="time-outline"
						[attr.color]="scheduleType != 'schedule' ? 'danger' : 'white'"></ion-icon>
					<p class="schedule-select__option-text" translate>Schedule Order</p>
				</button>
			</label>
		</ion-col>
	</ion-row>
</div>

<div *ngIf="scheduleType == 'schedule' || restaurantInfo.is_closed" class="fade-in-from-top">
	<h4 class="date-selection-title">{{ 'Select the date and hour' | translate: {deliveryMethod: deliveryType == 'delivery' ? ('entrega' | translate) : ('retirada' | translate)} }}</h4>
	<p class="only-with-online-payment-text text-center" *ngIf="restaurantInfo.scheduleSettings.onlyWithOnlinePayment" translate>Orders can only be scheduled when using online payment methods</p>

	<div class="days">
		<ion-segment class="days__segment" color="danger" mode="md" scrollable (ionChange)="changeWeekday($event.detail.value)" value="{{selectedWeekdayIndex}}">
			<ion-segment-button data-cy="btnDaysWeek" class="days__segment-button btn-{{day.weekday}}" *ngFor="let day of daysOfWeek; let i = index" value="{{i}}" [attr.disabled]="day.disabled">
				<ion-label><b translate>{{day.weekday}}</b></ion-label>
				<ion-label>{{day.day | date: translatePipe.transform('dateFormatWithoutYear') }}</ion-label>
			</ion-segment-button>
		</ion-segment>
	</div>

	<div class="hours" *ngIf="selectedWeekday && selectedWeekday.hours.length > 0 && !selectedWeekday.disabled">
		<div class="hours__option hours__option--to-right" *ngFor="let hourInterval of selectedWeekday.hours; let i = index">
			<input data-cy="btnHoursDayWeek" class="hours__option--input" type="radio" id="{{i}}" name="hour-selection" (click)="selectHour(hourInterval)">
			<label hours__option--text for="{{i}}">
				<strong translate>Schedule to</strong>: {{hourInterval.firstHour | formatTime }} - {{hourInterval.secondHour | formatTime }}
			</label>
		</div>
	</div>
</div>
