// shared.module.ts
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CartComponent } from './components/cart/cart.component';
import { CategoryNavComponent } from './components/category-nav/category-nav.component';

@NgModule({
    imports: [CommonModule, IonicModule, TranslateModule, FormsModule],
    declarations: [CartComponent, CategoryNavComponent],
    exports: [CartComponent, CategoryNavComponent],
})
export class SharedModule {}
