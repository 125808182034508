export const environment = {
  production: true,
  apiURL: 'https://api.multipedidos.com.br',
  imgURL: 'https://images.multipedidos.com.br',
  orderCacheURL: 'https://product-suggestion.lambda.multipedidos.com.br',
  onesignalAppID: "81ee0c26-fc4e-4d22-90f6-5fd176a67fda",
  pusher: {
    key: '715a9e0b34b517fdbc67',
    cluster: 'us3',
    forceTLS: true
  },
  googleMapsKey: 'AIzaSyCaspbeWCXZIbIBNfrP621V_cO6EXVqTd0',
  orderCacheURLTracking: 'https://track.lambda.multipedidos.com.br'
};
