import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CartRecoverService } from 'src/app/core';
import { NavService } from 'src/app/core/services/nav.service';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'show-recommended-products',
  templateUrl: './show-recommended-products.html',
  styleUrls: ['./show-recommended-products.page.scss'],
})
export class ShowRecommendedProductsPage {

	@Input() recommendedProducts: [];

	public imgURL = environment.imgURL;
	private item: any = {};

	constructor(
		public modalController: ModalController,
		private cartRecoverService: CartRecoverService,
		private navService: NavService,
		private cartService: ShoppingCartService
	) {}

	goToShoppingCart(item: any) {
		this.item = {};
		Object.assign(this.item, item);
		this.cartRecoverService.updateTimestamp();

		if (!this.allItemsIsAvailable())
			return this.openProductViewer();

		this.cartService.addProductToCart(this.item);
		this.navService.push('shopping-cart');
	}

	allItemsIsAvailable() {
		let AllItemsIsAvailable = true;
		
		if (this.item.extras)
			AllItemsIsAvailable = !this.item.extras.find(extra => extra.isAvailable == false);

		return AllItemsIsAvailable;
	}

	openProductViewer() {
		this.navService.push('extra', {
			'extras': this.item.extraInfo,
			'item': this.item,
		});
	}
}
