import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UseLoyaltyDialogPageRoutingModule } from './use-loyalty-dialog-routing.module';

import { UseLoyaltyDialogPage } from './use-loyalty-dialog.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    UseLoyaltyDialogPageRoutingModule
  ],
  declarations: [UseLoyaltyDialogPage]
})
export class UseLoyaltyDialogPageModule {}
