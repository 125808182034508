import { Component, OnInit, Input } from '@angular/core';
import { CartRecoverService, RestaurantService } from 'src/app/core';
import { AnimationController, ModalController } from '@ionic/angular';
import { MenuProduct } from 'src/app/shared/model/MenuProduct';
import { Extra } from 'src/app/shared/model/Extra';
import { NavService } from 'src/app/core/services/nav.service';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { environment } from 'src/environments/environment';
import { ViewerModalComponent } from 'ngx-ionic-image-viewer';
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';
import { ExtraPage } from '../extra/extra.page';
import { MenuSelector } from 'src/app/core/services/menuSelector.service';
import { ShoppingCartPage } from 'src/app/pages/shopping-cart/shopping-cart.page';
import { PizzaPage } from '../pizza/pizza.page';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
	selector: 'card',
	templateUrl: './card.page.html',
	styleUrls: ['./card.page.scss'],
})
export class CardPage implements OnInit {
	@Input() item: MenuProduct;
	@Input() extrasList: Extra[];
	fromPrice: any = false;

	imgURL = environment.imgURL;

	public isTabletMenu = false;

	constructor(
		private rService: RestaurantService,
		private navService: NavService,
		public modalController: ModalController,
		private cartService: ShoppingCartService,
		private keen: KeenTrackingService,
		private menuSelector: MenuSelector,
		private cartRecoverService: CartRecoverService,
		private animationCtrl: AnimationController,
        private sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.isTabletMenu = this.menuSelector.isTabletLink();
	}

	htmlToTextWithSpacing(htmlString) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');
	
		// Insert a space before and after each block-level element's content
		doc.querySelectorAll('p, h1, h2, h3, h4, h5, h6, li').forEach(element => {
			const spaceBefore = document.createTextNode(' ');
			const spaceAfter = document.createTextNode(' ');
			element.parentNode.insertBefore(spaceBefore, element);
			element.parentNode.insertBefore(spaceAfter, element.nextSibling);
		});
	
		// Handle lists specifically
		doc.querySelectorAll('ul, ol').forEach(list => {
			const items = Array.from(list.querySelectorAll('li')).map(li => li.textContent.trim());
			const listText = items.join(', '); // Join list items with a comma and a space
			list.replaceWith(document.createTextNode(listText));
		});
	
		return doc.body.textContent.replace(/"/g, '') || "";
	}

	async openViewer(item) {

		const modal = await this.modalController.create({
			component: ViewerModalComponent,
			componentProps: {
				src: this.imgURL+'/products/'+item.image+'/lg_'+item.image+'.jpg',
				scheme: 'dark',
				swipeToClose: true,
				title: item.name,
				text: item.description ? this.htmlToTextWithSpacing(item.description) : ''
			},
			cssClass: 'ion-img-viewer',
			keyboardClose: true,
			showBackdrop: true
		});
	
		return await modal.present();
	}

    convertToSafeHtml(rawHtml: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(rawHtml);
    }

    getSafeHtml(value: any) {
        try {
            return this.convertToSafeHtml(JSON.parse(value));
        } catch (error) {
            return value;
        }
    }

	goToItemPage(item: MenuProduct) {
		this.keen.event('goToItemPage', item);
		this.cartRecoverService.updateTimestamp();

		if(item.type === 'combo') {
			this.goToPizzaPage(item, true);
		}else if(item.type === 'pizza') {
			this.goToPizzaPage(item);
		}else if (item.extras) {
			this.goToExtrasPage(item.extras);
		}
	}

	async goToPizzaPage(item, isCombo=false) {
		let pizzaParams = {
			'product': item,
			'isCombo': isCombo,
			'menu': this.rService.restaurant.menu
		};

		if (this.isTabletMenu) {
			const modal = await this.modalController.create({
				component: PizzaPage,
				componentProps: pizzaParams,
			});
			return modal.present();
		}

		this.navService.push(`pizza`, pizzaParams);
	}

	async goToExtrasPage(itemExtras: number[]) {
        let list = itemExtras.map(extraID => {
            return this.rService.restaurant.menu.extras.filter((menuExtra) => menuExtra.id == extraID)[0] ?? null; 
        }).filter(extra => extra?.options.length > 0);

		let selectedItem = { ...this.item};

		if (selectedItem.type == 'general' && list.length == 0) {
			selectedItem.extras = [];
			selectedItem.qty = 1;
			this.cartService.addProductToCart(selectedItem);
			this.goToShoppingCart();
		} else {
			let extraParams = {
				'extras': JSON.parse(JSON.stringify(list)),
				'item': selectedItem,
			};
			if (this.isTabletMenu) {
				const modal = await this.modalController.create({
					component: ExtraPage,
					componentProps: extraParams,
				});

				modal.onDidDismiss().then((res) => {
					if(!res.role)
						this.goToShoppingCart();
				})

				return modal.present();
			}

			this.navService.push('extra', extraParams)
		}
	}

	async goToShoppingCart() {
		if (this.menuSelector.isTabletLink()) {
			const modal = await this.modalController.create({
				component: ShoppingCartPage,
				cssClass: 'tablet-modal',
				leaveAnimation: (baseEl) => {
					const backdropAnimation = this.animationCtrl.create()
					  .addElement(baseEl.querySelector('ion-backdrop'))
					  .fromTo('opacity', '0.4', '0.01');

					const wrapperAnimation = this.animationCtrl.create()
					  .addElement(baseEl.querySelector('.modal-wrapper'))
					  .fromTo('transform', 'translateX(0)', 'translateX(100%)');

					return this.animationCtrl.create().addElement(baseEl)
					  .easing('ease-in-out')
					  .duration(300)
					  .addAnimation([backdropAnimation, wrapperAnimation]);
				},
			});
			return modal.present();
		}

		this.navService.push('shopping-cart');
	}

	showStockPausedItems() {
		return this.rService.restaurant.info.stock_configuration.display_paused_items_as_sold_out;
	}
}
