import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HeaderPageRoutingModule } from './header-routing.module';

import { HeaderPage } from './header.page';
import { MenuPage } from '../menu/menu.page';
import { TranslateModule } from '@ngx-translate/core';
import { LoginPageModule } from '../login/login.module';
import { ModalPageModule } from '../modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HeaderPageRoutingModule,
    TranslateModule,
    ModalPageModule,
    LoginPageModule,
  ],
  declarations: [HeaderPage],
  exports: [HeaderPage],
  providers: [MenuPage]
})
export class HeaderPageModule {}
