import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-card-dialog',
	templateUrl: './card-dialog.page.html',
	styleUrls: ['./card-dialog.page.scss'],
})
export class CardDialogPage implements OnInit {

	grandTotal: number;
	orderTotal: number;
	restaurantPaymentSettings;
	cardTypes;
	card = null;
	methods = [];
	appliedPaymentSetting:any = false;

	constructor(
		private modalCtrl: ModalController
	) { }

	ngOnInit() {
	}

	chooseCard(currentCard) {
		this.card = currentCard;

		this.methods = [];
		let methods = [];
		if (currentCard.isDebit == 1) methods.push("debit");
		if (currentCard.isCredit == 1) methods.push("credit");
		if (currentCard.isVoucher == 1) methods.push("voucher");

		if (methods.length < 2) {
			this.card.type = methods.pop() ?? currentCard.name;
			return this.getCardMethodOperation();
		}

		this.methods = methods;
	}

	chooseMethod(chosenMethod) {
		this.card.type = chosenMethod;
		this.getCardMethodOperation();
	}

	getCardMethodOperation() {
		this.appliedPaymentSetting = this.restaurantPaymentSettings.filter(setting => setting.paymentType == this.card.type && setting.isActive && setting.value > 0)[0];

		if (!this.appliedPaymentSetting) return this.dismiss();

		let value = this.appliedPaymentSetting.value;
		if (this.appliedPaymentSetting.valueType == 'percent') value = (this.orderTotal * this.appliedPaymentSetting.value) / 100;

		this.appliedPaymentSetting.calculatedValue = value;

		this.appliedPaymentSetting.operationName = 'Addition'
		if (this.appliedPaymentSetting.operation == 'discount') {
			value = -value;
			this.appliedPaymentSetting.operationName = 'Discount';
		}

		this.grandTotal = this.grandTotal + value;
	}

	async dismiss() {
		await this.modalCtrl.dismiss({
			'dismissed': true,
			'choosedCard': this.card,
			'appliedPaymentSetting': this.appliedPaymentSetting,
		});
	}
}
