import { Component, OnInit } from '@angular/core';
import { LoadingController, AlertController, ModalController } from '@ionic/angular'
import { AuthenticationService } from 'src/app/core/services/authentication.service'
import { TranslatePipe } from '@ngx-translate/core';
import { NavService } from 'src/app/core/services/nav.service';
import { RegisterPage } from '../register/register.page';
import { KeenTrackingService } from 'src/app/core/services/keenTracking.service';
import { SocialAuth } from '../../core/services/SocialAuth.service'

@Component({
	selector: 'app-login',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

	email: string = ''
	password: string = ''
	captchaToken: string = ''
	restaurantUrl: string
	next: string = ''

	constructor(
		private authService: AuthenticationService,
		private socialAuthService: SocialAuth,
		private loadingController: LoadingController,
		private alertController: AlertController,
		private translatePipe: TranslatePipe,
		private navService: NavService,
		private modalController: ModalController,
		private keen: KeenTrackingService,
	) { }

	async ngOnInit() {
	}

	async goToRegister() {
		const nav = document.querySelector('ion-nav');
		nav.push(RegisterPage);
		// this.navService.push('register');
	}

	goToForgotPassword() {
		this.navService.push('forgot-password');
		this.modalController.dismiss();
	}

	loginByEmail() {
		if (this.email == '') {
			this.pageLoginAlert(this.translatePipe.transform("Insert an email!"))
			return;
		}

		if (this.password == '') {
			this.pageLoginAlert(this.translatePipe.transform("Insert a password!"))
			return;
		}

		if (this.captchaToken == '') {
			this.pageLoginAlert(this.translatePipe.transform("Please complete the reCAPTCHA."))
			return;
		}

		this.loadingLogin('email')
	}

	resolvedRecaptcha(captchaResponse: string) {
		this.captchaToken = captchaResponse
	}

	loginWithGoogle() {
		this.socialAuthService.login().then(() => {
			this.modalController.dismiss({ loginSuccess: true });
		});
	}

	async loadingLogin(mode) {
		const loading = await this.loadingController.create({
			message: `${this.translatePipe.transform('Wait')}...`
		})
		await loading.present()

		if (mode == 'email') {
			this.authService.login({ email: this.email, password: this.password, captchaToken: this.captchaToken, loginMode: 'email' }, 'email').then(() => {
				loading.dismiss()
				this.email = '';
				this.password = '';
				this.modalController.dismiss({ loginSuccess: true })

				this.keen.setEventBase().then(() => {
					this.keen.event('login_email');
				})
			}, error => {
				if (error.error == 'Email does not exist.') {
					loading.dismiss()
					this.pageLoginAlert(this.translatePipe.transform("Email does not exist!"))
				}
				if (error.error == 'Email or password is wrong.') {
					loading.dismiss()
					this.pageLoginAlert(this.translatePipe.transform("Email or password is wrong!"))
				}
			})
		}
	}

	async pageLoginAlert(message) {
		const alert = await this.alertController.create({
			cssClass: 'my-custom-class',
			header: message,
			buttons: ['OK']
		});

		await alert.present();
	}

	goBack() {
		this.modalController.dismiss();
	}
}
