import { Injectable } from '@angular/core';
import { reject, resolve } from 'cypress/types/bluebird';
import { CreditCardDTO } from 'src/app/shared/model/dto/CreditCardDTO';

import { ApiService } from './api.service';
import { FingerprintService } from './fingerprint.service';
import { RestaurantService } from './restaurant.service';
import { UtilsService } from './utils.service';

@Injectable()
export class ConvenixService {

    public baseUrl: string = `/restaurant/${this.restaurantService.restaurant.info.id}/convenix/sale`;

    constructor(
        private apiService: ApiService,
        private restaurantService: RestaurantService,
    ) { 

    }

    getConfirmationCode(clientCPF, sale): Promise<object> {
        return new Promise((resolve, reject) => {
            return this.apiService.post(this.baseUrl, {
                clientCPF: clientCPF,
                sale: {
                    value: sale.value,
                    discount: sale.discount ?? 0,
                }
            }).subscribe(result => {
                resolve(result);
            }, error => {
                reject(error);
            });
        });
    }

    approveConfirmationCode(saleID, saleConfirmationCode): Promise<object> {
        return new Promise((resolve, reject) => {
            return this.apiService.post(this.baseUrl + '/approve', {
                saleID: saleID,
                saleConfirmationCode: saleConfirmationCode
            }).subscribe(result => {
                resolve(result);
            }, error => {
                reject(error);
            });
        });
    }
}