import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PasswordStrengthMeter } from './password-strength-meter.page';

const routes: Routes = [
  {
    path: '',
    component: PasswordStrengthMeter
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PasswordStrengthMeterRoutingModule {}
