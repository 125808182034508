import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({ name: 'manualTranslate' })

export class manualTranslate implements PipeTransform {

    constructor(private translatePipe: TranslatePipe) { }

    transform(value: string) {
        return this.translatePipe.transform(value);
    }
}