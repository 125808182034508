import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'category-nav',
	templateUrl: './category-nav.component.html',
	styleUrls: ['./category-nav.component.scss'],
})
export class CategoryNavComponent implements OnInit {
	@Input() itemsToHide;
	@Input() categories;
	@Input() comboCategoryName = 'Combos';
	@Input() pizzaCategoryName = 'Pizzas';

	categoryToScroll: String;
	categoryToCentralizeInNav: String;
	selectedCategory: any;

	@Output() scrollToCategory = new EventEmitter();

	constructor() { }

	ngOnInit() {
		if(!this.itemsToHide.featuredItems) return this.categoryToScroll = 'featuredItems';
		if(!this.itemsToHide.combos) return this.categoryToScroll = 'combos';
		if(!this.itemsToHide.pizzas) return this.categoryToScroll = 'pizzas';
		if(!this.categories) return this.categoryToScroll = this.categories[0].name+'-'+this.categories[0].id;
	}

	changeCategory(ev: any) {
		this.scrollToCategory.emit(ev.detail.value);
	}

	showCategory(items) {
		if(!items) return false;

		return items.some(item => !item.unavailable && !item.unavailableByTemplate);
	}

}
