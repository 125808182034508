import { Component, Input } from '@angular/core';
import{ GlobalConstants } from 'src/app/global-constants';
@Component({
	selector: 'mp-img',
	template: `
		<ion-img *ngIf="!isIFrameAndIOS" src="{{imgURL}}"></ion-img>
		<img *ngIf="isIFrameAndIOS" src="{{imgURL}}"/>
	`,
	styleUrls: ['./image-tag.page.scss'],
})
export class MpImgPage {
	@Input() imgURL: "";
	isIFrameAndIOS = GlobalConstants.isIFrameAndIOS;
}
