<div class="modal-content" >
	<ion-card style="box-shadow: none !important; margin-top: 0px;">
		<ion-grid style="padding-left: 10px;">
			<div >
				<app-message-method-operation-dialog [grandTotal]="grandTotal" [paymenSettingstValue]="paymenSettingstValue" [paymenSettingstOperation]="paymenSettingstOperation"></app-message-method-operation-dialog>
			</div>

			<ion-row>
				<ion-col>
					<h5 [hidden]="needChange" style="margin-top: 0px;"><b class="bold-statements" translate>Do you need change?</b></h5>
					<h5 [hidden]="!needChange" style="margin-top: 0px;"><b class="bold-statements" translate>Change for how much?</b></h5>
				</ion-col>
			</ion-row>

			<ion-row *ngFor="let boolean of [true, false]" [hidden]="needChange">
				<ion-col>
					<input type="radio" [id]="'change-' + boolean" name="change" class="d-none radio">
					<label [for]="'change-' + boolean" class="d-block">
						<button class="button-text payment-choice-button payment-row" (click)="setNeedChange(boolean)" translate data-cy="btnNeedChange">
							<span class="bold-statements" translate>
								{{boolean ? 'Yes': 'No'}}
							</span>
						</button>
					</label>
				</ion-col>
			</ion-row>

			<ion-grid class="ion-no-padding ion-no-margin" [hidden]="!needChange">
				<ion-row>
					<ion-col style="margin-right: 3px; margin-left: 3px;">
						<input 
							style="text-align: center; text-transform: uppercase; margin-bottom: 10px!important;" 
							class="inputChange"
							id="changeValue"
							enterkeyhint="done"
							inputmode="numeric"
							type="text"
							autocomplete="off"
							currencyMask
							placeholder="{{'Enter the Value' | translate}}" 
							[(ngModel)]="changeValue" 
							[options]="setOptionsCurrencyMask()"
							autofocus
						>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col>
						<button class="button-text payment-choice-button__main-button payment-row" (click)="dismiss()" [disabled]="!changeValue || changeValue <= grandTotal " translate>
							CONFIRM
						</button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-grid>
	</ion-card>
</div>