import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { RestaurantService } from './restaurant.service';

@Injectable()
export class NavService {
    data: any = [];

    constructor(public navCtrl: NavController,
                private restaurantService: RestaurantService,
                private location: Location,
    ) {}

    setTopOutlet(outlet: IonRouterOutlet) {
        this.navCtrl.setTopOutlet(outlet);
    }

    async push(url: string, data: any = '') {        
        this.data = data;
        return await this.navCtrl.navigateForward(this.parseURL(url));
    }

    pop() {
        this.navCtrl.pop();
        // const previousState = this.location.getState()
        // this.navCtrl.pop().then(() => {
        //     if (this.location.getState() != previousState) return
        //     this.navigateBackward('');
        // });
    }

    navigateBackward(url: string = '') {
        this.navCtrl.navigateBack(this.parseURL(url));
    }

    async setRoot(url: string, data: any = '') {
        this.data = data;
        return await this.navCtrl.navigateRoot(this.parseURL(url));
    }

    get(key: string) {
        return this.data.hasOwnProperty(key) ? this.data[key] : false;
    }

    parseURL(url='') {
        if(url.charAt(0) === '/') url = url.substring(1);
        let parsedURL = this.restaurantService.restaurant.info.restaurant_url + (url && url!='/' ? `/${url}` : '');
        return parsedURL;
    }
}