import { Component, OnInit, Input } from '@angular/core';
import { featureGroup, latLng, Map, marker, tileLayer } from 'leaflet';
import { RestaurantService } from 'src/app/core';
import { AddressDTO } from 'src/app/shared/model/dto/AddressDTO';
import { ModalController } from '@ionic/angular';
import { RestaurantLocalStorageService } from 'src/app/core/services/restaurantLocalStorage.service';
import { CloudwatchMetricsService } from 'src/app/core/services/cloudwatchMetrics.service';
import { PhotonService } from 'src/app/core/services/photon.service';
import { OrderService } from 'src/app/core/services/order.service';

declare let google: any;

@Component({
    selector: 'app-confirm-address-lat-lng',
    templateUrl: './confirm-address-lat-lng.page.html',
    styleUrls: ['./confirm-address-lat-lng.page.scss'],
})
export class ConfirmAddressLatLngPage implements OnInit {
    @Input() storageAddress: any;

    address: AddressDTO = {
        street: '',
        number: '',
        zone: '',
        complemento: '',
        address_ref: '',
        city: '',
        region: '',
        deliveryFeeID: null,
        coordinates: {
            latitude: null,
            longitude: null,
        },
    };

    map: Map | any;
	mapState = 'hidden';
    isMapInitializing = true;
    isGoogleMapsProvider = false;

    constructor(
        private restaurantService: RestaurantService,
        private modalCtrl: ModalController,
        private restaurantLocalStorageService: RestaurantLocalStorageService,
        private cloudwatchMetrics: CloudwatchMetricsService,
        private photonService: PhotonService,
        private orderService: OrderService,
    ) {}

    ngOnInit() {
        this.isGoogleMapsProvider =
            this.restaurantService.restaurant.info.maps_provider ===
            'google_maps';

        if (this.storageAddress) {
            this.address = this.storageAddress;
        }

        this.initMap();
    }

    initMap() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.cloudwatchMetrics.confirm_coordinates_locations_accepted();
                const { latitude, longitude } = position.coords;

                this.save_coordinates(latitude, longitude);

                setTimeout(() => {                    
                    this.initializeMap(latitude, longitude);
                }, 500);
            },
            async (err) => {
                this.cloudwatchMetrics.confirm_coordinates_locations_denied();

                let coordinates = await this.hereMapsGeocode(`${this.address.street} ${this.address.number}, ${this.address.zone}, ${this.address.city}, ${this.restaurantService.restaurant.info.uf}`);

                this.save_coordinates(coordinates.latitude, coordinates.longitude);
    
                setTimeout(() => {                    
                    this.initializeMap(coordinates.latitude, coordinates.longitude);
                }, 500);
            }
        );            
    }

    save_coordinates(lat, lng) {
        this.orderService.order.details.coordinates = {
            latitude:  String(lat),
            longitude: String(lng),
        };

        this.orderService.order.user.coordinates = {
            latitude:  String(lat),
            longitude: String(lng),
        };

        this.address.coordinates.latitude  = String(lat);
        this.address.coordinates.longitude = String(lng);        

        this.saveDataInLocalStorage();
    }

    async hereMapsGeocode(query: string) {
        const coordinates = await this.photonService.hereMapsGeocode(this.restaurantService.restaurant.info.id, query);

        return {
            latitude: coordinates.lat,
            longitude: coordinates.lng,
        }
    }

    resizeMap() {
        this.mapState = 'decreased';
        
        // Resize Google Map
        if (this.isGoogleMapsProvider) {
            this.map.zoomControl = false;
            return;
        }

        // Resize Leaflet
        (this.map as Map).zoomControl.remove();
        (this.map as Map).dragging.disable();
        setTimeout(() => {
            this.map.invalidateSize();
        }, 500);
    }

    saveDataInLocalStorage() {
        if(this.address.coordinates.latitude) this.restaurantLocalStorageService.setItem(`latitude`, this.address.coordinates.latitude);
        else this.restaurantLocalStorageService.setItem(`latitude`, '');

        if(this.address.coordinates.longitude) this.restaurantLocalStorageService.setItem(`longitude`, this.address.coordinates.longitude);
        else this.restaurantLocalStorageService.setItem(`longitude`, '');
    }

    dismissModal() {
        this.saveDataInLocalStorage();

        this.modalCtrl.dismiss({
            dismissed: true,
            address: this.address,
        });
    }
    
    initializeMap(lat: number, lng: number) {
        this.mapState = 'show';

        this.isGoogleMapsProvider
            ? this.initializeGoogleMap(lat, lng)
            : this.initializeLeafletMap(lat, lng);
        
        window.dispatchEvent(new Event('resize'));
    }

    initializeLeafletMap(lat: number, lng: number) {
        this.address.coordinates.latitude = String(lat);
        this.address.coordinates.longitude = String(lng);

        this.map = new Map('map', {
            center: { lat, lng },
            zoom: 18,
            minZoom: 11
        });

        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution:
                'Map data © <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY- SA</a>',
        }).addTo(this.map);

        
        const fg = featureGroup();
        fg.addLayer(marker(latLng(lat, lng)));

        this.map.fitBounds(fg.getBounds());

        this.map.on('moveend', () => {
            this.address.coordinates.latitude = String(
                this.map.getCenter().lat
            );
            this.address.coordinates.longitude = String(
                this.map.getCenter().lng
            );
        });

        this.isMapInitializing = false;
    }

    initializeGoogleMap(lat: number, lng: number) {
        let mapOptions = {
            zoom: 18,
            center: new google.maps.LatLng(lat, lng),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
            gestureHandling: 'cooperative',
        };

        this.map = new google.maps.Map(
            document.getElementById('map'),
            mapOptions
        );

        this.address.coordinates.latitude = this.map.getCenter().lat();
        this.address.coordinates.longitude = this.map.getCenter().lng();

        google.maps.event.addListener(this.map, 'dragend', () => {
            this.address.coordinates.latitude = this.map.getCenter().lat();
            this.address.coordinates.longitude = this.map.getCenter().lng();
        });

        this.isMapInitializing = false;
    }
}
