<ion-header>
    <ion-toolbar color="danger" style="text-align: center;">
        <ion-buttons slot="start">
            <ion-button (click)="dismissModal()">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-label class="ion-text-wrap" style="font-weight: 800; font-size: 16px;" translate text-wrap>
            {{'Delivery Address' | translate}}
        </ion-label>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <div id="map" [class]="mapState"></div>

    <ng-container *ngIf="isSelectingAddressSearch">
        <ion-row style="margin-top: 50px;">
            <ion-col size="12">
                <ion-button 
                    expand="block" 
                    size="large" 
                    color="danger" 
                    mode="ios" 
                    fill="outline" 
                    class="mt-3" 
                    (click)="useGeolocation()"> 
                        <ion-icon name="location-outline"></ion-icon> 
                        {{'Use my location' | translate}}
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <ion-text 
                    class="refusal-text" 
                    style="text-align: center; display: block; text-decoration: underline;" 
                    color="danger" 
                    mode="ios" 
                    data-cy="search-address" 
                    class="mt-4" 
                    (click)="searchAddress()">
                        <ion-icon name="search-outline" style="margin-left: 4px;"></ion-icon> 
                        {{'Enter your address' | translate}}
                </ion-text>
            </ion-col>
        </ion-row>
    </ng-container>

    <ng-container *ngIf="isSearchingAddress">
        <ion-searchbar placeholder="Digite o seu endereço" show-clear-button="focus" [debounce]="400"
            (ionChange)="handleChangeSearchbar($event)" data-cy="search-address">
        </ion-searchbar>

        <div *ngIf="addressNotFound" class="text-center mt-4">
            <h4>
                {{ 'Address not found' | translate }}. <br>
                :(
            </h4>
            <div class="mt-4">
                <ion-button color="dark" (click)="searchInMapAddressNotFound()">
                    {{ 'Search on the map' | translate }}
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                </ion-button>
            </div>
        </div>

        <ion-list *ngIf="suggestedLocations.length > 0 && !isFetchingSuggestedLocations">
            <p class="mt-3 text-center">
                <a class="btn-link-search-map" (click)="searchInMapThisIsNotMyAddress()"> 
                    {{ 'This is not my address' | translate }} 
                </a>
            </p>
            <ion-item
                data-cy="search-address-suggestion-item"
                *ngFor="let suggestedLocation of suggestedLocations"
                (click)="handleClickManualAddress(suggestedLocation)"
                class="item-has-start-slot item md item-lines-default item-fill-none in-list hydrated item-label"
                role="listitem"
            >
                <ion-icon name="location-outline" size="large" slot="start" class="md hydrated"></ion-icon>
                <ion-label class="sc-ion-label-md-h sc-ion-label-md-s md hydrated">
                    <ion-label color="dark">
                        <span>{{suggestedLocation.street}}</span>
                    </ion-label>
                    <ion-label color="medium">
                        <h5>{{suggestedLocation.addr}}</h5>
                    </ion-label>
                </ion-label>
            </ion-item>            
        </ion-list>

        <ion-list *ngIf="isFetchingSuggestedLocations">
            <ion-item
                class="item-has-start-slot item md item-lines-default item-fill-none in-list hydrated item-label"
            >
                <ion-icon name="location-outline" size="large" slot="start" class="md hydrated"></ion-icon>
                <ion-label>
                    <p class="skeleton" style="width:50%;"></p>
                    <p class="skeleton" style="width:70%;"></p>
                </ion-label>
            </ion-item>
            <ion-item
                class="item-has-start-slot item md item-lines-default item-fill-none in-list hydrated item-label"
            >
                <ion-icon name="location-outline" size="large" slot="start" class="md hydrated"></ion-icon>
                <ion-label>
                    <p class="skeleton" style="width:70%;"></p>
                    <p class="skeleton" style="width:60%;"></p>
                </ion-label>
            </ion-item>
            <ion-item
                class="item-has-start-slot item md item-lines-default item-fill-none in-list hydrated item-label"
            >
                <ion-icon name="location-outline" size="large" slot="start" class="md hydrated"></ion-icon> <ion-label>
                    <p class="skeleton" style="width:90%;"></p>
                    <p class="skeleton" style="width:100%;"></p>
                </ion-label>
            </ion-item>
        </ion-list>
    </ng-container>

    <form [formGroup]="addressForm" class="mt-8" *ngIf="showFullAddressForm">
        <ion-grid>
            <ion-row>
                <ion-col size="12">
                    <ion-row *ngIf="needCity">
                        <ion-col size="12">
                            <div class="manual-neighborhood-select">
                                <ion-label translate>Select Your City</ion-label>
                                <ionic-selectable
                                    [(ngModel)]="selectedCity"
                                    [items]="cities"
                                    itemValueField="id"
                                    itemTextField="name"
                                    [canSearch]="true"
                                    (onChange)="cityChange($event)"
                                    closeButtonText="{{'CANCEL' | translate}}"
                                    [searchFailText]="searchFailText()"
                                    searchPlaceholder="{{'Search' | translate}}"
                                    required
                                    formControlName="city"
                                >
                                </ionic-selectable>
                                <div *ngIf="addressForm.get('city').invalid">
                                    <ion-text class="invalid-input-text">{{'City is Required' | translate}}</ion-text>
                                </div>	                        
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="!manualZoneSelection">
                        <ion-col size="12">
                            <ion-label>{{'Neighborhood' | translate}}</ion-label>
                            <ion-input normalizeInput type="text" formControlName="zoneInput" required></ion-input>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="manualZoneSelection">
                        <ion-col size="12">
                            <div class="manual-neighborhood-select">
                                <ion-label>{{'Select Your Neighborhood' | translate}}</ion-label>
                                <ionic-selectable
                                    formControlName="selectedZone"
                                    [items]="zones"
                                    itemTextField="zone"
                                    [canSearch]="true"
                                    closeButtonText="{{'CANCEL' | translate}}"
                                    [searchFailText]="searchFailText()"
                                    searchPlaceholder="{{'Search' | translate}}"
                                    required>
                                </ionic-selectable>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12">
                            <ion-label position="stacked">{{'Address' | translate}}</ion-label>
                            <ion-input normalizeInput data-cy="street" type="text" formControlName="street" [disabled]="!isNotMyAddress"></ion-input>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col size="4">
                    <ion-label position="stacked">{{'Number' | translate}}</ion-label>
                    <ion-input normalizeInput data-cy="number" type="text" formControlName="addressNumber" required></ion-input>
                    <ion-text color="danger"
                        *ngIf="addressForm.get('addressNumber').invalid && addressForm.get('addressNumber').touched">
                        <sub>{{'Number is required.' | translate}}</sub>
                    </ion-text>
                </ion-col>
                <ion-col size="8">
                    <ion-label position="stacked">{{'Complement' | translate}}</ion-label>
                    <ion-input normalizeInput  data-cy="complement" type="text" formControlName="complement"></ion-input>
                </ion-col>
                <ion-col size="12">
                    <ion-label position="stacked">
                        {{'Reference' | translate}}
                        <span *ngIf="referenceIsRequired">
                            ({{ 'required' | translate }})
                        </span>
                    </ion-label>
                    <ion-input normalizeInput type="text" formControlName="reference"></ion-input>
                    <ion-text color="danger"
                        *ngIf="addressForm.get('reference').hasError('required') && addressForm.get('reference').invalid && addressForm.get('reference').touched">
                        <sub>{{'Reference is required.' | translate}}</sub>
                    </ion-text>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>

    <ion-spinner name="dots" color="danger" duration="800" class="custom-spinner"
        *ngIf="isCalculatingDeliveryFee || isMapInitializing"></ion-spinner>

    <ion-button class="mt-8" *ngIf="mapState == 'show'" mode="ios" color="danger" expand="block"
        (click)="confirmMapLocation()" data-cy="confirm-customer-coordinates" translate>
        {{'Confirm Location' | translate}}
    </ion-button>
</ion-content>

<ion-footer class="footer" *ngIf="showFullAddressForm">
    <button data-cy="btnConfirm"
        class="confirm-button"
        [class]="!addressForm.invalid ? '' : 'disabled-button'"
        [ladda]="isCalculatingDeliveryFee"
        [disabled]="addressForm.invalid"
        (click)="calculateDeliveryFee()"
    >
        <ion-icon name="checkmark-outline" class="mr-4"></ion-icon>
        {{'CONFIRM' | translate}}
    </button>
</ion-footer>