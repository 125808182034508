import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { ThemeService } from "./theme.service";
import { MenuDTO } from 'src/app/shared/model/dto/MenuDTO';
import { DebugModeService } from './debugMode.service';
import { LoadingSpinnerService } from 'src/app/core/services/loadingSpinner.service';
import { HoursTemplate } from 'src/app/shared/model/HoursTemplate';
import { TranslateService } from '@ngx-translate/core';
import { CoupomStorageService } from './coupomLocalStorage.service';

import Cardapnator from 'cardapnator';
import { CartRecoverService } from './cartRecover.service';
import RestaurantOperations from  'restaurantoperations';
import CardapioCache from  'cardapiocache';
import { TableService } from './table.service';
import { MenuSelector } from './menuSelector.service';

export const DEFAULT_LANGUAGE = 'pt-br';

@Injectable()
export class RestaurantService {
    public restaurant: any;
    loaded: boolean = false;
    public menuType: 'table' | 'delivery' = 'delivery';

    public externalSourceDetails: {
        src: string,
        campaign: string,
        uuid: string
    }

    paymentMethodOperationInfo: any = {
        operatedValue: '',
        paymentMethodOperation: '',
        paymentMethodValueType: '',
        paymentMethodValue: '', 
    }

    private extraCategoriesMinPrice = [];

    constructor(
        private apiService: ApiService,
        private themeService: ThemeService,
        private debugModeService: DebugModeService,
        private LoadingSpinnerService: LoadingSpinnerService,
        private translateService: TranslateService,
        private cartRecoverService: CartRecoverService,
        private tableService: TableService,
        private menuSelector: MenuSelector,
    ) {}

    loadByURL(url): Promise<MenuDTO> {
        console.log('subiu');

        return new Promise(async (resolve, reject) => {
            const params = new URLSearchParams(window.location.search);
            this.externalSourceDetails = {
                src: params.get('src'),
                campaign: params.get('campaign'),
                uuid: params.get('u')
            }

            await this.cartRecoverService.checkUuid(this.externalSourceDetails.uuid);

            (new CardapioCache(url)).get().then((data) => {
                if(!data){
                    return this.apiService.get(`/restaurant/data/v2/${url}`).toPromise();
                }

                return data;
            }).catch((e) => {
                console.error(e);
                return this.apiService.get(`/restaurant/data/v2/${url}`).toPromise();
            }).then(async (data: any) => {
                if(!data){
                    this.loaded = true;
                    this.LoadingSpinnerService.dismiss();
                    resolve(null);
                    return;
                }   

                data = (new RestaurantOperations(data)).build();

				this.menuSelector.boot();
                const isTableLink = await this.tableService.setRestaurantInfo(data.info).validateTableLink();
                const cardapnator = new Cardapnator(data);

                if (isTableLink) {
                    this.menuType = 'table';
                    cardapnator.onlyTableProducts();
                }

                if (this.menuType === 'delivery')
                    cardapnator.onlyDeliveryProducts();

                data = (new RestaurantOperations(data)).build();

                this.restaurant = cardapnator.setMenuType(this.menuType).build();

                if(this.restaurant.info.google_tag_manager_id) this.initializeGoogleTagManager(this.restaurant.info.google_tag_manager_id)
                if(this.restaurant.info.google_analytics_id) this.initializeGoogleAnalytics(this.restaurant.info.google_analytics_id)
                if (this.restaurant.menu.combos.length > 0) this.checkCombosAvailability(this.restaurant.menu.combos);
                if (this.restaurant.menu.pizzas.sizes.length > 0) this.checkPizzaAvailability(this.restaurant.menu.pizzas.sizes);
                this.parseProductsShowFrom(this.restaurant);
                this.getFeaturedItems(this.restaurant.menu);
                if(!this.debugModeService.getMode()) this.setRestaurantTheme();

                this.loaded = true;
                resolve(data);
            }).catch((error: any) => {
                if(error.error == 'Restaurant not found'){
                    this.loaded = true;
                    this.LoadingSpinnerService.dismiss();
                    resolve(null);
                    return;
                }

                reject(error);
            }).finally(() => {
                this.setTranslations(this.restaurant);
            })
        });
    }

    setTranslations(restaurantData) {
        let restaurant_info_language = restaurantData?.info.language == 'ptBR' ? 'pt-br' : restaurantData?.info.language; 
        const languages = [navigator.language, restaurant_info_language, DEFAULT_LANGUAGE].filter(Boolean);

        const applyTranslation = () => {
            const language = languages.shift();
            this.translateService.use(language.toLowerCase()).subscribe(() => {}, () => applyTranslation());
        }
        applyTranslation();
    }

    checkCombosAvailability(menuCombos) {
        menuCombos = menuCombos.map(combo => {
            if (combo.pizzaSizes.length == 0) combo.unavailable = true;
            return combo;
        })
    }

    checkPizzaAvailability(menuPizzaSizes) {
        menuPizzaSizes = menuPizzaSizes.map(pizzaSize => {
            if (pizzaSize.flavors.length == 0) pizzaSize.unavailable = true;
            return pizzaSize;
        })
    }

    getPriceFromCombos(restaurantData){
        restaurantData.menu.combos = restaurantData.menu.combos.map(combo => {
            if(combo.price) {
                combo.fromPrice = combo.price; 
                return combo;
            }

            let minPrice = 0;
            combo.pizzaSizes.forEach(sizeID => {
                let size = restaurantData.menu.pizzas.sizes.find(foundSize => foundSize.id == sizeID);
                minPrice += this.calculatePizzaMinimalPrice(size, true);
            });

            let extraCategories = [];
            combo.extras.forEach(extraID => {
                let extra = combo.extras.find(foundExtra => foundExtra.id == extraID);
                if(extra) extraCategories.push(extra);
            });
            minPrice += this.calculateMinimalPriceFromExtraCategories(extraCategories);

            combo.fromPrice = minPrice;
            return combo;
        });
    }

    getPriceFromPizzas(restaurantData){
        restaurantData.menu.pizzas.sizes = restaurantData.menu.pizzas.sizes.map(pizzaSize => {
            if (!pizzaSize.unavailable) pizzaSize.fromPrice = this.calculatePizzaMinimalPrice(pizzaSize);
            return pizzaSize;
        })
    }

    getPriceFromGenericProduct(restaurantData) {
        restaurantData.menu.general = restaurantData.menu.general.map(item => {            
            if (!item.unavailable && item.products.length > 0) {
                item.products = item.products.map(product => {
                    if (!product.unavailable && product.price == 0) {
                        product.fromPrice = this.calculateFromPrice(product);
                    }
                    return product;
                });
            }
            return item;
        });
    }

    parseProductsShowFrom(restaurantData) {
        if(restaurantData.info.show_from){
            this.getPriceFromCombos(restaurantData);
            this.getPriceFromPizzas(restaurantData);
        }

        this.getPriceFromGenericProduct(restaurantData);        
    }

    calculatePizzaMinimalPrice(pizza, combo=false) {
        if((pizza.price === 0 && pizza.priceBehavior === 'incremental') || combo) {
            let prices = [];
            pizza.flavors.forEach(flavorCategory => {
                if('fractions' in flavorCategory) {
                    flavorCategory.fractions.forEach(fraction => {
                        if(fraction.price) prices.push(fraction.price);
                        fraction.exception.forEach(exception => {
                            if(exception.price) prices.push(exception.price);
                        });
                    });
                }
            });

            return prices.length == 0 ? 0 : Math.min.apply(Math, prices);
        }

        return pizza.price;
    }

	calculateFromPrice(item) {
        let extraCategories = this.restaurant.menu.extras.filter((filteredItem) => {
            return item.extras[0] == filteredItem.id;
        });

        const fromPrice = this.calculateMinimalPriceFromExtraCategories(extraCategories);
		return fromPrice;
	}

    calculateMinimalPriceFromExtraCategories(extraCategories) {
        let currentPrice = 0;
        let hasRequiredCategory = extraCategories.filter(category => {
            return category.required || (category.type != 1 && category.qty_min > 0)
        }).length;

        let minPrice = 0;
        extraCategories.forEach((category) => {
            if(category.required || (category.type != 1 && category.qty_min > 0) || !hasRequiredCategory) {
                minPrice = this.getMinValueFromCategory(category);
            }
            if((minPrice != 0 && minPrice < currentPrice) || (minPrice != 0 && currentPrice == 0)) currentPrice += minPrice;
            
        });
		return currentPrice;
    }

    getMinValueFromCategory(category) {
        if (this.extraCategoriesMinPrice[category.id]) 
            return this.extraCategoriesMinPrice[category.id];

        const minPrice = Math.min.apply(Math, category.options.filter(option => option.available).map(option => {
            return (option.price * (category.qty_min ? category.qty_min : 1)); 
        }));
        
        this.extraCategoriesMinPrice[category.id] = minPrice;
        return minPrice
    }

    setRestaurantTheme() {
        if (!this.restaurant.info.theme) return;

        this.themeService.setTheme(this.restaurant.info.theme);
    }

    getFeaturedItems(restaurantMenu) {
        let featuredItems = [];

        let menuItems = [...restaurantMenu.combos.filter(combo => !combo.unavailable), ...restaurantMenu.pizzas.sizes.filter(pizza => !pizza.unavailable)];

        let availableCategories = restaurantMenu.general.filter(general => !general.unavailable && !general.unavailableByTemplate);

        availableCategories.forEach(category => category.products.forEach(product => {
            if (product.unavailable) return;
            menuItems.push(product);
        }))        

        menuItems.forEach(menuItem => {
            if (!menuItem.featured) return;
            featuredItems.push(menuItem);
        })

        restaurantMenu['featuredItems'] = featuredItems;
    }

    filterMenu(str, menu): any {
        let searchResults = JSON.parse(JSON.stringify(menu));

        searchResults.general = searchResults.general.filter(category => {

            category.products = category.products.filter(product => {
                if (product.name.toLowerCase().includes(str.toLowerCase()) || (product.description && product.description.toLowerCase().includes(str.toLowerCase()))) {
                    return true;
                }
                return false;
            });
            return category.products.length > 0;
        })

        searchResults.pizzas.sizes = searchResults.pizzas.sizes.filter(size => {
            if (size.name.toLowerCase().includes(str.toLowerCase()) || (size.description && size.description.toLowerCase().includes(str.toLowerCase()))) {
                return true;
            }
            return false;
        });

        searchResults.combos = searchResults.combos.filter(combo => {
            if (combo.name.toLowerCase().includes(str.toLowerCase()) || (combo.description && combo.description.toLowerCase().includes(str.toLowerCase()))) {
                return true;
            }
            return false;
        });

        searchResults.featuredItems = searchResults.featuredItems.filter(featuredItem => {
            return (featuredItem.name.toLowerCase().includes(str.toLowerCase()) || (featuredItem.description && featuredItem.description.toLowerCase().includes(str.toLowerCase())))
        })

        return searchResults;
    }

    checkCoupon(code): Promise<any>{
        return this.apiService.post(`/restaurant/${this.restaurant.info.id}/coupom/check`, {code: code}).toPromise();
    }

    checkPaymentMethodOperation(method, grandTotal){
        let operationActive = false
        this.paymentMethodOperationInfo.paymentMethodOperation = 'Addition';
        this.paymentMethodOperationInfo.paymentMethodValueType = 'fix';
        this.paymentMethodOperationInfo.paymentMethodValue = 0;
        this.restaurant.info.paymentSettings.forEach(setting => {
            if(method == setting.paymentType || method == setting.paymentType){
                if(setting.isActive && setting.value > 0){
                    this.paymentMethodOperationInfo.operatedValue = setting.value;
                    if(setting.operation == 'discount'){
                        this.paymentMethodOperationInfo.paymentMethodOperation = 'Discount';
                        if(setting.valueType == "percent"){
                            this.paymentMethodOperationInfo.paymentMethodValueType = 'percent';
                            this.paymentMethodOperationInfo.paymentMethodValue = -(grandTotal*setting.value/100);
                        }else{
                            this.paymentMethodOperationInfo.paymentMethodValue = -(setting.value);
                        }
                    }else{
                        if(setting.valueType == "percent"){
                            this.paymentMethodOperationInfo.paymentMethodValueType = 'percent';
                            this.paymentMethodOperationInfo.paymentMethodValue = grandTotal*setting.value/100;
                        }else{
                            this.paymentMethodOperationInfo.paymentMethodValue = setting.value;
                        }
                    }
                    operationActive = true;
                }
            }
        });
        return operationActive;
    }

    getScheduleHourTemplateByID(templateID: number): Promise<HoursTemplate> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/restaurant/${this.restaurant.info.id}/hours-template/${templateID}/schedule`).subscribe(response => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    }

    updateCoordinates(address) {
        return new Promise((resolve) => {
            this.apiService.put(`/restaurant/${this.restaurant.info.id}/coordinates`, {address}).subscribe(response => {
                resolve(response);
            });
        })
    }

    initializeGoogleTagManager(googleTagManagerID){
        let googleTagManagerloadingTag = document.createElement("script");
        googleTagManagerloadingTag.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})(window,document,\'script\',\'dataLayer\', \'${googleTagManagerID}\');`
        document.body.appendChild(googleTagManagerloadingTag);
     
    }

    initializeGoogleAnalytics(googleAnalyticsID){
        
        let googleAnalyticsLoadingTag = document.createElement("script");
        googleAnalyticsLoadingTag.type = "application/javascript";
        googleAnalyticsLoadingTag.src = "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsID;
        document.body.appendChild(googleAnalyticsLoadingTag);

        let googleAnalyticsInitializationTag = document.createElement("script");
        googleAnalyticsInitializationTag.innerText = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date()); gtag(\'config\',\'${googleAnalyticsID}\');`
        document.body.appendChild(googleAnalyticsInitializationTag);

    }
}
