import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { ShowRecommendedProductsPage } from "./show-recommended-products.page";
import { CardPageModule } from "../card/card.module";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/shared/pipe/pipe.module";
import { MpImgPageModule } from '../image-tag/image-tag.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		CardPageModule,
		TranslateModule,
		PipesModule,
		MpImgPageModule,
	],
	declarations: [ShowRecommendedProductsPage],
	exports: [ShowRecommendedProductsPage],
})
export class ShowRecommendedProductsPageModule {}
