import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { NotFoundPageRoutingModule } from "./not-found-routing.module";

import { NotFoundPage } from "./not-found.page";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
        NotFoundPageRoutingModule,
        TranslateModule
	],
    declarations: [NotFoundPage],
    exports: [NotFoundPage]
})
export class NotFoundPageModule {}
