import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root'
})

export class ThemeService {

	private defaults = {
		"mainColor": "#EF4342",
		"mainDark": "#E22F28",
		"mainClear": "#FFF1F1",
		"gradientClear": "#E94358",
		"gradientDark": "#C43036"
	}

	constructor(
		@Inject(DOCUMENT) private document: Document
	) { }

	setTheme(theme) {
		const cssText = this.CSSTextGenerator(theme);
		this.setGlobalCSS(cssText);
	}

	private setGlobalCSS(css: string) {
		this.document.documentElement.style.cssText = css;
	}

	private CSSTextGenerator(colors) {
		colors = { ...this.defaults, ...colors };
		colors = this.createTintsAndShades(colors);

		const {
			mainColor,
			mainDark,
			mainClear,
			gradientClear,
			gradientDark,
		} = colors;

		return `
			--ion-background-color: #FFFFFF;
			--ion-text-color: #000000;
			--ion-color-main: ${mainColor.hexCode};
			--ion-color-main-darker: ${mainDark.hexCode};
			--ion-color-main-darker-opacity: ${mainDark.hexCode};
			--ion-color-main-lighter: #FFF1F1;
			--ion-color-main-gradient-darker: ${gradientDark.hexCode};
			--ion-color-main-gradient-lighter: ${gradientClear.hexCode};
			--ion-color-main-gradient: linear-gradient(to right, ${gradientClear.hexCode}, ${gradientDark.hexCode});
			--ion-color-primary: ${mainColor.hexCode};
			--ion-color-primary-rgb: ${mainColor.rgb};
			--ion-color-primary-contrast: ${mainColor.contrastHexcode};
			--ion-color-primary-contrast-rgb: ${mainColor.contrastRGB};
			--ion-color-primary-shade: ${mainColor.shade};
			--ion-color-primary-tint: ${mainColor.tint};
			--ion-color-secondary: ${mainColor.hexCode};
			--ion-color-secondary-rgb: ${mainColor.rgb};
			--ion-color-secondary-contrast: ${mainColor.contrastHexcode};
			--ion-color-secondary-contrast-rgb: ${mainColor.contrastRGB};
			--ion-color-secondary-shade: ${mainColor.shade};
			--ion-color-secondary-tint: ${mainColor.tint};			
			--ion-color-tertiary:  ${mainColor.hexCode};
			--ion-color-tertiary-rgb: ${mainColor.rgb};
			--ion-color-tertiary-contrast: ${mainColor.contrastHexcode};
			--ion-color-tertiary-contrast-rgb: ${mainColor.contrastRGB};
			--ion-color-tertiary-shade: ${mainColor.shade};
			--ion-color-tertiary-tint: ${mainColor.tint};			
			--ion-color-success: ${mainColor.hexCode};
			--ion-color-success-rgb: ${mainColor.rgb};
			--ion-color-success-contrast: ${mainColor.contrastHexcode};
			--ion-color-success-contrast-rgb: ${mainColor.contrastRGB};
			--ion-color-success-shade: ${mainColor.shade};
			--ion-color-success-tint: ${mainColor.tint};			
			--ion-color-warning: ${mainColor.hexCode};
			--ion-color-warning-rgb: ${mainColor.rgb};
			--ion-color-warning-contrast: ${mainColor.contrastHexcode};
			--ion-color-warning-contrast-rgb: ${mainColor.contrastRGB};
			--ion-color-warning-shade: ${mainColor.shade};
			--ion-color-warning-tint: ${mainColor.tint};			
			--ion-color-danger: ${mainColor.hexCode};
			--ion-color-danger-rgb: ${mainColor.rgb};
			--ion-color-danger-contrast: ${mainColor.contrastHexcode};
			--ion-color-danger-contrast-rgb: ${mainColor.contrastRGB};
			--ion-color-danger-shade: ${mainColor.shade};
			--ion-color-danger-tint: ${mainColor.tint};			
			--ion-color-dark: ${mainDark.hexCode};
			--ion-color-dark-rgb: ${mainDark.rgb};
			--ion-color-dark-contrast: ${mainDark.contrastHexcode};
			--ion-color-dark-contrast-rgb: ${mainDark.contrastRGB};
			--ion-color-dark-shade: ${mainDark.shade};
			--ion-color-dark-tint: ${mainDark.tint};			
			--ion-color-medium: ${mainColor.hexCode};
			--ion-color-medium-rgb: ${mainColor.rgb};
			--ion-color-medium-contrast: ${mainColor.contrastHexcode};
			--ion-color-medium-contrast-rgb: ${mainColor.contrastRGB};
			--ion-color-medium-shade: ${mainColor.shade};
			--ion-color-medium-tint: ${mainColor.tint};
			--ion-color-light: #F4F5F8;
			--ion-color-light-rgb: 244, 245, 248;
			--ion-color-light-contrast: #000000;
			--ion-color-light-contrast-rgb: 0, 0, 0;
			--ion-color-light-shade: #D7D8DA;
			--ion-color-light-tint: #F5F6F9;
			--ion-color-clear: ${mainClear.hexCode};
			--ion-color-clear-rgb: ${mainClear.rgb};
			--ion-color-clear-contrast: ${mainClear.contrastHexcode};
			--ion-color-clear-contrast-rgb: ${mainClear.contrastRGB};
			--ion-color-clear-shade: ${mainClear.shade};
			--ion-color-clear-tint: ${mainClear.tint};
			`;
	}

	private pad(number: string, length: number) {
		let str = '' + number;

		while (str.length < length) {
			str = '0' + str;
		}

		return str;
	}

	private hexToRGB(colorValue: string) {
		return `${parseInt(colorValue.substr(0, 2), 16)}, ${parseInt(colorValue.substr(2, 2), 16)}, ${parseInt(colorValue.substr(4, 2), 16)}`
	}

	private intToHex(rgbint: number) {
		return this.pad(Math.min(Math.max(Math.round(rgbint), 0), 255).toString(16), 2);
	}

	private rgbToHex(rgb) {
		return this.intToHex(rgb.red) + this.intToHex(rgb.green) + this.intToHex(rgb.blue);
	}

	private rgbShade(rgb, i) {
		return {
			red: rgb.red * (1 - 0.1 * i),
			green: rgb.green * (1 - 0.1 * i),
			blue: rgb.blue * (1 - 0.1 * i)
		}
	}

	private rgbTint(rgb, i) {
		return {
			red: rgb.red + (255 - rgb.red) * i * 0.1,
			green: rgb.green + (255 - rgb.green) * i * 0.1,
			blue: rgb.blue + (255 - rgb.blue) * i * 0.1
		}
	}

	private calculate(colorValue, shadeOrTint, ratio) {
		let hexColor = this.hexToRGB(colorValue);

		let rgbObject = {
			"red": hexColor.split(',')[0],
			"green": hexColor.split(',')[1],
			"blue": hexColor.split(',')[2]
		}

		return this.rgbToHex(shadeOrTint(rgbObject, ratio));
	}

	private calculateShades(colorValue: string) {
		return this.calculate(colorValue, this.rgbShade, 1.2);
	}


	private calculateTints(colorValue: string) {
		return this.calculate(colorValue, this.rgbTint, 1);
	}

	private invertColor(hex, bw) {
		if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}

		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}

		if (hex.length !== 6) {
			console.error('Invalid HEX color.');
			return;
		}

		let r = parseInt(hex.slice(0, 2), 16),
			g = parseInt(hex.slice(2, 4), 16),
			b = parseInt(hex.slice(4, 6), 16);

		if (bw) {
			return (r * 0.299 + g * 0.587 + b * 0.114) > 186
				? '#000000'
				: '#FFFFFF';
		}

		let red = (255 - r).toString(16);
		let green = (255 - g).toString(16);
		let blue = (255 - b).toString(16);

		return "#" + this.padZero(red) + this.padZero(green) + this.padZero(blue);
	}

	private padZero(str: string, len = 2) {
		let zeros = new Array(len).join('0');
		return (zeros + str).slice(-len);
	}

	private createTintsAndShades(theme) {
		Object.entries(theme).map(themeProperty => {
			let colorObject = {};

			let colorName = themeProperty[0];
			let colorHexcode: any = themeProperty[1];

			if (['id', 'restaurantID', 'active'].includes(colorName)) return;

			colorObject['hexCode'] = colorHexcode;

			let parsedColorHexcode = colorHexcode.substr(1);

			colorObject['rgb'] = this.hexToRGB(parsedColorHexcode);
			colorObject['contrastHexcode'] = this.invertColor(parsedColorHexcode, true);
			colorObject['contrastRGB'] = this.hexToRGB(colorObject['contrastHexcode'].substr(1));
			colorObject['shade'] = this.calculateShades(parsedColorHexcode);
			colorObject['tint'] = this.calculateTints(parsedColorHexcode);

			theme[colorName] = colorObject;
		})

		return theme;
	}
}