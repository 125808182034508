import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-successfully-order-dialog',
  templateUrl: './successfully-order-dialog.page.html',
  styleUrls: ['./successfully-order-dialog.page.scss'],
})
export class SuccessfullyOrderDialogPage implements OnInit {

  header: string
  message: string

  constructor(public modalCtrl: ModalController,) { }

  ngOnInit() {
  }

  async dismiss() {
    await this.modalCtrl.dismiss({
      'dismissed': true,
    });
  }
}
